import React from 'react';
import { FormTextInput, FormRadioAccordion } from 'components/Business/Form';
import { COUPONMAXDISTRIBUTIONS_VALUES } from 'constants/index';
import { objectToRadioList } from 'utils';
import { useValidationDuplicateCoupon } from 'hooks/useValidationDuplicateCoupon';
import { CouponDetailCommonChildren1 } from '../CouponDetailCommonChildren1';
import { CouponDetailCommonChildren2 } from '../CouponDetailCommonChildren2';
import { CouponDetailCommonChildren3 } from '../CouponDetailCommonChildren3';
import { CouponDetailCommonChildren4 } from '../CouponDetailCommonChildren4';

type Props = {
  k: number;
};

export const CouponDetailCouponCode: React.FC<Props> = ({ k }) => {
  const validationDuplicateCoupon = useValidationDuplicateCoupon();

  return (
    <>
      <CouponDetailCommonChildren1 k={k} />

      <FormTextInput
        type="alphaNumeric"
        name={`campaignDetail[${k}].couponCode`}
        label="クーポンコード"
        placeholder="クーポンコードを入力"
        help="20桁以内の半角英数字で入力。過去発行したコードは指定できません。"
        isRequired
        hasUndecidedButton
        onBlur={(value, name) => {
          validationDuplicateCoupon(name, value);
        }}
      />

      <FormRadioAccordion
        name={`campaignDetail[${k}].couponMaxDistributions`}
        label="上限配布数"
        isRequired
        hasUndecidedButton
        list={objectToRadioList(COUPONMAXDISTRIBUTIONS_VALUES)}
        contents={{
          '1': null,
          '2': (
            <FormTextInput
              type="number"
              name={`campaignDetail[${k}].couponMaxNumber`}
              label="最大件数"
              placeholder="1000"
              unit="件"
              isRequired
              hasUndecidedButton
            />
          ),
        }}
      />

      <CouponDetailCommonChildren2 k={k} />

      <CouponDetailCommonChildren3 k={k} />

      <CouponDetailCommonChildren4 k={k} />
    </>
  );
};
