import * as yup from 'yup';
import { isNumber, isValidDate } from 'utils';
import { useTranslation } from 'react-i18next';
import { UNDECIDED_VALUE } from 'constants/index';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    sampleSettingsDetail: yup.object().shape({
      sampleType: yup.string().required(t('ERR-REQUIRED')),

      presentName: yup
        .string()
        .required(t('ERR-REQUIRED'))
        .max(100, t('ERR-LENGTH-MAX', { length: '100' })),

      startDateYear: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
            return isValidDate({ year: value, month: this.parent.startDateMonth, date: this.parent.startDateDay });
          });
      }),

      startDateMonth: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
            return isValidDate({ year: this.parent.startDateYear, month: value, date: this.parent.startDateDay });
          });
      }),

      startDateDay: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
            return isValidDate({ year: this.parent.startDateYear, month: this.parent.startDateMonth, date: value });
          });
      }),

      endDateYear: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
            return isValidDate({ year: value, month: this.parent.endDateMonth, date: this.parent.endDateDay });
          });
      }),

      endDateMonth: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
            return isValidDate({ year: this.parent.endDateYear, month: value, date: this.parent.endDateDay });
          });
      }),

      endDateDay: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
            return isValidDate({ year: this.parent.endDateYear, month: this.parent.endDateMonth, date: value });
          });
      }),

      applicationFlow: yup.string().required(t('ERR-REQUIRED')),

      measuresType: yup.string().required(t('ERR-REQUIRED')),

      maxWinners: yup.string().when('measuresType', {
        is: (value: string) => value === '2',
        then: yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          }),
      }),

      mailPermission: yup.string(),

      notices: yup.string(),

      deliveryHiddenSupport: yup.string(),

      couponAmount: yup.string().test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      }),

      couponId: yup.string(),

      questionnaire: yup.string(),

      couponBanner: yup.string(),

      brandBanner: yup.string(),

      afterFollowHtml: yup.string(),

      afterFollowText: yup.string(),

      noProduction: yup.string(),

      imjManagementNumber: yup.string(),
    }),
  });
};
