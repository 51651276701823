import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonContainer } from 'components/Atoms/Layout';
import { Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Modal } from 'components/Organisms/Modal';

type Props = {
  isShow: boolean;
};

export const LoginSessionErrorModal: React.FC<Props> = ({ isShow }) => {
  const history = useHistory();
  const { state } = useLocation();

  const handleBack = React.useCallback(() => {
    history.push('/CMN002', state);
  }, [history, state]);

  React.useEffect(() => {
    console.log(state);
  }, [state]);

  return React.useMemo(
    () => (
      <Modal type="warn" title="ログイン認証画面の有効期限が切れました" isShow={isShow} onCancel={handleBack}>
        <Text align="center">ログインページへ戻り、再度ログイン操作を行ってください。</Text>
        <ButtonContainer>
          <Button type="secondary" icon="arrow-left" iconPosition="left" text="ログインページへ戻る" onClick={handleBack} />
        </ButtonContainer>
      </Modal>
    ),
    [handleBack, isShow],
  );
};
