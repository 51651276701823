import React from 'react';
import { ButtonContainer } from 'components/Atoms/Layout';
import { Button } from 'components/Atoms/Button';
import { Text } from 'components/Atoms/Typography';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';

const ModalTypes = {
  warn: 'warn',
  success: 'success',
} as const;
export type ModalTypesProps = typeof ModalTypes[keyof typeof ModalTypes];

type Props = {
  type?: ModalTypesProps;
  title?: string;
  isShow: boolean;
  hasCancel?: boolean;
  hasSubmit?: boolean;
  cancelText?: string;
  submitText?: string;
  disabledSubmit?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
};

export const Modal: React.FC<Props> = ({
  type,
  title,
  isShow,
  hasCancel = false,
  hasSubmit = false,
  cancelText = '閉じる',
  submitText = 'OK',
  disabledSubmit = false,
  onCancel = () => {},
  onSubmit = () => {},
  children,
}) => {
  return (
    <>
      {isShow && (
        <div className={style['modal']}>
          <div className={`${style['modal-inner']}`}>
            <div className={`${style['modal-content']}`}>
              {type === 'warn' && <SvgIcon type="warn" width={47} className={style['modal-icon']} />}
              {type === 'success' && <SvgIcon type="check" fill="#00d299" width={51} className={style['modal-icon']} />}

              {title && (
                <Text size={28} bold align="center" className={style['modal-title']}>
                  {title}
                </Text>
              )}

              <div className={style['modal-content-inner']}>{children}</div>

              {(hasCancel || hasSubmit) && (
                <ButtonContainer>
                  {hasCancel && <Button type="cancel" text={cancelText} onClick={onCancel} />}
                  {hasSubmit && <Button type="primary" text={submitText} onClick={onSubmit} disabled={disabledSubmit} />}
                </ButtonContainer>
              )}
            </div>

            <button onClick={onCancel} className={style['modal-close']}>
              <SvgIcon type="cross" fill="#fff" width={30} />
            </button>
          </div>

          <div className={style['modal-overlay']} onClick={onCancel}></div>
        </div>
      )}
    </>
  );
};
