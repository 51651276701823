import React from 'react';
import style from './style.module.scss';

type Props = {
  className?: string;
};

export const NestBox: React.FC<Props> = ({ children, className = '' }) => (
  <div className={`${style['layout-nestbox-wrapper']} ${className}`}>
    <div className={`${style['layout-nestbox']} layout-nestbox`}>{children}</div>
    <div className={style['layout-nestbox-line']}></div>
  </div>
);
