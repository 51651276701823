import React from 'react';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { FlexRow, FlexCol } from 'components/Atoms/Layout';
import { Text, MultiLineText } from 'components/Atoms/Typography';
import { UNDECIDED_VALUE, UNDECIDED_LABEL, EMPTY_LABEL } from 'constants/index';
import style from './style.module.scss';

dayjs.locale(ja);

const FormType = {
  text: 'text',
  datetime: 'datetime',
  date: 'date',
  radio: 'radio',
  checkbox: 'checkbox',
  arrayNum: 'arrayNum',
  array: 'array',
} as const;
type FormTypeProps = typeof FormType[keyof typeof FormType];

export type DataTableProps = {
  condition?: boolean;
  type: FormTypeProps;
  label?: string;
  value: any;
  unit?: string;
  before?: string;
  after?: string;
  nextAfter?: string;
  master?: { [key: string]: string } | string[];
  content?: (key: number) => any;
};

type Props = {
  data: DataTableProps[];
};

type DataTableRowProps = {
  elem: DataTableProps;
};

export const DataTable: React.FC<Props> = ({ data }) => {
  const outputFromResponse = React.useCallback(({ type, value, master, unit = '' }) => {
    const switchOutputType = () => {
      switch (type) {
        case 'text':
          if (!value) return EMPTY_LABEL;
          if (value === UNDECIDED_VALUE) return UNDECIDED_LABEL;
          return value;

        case 'datetime':
          if (!value || !value.year || !value.month || !value.date || !value.hour || !value.minute) return EMPTY_LABEL;
          const dateTimeValuesArray = Object.values(value);
          if (dateTimeValuesArray.includes('')) return EMPTY_LABEL;
          if (dateTimeValuesArray.includes(UNDECIDED_VALUE)) return UNDECIDED_LABEL;
          const dateTimeObject = dayjs()
            .year(Number(value.year))
            .month(Number(value.month) - 1)
            .date(Number(value.date));
          return `${value.year}/${value.month}/${value.date}（${dateTimeObject.format('ddd')}）${value.hour}:${value.minute}`;

        case 'date':
          if (!value || !value.year || !value.month || !value.date) return EMPTY_LABEL;
          const dateValuesArray = Object.values(value);
          if (dateValuesArray.includes('')) return EMPTY_LABEL;
          if (dateValuesArray.includes(UNDECIDED_VALUE)) return UNDECIDED_LABEL;
          const dateObject = dayjs()
            .year(Number(value.year))
            .month(Number(value.month) - 1)
            .date(Number(value.date));
          return `${value.year}/${value.month}/${value.date}（${dateObject.format('ddd')}）`;

        case 'radio':
          if (!master || !value) return EMPTY_LABEL;
          if (value === UNDECIDED_VALUE) return UNDECIDED_LABEL;
          return master[value];

        case 'checkbox':
          if (!master || !value) return EMPTY_LABEL;
          if (value.includes(UNDECIDED_VALUE)) return UNDECIDED_LABEL;
          const labels = master.filter((_val: any, index: number) => {
            return value[index] === '1';
          });
          if (!labels.length) return EMPTY_LABEL;
          const returnValue = labels.join('\n');
          return <MultiLineText value={returnValue} />;

        case 'arrayNum':
          if (!value) return EMPTY_LABEL;
          if (value === UNDECIDED_VALUE) return UNDECIDED_LABEL;
          return String(value.length);
      }
    };
    let resultValue = switchOutputType();
    if (unit && resultValue !== EMPTY_LABEL && resultValue !== UNDECIDED_LABEL) resultValue = `${resultValue} ${unit}`;
    return resultValue;
  }, []);

  const DataTableRow: React.FC<DataTableRowProps> = ({ elem }) => (
    <FlexRow className={`${style['dataTable-row']}`} gap="small">
      <FlexCol col={4} className={`${style['dataTable-col']} ${style['dataTable-col-heading']}`}>
        <Text ml={10}>{elem.label}</Text>
      </FlexCol>
      <FlexCol col={8} className={style['dataTable-col']}>
        <Text>
          {elem.before && (
            <>
              {elem.before}
              <br />
            </>
          )}
          {outputFromResponse({
            type: elem.type,
            value: elem.value,
            ...(elem.master && { master: elem.master }),
            ...(elem.unit && { unit: elem.unit }),
          })}
          {elem.after && (
            <>
              <br />
              {elem.after}
            </>
          )}
          {elem.nextAfter && (
            <>
              <br />
              {elem.nextAfter}
            </>
          )}
        </Text>
      </FlexCol>
    </FlexRow>
  );

  return React.useMemo(
    () => (
      <div>
        {data.map((elem, index) => {
          return (
            <React.Fragment key={index}>
              {(elem.condition === undefined ? true : elem.condition) && (
                <>
                  {elem.type !== 'array' && <DataTableRow elem={elem} />}
                  {elem.type === 'array' && (
                    <>
                      {typeof elem.value === 'object' &&
                        elem.value.map((_dElem: any, dIndex: number) => {
                          if (!elem.content) return false;
                          const arrayList = elem.content(dIndex);
                          return arrayList.map((dChildElem: any, dChildIndex: number) => {
                            return <DataTableRow elem={dChildElem} key={dChildIndex} />;
                          });
                        })}
                      {typeof elem.value === 'string' && elem.label && (
                        <DataTableRow
                          elem={{
                            type: 'text',
                            label: elem.label,
                            value: elem.value,
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </React.Fragment>
          );
        })}
      </div>
    ),
    [data],
  );
};
