import React from 'react';
import { ReactComponent as IconTriangleDown } from 'images/icon_triangle_down.svg';
import { ReactComponent as IconTriangleRight } from 'images/icon_triangle_right.svg';
import { ReactComponent as IconTriangleLeft } from 'images/icon_triangle_left.svg';
import { ReactComponent as IconTriangleRightDown } from 'images/icon_triangle_rightdown.svg';
import { ReactComponent as IconCross } from 'images/icon_cross.svg';
import { ReactComponent as IconCrossBold } from 'images/icon_cross_bold.svg';
import { ReactComponent as IconArrowRight } from 'images/icon_arrow_right.svg';
import { ReactComponent as IconArrowLeft } from 'images/icon_arrow_left.svg';
import { ReactComponent as IconArrowDoubleRight } from 'images/icon_arrow_double_right.svg';
import { ReactComponent as IconArrowDoubleLeft } from 'images/icon_arrow_double_left.svg';
import { ReactComponent as IconArrowTop } from 'images/icon_arrow_top.svg';
import { ReactComponent as IconCheck } from 'images/icon_checked.svg';
import { ReactComponent as IconPlus } from 'images/icon_plus.svg';
import { ReactComponent as IconSort } from 'images/icon_sort.svg';
import { ReactComponent as IconNote } from 'images/icon_note.svg';
import { ReactComponent as IconClipboard } from 'images/icon_clipboard.svg';
import { ReactComponent as IconWindow } from 'images/icon_window.svg';
import IconLoading from 'images/icon_loading.png';
import IconLoadingSmall from 'images/icon_loading_small.png';
import IconWarn from 'images/icon_warn.png';
import IconSearch from 'images/icon_search.png';
import IconSearchWhite from 'images/icon_search_white.png';
import IconDustbox from 'images/icon_dustbox.png';
import IconLipstick from 'images/icon_lipstick.png';
import IconPalette from 'images/icon_palette.png';
import IconEye from 'images/icon_eye.png';
import style from './style.module.scss';

const SvgIconType = {
  'triangle-right': 'triangle-right',
  'triangle-left': 'triangle-left',
  'triangle-down': 'triangle-down',
  'triangle-rightdown': 'triangle-rightdown',
  cross: 'cross',
  'cross-bold': 'cross-bold',
  'arrow-right': 'arrow-right',
  'arrow-left': 'arrow-left',
  'arrow-double-right': 'arrow-double-right',
  'arrow-double-left': 'arrow-double-left',
  'arrow-top': 'arrow-top',
  check: 'check',
  plus: 'plus',
  sort: 'sort',
  note: 'note',
  clipboard: 'clipboard',
  window: 'window',
  loading: 'loading',
  'loading-small': 'loading-small',
  warn: 'warn',
  search: 'search',
  'search-white': 'search-white',
  dustbox: 'dustbox',
  lipstick: 'lipstick',
  palette: 'palette',
  eye: 'eye',
} as const;
export type SvgIconTypeProps = typeof SvgIconType[keyof typeof SvgIconType];

type Props = {
  type: SvgIconTypeProps;
  fill?: string;
  width: number;
  className?: string;
};

export const SvgIcon: React.FC<Props> = ({ type, fill, width, className = '' }) =>
  React.useMemo(
    () => (
      <>
        {type === 'triangle-right' && (
          <span className={`${style['svgicon']} ${style['svgicon-triangle-right']} ${className}`}>
            <IconTriangleRight fill={fill} width={width} />
          </span>
        )}

        {type === 'triangle-left' && (
          <span className={`${style['svgicon']} ${style['svgicon-triangle-left']} ${className}`}>
            <IconTriangleLeft fill={fill} width={width} />
          </span>
        )}

        {type === 'triangle-down' && (
          <span className={`${style['svgicon']} ${style['svgicon-triangle-down']} ${className}`}>
            <IconTriangleDown fill={fill} width={width} />
          </span>
        )}

        {type === 'triangle-rightdown' && (
          <span className={`${style['svgicon']} ${style['svgicon-triangle-rightdown']} ${className}`}>
            <IconTriangleRightDown fill={fill} width={width} />
          </span>
        )}

        {type === 'cross' && (
          <span className={`${style['svgicon']} ${style['svgicon-cross']} ${className}`}>
            <IconCross fill={fill} width={width} />
          </span>
        )}

        {type === 'cross-bold' && (
          <span className={`${style['svgicon']} ${style['svgicon-cross-bold']} ${className}`}>
            <IconCrossBold fill={fill} width={width} />
          </span>
        )}

        {type === 'arrow-right' && (
          <span className={`${style['svgicon']} ${style['svgicon-arrow-right']} ${className}`}>
            <IconArrowRight fill={fill} width={width} />
          </span>
        )}

        {type === 'arrow-left' && (
          <span className={`${style['svgicon']} ${style['svgicon-arrow-left']} ${className}`}>
            <IconArrowLeft fill={fill} width={width} />
          </span>
        )}

        {type === 'arrow-double-right' && (
          <span className={`${style['svgicon']} ${style['svgicon-arrow-double-right']} ${className}`}>
            <IconArrowDoubleRight fill={fill} width={width} />
          </span>
        )}

        {type === 'arrow-double-left' && (
          <span className={`${style['svgicon']} ${style['svgicon-arrow-double-left']} ${className}`}>
            <IconArrowDoubleLeft fill={fill} width={width} />
          </span>
        )}

        {type === 'arrow-top' && (
          <span className={`${style['svgicon']} ${style['svgicon-arrow-top']} ${className}`}>
            <IconArrowTop fill={fill} width={width} />
          </span>
        )}

        {type === 'check' && (
          <span className={`${style['svgicon']} ${style['svgicon-check']} ${className}`}>
            <IconCheck fill={fill} width={width} />
          </span>
        )}

        {type === 'plus' && (
          <span className={`${style['svgicon']} ${style['svgicon-plus']} ${className}`}>
            <IconPlus fill={fill} width={width} />
          </span>
        )}

        {type === 'sort' && (
          <span className={`${style['svgicon']} ${style['svgicon-sort']} ${className}`}>
            <IconSort fill={fill} width={width} />
          </span>
        )}

        {type === 'note' && (
          <span className={`${style['svgicon']} ${style['svgicon-note']} ${className}`}>
            <IconNote fill={fill} width={width} />
          </span>
        )}

        {type === 'clipboard' && (
          <span className={`${style['svgicon']} ${style['svgicon-clipboard']} ${className}`}>
            <IconClipboard fill={fill} width={width} />
          </span>
        )}

        {type === 'window' && (
          <span className={`${style['svgicon']} ${style['svgicon-window']} ${className}`}>
            <IconWindow fill={fill} width={width} />
          </span>
        )}

        {/* 以下はSVGが提供されなかったためPNG... */}

        {type === 'loading' && (
          <span className={`${style['svgicon']} ${style['svgicon-loading']} ${className}`}>
            <img src={IconLoading} width={`${width}px`} alt="Loading..." />
          </span>
        )}

        {type === 'loading-small' && (
          <span className={`${style['svgicon']} ${style['svgicon-loading-small']} ${className}`}>
            <img src={IconLoadingSmall} width={`${width}px`} alt="Loading..." />
          </span>
        )}

        {type === 'warn' && (
          <span className={`${style['svgicon']} ${style['svgicon-warn']} ${className}`}>
            <img src={IconWarn} width={`${width}px`} alt="" />
          </span>
        )}

        {type === 'search' && (
          <span className={`${style['svgicon']} ${style['svgicon-search']} ${className}`}>
            <img src={IconSearch} width={`${width}px`} alt="検索" />
          </span>
        )}

        {type === 'search-white' && (
          <span className={`${style['svgicon']} ${style['svgicon-search-white']} ${className}`}>
            <img src={IconSearchWhite} width={`${width}px`} alt="検索" />
          </span>
        )}

        {type === 'dustbox' && (
          <span className={`${style['svgicon']} ${style['svgicon-dustbox']} ${className}`}>
            <img src={IconDustbox} width={`${width}px`} alt="" />
          </span>
        )}

        {type === 'lipstick' && (
          <span className={`${style['svgicon']} ${style['svgicon-lipstick']} ${className}`}>
            <img src={IconLipstick} width={`${width}px`} alt="" />
          </span>
        )}

        {type === 'palette' && (
          <span className={`${style['svgicon']} ${style['svgicon-palette']} ${className}`}>
            <img src={IconPalette} width={`${width}px`} alt="" />
          </span>
        )}

        {type === 'eye' && (
          <span className={`${style['svgicon']} ${style['svgicon-eye']} ${className}`}>
            <img src={IconEye} width={`${width}px`} alt="" />
          </span>
        )}
      </>
    ),
    [type, fill, width, className],
  );
