import React from 'react';
import style from './style.module.scss';

const Size = {
  large: 'large',
  medium: 'medium',
  small: 'small',
} as const;
export type FormGridSizeProps = typeof Size[keyof typeof Size];

type Props = {
  className?: string;
  size?: FormGridSizeProps;
};

export const FormGrid: React.FC<Props> = ({ children, className = '', size = 'large' }) => (
  <div className={`${style['layout-formGrid']} is-${size} ${className}`}>{children}</div>
);
