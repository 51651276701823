import React from 'react';
import { FormTextInput, FormDate, FormRadioAccordion } from 'components/Business/Form';
import { COUPONEXPIRATIONDATE_VALUES } from 'constants/index';
import { objectToRadioList } from 'utils';

type Props = {
  k: number;
};

export const CouponDetailCommonChildren2: React.FC<Props> = ({ k }) => {
  return (
    <>
      <FormRadioAccordion
        name={`campaignDetail[${k}].couponExpirationDate`}
        label="有効期限"
        isRequired
        hasUndecidedButton
        list={objectToRadioList(COUPONEXPIRATIONDATE_VALUES)}
        contents={{
          '1': (
            <FormTextInput
              type="number"
              name={`campaignDetail[${k}].couponAfterGrantDate`}
              label="クーポン付与後○日間"
              placeholder="45"
              unit="日間"
              note="※30日以上で設定してください。"
              isRequired
              hasUndecidedButton
            />
          ),
          '2': (
            <FormDate
              names={{
                year: `campaignDetail[${k}].couponSpecifyDateYear`,
                month: `campaignDetail[${k}].couponSpecifyDateMonth`,
                date: `campaignDetail[${k}].couponSpecifyDateDay`,
              }}
              label="日付指定"
              note="※キャンペーン終了日の翌日以降の日付を指定してください。"
              isRequired
              hasUndecidedButton
            />
          ),
        }}
      />
    </>
  );
};
