type Props = {
  value?: string;
  min?: number;
  max?: number;
};

export const isRange = ({ value, min, max }: Props) => {
  if (!value) return false;
  const valueAsNumber = Number(value);
  if (!Number.isInteger(valueAsNumber)) return false;
  if (min !== undefined && valueAsNumber < min) return false;
  if (max !== undefined && valueAsNumber > max) return false;
  return true;
};
