export const trimArrayEnd = (array: any[]): any[] => {
  if (!array.length) return array;
  const result = JSON.parse(JSON.stringify(array));
  const trim = () => {
    if (!result[result.length - 1]) {
      result.pop();
      trim();
    }
  };
  trim();
  return result;
};
