import React from 'react';
import css from './style.module.scss';

const Colors = {
  green: 'green',
  red: 'red',
  grey: 'grey',
  blue: 'blue',
  black: 'black',
} as const;
export type ColorsProps = typeof Colors[keyof typeof Colors];

type Props = {
  color: ColorsProps;
  className?: string;
  style?: any;
};

export const Tag: React.FC<Props> = ({ children, color, className = '', style }) =>
  React.useMemo(
    () => (
      <span className={`${css['tag']} ${css['tag-' + color]} ${className}`} {...(style && { style: style })}>
        {children}
      </span>
    ),
    [children, color, className, style],
  );
