import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'components/Atoms/Button';
import type { MeasureParamsProps, DesignDetailProps } from 'types';

type Props = {
  formData: DesignDetailProps;
};

export const BackButton: React.FC<Props> = ({ formData }) => {
  const { measureId } = useParams<MeasureParamsProps>();
  const history = useHistory();

  const handleBack = React.useCallback(() => {
    history.push(`/AID002/${measureId}`, formData);
  }, [history, measureId, formData]);

  return (
    <>
      <Button type="secondary" icon="arrow-left" iconPosition="left" onClick={handleBack} text="入力画面へ戻る" />
    </>
  );
};
