import React from 'react';
import style from './style.module.scss';

type Props = {
  id?: string;
  hasBg?: boolean;
};

export const Section: React.FC<Props> = ({ children, id, hasBg = false }) => (
  <section {...(id && { id: id })} className={`${style['layout-section']} ${hasBg ? 'has-bg' : ''}`}>
    {children}
  </section>
);
