import React from 'react';
import { FormTextInput } from 'components/Business/Form';

type Props = {
  k: number;
};

export const CouponDetailCommonChildren3: React.FC<Props> = ({ k }) => {
  return (
    <>
      <FormTextInput type="number" name={`campaignDetail[${k}].couponAmount`} label="クーポン金額" placeholder="1000" unit="円" isRequired hasUndecidedButton />

      <FormTextInput
        type="number"
        name={`campaignDetail[${k}].couponConditionsUse`}
        label="利用条件（最低購入金額/税込）"
        placeholder="1000"
        unit="円"
        isRequired
        hasUndecidedButton
      />
    </>
  );
};
