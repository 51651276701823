import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Header } from 'components/Business/Header';
import { TextLink } from 'components/Business/TextLink';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { URL_AID_DESIGN_CONFIGURATION_DIRECTORY, URL_AID_DESIGN_CONFIGURATION_PARAMS } from 'constants/index';
import type { MeasureParamsProps, DesignDetailProps } from 'types';

export const AID004: React.FC = () => {
  const history = useHistory();
  const { measureId } = useParams<MeasureParamsProps>();
  const { state } = useLocation<DesignDetailProps>();
  const [formData, setFormData] = React.useState<DesignDetailProps>();

  React.useEffect(() => {
    console.log(state);
    // stateがない場合はフォームトップにreplace
    if (!state) history.replace(`/AID002/${measureId}`);
    // routerで受け取ったstateをページのstateとして保存
    setFormData(state);
    // routerで受け取ったstateをを破棄(reload対応)
    history.replace(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Container>
          <Breadcrumb
            list={[
              {
                label: 'TOP',
                url: '/CMN001',
              },
              {
                label: 'AI Designer',
                url: '/AID001',
              },
              {
                label: '制作要件・素材指定',
              },
              {
                label: '入力完了',
              },
            ]}
          />
          <Section>
            <H1>入力完了</H1>

            <Text align="center" size={18}>
              施策名:{formData?.measureName}
            </Text>

            <Text align="center">
              入力情報がAIに連携されました。
              <br />
              デザイン推論には一定の時間が必要となります。
              <br />
              各画面（AI Designer、デザイン生成概要）内にあるデザイン推論のステータスが「完了」に変わった後、
              <br />
              「デザイン生成概要」から推論結果の詳細をご確認いただけます。
            </Text>

            <Text align="center">
              <TextLink
                icon="arrow-right"
                iconPosition="before"
                to={`${URL_AID_DESIGN_CONFIGURATION_DIRECTORY}${URL_AID_DESIGN_CONFIGURATION_PARAMS}`}
                isExternal
              >
                推論済み施策のディレクトリ一覧
              </TextLink>
            </Text>

            <ButtonContainer isVertical>
              <Button type="primary" to={`/AID005/${measureId}`} text="デザイン生成概要" />
              <Button type="secondary" icon="arrow-left" iconPosition="left" to={`/CMN001`} text="TOPページへ戻る" />
            </ButtonContainer>
          </Section>
        </Container>
      </Main>
      <Footer />
    </>
  );
};
