import React from 'react';
import style from './style.module.scss';

type PositionContainerProps = {
  mt?: number;
};

export const PositionContainer: React.FC<PositionContainerProps> = ({ children, mt }) => (
  <div
    className={style['layout-position-container']}
    style={{
      ...(mt !== undefined && { marginTop: `${mt}px` }),
    }}
  >
    {children}
  </div>
);

type PositionItemProps = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  centerY?: boolean;
};

export const PositionItem: React.FC<PositionItemProps> = ({ children, top, right, bottom, left, centerY }) => (
  <div
    className={`${style['layout-position-item']} ${centerY ? 'is-center-y' : ''}`}
    style={{
      ...(top !== undefined && { top: top }),
      ...(right !== undefined && { right: right }),
      ...(bottom !== undefined && { bottom: bottom }),
      ...(left !== undefined && { left: left }),
    }}
  >
    {children}
  </div>
);
