import React from 'react';
import { Radio } from 'components/Atoms/Form';
import style from './style.module.scss';
import type { RadioSizeProps } from 'components/Atoms/Form/Radio';
import { RefCallBack } from 'react-hook-form';

export type RadioListProps = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  value: string;
  refs?: RefCallBack;
  onChange: (value: string) => void;
  list: Array<RadioListProps>;
  disabled?: boolean;
  isInvalid?: boolean;
  className?: string;
  isHorizontal?: boolean;
  size?: RadioSizeProps;
};

export const RadioList: React.FC<Props> = ({
  name,
  value,
  refs,
  onChange,
  list,
  disabled,
  isInvalid,
  className = '',
  isHorizontal = false,
  size = 'medium',
}) => {
  const handleChange = React.useCallback(
    (value) => {
      onChange(value);
    },
    [onChange],
  );

  return React.useMemo(
    () => (
      <div className={`${style.radioList} ${isHorizontal ? 'is-horizontal' : ''} ${className}`}>
        {list.map((elem, index) => (
          <Radio
            key={`${name}-${index}`}
            refs={refs}
            name={name}
            value={elem.value}
            label={elem.label}
            checked={value === elem.value}
            className={`${style['radioList-item']} ${isInvalid ? 'is-invalid' : ''}`}
            disabled={disabled}
            isInvalid={isInvalid}
            onChange={handleChange}
            size={size}
          />
        ))}
      </div>
    ),
    [refs, className, disabled, isInvalid, list, name, value, isHorizontal, handleChange, size],
  );
};
