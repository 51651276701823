import React from 'react';
import { Tab, TabList, TabContent } from 'components/Atoms/TabMenu';
import style from './style.module.scss';

export type TabDataProps = {
  label: string;
  content: JSX.Element;
} | null;

type Props = {
  data: TabDataProps[];
};

export const TabMenu: React.FC<Props> = ({ data }) => {
  const [currentTab, setCurrentTab] = React.useState(0);

  return (
    <div className={style['tabmenu']}>
      <TabList>
        {data
          .filter((elem) => elem)
          .map((elem, index) => (
            <Tab key={index} isActive={currentTab === index} onClick={() => setCurrentTab(index)}>
              {elem?.label}
            </Tab>
          ))}
      </TabList>

      {data
        .filter((elem) => elem)
        .map((elem, index) => (
          <TabContent key={index} isShow={currentTab === index}>
            {elem?.content}
          </TabContent>
        ))}
    </div>
  );
};
