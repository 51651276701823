import React from 'react';
import style from './style.module.scss';

type Props = {
  className?: string;
  mt?: number;
};

export const InlineList: React.FC<Props> = ({ children, className = '', mt }) => {
  return React.useMemo(
    () => (
      <div className={`${style['list-inline']} ${className}`} style={{ ...(mt !== undefined && { marginTop: `${mt}px` }) }}>
        {children}
      </div>
    ),
    [className, children, mt],
  );
};
