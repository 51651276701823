import React from 'react';
import { useFormContext, useWatch, useFormState } from 'react-hook-form';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import { FormGrid, FormField, Flex, ButtonContainer, NestBox } from 'components/Atoms/Layout';
import { Text, ErrorText, Note, MultiLineText, Unit } from 'components/Atoms/Typography';
import { NoteList } from 'components/Atoms/List';
import { Button } from 'components/Atoms/Button';
import { SelectMenu } from 'components/Atoms/Form/SelectMenu';
import { TabContent } from 'components/Atoms/TabMenu';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { UndecidedButton } from 'components/Molecules/UndecidedButton';
import { Modal } from 'components/Organisms/Modal';
import { createNumberOptions } from 'utils';
import { UNDECIDED_VALUE, RHF_UNREGISTER_PARAMS, RHF_RESET_COPY_PARAMS } from 'constants/index';
import { CustomTabList } from './CustomTabList';
import { DeleteButton } from './DeleteButton';
import style from './style.module.scss';

const Types = {
  basic: 'basic',
  tab: 'tab',
  select: 'select',
} as const;
type TypesProps = typeof Types[keyof typeof Types];

type Props = {
  ref?: React.Ref<any>;
  type?: TypesProps;
  min?: number;
  max?: number;
  name: string;
  label?: string;
  unit?: string;
  isRequired?: boolean;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  help?: string;
  className?: string;
  hasUndecidedButton?: boolean;
  hasSelect?: boolean;
  hasAddButton?: boolean;
  hasDeleteButtonOnTop?: boolean;
  hasDeleteButtonOnBottom?: boolean;
  hasTabOnBottom?: boolean;
  hasCopy?: boolean;
  copyFrom?: string;
  onChange?: (index: number) => void;
  onChangeUndecided?: (undecided: boolean) => void;
  contentLabel: (key: number) => string;
  content: (key: number) => JSX.Element;
  dropdown?: JSX.Element;
  onClickSelect?: (event: React.MouseEvent<any, MouseEvent>) => void;
  isLoading?: boolean;
};

export const FormArray: React.FC<Props> = React.forwardRef(
  (
    {
      type = 'basic',
      min = 1,
      max = 100,
      name,
      label,
      unit,
      isRequired = false,
      note,
      notes,
      help,
      className = '',
      hasCopy = false,
      copyFrom,
      hasUndecidedButton = false,
      hasSelect = true,
      hasAddButton = true,
      hasDeleteButtonOnTop = false,
      hasDeleteButtonOnBottom = true,
      hasTabOnBottom,
      onChangeUndecided = () => {},
      onChange = () => {},
      contentLabel,
      content,
      dropdown,
      onClickSelect,
      isLoading,
    },
    ref,
  ) => {
    const { register, unregister, getValues, setValue, clearErrors, reset } = useFormContext();
    const watchedValue: any[] | string | undefined = useWatch({ name });
    const [keys, setKeys] = React.useState<number[]>([]);
    const [isShowDeleteModal, setIsShowDeleteModal] = React.useState(false);
    const [deleteTargetKeys, setDeleteTargetKeys] = React.useState<number[]>([]);
    const [deleteTargetLabels, setDeleteTargetLabels] = React.useState<string[]>([]);

    const [selectValue, setSelectValue] = React.useState('');

    const [undecided, setUndecided] = React.useState(watchedValue === UNDECIDED_VALUE);
    const [undecidedModalFlag, setUndecidedModalFlag] = React.useState(false);

    const [currentTab, setCurrentTab] = React.useState(0);

    const selectName = React.useMemo(() => `${name}_select`, [name]);

    const { errors } = useFormState();
    const error = get(errors, name);

    const componentDidMount = React.useCallback(() => {
      if (watchedValue !== UNDECIDED_VALUE) return;
      register(name);
      setValue(name, UNDECIDED_VALUE);
    }, [name, register, setValue, watchedValue]);

    const componentWillUnmount = React.useCallback(() => {
      console.log(`unregister: ${name}`);
      unregister(name, RHF_UNREGISTER_PARAMS);
      setKeys([]);
    }, [name, unregister]);

    const selectFirstTab = React.useCallback(
      (keys: number[]) => {
        if (type !== 'tab') return;
        const activeKey = keys.filter((key: number) => key !== null)[0];
        setCurrentTab(activeKey);
      },
      [type],
    );

    const init = React.useCallback(() => {
      if (typeof watchedValue !== 'object') return;
      // const newKeys = watchedValue.map((elem: any, index: any) => (elem === null ? null : index)).filter((elem: any) => elem !== null) as number[];
      const newKeys = watchedValue.map((elem: any, index: any) => (elem === null ? null : index)) as number[];
      setKeys(newKeys);
      selectFirstTab(newKeys);
    }, [watchedValue, selectFirstTab]);

    const addContent = React.useCallback(
      (numToAdd: number): void => {
        if (!numToAdd) return;
        let lastKey = keys[keys.length - 1];
        // let lastKey = keys.length - 1;
        let newKeys = keys.concat();
        for (let i = 0; i < numToAdd; i++) {
          const newKey = lastKey === undefined ? 0 : lastKey + 1;
          newKeys.push(newKey);
          lastKey = newKey;
        }

        // 変更前のkeysがない場合、最初のタブをcurrentにする
        if (keys.length === 0) selectFirstTab(newKeys);

        setKeys(newKeys);
        clearErrors(name);
      },
      [name, keys, setKeys, clearErrors, selectFirstTab],
    );

    const deleteContent = React.useCallback(
      (deleteTargets: number[] = deleteTargetKeys): void => {
        const newKeys = keys.filter((k) => !deleteTargets.includes(k));
        setKeys(newKeys);

        const newValues = getValues();
        deleteTargets.forEach((deleteKey) => {
          unset(newValues, `${name}[${deleteKey}]`);
        });
        reset(newValues, RHF_RESET_COPY_PARAMS);

        // 最初のタブをカレント状態にする
        if (deleteTargets.includes(currentTab)) selectFirstTab(newKeys);

        setDeleteTargetKeys([]);

        if (undecidedModalFlag) {
          setUndecided(true);
          register(name);
          setValue(name, UNDECIDED_VALUE, { shouldValidate: true, shouldDirty: true });
          setSelectValue('');
          setUndecidedModalFlag(false);
        }
      },
      [keys, currentTab, deleteTargetKeys, undecidedModalFlag, name, setValue, register, setUndecided, setUndecidedModalFlag, getValues, reset, selectFirstTab],
    );

    const showDeleteModal = React.useCallback(
      (deleteKeys: number[]): void => {
        setDeleteTargetKeys(deleteKeys);
        const targetLabels = deleteKeys.map((elem) => `「${contentLabel(elem)}」`);
        setDeleteTargetLabels(targetLabels);
        setIsShowDeleteModal(true);
      },
      [contentLabel, setDeleteTargetKeys],
    );

    const modalCancel = React.useCallback((): void => {
      setIsShowDeleteModal(false);
      setDeleteTargetKeys([]);
      if (undecidedModalFlag) setUndecidedModalFlag(false);
    }, [undecidedModalFlag]);

    const modalSubmit = React.useCallback((): void => {
      deleteContent();
      setIsShowDeleteModal(false);
    }, [deleteContent]);

    const handleChangeSelect = React.useCallback(
      (value: string) => {
        const prevIndex = Number(selectValue);
        const nextIndex = Number(value);
        if (prevIndex < nextIndex) {
          addContent(nextIndex - prevIndex);
          setSelectValue(value);
        }
        if (prevIndex > nextIndex) {
          const diff = prevIndex - nextIndex;
          const deleteKeys = keys.filter((_, index) => keys.length - diff <= index);
          showDeleteModal(deleteKeys);
        }
      },
      [addContent, keys, selectValue, showDeleteModal],
    );

    const handleUndecided = React.useCallback(
      (checked: boolean) => {
        if (checked) {
          if (keys.length > 0) {
            setUndecidedModalFlag(true);
            showDeleteModal(keys);
          } else {
            setUndecided(true);
            register(name);
            setValue(name, UNDECIDED_VALUE, { shouldValidate: true, shouldDirty: true });
          }
        } else {
          setUndecided(false);
          unregister(name);
        }
      },
      [keys, name, register, setValue, showDeleteModal, unregister],
    );

    const getTabName = React.useCallback((k?: number): string => `${name}[${k}]`, [name]);

    const handleCopy = React.useCallback(() => {
      if (!copyFrom) return;
      const allValues = getValues();
      const fromValues = get(allValues, copyFrom);
      // const fromValuesLength = fromValues?.length || 0;

      // if (fromValuesLength > keys.length) {
      //   const diff = fromValuesLength - keys.length;
      //   addContent(diff);
      // }
      // if (fromValuesLength < keys.length) {
      //   const diff = keys.length - fromValuesLength;
      //   const deleteKeys = keys.filter((_, index) => keys.length - diff <= index);
      //   showDeleteModal(deleteKeys);
      // }
      const newKeys = fromValues.map((elem: any, index: number) => (elem === null ? null : index));
      setKeys(newKeys);

      // const activeNewKeys = newKeys.filter((key: number) => key !== null);
      // setSelectValue(activeNewKeys.length ? String(activeNewKeys.length) : min === 0 ? '0' : '');

      selectFirstTab(newKeys);

      setTimeout(() => {
        const newValues = fromValues?.map((elem: { [key: string]: string }, index: number) => {
          return Object.fromEntries(
            Object.entries(elem).map(([k, v]) => {
              const newK = k === 'pcUrl' ? 'spUrl' : k;
              let newV = v;
              if (k === 'pcUrl') {
                const regex = /https:\/\/www\.shiseido\.co\.jp\/cms\//g;
                newV = v.match(regex) ? v.replace(regex, 'https://www.shiseido.co.jp/sp/cms/') : getValues(`${name}[${index}].spUrl`);
              }
              return [newK, newV];
            }),
          );
        });
        set(allValues, name, newValues);
        reset(allValues, RHF_RESET_COPY_PARAMS);
        // setValue(name, newValues, { shouldValidate: false, shouldDirty: true });
      }, 0);
    }, [copyFrom, name, reset, getValues, selectFirstTab]);

    React.useEffect(() => {
      onChange(currentTab);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    React.useEffect(() => {
      const filteredKeys = keys.filter((key) => key !== null);
      setSelectValue(filteredKeys ? String(filteredKeys.length) : min === 0 ? '0' : '');
    }, [min, keys]);

    React.useEffect(() => {
      init();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const append = React.useCallback(
      (newData: any) => {
        const lastKey = keys[keys.length - 1];
        // const lastKey = keys.length - 1;
        // const newKey = lastKey + 1;
        const newKey = lastKey === undefined ? 0 : lastKey + 1;
        addContent(1);
        let newValues = getValues();
        set(newValues, `${name}[${newKey}]`, newData);
        reset(newValues, RHF_RESET_COPY_PARAMS);
      },
      [addContent, name, keys, getValues, reset],
    );

    const remove = React.useCallback(
      (index: number) => {
        if (!keys) return;
        const newKeys = cloneDeep(keys).filter((key) => key !== index);
        setKeys(newKeys);
      },
      [keys],
    );

    React.useImperativeHandle(ref, () => ({ append, remove }));

    React.useEffect(() => {
      onChangeUndecided(undecided);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [undecided]);

    React.useEffect(() => {
      console.log(name, watchedValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedValue]);

    React.useEffect(() => {
      console.log(name, keys);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keys]);

    React.useEffect(() => {
      componentDidMount();
      return () => componentWillUnmount();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        {hasCopy && (
          <div className={style['copyButton']}>
            <ButtonContainer className={style['copyButton-buttonContainer']}>
              <Button type="secondary" text="SPに入力内容をコピー" onClick={handleCopy} />
            </ButtonContainer>
            <NoteList
              list={[
                '※PCの全ての入力内容がSPにコピーされ、すでに入力されている場合は上書きされます。',
                '※URLは「https://www.shiseido.co.jp/sp/cms/~」に変換されます。それ以外のURLを入力している場合は、コピーされません。',
              ]}
              className={style['copyButton-noteList']}
            />
          </div>
        )}

        <FormGrid className={`${style['form-array']} type-${type} ${hasSelect ? 'has-select' : ''} ${className}`}>
          {label && <FormLabelSet label={label} isRequired={isRequired} help={help} />}

          <FormField>
            {(type === 'basic' || type === 'tab') && hasSelect && (
              <>
                <Flex alignItems="center">
                  <SelectMenu
                    name={selectName}
                    list={createNumberOptions(min, max)}
                    value={selectValue}
                    disabled={undecided}
                    isInvalid={error?.message ? true : false}
                    onChange={handleChangeSelect}
                    className={style['form-array-select']}
                  />
                  {unit && <Unit text={unit} />}
                </Flex>
              </>
            )}

            {type === 'select' && (
              <div
                className={style['selected-wrapper']}
                onClick={(event) => {
                  if (undecided) return;
                  onClickSelect?.(event);
                }}
              >
                <div className={`${style['selected']} ${error?.message ? 'is-invalid' : ''} ${undecided ? 'is-disabled' : ''}`}>
                  {keys
                    .filter((key) => key !== null)
                    .map((key) => (
                      <React.Fragment key={key}>{content(key)}</React.Fragment>
                    ))}
                </div>
                {isLoading && <SvgIcon type="loading-small" width={22} className={style['selected-icon-loading']} />}
                {!isLoading && keys.length > 0 && (
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      if (undecided) return;
                      deleteContent(keys);
                    }}
                    className={style['selected-icon-delete']}
                  >
                    <SvgIcon type="cross" fill="#111" width={10} />
                  </button>
                )}
                <SvgIcon type="triangle-down" fill="#2896f0" width={12} className={style['selected-icon']} />
              </div>
            )}

            {type === 'select' && dropdown}

            <ErrorText error={error?.message} />
            {note && <Note>{note}</Note>}
            {notes && <NoteList list={notes}></NoteList>}

            {keys.length > 0 && (
              <>
                {type === 'basic' && (
                  <div className={style['form-array-content']}>
                    {keys
                      .filter((key) => key !== null)
                      .map((key) => (
                        <NestBox key={key} className={style['form-array-item']}>
                          {hasDeleteButtonOnTop && keys.length > 0 && (
                            <DeleteButton deleteKeys={[key]} showDeleteModal={showDeleteModal} className="type-basic is-top" />
                          )}
                          {content(key)}
                          {hasDeleteButtonOnBottom && keys.length > 0 && (
                            <DeleteButton deleteKeys={[key]} showDeleteModal={showDeleteModal} className="type-basic is-bottom" />
                          )}
                        </NestBox>
                      ))}
                  </div>
                )}
              </>
            )}
          </FormField>

          <UndecidedButton hasUndecidedButton={hasUndecidedButton} undecided={undecided} onChangeDecided={handleUndecided} />
        </FormGrid>

        {keys.length > 0 && (
          <>
            {type === 'tab' && (
              <div className={style['form-array-tab']}>
                <CustomTabList
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  selectName={selectName}
                  name={name}
                  contentLabel={contentLabel}
                  keys={keys.filter((key) => key !== null)}
                  getTabName={getTabName}
                  hasAddButton={hasAddButton}
                  addContent={addContent}
                  max={max}
                />
                {keys
                  .filter((key) => key !== null)
                  .map((key) => (
                    <TabContent key={key} isShow={currentTab === key}>
                      {hasDeleteButtonOnTop && <DeleteButton deleteKeys={[key]} showDeleteModal={showDeleteModal} className={`type-tab is-top`} />}
                      <div className={style['form-array-tab-item']}>{content(key)}</div>
                      {hasDeleteButtonOnBottom && <DeleteButton deleteKeys={[key]} showDeleteModal={showDeleteModal} className={`type-tab is-bottom`} />}
                    </TabContent>
                  ))}
                {hasTabOnBottom && (
                  <CustomTabList
                    isBottom
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    selectName={selectName}
                    name={name}
                    contentLabel={contentLabel}
                    keys={keys.filter((key) => key !== null)}
                    getTabName={getTabName}
                    hasAddButton={hasAddButton}
                    addContent={addContent}
                    max={max}
                  />
                )}
              </div>
            )}
          </>
        )}

        <Modal
          type="warn"
          title="入力内容を削除します"
          isShow={isShowDeleteModal}
          hasCancel={true}
          hasSubmit={true}
          cancelText="キャンセル"
          onCancel={modalCancel}
          onSubmit={modalSubmit}
        >
          <MultiLineText value={deleteTargetLabels.join('\n')} align="center" />
          <Text align="center" mt={0}>
            で入力した内容は削除されますがよろしいですか？
            <br />
            任意の項目を削除したい場合は、削除ボタンで削除してください。
          </Text>
        </Modal>
      </>
    );
  },
);
