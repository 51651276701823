import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Note } from 'components/Atoms/Typography';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { Modal } from 'components/Organisms/Modal';
import style from './style.module.scss';
import { DirtyState } from 'context';
import type { SvgIconTypeProps } from 'components/Atoms/SvgIcon';

const Color = {
  blue: 'blue',
  white: 'white',
} as const;
export type TextLinkColorProps = typeof Color[keyof typeof Color];

const IconPosition = {
  before: 'before',
  after: 'after',
} as const;
type IconPositionProps = typeof IconPosition[keyof typeof IconPosition];

export type TextLinkProps = {
  to?: string;
  onClick?: () => void;
  current?: boolean;
  disabled?: boolean;
  isExternal?: boolean;
  isSync?: boolean;
  itemProp?: string;
  className?: string;
  color?: TextLinkColorProps;
  icon?: SvgIconTypeProps;
  iconPosition?: IconPositionProps;
  nowrap?: boolean;
  ml?: number;
};

export const TextLink: React.FC<TextLinkProps> = ({
  children,
  to,
  onClick,
  current = false,
  disabled = false,
  isExternal = false,
  isSync = false,
  itemProp,
  className = '',
  color = 'blue',
  icon,
  iconPosition,
  nowrap = false,
  ml,
}) => {
  const history = useHistory();
  const isDirty = React.useContext(DirtyState);
  const [isOpenConfirmModal, setOpenConfirmModal] = React.useState(false);

  const iconTheme = React.useMemo(() => {
    if (color === 'blue') return '#2896f0';
    if (color === 'white') return '#fff';
  }, [color]);

  const iconWidth = React.useMemo(() => {
    if (icon === 'arrow-right') return 6;
    if (icon === 'window') return 14;

    return 0;
  }, [icon]);

  const handleCancelConfirmModal = React.useCallback(() => {
    setOpenConfirmModal(false);
  }, []);

  const handleSubmitConfirmModal = React.useCallback(() => {
    setOpenConfirmModal(false);
    if (!to) return;
    history.push(to);
  }, [history, to]);

  return React.useMemo(
    () => (
      <>
        {!isSync && !isExternal && !onClick && (
          <>
            <a
              className={`${style['typoraphy-textlink']} ${current ? 'is-current' : ''} ${disabled ? 'is-disabled' : ''} is-${color} ${className} ${
                nowrap ? 'is-nowrap' : ''
              }`}
              href={to}
              onClick={(e) => {
                e.preventDefault();
                if (disabled) return;
                if (!to) return;
                if (isDirty) {
                  setOpenConfirmModal(true);
                } else {
                  history.push(to);
                }
              }}
              style={{ ...(ml !== undefined && { marginLeft: `${ml}px` }) }}
              {...(itemProp && { itemProp: itemProp })}
            >
              {icon && iconPosition === 'before' && (
                <SvgIcon type="arrow-right" fill={iconTheme} width={iconWidth} className={`${style['typoraphy-textlink-icon']} is-before`} />
              )}
              {children}
              {icon && iconPosition === 'after' && (
                <SvgIcon type="arrow-right" fill={iconTheme} width={iconWidth} className={`${style['typoraphy-textlink-icon']} is-after`} />
              )}
            </a>

            <Modal
              type="warn"
              title="更新内容が保存されていません"
              isShow={isOpenConfirmModal}
              hasCancel
              hasSubmit
              cancelText="キャンセル"
              onCancel={handleCancelConfirmModal}
              onSubmit={handleSubmitConfirmModal}
            >
              <Text align="center">
                保存ボタン*がクリックされていないため、更新内容は保存されません。
                <br />
                ページを移動してよろしいですか？
              </Text>
              <Note align="center">* 保存ボタン：「入力完了」「下書き保存」「入力内容確認」のいずれか</Note>
            </Modal>
          </>
        )}

        {!isSync && isExternal && !onClick && (
          <a
            className={`${style['typoraphy-textlink']} ${current ? 'is-current' : ''} ${disabled ? 'is-disabled' : ''} is-external is-${color} ${
              nowrap ? 'is-nowrap' : ''
            }`}
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            style={{ ...(ml !== undefined && { marginLeft: `${ml}px` }) }}
            {...(itemProp && { itemProp: itemProp })}
          >
            {icon && iconPosition === 'before' && (
              <SvgIcon type="arrow-right" fill={iconTheme} width={iconWidth} className={`${style['typoraphy-textlink-icon']} is-before`} />
            )}
            {children}
            <SvgIcon type="window" fill={iconTheme} width={14} className={`${style['typoraphy-textlink-icon']} is-after`} />
            {icon && iconPosition === 'after' && (
              <SvgIcon type="arrow-right" fill={iconTheme} width={iconWidth} className={`${style['typoraphy-textlink-icon']} is-after`} />
            )}
          </a>
        )}

        {isSync && !isExternal && !onClick && (
          <a
            className={`${style['typoraphy-textlink']} ${current ? 'is-current' : ''} ${disabled ? 'is-disabled' : ''} is-external ${
              nowrap ? 'is-nowrap' : ''
            }`}
            href={to}
            style={{ ...(ml !== undefined && { marginLeft: `${ml}px` }) }}
            {...(itemProp && { itemProp: itemProp })}
          >
            {icon && iconPosition === 'before' && (
              <SvgIcon type="arrow-right" fill={iconTheme} width={iconWidth} className={`${style['typoraphy-textlink-icon']} is-before`} />
            )}
            {children}
            {icon && iconPosition === 'after' && (
              <SvgIcon type="arrow-right" fill={iconTheme} width={iconWidth} className={`${style['typoraphy-textlink-icon']} is-after`} />
            )}
          </a>
        )}

        {!isSync && !isExternal && onClick && (
          <button
            className={`${style['typoraphy-textlink']} ${current ? 'is-current' : ''} ${disabled ? 'is-disabled' : ''} is-${color} ${className} ${
              nowrap ? 'is-nowrap' : ''
            }`}
            onClick={(e) => {
              e.preventDefault();
              if (disabled) return;
              onClick();
            }}
            style={{ ...(ml !== undefined && { marginLeft: `${ml}px` }) }}
            {...(itemProp && { itemProp: itemProp })}
          >
            {icon && iconPosition === 'before' && (
              <SvgIcon type="arrow-right" fill={iconTheme} width={iconWidth} className={`${style['typoraphy-textlink-icon']} is-before`} />
            )}
            {children}
            {icon && iconPosition === 'after' && (
              <SvgIcon type="arrow-right" fill={iconTheme} width={iconWidth} className={`${style['typoraphy-textlink-icon']} is-after`} />
            )}
          </button>
        )}
      </>
    ),
    [
      disabled,
      children,
      current,
      history,
      itemProp,
      to,
      onClick,
      isExternal,
      className,
      color,
      isSync,
      icon,
      iconPosition,
      iconTheme,
      iconWidth,
      ml,
      nowrap,
      isDirty,
      handleCancelConfirmModal,
      handleSubmitConfirmModal,
      isOpenConfirmModal,
    ],
  );
};
