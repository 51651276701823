import React from 'react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import { ButtonContainer } from 'components/Atoms/Layout';
import { NoteList } from 'components/Atoms/List';
import { Button } from 'components/Atoms/Button';
import style from './style.module.scss';

type Props = {
  from: string;
  to: string;
};

export const CopyButton: React.FC<Props> = ({ from, to }) => {
  const { getValues, setValue } = useFormContext();

  const handleCopy = React.useCallback(() => {
    const allValues = getValues();
    const fromValues = get(allValues, from);
    const regex = /https:\/\/www\.shiseido\.co\.jp\/cms\//g;
    if (fromValues.match(regex)) setValue(to, fromValues.replace(regex, 'https://www.shiseido.co.jp/sp/cms/'), { shouldValidate: true, shouldDirty: true });
  }, [from, to, getValues, setValue]);

  return (
    <div className={style['copyButton']}>
      <ButtonContainer className={style['copyButton-buttonContainer']}>
        <Button type="secondary" text="SPに入力内容をコピー" onClick={handleCopy} />
      </ButtonContainer>
      <NoteList
        list={[
          '※PCの全ての入力内容がSPにコピーされ、すでに入力されている場合は上書きされます。',
          '※URLは「https://www.shiseido.co.jp/sp/cms/~」に変換されます。それ以外のURLを入力している場合は、コピーされません。',
        ]}
        className={style['copyButton-noteList']}
      />
    </div>
  );
};
