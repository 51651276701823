import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from 'aws-exports';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { FormTextInput } from 'components/Business/Form';
import { TextLink } from 'components/Business/TextLink';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { RHF_SETTINGS } from 'constants/index';
import { TempCognitoUserUpdate, NetworkErrorUpdate } from 'context';
import { scrollToFirstError } from 'utils';
import { useValidationSchema } from './useValidationSchema';

Amplify.configure(awsconfig);

export type StateProps = {
  username: string;
};

export const CMN002: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { t } = useTranslation();
  const setTempCognitoUser = React.useContext(TempCognitoUserUpdate);
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, handleSubmit, setError } = useFormMethods;
  const [isLoading, setLoading] = React.useState(false);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    const data = {
      username: formValues.username || '',
      password: formValues.password || '',
    };
    return data;
  }, [getValues]);

  const signIn = React.useCallback(async () => {
    setLoading(true);
    const data = getRequestData();
    try {
      const user = await Auth.signIn(data.username, data.password);
      console.log(user);
      setTempCognitoUser(user);
      if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // 2回目以降ログイン
        history.push('/CMN013', state || { nextPage: '/CMN001' });
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // パスワード本登録
        history.push('/CMN009');
      } else if (user.challengeName === 'MFA_SETUP') {
        // 二段階認証
        history.push('/CMN011');
      }
    } catch (error) {
      console.error(error);
      if (error.code === 'UserNotFoundException' || error.code === 'NotAuthorizedException') {
        setError('username', {
          type: 'custom',
          message: t('ERR-INVALID', { target: 'ユーザー名またはパスワード' }),
        });
        setError('password', {
          type: 'custom',
          message: t('ERR-INVALID', { target: 'ユーザー名またはパスワード' }),
        });
        scrollToFirstError();
      } else {
        setNetworkError(true);
      }
      setLoading(false);
    }
  }, [getRequestData, setError, t, history, setTempCognitoUser, setNetworkError, state]);

  const onSuccess = React.useCallback(() => {
    signIn();
  }, [signIn]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />
          <Container type="login">
            <Section>
              <H1>ログイン</H1>
              <Text align="center">
                ログイン後に施策情報の登録、販促品の閲覧ができます。
                <br />
                セキュリティ上、3分以内にログインを完了してください。
              </Text>

              <FormTextInput
                name="username"
                label="ユーザー名"
                notes={[`※${t('MSG-USERNAME-BACKLOG')}`, '※英字を使用する場合は小文字を入力してください。例：acn、imj']}
                size="medium"
                inputSize="large"
              />

              <FormTextInput type="password" name="password" label="パスワード" size="medium" inputSize="large" />

              <ButtonContainer>
                <Button type="secondary" icon="arrow-right" iconPosition="right" text="次へ進む" onClick={() => handleSubmit(onSuccess, onError)()} />
              </ButtonContainer>

              <Text align="center">
                <TextLink icon="arrow-right" iconPosition="after" to="/CMN003">
                  パスワードを忘れた方
                </TextLink>
              </Text>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
