import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { RHF_UNREGISTER_PARAMS } from 'constants/index';

type Props = {
  name: string;
  value?: string;
};

export const FormHiddenInput: React.FC<Props> = ({ name, value = '' }) => {
  const { control, unregister } = useFormContext();
  const { field } = useController({ control, name, defaultValue: value, shouldUnregister: true });

  const componentWillUnmount = React.useCallback(() => {
    console.log(`unregister: ${name}`);
    unregister(name, RHF_UNREGISTER_PARAMS);
  }, [name, unregister]);

  React.useEffect(() => {
    return () => componentWillUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <input type="hidden" value={field.value} ref={field.ref} />;
};
