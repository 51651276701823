import React from 'react';
import style from './style.module.scss';

type Props = {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  className?: string;
};

export const FlexCol: React.FC<Props> = ({ children, col, className = '' }) => {
  return <div className={`${style['layout-col']} layout-col ${col !== undefined ? 'col-' + col : ''} ${className}`}>{children}</div>;
};
