import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { FormGrid, FormField, Box } from 'components/Atoms/Layout';
import { Text, Note, ErrorText } from 'components/Atoms/Typography';
import { NoteList } from 'components/Atoms/List';
import { AccordionContent } from 'components/Atoms/AccordionContent';
import { Radio } from 'components/Atoms/Form';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { UndecidedButton } from 'components/Molecules/UndecidedButton';
import { Modal } from 'components/Organisms/Modal';
import { UNDECIDED_VALUE, RHF_UNREGISTER_PARAMS } from 'constants/index';
import { smoothScroll } from 'utils';
import style from './style.module.scss';
import ImageRemoveChildren from 'images/image_remove_children.png';
import type { RadioSizeProps } from 'components/Atoms/Form/Radio';

type ListProps = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  list: Array<ListProps>;
  label?: string;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  isRequired?: boolean;
  help?: string;
  hasUndecidedButton?: boolean;
  contents: {
    [key: string]: JSX.Element | null;
  };
  defaultValue?: string;
  radioSize?: RadioSizeProps;
};

export const FormRadioAccordion: React.FC<Props> = ({
  name,
  label = '',
  note,
  notes,
  isRequired = false,
  help,
  hasUndecidedButton = false,
  list,
  contents,
  defaultValue = '',
  radioSize = 'medium',
}) => {
  const { control, trigger, unregister } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, defaultValue, shouldUnregister: true });
  const [undecided, setUndecided] = React.useState(false);
  const [backupValue, setBackupValue] = React.useState('');
  const [isOpenConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [changedValue, setChangedValue] = React.useState('');

  const componentWillUnmount = React.useCallback(() => {
    console.log(`unregister: ${name}`);
    unregister(name, RHF_UNREGISTER_PARAMS);
  }, [name, unregister]);

  const scrollTo = React.useCallback((to: string | HTMLElement) => {
    window.setTimeout(() => {
      smoothScroll({
        to,
        easing: 'easeInOutCirc',
        offset: -50,
      });
    }, 0);
  }, []);

  const handleChange = React.useCallback(
    (value) => {
      if (field.value && contents[field.value]) {
        setChangedValue(value);
        setOpenConfirmModal(true);
      } else {
        field.onChange(value);

        if (value && contents[value]) {
          // 変更後の値に紐づくコンテンツがある場合、対象ラジオボタンの上部に移動
          const targetElements = document.getElementsByName(name);
          const targetArray = [].slice.call(targetElements) as HTMLElement[];
          const targetElement = targetArray.find((element) => element.getAttribute('value') === value);
          if (!targetElement) return;
          scrollTo(targetElement);
        } else if (field.value && contents[field.value]) {
          // 変更後の値に紐づくコンテンツがなく、変更前の値に紐づくコンテンツがある場合、ラジオ全体の上部に移動
          scrollTo(name);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contents, field.value, field.onChange, name],
  );

  const handleUndecided = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setBackupValue(field.value);

        if (field.value && contents[field.value]) {
          setChangedValue(UNDECIDED_VALUE);
          setOpenConfirmModal(true);
        } else {
          field.onChange(UNDECIDED_VALUE);
          trigger(name); // 初回なぜかバリデーションされないため
        }
        return;
      }
      field.onChange(backupValue);
      setBackupValue('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.value, backupValue, setBackupValue, contents, field.value, field.onChange, name, trigger],
  );

  const handleSubmitConfirmModal = React.useCallback(() => {
    field.onChange(changedValue);

    if (changedValue && contents[changedValue]) {
      // 変更後の値に紐づくコンテンツがある場合、対象ラジオボタンの上部に移動
      const targetElements = document.getElementsByName(name);
      const targetArray = [].slice.call(targetElements) as HTMLElement[];
      const targetElement = targetArray.find((element) => element.getAttribute('value') === changedValue);
      if (!targetElement) return;
      scrollTo(targetElement);
    } else if (field.value && contents[field.value]) {
      // 変更後の値に紐づくコンテンツがなく、変更前の値に紐づくコンテンツがある場合、ラジオ全体の上部に移動
      scrollTo(name);
    }

    setChangedValue('');
    setOpenConfirmModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedValue, field.onChange, field.value, contents, name]);

  const handleCancelConfirmModal = React.useCallback(() => {
    setChangedValue('');
    setOpenConfirmModal(false);
  }, []);

  React.useEffect(() => {
    setUndecided(field.value === UNDECIDED_VALUE);
  }, [field.value]);

  React.useEffect(() => {
    return () => componentWillUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormGrid className={style['form-radio-accordion']}>
        <FormLabelSet label={label} isRequired={isRequired} help={help} />

        <FormField>
          {list.map((elem, index) => (
            <div key={`${name}-${index}`} className={style['form-radio-accordion-item']}>
              <Radio
                name={name}
                refs={field.ref}
                onChange={handleChange}
                value={elem.value}
                label={elem.label}
                checked={field.value === elem.value}
                disabled={undecided}
                isInvalid={error ? true : false}
                size={radioSize}
              />

              {contents[elem.value] && (
                <AccordionContent isShow={!undecided && field.value === elem.value} unmountOnClosed className={`${style['form-radio-accordion-content']}`}>
                  {contents[elem.value]}
                </AccordionContent>
              )}
            </div>
          ))}
          <ErrorText error={error?.message} />
          {note && <Note>{note}</Note>}
          {notes && <NoteList list={notes} />}
        </FormField>

        <UndecidedButton name={name} hasUndecidedButton={hasUndecidedButton} undecided={undecided} onChangeDecided={handleUndecided} />
      </FormGrid>

      <Modal
        type="warn"
        title="入力済みの内容が削除されます"
        isShow={isOpenConfirmModal}
        hasSubmit={true}
        hasCancel={true}
        cancelText="キャンセル"
        onSubmit={handleSubmitConfirmModal}
        onCancel={handleCancelConfirmModal}
      >
        <Text align="center">
          イメージ図の様に上下関係がある設問では、
          <br />
          上位の回答を変更すると下位の回答が削除されます。
        </Text>
        <Box mt={30}>
          <img src={ImageRemoveChildren} alt="" />
        </Box>
        <Text align="center">このまま回答を変更してよろしいですか？</Text>
      </Modal>
    </>
  );
};
