import React from 'react';
import { useHistory } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from 'aws-exports';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { FormTextInput } from 'components/Business/Form';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { RHF_SETTINGS } from 'constants/index';
import { NetworkErrorUpdate } from 'context';
import { scrollToFirstError } from 'utils';
import { useValidationSchema } from './useValidationSchema';

Amplify.configure(awsconfig);

export type StateProps = {
  username: string;
};

export const CMN003: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, handleSubmit, setError } = useFormMethods;
  const [isLoading, setLoading] = React.useState(false);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    const data = {
      username: formValues.username || '',
    };
    return data;
  }, [getValues]);

  const forgotPassword = React.useCallback(async () => {
    setLoading(true);
    const data = getRequestData();
    try {
      await Auth.forgotPassword(data.username);
      const state: StateProps = { username: data.username };
      history.push('/CMN004', state);
    } catch (error) {
      console.error(error);
      if (error.code === 'UserNotFoundException') {
        setError('username', {
          type: 'custom',
          message: t('ERR-NOTFOUND-USERNAME'),
        });
        scrollToFirstError();
      } else {
        setNetworkError(true);
      }
      setLoading(false);
    }
  }, [getRequestData, setError, t, history, setNetworkError]);

  const onSuccess = React.useCallback(() => {
    forgotPassword();
  }, [forgotPassword]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />
          <Container type="login">
            <Section>
              <H1>パスワードを忘れた方</H1>
              <Text align="center">
                パスワードを忘れた方は再登録が必要となります。
                <br />
                ユーザ名を入力すると登録されているメールアドレスに認証コードが送信されます。
              </Text>

              <FormTextInput name="username" label="ユーザー名" size="medium" inputSize="large" />

              <ButtonContainer>
                <Button type="secondary" icon="arrow-right" iconPosition="right" text="次へ進む" onClick={() => handleSubmit(onSuccess, onError)()} />
              </ButtonContainer>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
