import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Modal } from 'components/Organisms/Modal';
import { CognitoUserState } from 'context';
import {
  RHF_RESET_PARAMS,
  ENDPOINT_PATCH_TEMP_MEASURE_WEB_API,
  ENDPOINT_PATCH_TEMP_MEASURE_CAMPAIGN_API,
  ENDPOINT_PATCH_TEMP_MEASURE_SAMPLE_API,
} from 'constants/index';
import { useApiCall } from 'hooks/useApiCall';
import style from './style.module.scss';
import type { AxiosRequestConfig } from 'axios/index.d';
import type { MeasureParamsProps } from 'types';

const Type = {
  web: 'web',
  campaign: 'campaign',
  sample: 'sample',
} as const;
export type ApiSaveTempMeasureButtonTypeProps = typeof Type[keyof typeof Type];

type Props = {
  type: ApiSaveTempMeasureButtonTypeProps;
  getRequestData: () => { [key: string]: any };
  disabled?: boolean;
};

export const ApiSaveTempMeasureButton: React.FC<Props> = ({ type, getRequestData, disabled = false }) => {
  const apiCall = useApiCall();
  const userInfo = React.useContext(CognitoUserState);
  const { measureId } = useParams<MeasureParamsProps>();
  const history = useHistory();
  const { reset } = useFormContext();
  const [openSaveModal, setOpenSaveModal] = React.useState(false);
  const [newMeasuresId, setNewMeasuresId] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);

  const saveTemp = React.useCallback(async () => {
    setIsLoading(true);
    const data = getRequestData();
    data.updateInCharge = userInfo?.username;
    console.log(data);

    let method: AxiosRequestConfig['method'];
    let url: string;

    method = 'PATCH';
    switch (type) {
      case 'web':
        url = `${ENDPOINT_PATCH_TEMP_MEASURE_WEB_API}${measureId}`;
        break;
      case 'campaign':
        url = `${ENDPOINT_PATCH_TEMP_MEASURE_CAMPAIGN_API}${measureId}`;
        break;
      case 'sample':
        url = `${ENDPOINT_PATCH_TEMP_MEASURE_SAMPLE_API}${measureId}`;
        break;
    }

    apiCall({
      method: method,
      url: url,
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          if (!measureId) setNewMeasuresId(res.data.result.id);
          setOpenSaveModal(true);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [getRequestData, measureId, type, userInfo, apiCall]);

  const onCloseModal = React.useCallback(() => {
    if (!measureId) {
      history.push(`${history.location.pathname}/${newMeasuresId}`);
      // window.location.reload();
    } else {
      reset(getRequestData(), RHF_RESET_PARAMS);
    }
    setOpenSaveModal(false);
  }, [setOpenSaveModal, history, measureId, newMeasuresId, reset, getRequestData]);

  return (
    <>
      <Loading isLoading={isLoading} />

      <Button type="secondary" isFlexible isNoBorder text="下書き保存" disabled={disabled} onClick={saveTemp} className={`${style['api-save-temp-measure']}`} />

      <Modal type="success" title="下書き保存をしました" isShow={openSaveModal} hasSubmit={true} onSubmit={onCloseModal} onCancel={onCloseModal}>
        <Text align="center">
          こちらのページは、まだ入力が完了しておりません。​
          <br />
          すべての項目を入力後、「入力完了」をクリックして、​
          <br />
          施策情報をご登録いただきますよう、お願いします。​
        </Text>
      </Modal>
    </>
  );
};
