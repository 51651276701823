export const defaultValues = {
  measuresName: '',
  inCharge: '',
  inChargeSubNum: [],
  measuresClassification: '',
  // measuresPurpose: '',
  // benefitContent: '',
  // benefitGrantConditions: '',
  // measuresProduct: '',
  // monthlySalesPlan: '',
  measuresStartDateYear: '',
  measuresStartDateMonth: '',
  measuresStartDateDay: '',
  measuresStartTimeHours: '00',
  measuresStartTimeMinutes: '00',
  measuresEndConditions: '',
  // measuresEndDateYear: '',
  // measuresEndDateMonth: '',
  // measuresEndDateDay: '',
  // measuresEndTimeHours: '23',
  // measuresEndTimeMinutes: '59',
  olsDocumentCreation: '',
  webProduction: '',
  janCodeIssuance: '',
  osWorkRequest: '',
  campaignSettings: '',
  sampleSettings: '',
  giftSetCodeSettings: '',
  remarksColumn: '',
  xPostAspiration: '',
  xImagePreparation: '',
  igStoryPostAspiration: '',
  igStoryImagePreparation: '',
  lineMessagePostAspiration: '',
  // lineConnectStreaming: '',
  // streamingConditions: '',
  lineHomePostAspiration: '',
  snsLineRemarks: ''
};
