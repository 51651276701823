import React from 'react';
import { Main, Container, Section, SectionInner, ButtonContainer } from 'components/Atoms/Layout';
import { H1, H2, H3, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { DataTable } from 'components/Organisms/DataTable';
import { TabMenu } from 'components/Organisms/TabMenu';
import { ApiGetPromotionDetailProvider } from 'components/Business/Api';
import type { PromotionDetailProps } from 'types';
import type { TabDataProps } from 'components/Organisms/TabMenu';
import type { DataTableProps } from 'components/Organisms/DataTable';

export const PRF002: React.FC = () => {
  const [response, setResponse] = React.useState<PromotionDetailProps>();
  const [promotionDetailData, setPromotionDetailData] = React.useState<DataTableProps[]>([]);
  const [measuresData, setMeasuresData] = React.useState<TabDataProps[]>([]);
  const [ylcData, setYlcData] = React.useState<TabDataProps[]>([]);

  const apiCallbackHandler = React.useCallback((result) => {
    setResponse(result);
  }, []);

  React.useEffect(() => {
    if (!response) return;

    const promotionDetailData: DataTableProps[] = [
      {
        type: 'text',
        label: '販促品名',
        value: response.promotionalName,
      },
      {
        type: 'text',
        label: '宣伝物コード',
        value: response.promotionCode,
      },
      {
        type: 'text',
        label: 'JANコード',
        value: response.janCode,
      },
      {
        type: 'text',
        label: 'JANコード発番日',
        value: response.janCodeNumberingDate,
      },
      {
        type: 'text',
        label: 'JANコード読み取り確認日',
        value: response.janCodeConfirmationDate,
      },
      {
        type: 'text',
        label: 'OS作業依頼',
        value: response.osWorkRequest,
      },
    ];
    setPromotionDetailData(promotionDetailData);

    const measuresData = response.measures.map((measure, index) => {
      return {
        label: `${index + 1}:施策情報`,
        content: (
          <>
            <DataTable
              data={[
                {
                  type: 'text',
                  label: '施策開始日',
                  value: measure.measuresDateStart,
                },
                {
                  type: 'text',
                  label: 'ブランド',
                  value: measure.brandName,
                },
                {
                  type: 'text',
                  label: '施策担当者名',
                  value: measure.inCharge,
                },
                {
                  type: 'text',
                  label: '施策名',
                  value: measure.measuresName,
                },
              ]}
            />
          </>
        ),
      };
    });
    setMeasuresData(measuresData);

    const ylcData = response.ylcDelivery.map((ylc, index) => {
      return {
        label: `${index + 1}:納品情報`,
        content: (
          <>
            <DataTable
              data={[
                {
                  type: 'text',
                  label: '納品種類',
                  value: ylc.classification || '-',
                },
                {
                  type: 'text',
                  label: '賞味期限',
                  value: ylc.expirationDate || '-',
                },
                {
                  type: 'text',
                  label: 'サイト名',
                  value: ylc.siteName || '-',
                },
                {
                  type: 'text',
                  label: 'YLC納品日（東）',
                  value: ylc.ylcDeliveryDateEast,
                },
                {
                  type: 'text',
                  label: '納品元（東）',
                  value: ylc.deliverySourceEast || '-',
                },
                {
                  type: 'text',
                  label: '納品業者（東）',
                  value: ylc.deliveryVendorEast || '-',
                },
                {
                  type: 'text',
                  label: 'YLC納品数（東）',
                  value: ylc.ylcDeliveryNumEast || '-',
                },
                {
                  type: 'text',
                  label: '入り数（東）１',
                  value: ylc.packingCountEast1 || '-',
                },
                {
                  type: 'text',
                  label: '箱数（東）１',
                  value: ylc.boxCountEast1 || '-',
                },
                {
                  type: 'text',
                  label: '入り数（東）２',
                  value: ylc.packingCountEast2 || '-',
                },
                {
                  type: 'text',
                  label: '箱数（東）２',
                  value: ylc.boxCountEast2 || '-',
                },
                {
                  type: 'text',
                  label: 'YLC確保数（東）',
                  value: ylc.ylcSecureNumEast || '-',
                },
                {
                  type: 'text',
                  label: 'YLC納品日（西）',
                  value: ylc.ylcDeliveryDateWest,
                },
                {
                  type: 'text',
                  label: '納品元（西）',
                  value: ylc.deliverySourceWest || '-',
                },
                {
                  type: 'text',
                  label: '納品業者（西）',
                  value: ylc.deliveryVendorWest || '-',
                },
                {
                  type: 'text',
                  label: 'YLC納品数（西）',
                  value: ylc.ylcDeliveryNumWest || '-',
                },
                {
                  type: 'text',
                  label: '入り数（西）１',
                  value: ylc.packingCountWest1 || '-',
                },
                {
                  type: 'text',
                  label: '箱数（西）１',
                  value: ylc.boxCountWest1 || '-',
                },
                {
                  type: 'text',
                  label: '入り数（西）２',
                  value: ylc.packingCountWest2 || '-',
                },
                {
                  type: 'text',
                  label: '箱数（西）２',
                  value: ylc.boxCountWest2 || '-',
                },
                {
                  type: 'text',
                  label: 'YLC確保数（西）',
                  value: ylc.ylcSecureNumWest || '-',
                },
                {
                  type: 'text',
                  label: 'サイズ',
                  value: `（W） ${ylc.width || '-'} mm （D） ${ylc.depth || '-'} mm （H） ${ylc.height || '-'} mm`,
                },
              ]}
            />
          </>
        ),
      };
    });
    setYlcData(ylcData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <>
      <Header />
      <Main>
        <ApiGetPromotionDetailProvider callback={apiCallbackHandler}>
          <Container>
            <Breadcrumb
              list={[
                {
                  label: 'TOP',
                  url: '/CMN001',
                },
                {
                  label: '販促品一覧',
                },
                {
                  label: response?.promotionalName || '',
                },
              ]}
            />

            <Section>
              <H1>{response?.promotionalName}</H1>

              <Section hasBg>
                <H2>販促品詳細</H2>

                <SectionInner>
                  <DataTable data={promotionDetailData} />

                  <Section>
                    <H3>施策情報</H3>

                    {measuresData.length === 0 && <Text>施策情報が入力されていないため表示する項目はありません。</Text>}

                    {measuresData.length > 0 && <TabMenu data={measuresData} />}
                  </Section>

                  <Section>
                    <H3>納品情報</H3>

                    {ylcData.length === 0 && <Text>納品情報が入力されていないため表示する項目はありません。</Text>}

                    {ylcData.length > 0 && <TabMenu data={ylcData} />}
                  </Section>

                  <ButtonContainer isLeft>
                    <Button type="secondary" icon="arrow-left" iconPosition="before" size="small" isFlexible text="販促品一覧へ戻る" to="/PRF001" />
                  </ButtonContainer>
                </SectionInner>
              </Section>
            </Section>
          </Container>
        </ApiGetPromotionDetailProvider>
      </Main>
      <Footer />
    </>
  );
};
