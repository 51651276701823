import React from 'react';
import style from './style.module.scss';

const AlignItems = {
  start: 'start',
  center: 'center',
  end: 'end',
} as const;
type AlignItemsProps = typeof AlignItems[keyof typeof AlignItems];

const JustifyContent = {
  center: 'center',
  start: 'start',
  end: 'end',
  'space-between': 'space-between',
  'space-around': 'space-around',
  'space-evenly': 'space-evenly',
  stretch: 'stretch',
  normal: 'normal',
} as const;
type JustifyContentProps = typeof JustifyContent[keyof typeof JustifyContent];

const Gap = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  xsmall: 'xsmall',
} as const;
type GapProps = typeof Gap[keyof typeof Gap];

type Props = {
  alignItems?: AlignItemsProps;
  justifyContent?: JustifyContentProps;
  gap?: GapProps;
  separator?: Boolean;
  nowrap?: boolean;
  multiLine?: boolean;
  mt?: number;
  mb?: number;
  className?: string;
};

export const FlexRow: React.FC<Props> = ({
  children,
  alignItems = 'normal',
  justifyContent = 'normal',
  gap = 'medium',
  separator = false,
  nowrap = false,
  multiLine = false,
  mt,
  mb,
  className = '',
}) => (
  <div
    className={`${style['layout-row']} layout-row is-alignitems-${alignItems} is-justifycontent-${justifyContent} is-gap-${gap} ${
      separator ? 'has-separator' : ''
    } ${nowrap ? 'is-nowrap' : ''} ${multiLine ? 'is-multiline' : ''} ${className} has-children-${React.Children.count(children)}`}
    style={{ ...(mt !== undefined && { marginTop: `${mt}px` }), ...(mb !== undefined && { marginBottom: `${mb}px` }) }}
  >
    {children}
  </div>
);
