import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { FormGrid, FormField } from 'components/Atoms/Layout';
import { Note, ErrorText } from 'components/Atoms/Typography';
import { NoteList } from 'components/Atoms/List';
import { Checkbox } from 'components/Atoms/Form';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { UndecidedButton } from 'components/Molecules/UndecidedButton';
import { UNDECIDED_VALUE, RHF_UNREGISTER_PARAMS } from 'constants/index';
import style from './style.module.scss';
import type { FieldError } from 'react-hook-form';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';

type ListProps = {
  name: string;
  label: string;
  value: string;
  defaultValue?: string;
};

type Props = {
  list: ListProps[];
  label?: string;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  isRequired?: boolean;
  help?: string;
  hasUndecidedButton?: boolean;
  isHorizontal?: boolean;
  size?: FormGridSizeProps;
  // onChange?: (value: string) => void;
};

type CheckboxComponentProps = ListProps & {
  names: string[];
  undecided: boolean;
  setCheckboxError: React.Dispatch<React.SetStateAction<FieldError | undefined>>;
  setUndecided: React.Dispatch<React.SetStateAction<boolean>>;
};

const CheckboxComponent = React.forwardRef(
  ({ name, value, label, names, defaultValue = '', undecided, setCheckboxError, setUndecided }: CheckboxComponentProps, ref) => {
    const { control, trigger, unregister } = useFormContext();
    const {
      field,
      fieldState: { error },
    } = useController({ control, name, defaultValue, shouldUnregister: true });
    const [backupValue, setBackupValue] = React.useState('');

    const componentWillUnmount = React.useCallback(() => {
      console.log(`unregister: ${name}`);
      unregister(name, RHF_UNREGISTER_PARAMS);
    }, [name, unregister]);

    const handleChange = React.useCallback(
      (checked: boolean) => {
        let changedValue = '';
        if (checked) changedValue = value;
        field.onChange(changedValue);
        trigger(names);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [field.onChange, names, trigger, value],
    );

    const handleUndecided = React.useCallback(
      (checked: boolean) => {
        if (checked) {
          setBackupValue(field.value);
          field.onChange(UNDECIDED_VALUE);
          return;
        }
        field.onChange(backupValue);
        setBackupValue('');
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [backupValue, field.value, field.onChange],
    );

    React.useImperativeHandle(ref, () => ({
      handleUndecided,
      fieldValue: field.value,
    }));

    React.useEffect(() => {
      setUndecided(field.value === UNDECIDED_VALUE);
    }, [field.value, setUndecided]);

    React.useEffect(() => {
      const hasError = names.some(() => {
        if (error) {
          setCheckboxError(error);
          return true;
        }
        return false;
      });
      if (!hasError) setCheckboxError(undefined);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    React.useEffect(() => {
      return () => componentWillUnmount();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Checkbox
        name={name}
        refs={field.ref}
        label={label}
        checked={field.value === value}
        disabled={undecided}
        isInvalid={error ? true : false}
        onChange={handleChange}
        className={style['form-checkboxList-item']}
      />
    );
  },
);

export const FormCheckboxList: React.FC<Props> = ({
  list,
  label = '',
  note,
  notes,
  isRequired = false,
  help,
  hasUndecidedButton = false,
  isHorizontal = false,
  size = 'large',
}) => {
  const { trigger } = useFormContext();
  const [checkboxError, setCheckboxError] = React.useState<FieldError | undefined>();
  const [undecided, setUndecided] = React.useState(false);
  const names = React.useMemo(() => list.map((obj: ListProps) => obj.name), [list]);
  const checkboxRef = React.useRef<any>([...Array(names.length)].map(() => React.createRef()));

  const handleUndecided = React.useCallback(
    (checked: boolean) => {
      checkboxRef.current.forEach((elem: { current: { handleUndecided: (arg0: boolean) => void } }) => {
        elem.current.handleUndecided(checked);
      });
      trigger(names);
    },
    [names, trigger],
  );

  return (
    <FormGrid className={`${style['form-checkboxList']} ${isHorizontal ? 'is-horizontal' : ''}`} size={size}>
      <FormLabelSet label={label} isRequired={isRequired} help={help} size={size} mt={size === 'small' ? 4 : undefined} />

      <FormField>
        <div className={style['form-checkboxList-inner']}>
          {list.map((elem, index) => (
            <CheckboxComponent
              key={elem.name}
              ref={checkboxRef.current[index]}
              name={elem.name}
              value={elem.value}
              label={elem.label}
              defaultValue={elem.defaultValue}
              setCheckboxError={setCheckboxError}
              names={names}
              undecided={undecided}
              setUndecided={setUndecided}
            />
          ))}
        </div>
        <ErrorText error={checkboxError?.message} />
        {note && <Note>{note}</Note>}
        {notes && <NoteList list={notes} />}
      </FormField>

      <UndecidedButton hasUndecidedButton={hasUndecidedButton} undecided={undecided} onChangeDecided={handleUndecided} />
    </FormGrid>
  );
};
