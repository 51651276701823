import React from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from 'components/Atoms/Loading';
import { ENDPOINT_GET_PROMOTION_API } from 'constants/index';
import { NetworkErrorUpdate } from 'context';
import { useApiCall } from 'hooks/useApiCall';
import type { JanCodeParamsProps } from 'types';

type Props = {
  callback?: (result?: {}) => void;
};

export const ApiGetPromotionDetailProvider: React.FC<Props> = ({ callback = () => {}, children }) => {
  const apiCall = useApiCall();
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  const { janCode } = useParams<JanCodeParamsProps>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isShow, setShow] = React.useState(false);

  React.useEffect(() => {
    if (janCode) {
      apiCall({
        method: 'GET',
        url: `${ENDPOINT_GET_PROMOTION_API}/${janCode}`,
      })
        .then((res) => {
          callback(res.data.result);
          setShow(true);
        })
        .catch((error) => {
          if (error.response?.status === 400) setNetworkError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setShow(true);
      setIsLoading(false);
      callback(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [janCode]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && isShow && children}
    </>
  );
};
