import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { useApiCall } from 'hooks/useApiCall';
import { CognitoUserState, DirtyUpdate, Server400ErrorUpdate } from 'context';
import { ENDPOINT_POST_AID_DETAIL_API, ENDPOINT_AID_RECOMMENDATION_API } from 'constants/index';
import type { MeasureParamsProps, DesignDetailProps, AuthUser } from 'types';

type Props = {
  formData: DesignDetailProps;
  getRequestData: () => { [key: string]: any };
};

export const SubmitButton: React.FC<Props> = ({ formData, getRequestData }) => {
  const { measureId } = useParams<MeasureParamsProps>();
  const history = useHistory();
  const userInfo = React.useContext<AuthUser>(CognitoUserState);
  const setDirty = React.useContext(DirtyUpdate);
  const setServer400Error = React.useContext(Server400ErrorUpdate);
  const apiCall = useApiCall();
  const [isLoading, setIsLoading] = React.useState(false);

  const handlePostDesignDetail = React.useCallback(async (): Promise<number> => {
    const data = getRequestData();
    data.id = measureId;
    data.updateInCharge = userInfo?.username;
    console.log(data);

    const result = await apiCall({
      method: 'POST',
      url: ENDPOINT_POST_AID_DETAIL_API,
      data: data,
    })
      .then((res: { status: number }) => {
        console.log(res);
        return res.status;
      })
      .catch((error) => {
        if (error?.response?.status === 400) setServer400Error(error.response.data.error);
        setIsLoading(false);
        console.log(error);
        console.log(error?.response);
        return error?.response?.status;
      });
    return result;
  }, [apiCall, getRequestData, userInfo, measureId, setServer400Error]);

  const handlePostRecommendation = React.useCallback(async (): Promise<number> => {
    const result = await apiCall({
      method: 'POST',
      url: `${ENDPOINT_AID_RECOMMENDATION_API}/${measureId}/`,
      data: {
        updateInCharge: userInfo?.username,
      },
    })
      .then((res: { status: number }) => {
        console.log(res);
        return res.status;
      })
      .catch((error: { response: { status: number } }) => {
        setIsLoading(false);
        console.log(error);
        console.log(error?.response);
        return error?.response?.status;
      });
    return result;
  }, [apiCall, measureId, userInfo]);

  const handleSubmit = React.useCallback(async () => {
    setIsLoading(true);
    const resultPostDesignDetail = await handlePostDesignDetail();
    if (resultPostDesignDetail === 200) {
      const resultPostRecommendation = await handlePostRecommendation();
      if (resultPostRecommendation === 200) {
        history.push(`/AID004/${measureId}`, formData);
      } else {
        // 400 or 500
        setIsLoading(false);
      }
    } else {
      // 400 or 500
      setIsLoading(false);
    }
  }, [handlePostDesignDetail, handlePostRecommendation, history, measureId, formData]);

  React.useEffect(() => {
    setDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />

      <Button type="primary" onClick={handleSubmit} text="入力完了" />
    </>
  );
};
