import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useTranslation } from 'react-i18next';
import { Flex, FormGrid, FormField } from 'components/Atoms/Layout';
import { Note, Unit, ErrorText } from 'components/Atoms/Typography';
import { NoteList } from 'components/Atoms/List';
import { TextInput } from 'components/Atoms/Form';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { UndecidedButton } from 'components/Molecules/UndecidedButton';
import { DATE_FORMAT_DATEPICKER, UNDECIDED_VALUE, RHF_UNREGISTER_PARAMS, EMPTY_LABEL } from 'constants/index';
import { HolidaysState } from 'context';
import { numberToDayString, isValidDate } from 'utils';
import style from './style.module.scss';
import IconCalendar from 'images/icon_calendar.png';
import { TimeField } from './TimeField';
import type { FieldError } from 'react-hook-form';
import type { HolidayProps } from 'types';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';

registerLocale('ja', ja);
dayjs.extend(isSameOrAfter);

type Props = {
  names: { year: string; month: string; date: string; hour?: string; minute?: string };
  defaultValues?: { year: string; month: string; date: string; hour?: string; minute?: string };
  label?: string;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  isRequired?: boolean;
  help?: string;
  hasUndecidedButton?: boolean;
  size?: FormGridSizeProps;
  hasDayOfWeek?: boolean;
};

export const FormDate: React.FC<Props> = ({
  names,
  defaultValues = { year: '', month: '', date: '', hour: '', minute: '' },
  label,
  note,
  notes,
  isRequired = false,
  help,
  hasUndecidedButton = false,
  size = 'large',
  hasDayOfWeek = true,
}) => {
  const { control, trigger, unregister } = useFormContext();
  const { t } = useTranslation();
  const holidays: HolidayProps = React.useContext(HolidaysState);
  const timeRef = React.useRef<any>();

  const {
    field: yearField,
    fieldState: { error: yearError },
  } = useController({ control, name: names.year, defaultValue: defaultValues.year, shouldUnregister: true });
  const {
    field: monthField,
    fieldState: { error: monthError },
  } = useController({ control, name: names.month, defaultValue: defaultValues.month, shouldUnregister: true });
  const {
    field: dateField,
    fieldState: { error: dateError },
  } = useController({ control, name: names.date, defaultValue: defaultValues.date, shouldUnregister: true });

  const [dayOfWeek, setDayOfWeek] = React.useState(EMPTY_LABEL);

  const [backupYearValue, setBackupYearValue] = React.useState('');
  const [backupMonthValue, setBackupMonthValue] = React.useState('');
  const [backupDateValue, setBackupDateValue] = React.useState('');

  const [calendarError, setCalendarError] = React.useState<FieldError | undefined>();

  const [selectedDatePickerValue, setSelectedDatePickerValue] = React.useState<Date>();

  const [undecided, setUndecided] = React.useState(false);

  const today = React.useMemo(() => dayjs().hour(0).minute(0).second(0).millisecond(0).toDate(), []);

  const componentWillUnmount = React.useCallback(() => {
    console.log(`unregister: ${(names.year, names.month, names.date)}`);
    unregister([names.year, names.month, names.date], RHF_UNREGISTER_PARAMS);
  }, [names.date, names.month, names.year, unregister]);

  const handleClickCalendar = React.useCallback(() => {
    const yearValue = Number(yearField.value);
    const monthValue = Number(monthField.value) - 1;
    const dateValue = Number(dateField.value);
    const dayjsObject = dayjs().year(yearValue).month(monthValue).date(dateValue);
    if (dayjsObject.isValid() && dayjsObject.isSameOrAfter(today)) {
      setSelectedDatePickerValue(dayjsObject.toDate());
    } else {
      setSelectedDatePickerValue(undefined);
    }
  }, [today, yearField.value, monthField.value, dateField.value, setSelectedDatePickerValue]);

  const handleChangeCalendar = React.useCallback(
    (date: Date) => {
      const dayjsObject = dayjs(date);
      const yearValue = String(dayjsObject.year());
      const monthValue = String(dayjsObject.month() + 1).padStart(2, '0');
      const dateValue = String(dayjsObject.date()).padStart(2, '0');
      yearField.onChange(yearValue);
      monthField.onChange(monthValue);
      dateField.onChange(dateValue);
      setSelectedDatePickerValue(date);
      trigger([names.year, names.month, names.date]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [names.year, names.month, names.date, yearField.onChange, monthField.onChange, dateField.onChange, setSelectedDatePickerValue, trigger],
  );

  const handleUndecided = React.useCallback(
    (checked: boolean) => {
      if (names.hour && names.minute) {
        timeRef.current && timeRef.current.handleUndecided(checked);
      }
      if (checked) {
        setBackupYearValue(yearField.value);
        setBackupMonthValue(monthField.value);
        setBackupDateValue(dateField.value);
        yearField.onChange(UNDECIDED_VALUE);
        monthField.onChange(UNDECIDED_VALUE);
        dateField.onChange(UNDECIDED_VALUE);
        trigger([names.year, names.month, names.date]);
        return;
      }
      yearField.onChange(backupYearValue);
      monthField.onChange(backupMonthValue);
      dateField.onChange(backupDateValue);
      setBackupYearValue('');
      setBackupMonthValue('');
      setBackupDateValue('');
      trigger([names.year, names.month, names.date]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      backupYearValue,
      backupMonthValue,
      backupDateValue,
      names.year,
      names.month,
      names.date,
      yearField.value,
      monthField.value,
      dateField.value,
      yearField.onChange,
      monthField.onChange,
      dateField.onChange,
      trigger,
    ],
  );

  const handleChangeYear = React.useCallback(
    (value) => {
      yearField.onChange(value);
    },
    [yearField],
  );

  const handleBlurYear = React.useCallback(() => {
    yearField.onBlur();
    trigger([names.month, names.date]);
  }, [names.month, names.date, yearField, trigger]);

  const handleChangeMonth = React.useCallback(
    (value) => {
      monthField.onChange(value);
    },
    [monthField],
  );

  const handleBlurMonth = React.useCallback(() => {
    monthField.onBlur();
    trigger([names.year, names.date]);
  }, [names.year, names.date, monthField, trigger]);

  const handleChangeDate = React.useCallback(
    (value) => {
      dateField.onChange(value);
    },
    [dateField],
  );

  const handleBlurDate = React.useCallback(() => {
    dateField.onBlur();
    trigger([names.year, names.month]);
  }, [names.year, names.month, dateField, trigger]);

  React.useEffect(() => {
    const yearErrorType = yearError?.type;
    const monthErrorType = monthError?.type;
    const dateErrorType = dateError?.type;
    // console.log(yearError, monthError, dateError);
    if (yearErrorType === 'required' || monthErrorType === 'required' || dateErrorType === 'required') {
      setCalendarError({ message: t('ERR-REQUIRED'), type: 'required' });
    } else if (yearErrorType === 'isNumber' || monthErrorType === 'isNumber' || dateErrorType === 'isNumber') {
      setCalendarError({ message: t('ERR-TYPE-NUMBER'), type: 'isNumber' });
    } else if (yearErrorType === 'isValidDate' || monthErrorType === 'isValidDate' || dateErrorType === 'isValidDate') {
      setCalendarError({ message: t('ERR-INVALID-DATE'), type: 'isValidDate' });
    } else if (yearErrorType === 'isFutureDate' || monthErrorType === 'isFutureDate' || dateErrorType === 'isFutureDate') {
      setCalendarError({ message: t('ERR-PAST-DATE'), type: 'isFutureDate' });
    } else if (yearErrorType === 'isFutureDateCompared' || monthErrorType === 'isFutureDateCompared' || dateErrorType === 'isFutureDateCompared') {
      setCalendarError({ message: yearError?.message, type: 'isFutureDateCompared' });
    } else if (yearErrorType === 'isAnotherDate' || monthErrorType === 'isAnotherDate' || dateErrorType === 'isAnotherDate') {
      setCalendarError({ message: yearError?.message, type: 'isAnotherDate' });
    } else if (yearErrorType === 'isDayOfWeek' || monthErrorType === 'isDayOfWeek' || dateErrorType === 'isDayOfWeek') {
      setCalendarError({ message: t('ERR-WEEKDAYS'), type: 'isDayOfWeek' });
    } else if (yearErrorType === 'isNotHoliday' || monthErrorType === 'isNotHoliday' || dateErrorType === 'isNotHoliday') {
      setCalendarError({ message: t('ERR-WEEKDAYS'), type: 'isNotHoliday' });
    } else {
      setCalendarError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearError, monthError, dateError]);

  React.useEffect(() => {
    setUndecided(yearField.value === UNDECIDED_VALUE);
  }, [yearField.value]);

  React.useEffect(() => {
    if (!hasDayOfWeek) return;
    const dayjsObject = dayjs()
      .year(Number(yearField.value))
      .month(Number(monthField.value) - 1)
      .date(Number(dateField.value));
    if (yearField.value && monthField.value && dateField.value && isValidDate({ year: yearField.value, month: monthField.value, date: dateField.value })) {
      setDayOfWeek(dayjsObject.format('ddd'));
    } else {
      setDayOfWeek(EMPTY_LABEL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearField.value, monthField.value, dateField.value]);

  React.useEffect(() => {
    return () => componentWillUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CalendarIcon = React.forwardRef<HTMLSpanElement, React.HTMLProps<HTMLSpanElement>>(({ onClick }: any, ref) => {
    return (
      <span className={`${style['datepicker-icon-calendar']} ${undecided ? style['datepicker-icon-calendar-disabled'] : ''}`} onClick={onClick} ref={ref}>
        <img src={IconCalendar} alt="カレンダーを開く" width="20px" />
      </span>
    );
  });

  return (
    <FormGrid className={style['form-date']} size={size}>
      {label && <FormLabelSet label={label} isRequired={isRequired} help={help} size={size} />}

      <FormField>
        <Flex alignItems="center">
          <TextInput
            name={yearField.name}
            value={yearField.value}
            refs={yearField.ref}
            onChange={handleChangeYear}
            onBlur={handleBlurYear}
            className={undecided ? 'is-undecided' : ''}
            disabled={undecided}
            isInvalid={yearError ? true : false}
            type="year"
            placeholder="2021"
            maxLength={4}
          />
          <Unit text="年" />

          <TextInput
            name={monthField.name}
            value={monthField.value}
            refs={monthField.ref}
            onChange={handleChangeMonth}
            onBlur={handleBlurMonth}
            className={undecided ? 'is-undecided' : ''}
            disabled={undecided}
            isInvalid={monthError ? true : false}
            type="month"
            placeholder="01"
            maxLength={2}
          />
          <Unit text="月" />

          <TextInput
            name={dateField.name}
            value={dateField.value}
            refs={dateField.ref}
            onChange={handleChangeDate}
            onBlur={handleBlurDate}
            className={undecided ? 'is-undecided' : ''}
            disabled={undecided}
            isInvalid={dateError ? true : false}
            type="date"
            placeholder="01"
            maxLength={2}
          />
          <Unit text="日" />

          {hasDayOfWeek && <Unit text={`（${dayOfWeek}）`} />}

          <DatePicker
            wrapperClassName={style['form-datepicker-wrapper']}
            calendarClassName={style['datepicker-calendar']}
            popperClassName={style['datepicker-calendar-popper']}
            dayClassName={(date) => {
              const dayjsObject = dayjs(date);
              const dayNumber = dayjsObject.day();
              const isHoliday = holidays.some((holiday) => {
                const holidayDateObject = dayjs(holiday);
                return dayjsObject.isSame(holidayDateObject, 'day');
              });
              return `${style['datepicker-calendar-date']} is-${numberToDayString(dayNumber)} ${
                dayjsObject.isSame(dayjs(selectedDatePickerValue), 'day') ? 'is-selected' : ''
              } ${isHoliday ? 'is-holiday' : ''}`;
            }}
            weekDayClassName={() => style['datepicker-calendar-week']}
            monthClassName={() => style['datepicker-calendar-month']}
            calendarContainer={({ children }) => <div className={style['datepicker-calendar-container']}>{children}</div>}
            customInput={<CalendarIcon />}
            // previousMonthButtonLabel={<>＜</>}
            // nextMonthButtonLabel={<>＞</>}
            dateFormat={DATE_FORMAT_DATEPICKER}
            disabled={undecided}
            selected={selectedDatePickerValue}
            // minDate={today}
            locale="ja"
            disabledKeyboardNavigation
            onInputClick={handleClickCalendar}
            onChange={handleChangeCalendar}
            // onCalendarOpen={() => setIsOpen(true)}
            // onCalendarClose={() => setIsOpen(false)}
            renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
              <div className={style['datepicker-calendar-header']}>
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  <SvgIcon type="arrow-left" fill="#373940" width={6} />
                </button>
                {dayjs(date).year().toString()}年{String(Number(dayjs(date).month().toString()) + 1)}月
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  <SvgIcon type="arrow-right" fill="#373940" width={6} />
                </button>
              </div>
            )}
          />
        </Flex>
        <ErrorText error={calendarError?.message} />

        {names.hour && names.minute && (
          <TimeField
            ref={timeRef}
            names={{ hour: names.hour, minute: names.minute }}
            defaultValues={{ hour: defaultValues.hour, minute: defaultValues.minute }}
            undecided={undecided}
          />
        )}

        {note && <Note>{note}</Note>}
        {notes && <NoteList list={notes} />}
      </FormField>

      <UndecidedButton name={names.year} hasUndecidedButton={hasUndecidedButton} undecided={undecided} onChangeDecided={handleUndecided} />
    </FormGrid>
  );
};
