import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, FlexRow, FlexCol } from 'components/Atoms/Layout';
import { Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Checkbox } from 'components/Atoms/Form';
import { Modal } from 'components/Organisms/Modal';
import { RHF_RESET_COPY_PARAMS } from 'constants/index';
import type { ModuleAssignmentProps } from 'types';

type Props = {
  selectedTabIndex: number;
};

export const CopyModal: React.FC<Props> = ({ selectedTabIndex }) => {
  const { getValues, reset } = useFormContext();
  const [data, setData] = React.useState<ModuleAssignmentProps[]>();
  const [isOpen, setOpen] = React.useState(false);
  const [isCheckedAll, setCheckedAll] = React.useState(false);
  const [isChecked, setChecked] = React.useState<boolean[]>();

  const resetModal = React.useCallback(() => {
    setCheckedAll(false);
    setChecked([]);
  }, []);

  const handleOpen = React.useCallback(() => {
    const values = getValues().moduleAssignments;
    setData(values);

    const defaultChecked = values.map(() => false);
    setChecked(defaultChecked);

    setOpen(true);
  }, [getValues]);

  const handleCancel = React.useCallback(() => {
    setOpen(false);
    resetModal();
  }, [resetModal]);

  const handleSubmit = React.useCallback(() => {
    const data = getValues();
    const fromData = getValues().moduleAssignments[selectedTabIndex];
    isChecked?.forEach((flag, moduleAssignmentIndex) => {
      if (flag) {
        // setValue(`moduleAssignments[${moduleAssignmentIndex}].designTarget`, ''); // 素材指定情報をアンマウントするため一旦設定
        data.moduleAssignments[moduleAssignmentIndex].designTarget = fromData.designTarget;
        data.moduleAssignments[moduleAssignmentIndex].modules = fromData.modules;
        // data.moduleAssignments[moduleAssignmentIndex].designTarget = '';
        // data.moduleAssignments[moduleAssignmentIndex].modules = [];
      }
    });
    reset({}, RHF_RESET_COPY_PARAMS); // 素材指定情報をアンマウントするため一旦設定
    reset(data, RHF_RESET_COPY_PARAMS);

    setTimeout(() => console.log(getValues()), 1000);

    setOpen(false);
    resetModal();
  }, [resetModal, getValues, reset, selectedTabIndex, isChecked]);

  const handleChangeCheckboxAll = React.useCallback(
    (checked: boolean) => {
      setCheckedAll(checked);
      const newChecked = isChecked?.map((elem, i) => {
        return checked;
      });
      setChecked(newChecked);
    },
    [isChecked],
  );

  const handleChangeCheckbox = React.useCallback(
    (checked: boolean, index: number) => {
      const newChecked = isChecked?.map((elem, i) => {
        if (i === index) return checked;
        return elem;
      });
      setChecked(newChecked);

      if (newChecked?.every((elem) => elem === true)) {
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }
    },
    [isChecked],
  );

  return React.useMemo(
    () => (
      <>
        <Button type="secondary" isFlexible onClick={handleOpen} text="他のバナーに素材指定情報をコピー" />

        <Modal
          isShow={isOpen}
          hasSubmit={true}
          hasCancel={true}
          cancelText="キャンセル"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          disabledSubmit={!isChecked?.includes(true)}
        >
          <Text size={20} bold align="center">
            制作物の選択
          </Text>
          <Box mt={30}>
            <Checkbox name="copyAll" label="すべて選択" checked={isCheckedAll} onChange={handleChangeCheckboxAll} align="center" />

            <Box mt={20} size="small" color="white" scrollY maxHeight={300}>
              <FlexRow gap="small" multiLine>
                {data &&
                  data.map((elem, index) => (
                    <FlexCol key={index}>
                      <Checkbox
                        name={`copy${index}`}
                        label={`${elem.productNumber}: ${elem.productType}`}
                        checked={isChecked?.[index]}
                        onChange={(checked: boolean) => {
                          handleChangeCheckbox(checked, index);
                        }}
                      />
                    </FlexCol>
                  ))}
              </FlexRow>
            </Box>
          </Box>
        </Modal>
      </>
    ),
    [data, handleCancel, handleChangeCheckbox, handleChangeCheckboxAll, handleOpen, handleSubmit, isChecked, isCheckedAll, isOpen],
  );
};
