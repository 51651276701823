import React from 'react';
import { useParams } from 'react-router-dom';
import { Main, Container, Section, SectionInner, ButtonContainer, PositionContainer, PositionItem, Flex, FlexRow, FlexCol } from 'components/Atoms/Layout';
import { H1, H2, H3, H4, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { LinkList } from 'components/Atoms/List';
import { Box } from 'components/Atoms/Layout/Box';
import { Header } from 'components/Business/Header';
import { CampaignStatusTag } from 'components/Molecules/Tag';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { DataTable } from 'components/Organisms/DataTable';
import { TabMenu } from 'components/Organisms/TabMenu';
import { ApiGetMeasuresDetailProvider } from 'components/Business/Api';
import {
  PRODUCTIONCOMPANY_VALUES,
  ADHOCMAIL_DELIVERYTYPE_VALUES,
  ADJUSTMENTDESIREDCONFDATE_VALUES,
  DESIREDLIST_VALUES,
  CRMMAIL_DELIVERYTYPE_VALUES,
  AFMAIL_DELIVERYTYPE_VALUES,
  FAVORITEBRANDMAIL_DELIVERYTYPE_VALUES,
  POSTINGAREA_VALUES,
  POSTINGENDCONDITIONS_VALUES,
  PRODUCTCONTENT_VALUES,
} from 'constants/index';
import type { MeasureParamsProps, MeasureDetailProps } from 'types';
import type { DataTableProps } from 'components/Organisms/DataTable';
import type { TabDataProps } from 'components/Organisms/TabMenu';

export const CPF009: React.FC = () => {
  const { measureId } = useParams<MeasureParamsProps>();
  const [response, setResponse] = React.useState<MeasureDetailProps>();
  const [webProductionDetailData, setWebProductionDetailData] = React.useState<DataTableProps[]>([]);
  const [lpPcNumberData, setLpPcNumberData] = React.useState<DataTableProps[]>([]);
  const [lpPcData, setLpPcData] = React.useState<TabDataProps[]>([]);
  const [lpSpNumberData, setLpSpNumberData] = React.useState<DataTableProps[]>([]);
  const [lpSpData, setLpSpData] = React.useState<TabDataProps[]>([]);
  const [adhocMailNumberData, setAdhocMailNumberData] = React.useState<DataTableProps[]>([]);
  const [adhocMailData, setAdhocMailData] = React.useState<TabDataProps[]>([]);
  const [crmMailNumberData, setCrmMailNumberData] = React.useState<DataTableProps[]>([]);
  const [crmMailData, setCrmMailData] = React.useState<TabDataProps[]>([]);
  const [afMailNumberData, setAfMailNumberData] = React.useState<DataTableProps[]>([]);
  const [afMailData, setAfMailData] = React.useState<TabDataProps[]>([]);
  const [favoriteBrandMailNumberData, setFavoriteBrandMailNumberData] = React.useState<DataTableProps[]>([]);
  const [favoriteBrandMailData, setFavoriteBrandMailData] = React.useState<TabDataProps[]>([]);
  const [freeAreaNumberData, setFreeAreaNumberData] = React.useState<DataTableProps[]>([]);
  const [freeAreaData, setFreeAreaData] = React.useState<TabDataProps[]>([]);
  const [bigBannerNumberData, setBigBannerNumberData] = React.useState<DataTableProps[]>([]);
  const [bigBannerData, setBigBannerData] = React.useState<TabDataProps[]>([]);
  const [purposeBannerNumberData, setPurposeBannerNumberData] = React.useState<DataTableProps[]>([]);
  const [purposeBannerData, setPurposeBannerData] = React.useState<TabDataProps[]>([]);
  const [myPageBannerNumberData, setMyPageBannerNumberData] = React.useState<DataTableProps[]>([]);
  const [myPageBannerData, setMyPageBannerData] = React.useState<TabDataProps[]>([]);
  const [imageBannerNumberData, setImageBannerNumberData] = React.useState<DataTableProps[]>([]);
  const [externalBannerNumberData, setExternalBannerNumberData] = React.useState<DataTableProps[]>([]);
  const [cartImageNumberData, setCartImageNumberData] = React.useState<DataTableProps[]>([]);
  const [cartImageData, setCartImageData] = React.useState<TabDataProps[]>([]);
  const [giftSetImageNumberData, setGiftSetImageNumberData] = React.useState<DataTableProps[]>([]);
  const [lineMessageNumberData, setLineMessageNumberData] = React.useState<DataTableProps[]>([]);
  const [lineCarouselNumberData, setLineCarouselNumberData] = React.useState<DataTableProps[]>([]);
  const [lineHomeNumberData, setLineHomeNumberData] = React.useState<DataTableProps[]>([]);
  const [lineRichNumberData, setLineRichNumberData] = React.useState<DataTableProps[]>([]);
  const [lineRichSplitNumberData, setLineRichSplitNumberData] = React.useState<DataTableProps[]>([]);
  const [twitterBannerNumberData, setTwitterBannerNumberData] = React.useState<DataTableProps[]>([]);
  const [rToasterBannerNumberData, setRToasterBannerNumberData] = React.useState<DataTableProps[]>([]);
  const [colorSimulatorNumberData, setColorSimulatorNumberData] = React.useState<DataTableProps[]>([]);
  const [otherBannerNumberData, setOtherBannerNumberData] = React.useState<DataTableProps[]>([]);
  const [crmDetailsNumberData, setCrmDetailsNumberData] = React.useState<DataTableProps[]>([]);
  const [otherBannerData, setOtherBannerData] = React.useState<TabDataProps[]>([]);

  const apiCallbackHandler = React.useCallback((result) => {
    setResponse(result);
  }, []);

  React.useEffect(() => {
    if (!response) return;

    const webProductionDetailData: DataTableProps[] = [
      {
        type: 'radio',
        label: '制作会社',
        value: response?.webProductionDetail?.productionCompany,
        master: PRODUCTIONCOMPANY_VALUES,
      },
      {
        condition: response?.webProductionDetail?.productionCompany === '2',
        type: 'array',
        value: response?.webProductionDetail?.companyDetail,
        content: (key: number) => {
          return [
            {
              type: 'text',
              label: `${key + 1}:制作会社名`,
              value: response?.webProductionDetail?.companyDetail?.[key]?.companyName,
            },
            {
              type: 'text',
              label: `${key + 1}:担当者氏名`,
              value: response?.webProductionDetail?.companyDetail?.[key]?.producer,
            },
            {
              type: 'text',
              label: `${key + 1}:担当者メールアドレス`,
              value: response?.webProductionDetail?.companyDetail?.[key]?.producerMailAddress,
            },
          ];
        },
      },
    ];
    setWebProductionDetailData(webProductionDetailData);

    const lpPcNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.lpPc,
      },
    ];
    setLpPcNumberData(lpPcNumberData);

    const lpPcData: TabDataProps[] =
      response?.webProductionDetail?.lpPc?.map((elem, index) => {
        return {
          label: `${index + 1}:PC`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'datetime',
                    label: 'LP公開日',
                    value: {
                      year: elem?.lpReleaseDateYear,
                      month: elem?.lpReleaseDateMonth,
                      date: elem?.lpReleaseDateDay,
                      hour: elem?.lpReleaseDateHours,
                      minute: elem?.lpReleaseDateMinutes,
                    },
                  },
                  {
                    type: 'datetime',
                    label: 'LP公開終了日',
                    value: {
                      year: elem?.lpReleaseEndDateYear,
                      month: elem?.lpReleaseEndDateMonth,
                      date: elem?.lpReleaseEndDateDay,
                      hour: elem?.lpReleaseEndDateHours,
                      minute: elem?.lpReleaseEndDateMinutes,
                    },
                  },
                  {
                    type: 'text',
                    label: 'URL',
                    value: elem?.pcUrl,
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setLpPcData(lpPcData);

    const lpSpNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.lpSp,
      },
    ];
    setLpSpNumberData(lpSpNumberData);

    const lpSpData: TabDataProps[] =
      response?.webProductionDetail?.lpSp?.map((elem, index) => {
        return {
          label: `${index + 1}:SP`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'datetime',
                    label: 'LP公開日',
                    value: {
                      year: elem?.lpReleaseDateYear,
                      month: elem?.lpReleaseDateMonth,
                      date: elem?.lpReleaseDateDay,
                      hour: elem?.lpReleaseDateHours,
                      minute: elem?.lpReleaseDateMinutes,
                    },
                  },
                  {
                    type: 'datetime',
                    label: 'LP公開終了日',
                    value: {
                      year: elem?.lpReleaseEndDateYear,
                      month: elem?.lpReleaseEndDateMonth,
                      date: elem?.lpReleaseEndDateDay,
                      hour: elem?.lpReleaseEndDateHours,
                      minute: elem?.lpReleaseEndDateMinutes,
                    },
                  },
                  {
                    type: 'text',
                    label: 'URL',
                    value: elem?.spUrl,
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setLpSpData(lpSpData);

    const adhocMailNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.adhocMail,
      },
    ];
    setAdhocMailNumberData(adhocMailNumberData);

    const adhocMailData: TabDataProps[] =
      response?.webProductionDetail?.adhocMail?.map((elem, index) => {
        return {
          label: `${index + 1}:adhocメール`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'radio',
                    label: '配信種類',
                    value: elem?.deliveryType,
                    master: ADHOCMAIL_DELIVERYTYPE_VALUES,
                  },
                  {
                    type: 'radio',
                    label: '事務局と調整済みの配信確定日',
                    value: elem?.adjustmentDesiredConfDate,
                    master: ADJUSTMENTDESIREDCONFDATE_VALUES,
                  },
                  {
                    condition: elem?.adjustmentDesiredConfDate === '1',
                    type: 'date',
                    label: '配信確定日',
                    value: {
                      year: elem?.desiredConfDateYear,
                      month: elem?.desiredConfDateMonth,
                      date: elem?.desiredConfDateDay,
                    },
                  },
                  {
                    condition: elem?.adjustmentDesiredConfDate === '2',
                    type: 'date',
                    label: '第1希望日',
                    value: {
                      year: elem?.desiredDeliveryDate1Year,
                      month: elem?.desiredDeliveryDate1Month,
                      date: elem?.desiredDeliveryDate1Day,
                    },
                  },
                  {
                    condition: elem?.adjustmentDesiredConfDate === '2',
                    type: 'date',
                    label: '第2希望日',
                    value: {
                      year: elem?.desiredDeliveryDate2Year,
                      month: elem?.desiredDeliveryDate2Month,
                      date: elem?.desiredDeliveryDate2Day,
                    },
                  },
                  {
                    condition: elem?.adjustmentDesiredConfDate === '2',
                    type: 'date',
                    label: '第3希望日',
                    value: {
                      year: elem?.desiredDeliveryDate3Year,
                      month: elem?.desiredDeliveryDate3Month,
                      date: elem?.desiredDeliveryDate3Day,
                    },
                  },
                  {
                    type: 'radio',
                    label: '配信対象リスト連携',
                    value: elem?.desiredList,
                    master: DESIREDLIST_VALUES,
                  },
                  {
                    type: 'text',
                    label: '配信対象条件',
                    value: elem?.desiredConditions,
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setAdhocMailData(adhocMailData);

    const crmMailNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.crmMail,
      },
    ];
    setCrmMailNumberData(crmMailNumberData);

    const crmMailData: TabDataProps[] =
      response?.webProductionDetail?.crmMail?.map((elem, index) => {
        return {
          label: `${index + 1}:CRMメール`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'radio',
                    label: '制作物内容',
                    value: elem?.deliveryType,
                    master: CRMMAIL_DELIVERYTYPE_VALUES,
                  },
                  {
                    type: 'date',
                    label: '配信開始日',
                    value: {
                      year: elem?.desiredStartDateYear,
                      month: elem?.desiredStartDateMonth,
                      date: elem?.desiredStartDateDay,
                    },
                  },
                  {
                    type: 'text',
                    label: '配信対象条件',
                    value: elem?.desiredConditions,
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setCrmMailData(crmMailData);

    const afMailNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.afMail,
      },
    ];
    setAfMailNumberData(afMailNumberData);

    const afMailData: TabDataProps[] =
      response?.webProductionDetail?.afMail?.map((elem, index) => {
        return {
          label: `${index + 1}:AFメール`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'radio',
                    label: '制作物内容',
                    value: elem?.deliveryType,
                    master: AFMAIL_DELIVERYTYPE_VALUES,
                  },
                  {
                    type: 'date',
                    label: '配信開始日',
                    value: {
                      year: elem?.desiredStartDateYear,
                      month: elem?.desiredStartDateMonth,
                      date: elem?.desiredStartDateDay,
                    },
                  },
                  {
                    type: 'text',
                    label: '配信対象条件',
                    value: elem?.desiredConditions,
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setAfMailData(afMailData);

    const favoriteBrandMailNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.favoriteBrandMail,
      },
    ];
    setFavoriteBrandMailNumberData(favoriteBrandMailNumberData);

    const favoriteBrandMailData: TabDataProps[] =
      response?.webProductionDetail?.favoriteBrandMail?.map((elem, index) => {
        return {
          label: `${index + 1}:お気に入りブランドメール`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'radio',
                    label: '配信種類',
                    value: elem?.deliveryType,
                    master: FAVORITEBRANDMAIL_DELIVERYTYPE_VALUES,
                  },
                  {
                    type: 'radio',
                    label: '事務局と調整済みの配信確定日',
                    value: elem?.adjustmentDesiredConfDate,
                    master: ADJUSTMENTDESIREDCONFDATE_VALUES,
                  },
                  {
                    condition: elem?.adjustmentDesiredConfDate === '1',
                    type: 'date',
                    label: '配信確定日',
                    value: {
                      year: elem?.desiredConfDateYear,
                      month: elem?.desiredConfDateMonth,
                      date: elem?.desiredConfDateDay,
                    },
                  },
                  {
                    condition: elem?.adjustmentDesiredConfDate === '2',
                    type: 'date',
                    label: '第1希望日',
                    value: {
                      year: elem?.desiredDeliveryDate1Year,
                      month: elem?.desiredDeliveryDate1Month,
                      date: elem?.desiredDeliveryDate1Day,
                    },
                  },
                  {
                    condition: elem?.adjustmentDesiredConfDate === '2',
                    type: 'date',
                    label: '第2希望日',
                    value: {
                      year: elem?.desiredDeliveryDate2Year,
                      month: elem?.desiredDeliveryDate2Month,
                      date: elem?.desiredDeliveryDate2Day,
                    },
                  },
                  {
                    condition: elem?.adjustmentDesiredConfDate === '2',
                    type: 'date',
                    label: '第3希望日',
                    value: {
                      year: elem?.desiredDeliveryDate3Year,
                      month: elem?.desiredDeliveryDate3Month,
                      date: elem?.desiredDeliveryDate3Day,
                    },
                  },
                  {
                    type: 'radio',
                    label: '配信対象リスト連携',
                    value: elem?.desiredList,
                    master: DESIREDLIST_VALUES,
                  },
                  {
                    type: 'text',
                    label: '配信対象条件',
                    value: elem?.desiredConditions,
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setFavoriteBrandMailData(favoriteBrandMailData);

    const freeAreaNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.freeArea,
      },
    ];
    setFreeAreaNumberData(freeAreaNumberData);

    const freeAreaData: TabDataProps[] =
      response?.webProductionDetail?.freeArea?.map((elem, index) => {
        return {
          label: `${index + 1}:商品詳細フリーエリア用画像`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'radio',
                    label: '掲載エリア',
                    value: elem?.postingArea,
                    master: POSTINGAREA_VALUES,
                  },
                  {
                    condition: elem?.postingArea === '1' || elem?.postingArea === '2',
                    type: 'arrayNum',
                    label: '掲載対象プール中コード数',
                    value: elem?.postingCode,
                  },
                  {
                    condition: elem?.postingArea === '1' || elem?.postingArea === '2',
                    type: 'array',
                    value: elem?.postingCode,
                    content: (key: number) => {
                      return [
                        {
                          type: 'text',
                          label: `${key + 1}:掲載対象プール中`,
                          value: elem?.postingCode?.[key]?.postingPool,
                        },
                      ];
                    },
                  },
                  {
                    condition: elem?.postingArea === '3',
                    type: 'array',
                    label: '指定ブランド名',
                    value: elem?.postingSelectedBrandNum,
                    content: (key: number) => {
                      return [
                        {
                          type: 'text',
                          label: `${key + 1}:指定ブランド名`,
                          value: elem?.postingSelectedBrandNum?.[key]?.postingSelectedBrandsName,
                        },
                      ];
                    },
                  },
                  {
                    type: 'radio',
                    label: '掲載終了条件',
                    value: elem?.postingEndConditions,
                    master: POSTINGENDCONDITIONS_VALUES,
                  },
                  {
                    condition: elem?.postingEndConditions === '1' || elem?.postingEndConditions === '2',
                    type: 'date',
                    label: '掲載終了日',
                    value: {
                      year: elem?.postingEndDateYear,
                      month: elem?.postingEndDateMonth,
                      date: elem?.postingEndDateDay,
                    },
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setFreeAreaData(freeAreaData);

    const bigBannerNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.bigBanner,
      },
    ];
    setBigBannerNumberData(bigBannerNumberData);

    const bigBannerData: TabDataProps[] =
      response?.webProductionDetail?.bigBanner?.map((elem, index) => {
        return {
          label: `${index + 1}:大バナー`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'text',
                    label: 'リンク先URL（PC）',
                    value: elem?.pcUrl,
                  },
                  {
                    type: 'text',
                    label: 'リンク先URL（SP）',
                    value: elem?.spUrl,
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setBigBannerData(bigBannerData);

    const purposeBannerNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.purposeBanner,
      },
    ];
    setPurposeBannerNumberData(purposeBannerNumberData);

    const purposeBannerData: TabDataProps[] =
      response?.webProductionDetail?.purposeBanner?.map((elem, index) => {
        return {
          label: `${index + 1}:汎用バナー`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'text',
                    label: 'リンク先URL（PC）',
                    value: elem?.pcUrl,
                  },
                  {
                    type: 'text',
                    label: 'リンク先URL（SP）',
                    value: elem?.spUrl,
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setPurposeBannerData(purposeBannerData);

    const myPageBannerNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.myPageBanner,
      },
    ];
    setMyPageBannerNumberData(myPageBannerNumberData);

    const myPageBannerData: TabDataProps[] =
      response?.webProductionDetail?.myPageBanner?.map((elem, index) => {
        return {
          label: `${index + 1}:マイページ掲載用バナー`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setMyPageBannerData(myPageBannerData);

    const imageBannerNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.imageBanner,
      },
    ];
    setImageBannerNumberData(imageBannerNumberData);

    const externalBannerNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.externalBanner,
      },
    ];
    setExternalBannerNumberData(externalBannerNumberData);

    const cartImageNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.cartImage,
      },
    ];
    setCartImageNumberData(cartImageNumberData);

    const cartImageData: TabDataProps[] =
      response?.webProductionDetail?.cartImage?.map((elem, index) => {
        return {
          label: `${index + 1}:カート内画像`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setCartImageData(cartImageData);

    const giftSetImageNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.giftSetImage,
      },
    ];
    setGiftSetImageNumberData(giftSetImageNumberData);

    const lineMessageNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.lineMessage,
      },
    ];
    setLineMessageNumberData(lineMessageNumberData);

    const lineCarouselNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.lineCarousel,
      },
    ];
    setLineCarouselNumberData(lineCarouselNumberData);

    const lineHomeNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.lineHome,
      },
    ];
    setLineHomeNumberData(lineHomeNumberData);

    const lineRichNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.lineRich,
      },
    ];
    setLineRichNumberData(lineRichNumberData);

    const lineRichSplitNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.lineRichSplit,
      },
    ];
    setLineRichSplitNumberData(lineRichSplitNumberData);

    const twitterBannerNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.twitterBanner,
      },
    ];
    setTwitterBannerNumberData(twitterBannerNumberData);

    const rToasterBannerNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.rToasterBanner,
      },
    ];
    setRToasterBannerNumberData(rToasterBannerNumberData);

    const colorSimulatorNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.colorSimulator,
      },
    ];
    setColorSimulatorNumberData(colorSimulatorNumberData);

    const otherBannerNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.otherBanner,
      },
    ];
    setOtherBannerNumberData(otherBannerNumberData);

    const otherBannerData: TabDataProps[] =
      response?.webProductionDetail?.otherBanner?.map((elem, index) => {
        return {
          label: `${index + 1}:その他バナー`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'radio',
                    label: '制作物内容',
                    value: elem?.productContent,
                    master: PRODUCTCONTENT_VALUES,
                  },
                  {
                    condition: elem?.productContent === '15',
                    type: 'text',
                    label: '詳細',
                    value: elem?.otherDetail,
                  },
                  {
                    type: 'text',
                    label: '（IMJ入力）管理番号',
                    value: elem?.imjManagementNumber,
                  },
                ]}
              />
            </>
          ),
        };
      }) || [];
    setOtherBannerData(otherBannerData);

    const crmDetailsNumberData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作物点数',
        unit: '点',
        value: response?.webProductionDetail?.crmDetails,
      },
    ];
    setCrmDetailsNumberData(crmDetailsNumberData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <>
      <Header />
      <Main>
        <ApiGetMeasuresDetailProvider callback={apiCallbackHandler}>
          <Container>
            <Breadcrumb
              list={[
                {
                  label: 'TOP',
                  url: '/CMN001',
                },
                {
                  label: '登録済み施策一覧',
                },
                {
                  label: response?.measuresName || '',
                },
              ]}
            />

            <Section>
              <H1>{response?.measuresName}</H1>

              <LinkList
                list={[
                  {
                    text: '施策概要・依頼内容',
                    to: `/CPF008/${measureId}`,
                  },
                  {
                    text: 'Web制作',
                    to: `/CPF009/${measureId}`,
                    current: true,
                    disabled: true,
                  },
                  {
                    text: 'キャンペーン設定・クーポン発行',
                    to: `/CPF010/${measureId}`,
                  },
                  {
                    text: 'サンプル・モニター設定',
                    to: `/CPF011/${measureId}`,
                  },
                ]}
              />

              <Section hasBg>
                <PositionContainer>
                  <H2>Web制作</H2>
                  <PositionItem top="0" right="0">
                    <Button type="primary" size="small" text="編集" to={`/CPF002/${measureId}`} />
                  </PositionItem>
                </PositionContainer>

                <SectionInner>
                  {response && (
                    <Flex justifyContent="center">
                      <Box color="grey" size="xsmall" width="fit-content">
                        <FlexRow justifyContent="center" alignItems="center" separator={true}>
                          <FlexCol>
                            <Text bold>入力状況</Text>
                          </FlexCol>
                          <FlexCol>
                            <CampaignStatusTag status={response?.webStatus} />
                          </FlexCol>
                        </FlexRow>
                      </Box>
                    </Flex>
                  )}

                  {response?.webStatus !== '3' && (
                    <>
                      <Section>
                        <H3>制作会社</H3>
                        <DataTable data={webProductionDetailData} />
                      </Section>

                      <Section>
                        <H3>LP制作</H3>

                        <Section>
                          <H4>PC</H4>
                          <DataTable data={lpPcNumberData} />
                          {lpPcData.length > 0 && <TabMenu data={lpPcData} />}
                        </Section>

                        <Section>
                          <H4>SP</H4>
                          <DataTable data={lpSpNumberData} />
                          {lpSpData.length > 0 && <TabMenu data={lpSpData} />}
                        </Section>
                      </Section>

                      <Section>
                        <H3>メール制作</H3>

                        <Section>
                          <H4>adhocメール</H4>
                          <DataTable data={adhocMailNumberData} />
                          {adhocMailData.length > 0 && <TabMenu data={adhocMailData} />}
                        </Section>

                        <Section>
                          <H4>CRMメール</H4>
                          <DataTable data={crmMailNumberData} />
                          {crmMailData.length > 0 && <TabMenu data={crmMailData} />}
                        </Section>

                        <Section>
                          <H4>AFメール</H4>
                          <DataTable data={afMailNumberData} />
                          {afMailData.length > 0 && <TabMenu data={afMailData} />}
                        </Section>

                        <Section>
                          <H4>お気に入りブランドメール</H4>
                          <DataTable data={favoriteBrandMailNumberData} />
                          {favoriteBrandMailData.length > 0 && <TabMenu data={favoriteBrandMailData} />}
                        </Section>
                      </Section>

                      <Section>
                        <H3>バナー・各種画像制作</H3>

                        <Section>
                          <H4>商品詳細フリーエリア用画像</H4>
                          <DataTable data={freeAreaNumberData} />
                          {freeAreaData.length > 0 && <TabMenu data={freeAreaData} />}
                        </Section>

                        <Section>
                          <H4>大バナー</H4>
                          <DataTable data={bigBannerNumberData} />
                          {bigBannerData.length > 0 && <TabMenu data={bigBannerData} />}
                        </Section>

                        <Section>
                          <H4>汎用バナー</H4>
                          <DataTable data={purposeBannerNumberData} />
                          {purposeBannerData.length > 0 && <TabMenu data={purposeBannerData} />}
                        </Section>

                        <Section>
                          <H4>マイページ掲載用バナー</H4>
                          <DataTable data={myPageBannerNumberData} />
                          {myPageBannerData.length > 0 && <TabMenu data={myPageBannerData} />}
                        </Section>

                        <Section>
                          <H4>OG image</H4>
                          <DataTable data={imageBannerNumberData} />
                        </Section>

                        <Section>
                          <H4>外部バナー</H4>
                          <DataTable data={externalBannerNumberData} />
                        </Section>

                        <Section>
                          <H4>カート内画像</H4>
                          <DataTable data={cartImageNumberData} />
                          {cartImageData.length > 0 && <TabMenu data={cartImageData} />}
                        </Section>

                        <Section>
                          <H4>ギフト・セット用カート画像</H4>
                          <DataTable data={giftSetImageNumberData} />
                        </Section>

                        <Section>
                          <H4>LINEメッセージバナー</H4>
                          <DataTable data={lineMessageNumberData} />
                        </Section>

                        <Section>
                          <H4>LINEカルーセルバナー</H4>
                          <DataTable data={lineCarouselNumberData} />
                        </Section>

                        <Section>
                          <H4>LINEホーム</H4>
                          <DataTable data={lineHomeNumberData} />
                        </Section>

                        <Section>
                          <H4>LINEリッチ</H4>
                          <DataTable data={lineRichNumberData} />
                        </Section>

                        <Section>
                          <H4>LINEリッチ分割</H4>
                          <DataTable data={lineRichSplitNumberData} />
                        </Section>

                        <Section>
                          <H4>Twitter専用バナー</H4>
                          <DataTable data={twitterBannerNumberData} />
                        </Section>

                        <Section>
                          <H4>Rトースターバナー</H4>
                          <DataTable data={rToasterBannerNumberData} />
                        </Section>

                        <Section>
                          <H4>カラーシミュレーター用画像</H4>
                          <DataTable data={colorSimulatorNumberData} />
                        </Section>

                        <Section>
                          <H4>その他バナー</H4>
                          <DataTable data={otherBannerNumberData} />
                          {otherBannerData.length > 0 && <TabMenu data={otherBannerData} />}
                        </Section>

                        <Section>
                          <H4>CRM明細用画像</H4>
                          <DataTable data={crmDetailsNumberData} />
                        </Section>
                      </Section>
                    </>
                  )}

                  <PositionContainer mt={40}>
                    <ButtonContainer>
                      <PositionItem left="0" centerY>
                        <Button type="secondary" icon="arrow-left" iconPosition="before" size="small" isFlexible text="施策一覧へ戻る" to="/CPF007" />
                      </PositionItem>
                      <Button type="primary" text="編集" to={`/CPF002/${measureId}`} />
                    </ButtonContainer>
                  </PositionContainer>
                </SectionInner>
              </Section>
            </Section>
          </Container>
        </ApiGetMeasuresDetailProvider>
      </Main>
      <Footer />
    </>
  );
};
