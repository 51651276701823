import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NetworkErrorUpdate } from 'context';
import { NotFound } from 'components/Pages/NotFound';
import { routes } from './routes';

type RouteComponentProps = {
  path?: any;
  component: any;
  exact?: boolean;
  key: string;
};

const RouteComponent: React.FC<RouteComponentProps> = ({ path, component, exact = false, key }) => {
  const setNetworkError = React.useContext(NetworkErrorUpdate);

  React.useEffect(() => {
    setNetworkError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Route key={key} {...(path && { path: path })} component={component} exact={exact} />;
};

export const PrivateRoutes: React.FC = () => {
  const memoizedRoutes = React.useMemo(() => {
    const allRoutes = Object.values(routes).map(({ path, component }) => <RouteComponent key={path} path={path} component={component} exact />);
    return allRoutes.concat([<RouteComponent key="NotFound" component={NotFound} />]);
  }, []);

  return (
    <Switch>
      <Redirect exact from="/" to="/CMN001" />
      {memoizedRoutes}
    </Switch>
  );
};
