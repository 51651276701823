import React from 'react';
import { useHistory } from 'react-router-dom';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';
import type { SvgIconTypeProps } from 'components/Atoms/SvgIcon';

const Types = {
  primary: 'primary',
  secondary: 'secondary',
} as const;
type TypesProps = typeof Types[keyof typeof Types];

const Size = {
  large: 'large',
  medium: 'medium',
} as const;
type SizeProps = typeof Size[keyof typeof Size];

type Props = {
  to: string;
  type: TypesProps;
  size?: SizeProps;
  icon: SvgIconTypeProps;
  text: string;
  className?: string;
};

export const PanelButton: React.FC<Props> = ({ to, type, size = 'medium', icon, text, className = '' }) => {
  const history = useHistory();

  const iconTheme = React.useMemo(() => {
    if (type === 'secondary') return '#2896f0';
    return '#fff';
  }, [type]);

  const iconWidth = React.useMemo(() => {
    if (icon === 'note') return 35;
    if (icon === 'clipboard') return 23;
    if (icon === 'lipstick') return 14;
    if (icon === 'palette') return 29;

    return 0;
  }, [icon]);

  return React.useMemo(
    () => (
      <a
        className={`${style['button-panel']} is-${type} is-${size} ${className}`}
        href={to}
        onClick={(e) => {
          e.preventDefault();
          history.push(to);
        }}
      >
        <SvgIcon type={icon} fill={iconTheme} width={iconWidth} className={`${style['button-panel-icon']} has-icon-${icon}`} />
        {text}
        <SvgIcon type="triangle-rightdown" fill={iconTheme} width={12} className={`${style['button-panel-icon-triangle']}`} />
      </a>
    ),
    [type, size, className, history, text, to, icon, iconWidth, iconTheme],
  );
};
