import React from 'react';
import style from './style.module.scss';

type Props = {
  split?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  className?: string;
  mt?: number;
};

export const BorderList: React.FC<Props> = ({ children, split, className = '', mt }) => {
  return React.useMemo(
    () => (
      <div className={`${style['list-border']} is-split-${split} ${className}`} style={{ ...(mt !== undefined && { marginTop: `${mt}px` }) }}>
        {children}
      </div>
    ),
    [split, className, children, mt],
  );
};
