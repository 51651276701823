import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Main, Container, Section, SectionInner, ButtonContainer } from 'components/Atoms/Layout';
import { H1, H2, H3, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { FormArray, FormRadioAccordion, FormHiddenInput, FormTextReadOnly } from 'components/Business/Form';
import { ApiGetAidDetailProvider } from 'components/Business/Api/ApiGetAidDetailProvider';
import { ApiSelectAidPurpose } from 'components/Business/Api/ApiSelectAidPurpose';
import { ApiSelectAidTargetUsers } from 'components/Business/Api/ApiSelectAidTargetUsers';
import { ApiSelectAidSpecialOffer } from 'components/Business/Api/ApiSelectAidSpecialOffer';
import { ApiSelectAidExternalBannerType } from 'components/Business/Api/ApiSelectAidExternalBannerType';
import { ApiFormAidSelectMaterialModal } from 'components/Business/Api/ApiFormAidSelectMaterialModal';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { RHF_SETTINGS, RHF_RESET_PARAMS, DESIGNTARGET_VALUES } from 'constants/index';
import { objectToRadioList } from 'utils';
import { DirtyUpdate } from 'context';
import { CopyModal } from './CopyModal';
import { useValidationSchema } from './useValidationSchema';
import { ProductionContentsList } from './ProductionContentsList';
import { SubmitButton } from './SubmitButton';
import type { DesignDetailProps } from 'types';

export const AID002: React.FC = () => {
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { reset } = useFormMethods;
  const setDirty = React.useContext(DirtyUpdate);
  const [response, setResponse] = React.useState<DesignDetailProps>();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const apiCallbackHandler = React.useCallback(
    (result, isTransaction) => {
      reset(result, RHF_RESET_PARAMS);
      setResponse(result);
      if (isTransaction) {
        setTimeout(() => setDirty(true), 0);
      }
    },
    [reset, setDirty],
  );

  const handleChangeTabIndex = React.useCallback((index: number) => {
    setSelectedTabIndex(index);
  }, []);

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <ApiGetAidDetailProvider callback={(result, isTransaction) => apiCallbackHandler(result, isTransaction)}>
            <Container>
              <Breadcrumb
                list={[
                  {
                    label: 'TOP',
                    url: '/CMN001',
                  },
                  {
                    label: 'AI Designer',
                    url: '/AID001',
                  },
                  {
                    label: '制作要件・素材指定',
                  },
                ]}
              />

              <Section>
                <H1>制作要件・素材指定</H1>

                {response?.recommendationStatus !== '1' && (
                  <Text align="center">
                    下記の項目を入力してください。
                    <br />
                    [必須]項目は、全て入力いただく必要があります。
                  </Text>
                )}

                <Text align="center" size={18}>
                  施策名: <FormTextReadOnly name="measureName" />
                </Text>

                {response?.recommendationStatus === '1' && (
                  <>
                    <Text align="center">
                      AI Designerでの下記、推論要件を満たしていません。 <br />
                      情報に不備がある場合は、施策担当者にご確認ください。
                    </Text>

                    <Text align="center">推論要件：デザイン推論対象バナーの制作点数が1点以上である。</Text>

                    <ButtonContainer>
                      <Button type="secondary" icon="arrow-left" iconPosition="left" text="AI Designer一覧へ戻る" to="/AID001" />
                    </ButtonContainer>
                  </>
                )}

                {response?.recommendationStatus !== '1' && (
                  <>
                    <Section hasBg>
                      <H2>制作要件</H2>
                      <SectionInner>
                        <ApiSelectAidPurpose />

                        <ApiSelectAidTargetUsers />

                        <ApiSelectAidSpecialOffer />
                      </SectionInner>
                    </Section>

                    <Section hasBg>
                      <H2>素材指定</H2>

                      <SectionInner>
                        <Section>
                          <H3>制作物内容</H3>
                          <ProductionContentsList data={response} />
                        </Section>

                        <FormArray
                          type="tab"
                          name="moduleAssignments"
                          hasSelect={false}
                          hasAddButton={false}
                          hasDeleteButtonOnBottom={false}
                          onChange={handleChangeTabIndex}
                          contentLabel={(key: number) => {
                            return `${response?.moduleAssignments[key].productNumber}:${response?.moduleAssignments[key].productType}`;
                          }}
                          content={(key: number) => {
                            return (
                              <React.Fragment key={key}>
                                <FormHiddenInput name={`moduleAssignments[${key}].productType`} />

                                <FormHiddenInput name={`moduleAssignments[${key}].productNumber`} />

                                <FormRadioAccordion
                                  name={`moduleAssignments[${key}].designTarget`}
                                  label="デザイン生成"
                                  isRequired
                                  list={objectToRadioList(DESIGNTARGET_VALUES)}
                                  contents={{
                                    '1': (
                                      <>
                                        {response?.moduleAssignments[key].productType === '外部バナー' && (
                                          <ApiSelectAidExternalBannerType name={`moduleAssignments[${key}].productSubType`} label="制作種別" isRequired />
                                        )}
                                        <ApiFormAidSelectMaterialModal name={`moduleAssignments[${key}].modules`} label="素材指定情報" isRequired />
                                      </>
                                    ),
                                    '2': null,
                                  }}
                                />
                              </React.Fragment>
                            );
                          }}
                        />

                        <ButtonContainer>{response && <CopyModal selectedTabIndex={selectedTabIndex} />}</ButtonContainer>
                      </SectionInner>
                    </Section>

                    <ButtonContainer>
                      <SubmitButton />
                    </ButtonContainer>
                  </>
                )}
              </Section>
            </Container>

            <RHFDevTools />
          </ApiGetAidDetailProvider>
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
