import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Main, Container, Section, SectionInner, ButtonContainer, NestBox } from 'components/Atoms/Layout';
import { H1, H2, H3, Text, Note } from 'components/Atoms/Typography';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { ApiGetMeasuresDetailProvider } from 'components/Business/Api';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { FormTextInput, FormTextbox, FormDate, FormRadioList, FormRadioAccordion, FormSelectMenu, FormArray } from 'components/Business/Form';
import { ApiSaveMeasureButton } from 'components/Business/Api/ApiSaveMeasureButton';
import { ApiSelectMultipleBrand } from 'components/Business/Api';
import { StickyFooter } from 'components/Organisms/StickyFooter';
import { DynamicContent } from 'components/Business/DynamicContent';
import { ServerErrorBox } from 'components/Business/ServerErrorBox';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { CopyButton } from './CopyButton';
import { FreeAreaPostingCode } from './FreeAreaPostingCode';
import {
  RHF_SETTINGS,
  RHF_RESET_PARAMS,
  PRODUCTIONCOMPANY_VALUES,
  ADHOCMAIL_DELIVERYTYPE_VALUES,
  ADJUSTMENTDESIREDCONFDATE_VALUES,
  DESIREDLIST_VALUES,
  CRMMAIL_DELIVERYTYPE_VALUES,
  AFMAIL_DELIVERYTYPE_VALUES,
  FAVORITEBRANDMAIL_DELIVERYTYPE_VALUES,
  POSTINGAREA_VALUES,
  POSTINGENDCONDITIONS_VALUES,
  PRODUCTCONTENT_VALUES,
} from 'constants/index';
import { objectToRadioList, createNumberOptions } from 'utils';
import { useValidationSchema } from './useValidationSchema';
import { ErrorView } from './ErrorView';
import type { MeasureDetailProps } from 'types';

export const CPF002: React.FC = () => {
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, reset } = useFormMethods;
  const [response, setResponse] = React.useState<MeasureDetailProps>();
  const [webProductionValue, setWebProductionValue] = React.useState();

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    return {
      webProductionDetail: {
        productionCompany: formValues.webProductionDetail.productionCompany || '',
        companyDetail: (() => {
          if (!formValues.webProductionDetail.companyDetail) return [];
          return formValues.webProductionDetail.companyDetail?.filter((e: {}) => e) || [];
        })(),
        lpPc: formValues.webProductionDetail.lpPc ? formValues.webProductionDetail.lpPc.filter((elem: {}) => elem) : [],
        lpSp: formValues.webProductionDetail.lpSp ? formValues.webProductionDetail.lpSp.filter((elem: {}) => elem) : [],
        adhocMail: (() => {
          return (formValues.webProductionDetail.adhocMail ? formValues.webProductionDetail.adhocMail.concat().filter((elem: {}) => elem) : []).map(
            (elem: any) => {
              return {
                deliveryType: elem.deliveryType || '',
                adjustmentDesiredConfDate: elem.adjustmentDesiredConfDate || '',
                ...(elem?.adjustmentDesiredConfDate === '1' && {
                  desiredConfDateYear: elem.desiredConfDateYear || '',
                  desiredConfDateMonth: elem.desiredConfDateMonth || '',
                  desiredConfDateDay: elem.desiredConfDateDay || '',
                }),
                ...(elem?.adjustmentDesiredConfDate === '2' && {
                  desiredDeliveryDate1Year: elem.desiredDeliveryDate1Year || '',
                  desiredDeliveryDate1Month: elem.desiredDeliveryDate1Month || '',
                  desiredDeliveryDate1Day: elem.desiredDeliveryDate1Day || '',
                  desiredDeliveryDate2Year: elem.desiredDeliveryDate2Year || '',
                  desiredDeliveryDate2Month: elem.desiredDeliveryDate2Month || '',
                  desiredDeliveryDate2Day: elem.desiredDeliveryDate2Day || '',
                  desiredDeliveryDate3Year: elem.desiredDeliveryDate3Year || '',
                  desiredDeliveryDate3Month: elem.desiredDeliveryDate3Month || '',
                  desiredDeliveryDate3Day: elem.desiredDeliveryDate3Day || '',
                }),
                desiredList: elem.desiredList || '',
                desiredConditions: elem.desiredConditions || '',
                imjManagementNumber: elem.imjManagementNumber || '',
              };
            },
          );
        })(),
        crmMail: formValues.webProductionDetail.crmMail ? formValues.webProductionDetail.crmMail.filter((elem: {}) => elem) : [],
        afMail: formValues.webProductionDetail.afMail ? formValues.webProductionDetail.afMail.filter((elem: {}) => elem) : [],
        favoriteBrandMail: (() => {
          return (formValues.webProductionDetail.favoriteBrandMail
            ? formValues.webProductionDetail.favoriteBrandMail.concat().filter((elem: {}) => elem)
            : []
          ).map((elem: any) => {
            return {
              deliveryType: elem.deliveryType || '',
              adjustmentDesiredConfDate: elem.adjustmentDesiredConfDate || '',
              ...(elem?.adjustmentDesiredConfDate === '1' && {
                desiredConfDateYear: elem.desiredConfDateYear || '',
                desiredConfDateMonth: elem.desiredConfDateMonth || '',
                desiredConfDateDay: elem.desiredConfDateDay || '',
              }),
              ...(elem?.adjustmentDesiredConfDate === '2' && {
                desiredDeliveryDate1Year: elem.desiredDeliveryDate1Year || '',
                desiredDeliveryDate1Month: elem.desiredDeliveryDate1Month || '',
                desiredDeliveryDate1Day: elem.desiredDeliveryDate1Day || '',
                desiredDeliveryDate2Year: elem.desiredDeliveryDate2Year || '',
                desiredDeliveryDate2Month: elem.desiredDeliveryDate2Month || '',
                desiredDeliveryDate2Day: elem.desiredDeliveryDate2Day || '',
                desiredDeliveryDate3Year: elem.desiredDeliveryDate3Year || '',
                desiredDeliveryDate3Month: elem.desiredDeliveryDate3Month || '',
                desiredDeliveryDate3Day: elem.desiredDeliveryDate3Day || '',
              }),
              desiredList: elem.desiredList || '',
              desiredConditions: elem.desiredConditions || '',
              imjManagementNumber: elem.imjManagementNumber || '',
            };
          });
        })(),
        freeArea: (() => {
          return (formValues.webProductionDetail.freeArea ? formValues.webProductionDetail.freeArea.concat().filter((elem: {}) => elem) : []).map(
            (elem: any) => {
              return {
                postingArea: elem.postingArea || '',
                ...((elem?.postingArea === '1' || elem?.postingArea === '2') && {
                  postingCode: (() => {
                    if (!elem.postingCode) return [];
                    if (typeof elem.postingCode === 'object') return elem.postingCode?.filter((e: {}) => e) || [];
                    return elem.postingCode;
                  })(),
                }),
                ...(elem?.postingArea === '3' && {
                  postingSelectedBrandNum: (() => {
                    if (!elem.postingSelectedBrandNum) return [];
                    if (typeof elem.postingSelectedBrandNum === 'object') return elem.postingSelectedBrandNum?.filter((e: {}) => e) || [];
                    return elem.postingSelectedBrandNum;
                  })(),
                }),
                postingEndConditions: elem.postingEndConditions || '',
                ...((elem?.postingEndConditions === '1' || elem?.postingEndConditions === '2') && {
                  postingEndDateYear: elem.postingEndDateYear || '',
                  postingEndDateMonth: elem.postingEndDateMonth || '',
                  postingEndDateDay: elem.postingEndDateDay || '',
                }),
                imjManagementNumber: elem.imjManagementNumber || '',
              };
            },
          );
        })(),
        bigBanner: formValues.webProductionDetail.bigBanner ? formValues.webProductionDetail.bigBanner.filter((elem: {}) => elem) : [],
        purposeBanner: formValues.webProductionDetail.purposeBanner ? formValues.webProductionDetail.purposeBanner.filter((elem: {}) => elem) : [],
        myPageBanner: formValues.webProductionDetail.myPageBanner ? formValues.webProductionDetail.myPageBanner.filter((elem: {}) => elem) : [],
        imageBanner: formValues.webProductionDetail.imageBanner,
        externalBanner: formValues.webProductionDetail.externalBanner,
        cartImage: formValues.webProductionDetail.cartImage ? formValues.webProductionDetail.cartImage.filter((elem: {}) => elem) : [],
        giftSetImage: formValues.webProductionDetail.giftSetImage,
        lineMessage: formValues.webProductionDetail.lineMessage,
        lineCarousel: formValues.webProductionDetail.lineCarousel,
        lineHome: formValues.webProductionDetail.lineHome,
        lineRich: formValues.webProductionDetail.lineRich,
        lineRichSplit: formValues.webProductionDetail.lineRichSplit,
        twitterBanner: formValues.webProductionDetail.twitterBanner,
        rToasterBanner: formValues.webProductionDetail.rToasterBanner,
        colorSimulator: formValues.webProductionDetail.colorSimulator,
        otherBanner: (() => {
          return (formValues.webProductionDetail.otherBanner ? formValues.webProductionDetail.otherBanner.concat().filter((elem: {}) => elem) : []).map(
            (elem: any) => {
              return {
                productContent: elem.productContent || '',
                ...(elem?.productContent === '100' && {
                  otherDetail: elem.otherDetail || '',
                }),
                imjManagementNumber: elem.imjManagementNumber || '',
              };
            },
          );
        })(),
        crmDetails: formValues.webProductionDetail.crmDetails,
      },
    };
  }, [getValues]);

  const apiCallbackHandler = React.useCallback(
    (result) => {
      reset(result, RHF_RESET_PARAMS);
      setResponse(result);
      setWebProductionValue(result.webProduction);
    },
    [reset],
  );

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <ApiGetMeasuresDetailProvider type="web" callback={apiCallbackHandler}>
            <Container>
              <Breadcrumb
                list={[
                  {
                    label: 'TOP',
                    url: '/CMN001',
                  },
                  {
                    label: '施策概要・依頼内容',
                  },
                  {
                    label: 'Web制作',
                  },
                ]}
              />

              <ServerErrorBox />

              <Section>
                <H1>Web制作</H1>

                {response?.measuresName && (
                  <Text align="center" size={24} bold={true}>
                    {response.measuresName}
                  </Text>
                )}

                {webProductionValue !== '1' && <ErrorView />}

                {webProductionValue === '1' && (
                  <>
                    <Text align="center">
                      下記の項目を入力してください。
                      <br />
                      [必須]項目は、全て入力いただく必要があります。
                    </Text>

                    <Section hasBg>
                      <H2>制作会社</H2>

                      <SectionInner>
                        <FormRadioAccordion
                          name="webProductionDetail.productionCompany"
                          label="制作会社"
                          notes={[
                            '※IMJ以外の場合、担当者情報（最大5名まで）の入力が必要です。',
                            '※申請の際に担当者情報として登録します。（管理番号発行のお知らせが飛びます）',
                          ]}
                          isRequired
                          list={objectToRadioList(PRODUCTIONCOMPANY_VALUES)}
                          contents={{
                            '1': null,
                            '2': (
                              <DynamicContent
                                max={5}
                                name="webProductionDetail.companyDetail"
                                content={(key: number, index: number) => {
                                  return (
                                    <React.Fragment key={key}>
                                      <FormTextInput
                                        name={`webProductionDetail.companyDetail[${key}].companyName`}
                                        label={`${index + 1}:制作会社名`}
                                        placeholder="制作会社名を入力"
                                        isRequired
                                        hasUndecidedButton
                                      />

                                      <FormTextInput
                                        name={`webProductionDetail.companyDetail[${key}].producer`}
                                        label={`${index + 1}:担当者氏名`}
                                        placeholder="担当者氏名を入力"
                                        isRequired
                                        hasUndecidedButton
                                      />

                                      <FormTextInput
                                        type="email"
                                        name={`webProductionDetail.companyDetail[${key}].producerMailAddress`}
                                        label={`${index + 1}:担当者メールアドレス`}
                                        placeholder="担当者のメールアドレスを入力"
                                        isRequired
                                        hasUndecidedButton
                                      />
                                    </React.Fragment>
                                  );
                                }}
                              />
                            ),
                          }}
                        />
                      </SectionInner>
                    </Section>

                    <Section hasBg>
                      <H2>制作物点数</H2>
                      <Text align="center">各制作物の点数を選択してください。選択された点数分、入力タブが表示されます。</Text>

                      <SectionInner>
                        <Section>
                          <H3>LP制作</H3>

                          <FormArray
                            type="tab"
                            label="PC"
                            unit="点"
                            name="webProductionDetail.lpPc"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:PC`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormDate
                                    names={{
                                      year: `webProductionDetail.lpPc[${key}].lpReleaseDateYear`,
                                      month: `webProductionDetail.lpPc[${key}].lpReleaseDateMonth`,
                                      date: `webProductionDetail.lpPc[${key}].lpReleaseDateDay`,
                                      hour: `webProductionDetail.lpPc[${key}].lpReleaseDateHours`,
                                      minute: `webProductionDetail.lpPc[${key}].lpReleaseDateMinutes`,
                                    }}
                                    defaultValues={{
                                      year: response?.measuresStartDateYear || '',
                                      month: response?.measuresStartDateMonth || '',
                                      date: response?.measuresStartDateDay || '',
                                      hour: response?.measuresStartTimeHours || '00',
                                      minute: response?.measuresStartTimeMinutes || '00',
                                    }}
                                    label="LP公開日"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormDate
                                    names={{
                                      year: `webProductionDetail.lpPc[${key}].lpReleaseEndDateYear`,
                                      month: `webProductionDetail.lpPc[${key}].lpReleaseEndDateMonth`,
                                      date: `webProductionDetail.lpPc[${key}].lpReleaseEndDateDay`,
                                      hour: `webProductionDetail.lpPc[${key}].lpReleaseEndDateHours`,
                                      minute: `webProductionDetail.lpPc[${key}].lpReleaseEndDateMinutes`,
                                    }}
                                    defaultValues={{
                                      year: response?.measuresEndDateYear || '',
                                      month: response?.measuresEndDateMonth || '',
                                      date: response?.measuresEndDateDay || '',
                                      hour: response?.measuresEndTimeHours || '23',
                                      minute: response?.measuresEndTimeMinutes || '59',
                                    }}
                                    label="LP公開終了日"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextbox
                                    name={`webProductionDetail.lpPc[${key}].pcUrl`}
                                    type="url"
                                    label="URL"
                                    placeholder="「https://www.shiseido.co.jp/cms/onlineshop/hk/b/promotion2020/」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.lpPc[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormArray
                            type="tab"
                            label="SP"
                            unit="点"
                            name="webProductionDetail.lpSp"
                            min={0}
                            hasCopy
                            copyFrom="webProductionDetail.lpPc"
                            contentLabel={(key: number) => `${key + 1}:SP`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormDate
                                    names={{
                                      year: `webProductionDetail.lpSp[${key}].lpReleaseDateYear`,
                                      month: `webProductionDetail.lpSp[${key}].lpReleaseDateMonth`,
                                      date: `webProductionDetail.lpSp[${key}].lpReleaseDateDay`,
                                      hour: `webProductionDetail.lpSp[${key}].lpReleaseDateHours`,
                                      minute: `webProductionDetail.lpSp[${key}].lpReleaseDateMinutes`,
                                    }}
                                    defaultValues={{
                                      year: '',
                                      month: '',
                                      date: '',
                                      hour: '00',
                                      minute: '00',
                                    }}
                                    label="LP公開日"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormDate
                                    names={{
                                      year: `webProductionDetail.lpSp[${key}].lpReleaseEndDateYear`,
                                      month: `webProductionDetail.lpSp[${key}].lpReleaseEndDateMonth`,
                                      date: `webProductionDetail.lpSp[${key}].lpReleaseEndDateDay`,
                                      hour: `webProductionDetail.lpSp[${key}].lpReleaseEndDateHours`,
                                      minute: `webProductionDetail.lpSp[${key}].lpReleaseEndDateMinutes`,
                                    }}
                                    defaultValues={{
                                      year: '',
                                      month: '',
                                      date: '',
                                      hour: '23',
                                      minute: '59',
                                    }}
                                    label="LP公開終了日"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextbox
                                    name={`webProductionDetail.lpSp[${key}].spUrl`}
                                    type="url"
                                    label="URL"
                                    placeholder="「https://www.shiseido.co.jp/sp/cms/onlineshop/hk/b/promotion2020/」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.lpSp[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />
                        </Section>

                        <Section>
                          <H3>メール制作</H3>

                          <FormArray
                            type="tab"
                            label="adhocメール"
                            unit="点"
                            name="webProductionDetail.adhocMail"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:adhocメール`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormRadioList
                                    name={`webProductionDetail.adhocMail[${key}].deliveryType`}
                                    label="配信種類"
                                    isRequired
                                    list={objectToRadioList(ADHOCMAIL_DELIVERYTYPE_VALUES)}
                                  />

                                  <FormRadioAccordion
                                    name={`webProductionDetail.adhocMail[${key}].adjustmentDesiredConfDate`}
                                    label="事務局と調整済みの配信確定日"
                                    isRequired
                                    list={objectToRadioList(ADJUSTMENTDESIREDCONFDATE_VALUES)}
                                    contents={{
                                      '1': (
                                        <FormDate
                                          names={{
                                            year: `webProductionDetail.adhocMail[${key}].desiredConfDateYear`,
                                            month: `webProductionDetail.adhocMail[${key}].desiredConfDateMonth`,
                                            date: `webProductionDetail.adhocMail[${key}].desiredConfDateDay`,
                                          }}
                                          label="配信確定日"
                                          isRequired
                                        />
                                      ),
                                      '2': (
                                        <>
                                          <Text>配信希望日</Text>
                                          <Note>※事務局と調整済みの場合を除き、土日祝日は申請できません。</Note>

                                          <NestBox>
                                            <FormDate
                                              names={{
                                                year: `webProductionDetail.adhocMail[${key}].desiredDeliveryDate1Year`,
                                                month: `webProductionDetail.adhocMail[${key}].desiredDeliveryDate1Month`,
                                                date: `webProductionDetail.adhocMail[${key}].desiredDeliveryDate1Day`,
                                              }}
                                              label="第1希望日"
                                              isRequired
                                            />
                                          </NestBox>

                                          <NestBox>
                                            <FormDate
                                              names={{
                                                year: `webProductionDetail.adhocMail[${key}].desiredDeliveryDate2Year`,
                                                month: `webProductionDetail.adhocMail[${key}].desiredDeliveryDate2Month`,
                                                date: `webProductionDetail.adhocMail[${key}].desiredDeliveryDate2Day`,
                                              }}
                                              label="第2希望日"
                                            />
                                          </NestBox>

                                          <NestBox>
                                            <FormDate
                                              names={{
                                                year: `webProductionDetail.adhocMail[${key}].desiredDeliveryDate3Year`,
                                                month: `webProductionDetail.adhocMail[${key}].desiredDeliveryDate3Month`,
                                                date: `webProductionDetail.adhocMail[${key}].desiredDeliveryDate3Day`,
                                              }}
                                              label="第3希望日"
                                            />
                                          </NestBox>
                                        </>
                                      ),
                                    }}
                                  />

                                  <FormRadioList
                                    name={`webProductionDetail.adhocMail[${key}].desiredList`}
                                    label="配信対象リスト連携"
                                    isRequired
                                    hasUndecidedButton
                                    list={objectToRadioList(DESIREDLIST_VALUES)}
                                  />

                                  <FormTextbox
                                    name={`webProductionDetail.adhocMail[${key}].desiredConditions`}
                                    label="配信対象条件"
                                    placeholder="「19年以降W+でスキンケア購入経験のある方で20年以降HK購入経験のない方」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.adhocMail[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormArray
                            type="tab"
                            label="CRMメール"
                            unit="点"
                            name="webProductionDetail.crmMail"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:CRMメール`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormRadioList
                                    name={`webProductionDetail.crmMail[${key}].deliveryType`}
                                    label="制作物内容"
                                    isRequired
                                    list={objectToRadioList(CRMMAIL_DELIVERYTYPE_VALUES)}
                                  />

                                  <FormDate
                                    names={{
                                      year: `webProductionDetail.crmMail[${key}].desiredStartDateYear`,
                                      month: `webProductionDetail.crmMail[${key}].desiredStartDateMonth`,
                                      date: `webProductionDetail.crmMail[${key}].desiredStartDateDay`,
                                    }}
                                    label="配信開始日"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextbox
                                    name={`webProductionDetail.crmMail[${key}].desiredConditions`}
                                    label="配信対象条件"
                                    placeholder="「直近1年間でDPの化粧水を購入した方」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.crmMail[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormArray
                            type="tab"
                            label="AFメール"
                            unit="点"
                            name="webProductionDetail.afMail"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:AFメール`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormRadioList
                                    name={`webProductionDetail.afMail[${key}].deliveryType`}
                                    label="制作物内容"
                                    isRequired
                                    list={objectToRadioList(AFMAIL_DELIVERYTYPE_VALUES)}
                                  />

                                  <FormDate
                                    names={{
                                      year: `webProductionDetail.afMail[${key}].desiredStartDateYear`,
                                      month: `webProductionDetail.afMail[${key}].desiredStartDateMonth`,
                                      date: `webProductionDetail.afMail[${key}].desiredStartDateDay`,
                                    }}
                                    label="配信開始日"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextbox
                                    name={`webProductionDetail.afMail[${key}].desiredConditions`}
                                    label="配信対象条件"
                                    placeholder="「直近1年間でDPの化粧水を購入した方」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.afMail[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormArray
                            type="tab"
                            label="お気に入りブランドメール"
                            unit="点"
                            name="webProductionDetail.favoriteBrandMail"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:お気に入りブランドメール`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormRadioList
                                    name={`webProductionDetail.favoriteBrandMail[${key}].deliveryType`}
                                    label="配信種類"
                                    isRequired
                                    list={objectToRadioList(FAVORITEBRANDMAIL_DELIVERYTYPE_VALUES)}
                                  />

                                  <FormRadioAccordion
                                    name={`webProductionDetail.favoriteBrandMail[${key}].adjustmentDesiredConfDate`}
                                    label="事務局と調整済みの配信確定日"
                                    isRequired
                                    list={objectToRadioList(ADJUSTMENTDESIREDCONFDATE_VALUES)}
                                    contents={{
                                      '1': (
                                        <FormDate
                                          names={{
                                            year: `webProductionDetail.favoriteBrandMail[${key}].desiredConfDateYear`,
                                            month: `webProductionDetail.favoriteBrandMail[${key}].desiredConfDateMonth`,
                                            date: `webProductionDetail.favoriteBrandMail[${key}].desiredConfDateDay`,
                                          }}
                                          label="配信確定日"
                                          isRequired
                                        />
                                      ),
                                      '2': (
                                        <>
                                          <Text>配信希望日</Text>
                                          <Note>※事務局と調整済みの場合を除き、土日祝日は申請できません。</Note>

                                          <NestBox>
                                            <FormDate
                                              names={{
                                                year: `webProductionDetail.favoriteBrandMail[${key}].desiredDeliveryDate1Year`,
                                                month: `webProductionDetail.favoriteBrandMail[${key}].desiredDeliveryDate1Month`,
                                                date: `webProductionDetail.favoriteBrandMail[${key}].desiredDeliveryDate1Day`,
                                              }}
                                              label="第1希望日"
                                              isRequired
                                            />
                                          </NestBox>

                                          <NestBox>
                                            <FormDate
                                              names={{
                                                year: `webProductionDetail.favoriteBrandMail[${key}].desiredDeliveryDate2Year`,
                                                month: `webProductionDetail.favoriteBrandMail[${key}].desiredDeliveryDate2Month`,
                                                date: `webProductionDetail.favoriteBrandMail[${key}].desiredDeliveryDate2Day`,
                                              }}
                                              label="第2希望日"
                                            />
                                          </NestBox>

                                          <NestBox>
                                            <FormDate
                                              names={{
                                                year: `webProductionDetail.favoriteBrandMail[${key}].desiredDeliveryDate3Year`,
                                                month: `webProductionDetail.favoriteBrandMail[${key}].desiredDeliveryDate3Month`,
                                                date: `webProductionDetail.favoriteBrandMail[${key}].desiredDeliveryDate3Day`,
                                              }}
                                              label="第3希望日"
                                            />
                                          </NestBox>
                                        </>
                                      ),
                                    }}
                                  />

                                  <FormRadioList
                                    name={`webProductionDetail.favoriteBrandMail[${key}].desiredList`}
                                    label="配信対象リスト連携"
                                    isRequired
                                    hasUndecidedButton
                                    list={objectToRadioList(DESIREDLIST_VALUES)}
                                  />

                                  <FormTextbox
                                    name={`webProductionDetail.favoriteBrandMail[${key}].desiredConditions`}
                                    label="配信対象条件"
                                    placeholder="「19年以降W+でスキンケア購入経験のある方で20年以降HK購入経験のない方」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.favoriteBrandMail[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />
                        </Section>

                        <Section>
                          <H3>バナー・各種画像制作</H3>

                          <FormArray
                            type="tab"
                            label="商品詳細フリーエリア用画像"
                            unit="点"
                            name="webProductionDetail.freeArea"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:商品詳細フリーエリア用画像`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormRadioAccordion
                                    name={`webProductionDetail.freeArea[${key}].postingArea`}
                                    label="掲載エリア"
                                    help="1_商品情報には1つの画像しか掲載できません。また、3_ブランド情報には、指定ブランドのすべての商品詳細に同じ画像が掲載されます。（同ブランドの中で、特定の商品にだけ掲載することはできません）"
                                    isRequired
                                    list={objectToRadioList(POSTINGAREA_VALUES)}
                                    contents={{
                                      '1': <FreeAreaPostingCode k={key} />,
                                      '2': <FreeAreaPostingCode k={key} />,
                                      '3': (
                                        <ApiSelectMultipleBrand
                                          name={`webProductionDetail.freeArea[${key}].postingSelectedBrandNum`}
                                          valueName="postingSelectedBrands"
                                          labelName="postingSelectedBrandsName"
                                          isRequired
                                          hasUndecidedButton
                                        />
                                      ),
                                    }}
                                  />

                                  <FormRadioAccordion
                                    name={`webProductionDetail.freeArea[${key}].postingEndConditions`}
                                    label="掲載終了条件"
                                    isRequired
                                    hasUndecidedButton
                                    list={objectToRadioList(POSTINGENDCONDITIONS_VALUES)}
                                    contents={{
                                      '1': (
                                        <FormDate
                                          names={{
                                            year: `webProductionDetail.freeArea[${key}].postingEndDateYear`,
                                            month: `webProductionDetail.freeArea[${key}].postingEndDateMonth`,
                                            date: `webProductionDetail.freeArea[${key}].postingEndDateDay`,
                                          }}
                                          label="掲載終了日"
                                          isRequired
                                          hasUndecidedButton
                                        />
                                      ),
                                      '2': (
                                        <FormDate
                                          names={{
                                            year: `webProductionDetail.freeArea[${key}].postingEndDateYear`,
                                            month: `webProductionDetail.freeArea[${key}].postingEndDateMonth`,
                                            date: `webProductionDetail.freeArea[${key}].postingEndDateDay`,
                                          }}
                                          defaultValues={{
                                            year: '2100',
                                            month: '12',
                                            date: '31',
                                          }}
                                          label="掲載終了日"
                                          isRequired
                                          hasUndecidedButton
                                        />
                                      ),
                                    }}
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.freeArea[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormArray
                            type="tab"
                            label="大バナー"
                            unit="点"
                            name="webProductionDetail.bigBanner"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:大バナー`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormTextbox
                                    name={`webProductionDetail.bigBanner[${key}].pcUrl`}
                                    type="url"
                                    label="リンク先URL（PC）"
                                    placeholder="「https://www.shiseido.co.jp/cms/onlineshop/hk/b/promotion2020/」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <CopyButton from={`webProductionDetail.bigBanner[${key}].pcUrl`} to={`webProductionDetail.bigBanner[${key}].spUrl`} />

                                  <FormTextbox
                                    name={`webProductionDetail.bigBanner[${key}].spUrl`}
                                    type="url"
                                    label="リンク先URL（SP）"
                                    placeholder="「https://www.shiseido.co.jp/sp/cms/onlineshop/hk/b/promotion2020/」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.bigBanner[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormArray
                            type="tab"
                            label="汎用バナー"
                            unit="点"
                            name="webProductionDetail.purposeBanner"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:汎用バナー`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormTextbox
                                    name={`webProductionDetail.purposeBanner[${key}].pcUrl`}
                                    type="url"
                                    label="リンク先URL（PC）"
                                    placeholder="「https://www.shiseido.co.jp/cms/onlineshop/hk/b/promotion2020/」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <CopyButton from={`webProductionDetail.purposeBanner[${key}].pcUrl`} to={`webProductionDetail.purposeBanner[${key}].spUrl`} />

                                  <FormTextbox
                                    name={`webProductionDetail.purposeBanner[${key}].spUrl`}
                                    type="url"
                                    label="リンク先URL（SP）"
                                    placeholder="「https://www.shiseido.co.jp/sp/cms/onlineshop/hk/b/promotion2020/」等を入力"
                                    isRequired
                                    hasUndecidedButton
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.purposeBanner[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormArray
                            type="tab"
                            label="マイページ掲載用バナー"
                            unit="点"
                            note="※汎用バナーの流用ではなく、マイページのみに掲載するバナーを制作する場合に入力してください。"
                            name="webProductionDetail.myPageBanner"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:マイページ掲載用バナー`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormTextInput
                                    name={`webProductionDetail.myPageBanner[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.imageBanner`}
                            label="OG image"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.externalBanner`}
                            label="外部バナー"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormArray
                            type="tab"
                            label="カート内画像"
                            unit="点"
                            name="webProductionDetail.cartImage"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:カート内画像`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormTextInput
                                    name={`webProductionDetail.cartImage[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.giftSetImage`}
                            label="ギフト・セット用カート画像"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.lineMessage`}
                            label="LINEメッセージバナー"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.lineCarousel`}
                            label="LINEカルーセルバナー"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.lineHome`}
                            label="LINEホーム"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.lineRich`}
                            label="LINEリッチ"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.lineRichSplit`}
                            label="LINEリッチ分割"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.twitterBanner`}
                            label="Twitter専用バナー"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.rToasterBanner`}
                            label="Rトースターバナー"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.colorSimulator`}
                            label="カラーシミュレーター用画像"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />

                          <FormArray
                            type="tab"
                            label="その他バナー"
                            unit="点"
                            name="webProductionDetail.otherBanner"
                            min={0}
                            contentLabel={(key: number) => `${key + 1}:その他バナー`}
                            content={(key: number) => {
                              return (
                                <React.Fragment key={key}>
                                  <FormRadioAccordion
                                    name={`webProductionDetail.otherBanner[${key}].productContent`}
                                    label="制作物内容"
                                    isRequired
                                    hasUndecidedButton
                                    list={objectToRadioList(PRODUCTCONTENT_VALUES)}
                                    contents={{
                                      '100': (
                                        <FormTextbox
                                          name={`webProductionDetail.otherBanner[${key}].otherDetail`}
                                          label="詳細"
                                          placeholder="制作するバナーについて詳細を入力"
                                        />
                                      ),
                                    }}
                                  />

                                  <FormTextInput
                                    name={`webProductionDetail.otherBanner[${key}].imjManagementNumber`}
                                    label="（IMJ入力）管理番号"
                                    placeholder="「w202101110」等を入力"
                                  />
                                </React.Fragment>
                              );
                            }}
                          />

                          <FormSelectMenu
                            name={`webProductionDetail.crmDetails`}
                            label="CRM明細用画像"
                            defaultValue="0"
                            placeholder="0"
                            unit="点"
                            list={createNumberOptions(0, 100)}
                          />
                        </Section>
                      </SectionInner>
                    </Section>

                    <ButtonContainer>
                      <ApiSaveMeasureButton type="web" getRequestData={getRequestData} />
                    </ButtonContainer>
                  </>
                )}
              </Section>
            </Container>

            <StickyFooter type="web" getRequestData={getRequestData} disabledSaveTemp={webProductionValue !== '1'} />

            <RHFDevTools />
          </ApiGetMeasuresDetailProvider>
        </FormProvider>
      </Main>
      <Footer mb={80} />
    </>
  );
};
