import React from 'react';
import { RefCallBack } from 'react-hook-form';
import { toHalfWIdthChars, toFullWIdthChars } from 'utils';
import style from './style.module.scss';

const Types = {
  text: 'text',
  url: 'url',
  fullWidth: 'fullWidth',
} as const;
export type TextboxTypesProps = typeof Types[keyof typeof Types];

type Props = {
  name: string;
  value: string;
  refs?: RefCallBack;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  type?: TextboxTypesProps;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  isInvalid?: boolean;
  className?: string;
};

export const Textbox: React.FC<Props> = ({
  name,
  value,
  refs,
  onChange,
  onBlur,
  type = 'text',
  placeholder = '',
  disabled = false,
  maxLength,
  isInvalid,
  className = '',
}) => {
  const handleChange = React.useCallback(
    (e) => {
      const inputted = e.target.value;

      if (maxLength && inputted.length > maxLength) return;

      onChange(inputted);
    },
    [onChange, maxLength],
  );

  const handleBlur = React.useCallback(
    (e) => {
      const inputted = e.target.value.trim();

      if (type === 'url') {
        onChange(toHalfWIdthChars(inputted));
        onBlur?.(toHalfWIdthChars(inputted));
        return;
      }

      if (type === 'fullWidth') {
        onChange(toFullWIdthChars(inputted));
        onBlur?.(toFullWIdthChars(inputted));
        return;
      }

      onChange(inputted);
      onBlur?.(inputted);
    },
    [onChange, onBlur, type],
  );

  return React.useMemo(
    () => (
      <textarea
        ref={refs}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        className={`${style['textbox']} ${style['textbox-' + type]} ${isInvalid ? 'is-invalid' : ''} ${className}`}
        onChange={handleChange}
        onBlur={handleBlur}
        rows={4}
      />
    ),
    [value, isInvalid, disabled, className, handleBlur, handleChange, maxLength, name, placeholder, refs, type],
  );
};
