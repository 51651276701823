import React from 'react';
import style from './style.module.scss';

type Props = {
  error?: string;
  mt?: number;
  className?: string;
};

export const ErrorText: React.FC<Props> = ({ error, mt, className = '' }) =>
  React.useMemo(
    () => (
      <>
        {error && (
          <p className={`${style['typography-error']} ${className}`} style={{ ...(mt !== undefined && { marginTop: `${mt}px` }) }}>
            {error.split('\n').map((str, index, array) => {
              return index === array.length - 1 ? (
                str
              ) : (
                <React.Fragment key={index}>
                  {str}
                  <br />
                </React.Fragment>
              );
            })}
          </p>
        )}
      </>
    ),
    [error, mt, className],
  );
