import React from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from 'components/Atoms/Layout';
import { TextLink } from 'components/Business/TextLink';
import { Container } from 'components/Atoms/Layout';
import { ApiSaveTempMeasureButton } from 'components/Business/Api/ApiSaveTempMeasureButton';
import style from './style.module.scss';
import type { ApiSaveTempMeasureButtonTypeProps } from 'components/Business/Api/ApiSaveTempMeasureButton';
import type { MeasureParamsProps } from 'types';

type Props = {
  type?: ApiSaveTempMeasureButtonTypeProps;
  getRequestData: () => { [key: string]: string };
  disabledSaveTemp?: boolean;
};

export const StickyFooter: React.FC<Props> = ({ type, getRequestData, disabledSaveTemp }) => {
  const { measureId } = useParams<MeasureParamsProps>();

  return (
    <>
      {measureId && (
        <>
          {' '}
          <div className={`${style.stickyFooter}`}>
            <div className={`${style['stickyFooter-inner']}`}>
              <Container>
                <Flex alignItems="center">
                  <ul className={style['stickyFooter-links']}>
                    <li>
                      <TextLink to={`/CPF001/${measureId}`} color="white">
                        施策概要・依頼内容
                      </TextLink>
                    </li>
                    <li>
                      <TextLink to={`/CPF002/${measureId}`} color="white">
                        Web制作
                      </TextLink>
                    </li>
                    <li>
                      <TextLink to={`/CPF003/${measureId}`} color="white">
                        キャンペーン設定・クーポン発行
                      </TextLink>
                    </li>
                    <li>
                      <TextLink to={`/CPF004/${measureId}`} color="white">
                        サンプル・モニター設定
                      </TextLink>
                    </li>
                  </ul>
                  {type && (
                    <div className={style['stickyFooter-buttons']}>
                      <ApiSaveTempMeasureButton type={type} getRequestData={getRequestData} disabled={disabledSaveTemp} />
                    </div>
                  )}
                </Flex>
              </Container>
            </div>
          </div>
          {/* <div className={style['stickyFooter-adjust']}></div> */}
        </>
      )}
    </>
  );
};
