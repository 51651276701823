import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { TextInput, SelectList } from 'components/Atoms/Form';
import { escapeRegExp } from 'utils';
import style from './style.module.scss';
import type { TextInputSizeProps } from 'components/Atoms/Form/TextInput';
import { RefCallBack } from 'react-hook-form';

export type SelectMenuListProps = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  value: string;
  refs?: RefCallBack;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  list: SelectMenuListProps[];
  isInvalid?: boolean;
  className?: string;
  searchable?: boolean;
  isLoading?: boolean;
  size?: TextInputSizeProps;
  width?: number;
};

export const SelectMenu: React.FC<Props> = ({
  name,
  value,
  onChange,
  disabled = false,
  list,
  isInvalid,
  className = '',
  searchable = false,
  isLoading = false,
  size = 'medium',
  width,
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [options, setOptions] = React.useState(list);
  const [labeledValue, setLabeledValue] = React.useState('');

  const handleChange = React.useCallback(
    (value) => {
      onChange(value);
      setOpen(false);
    },
    [onChange],
  );

  const handleOpen = React.useCallback(() => {
    if (disabled) return;
    setOpen(!isOpen);
  }, [isOpen, disabled]);

  const handleOutsideClick = React.useCallback(() => {
    if (isOpen) setOpen(false);
  }, [isOpen]);

  const handleChangeSearchInput = React.useCallback((val) => {
    setSearchValue(val);
  }, []);

  const handleReset = React.useCallback(
    (event) => {
      event.stopPropagation();
      console.log('reset');
      onChange('');
    },
    [onChange],
  );

  const filterOptions = React.useCallback(() => {
    const newOptions = list.filter((elem) => elem.label.match(new RegExp(escapeRegExp(searchValue), 'gi')));
    setOptions(newOptions);
  }, [list, searchValue]);

  React.useEffect(() => {
    filterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  React.useEffect(() => {
    filterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  React.useEffect(() => {
    const newLabeledValue = options.find((elem) => elem.value === value)?.label;
    setLabeledValue(newLabeledValue || '');
  }, [value, options]);

  return React.useMemo(
    () => (
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div className={`${style['selectMenu']} ${className}`}>
          <div className={style['selectMenu-input-wrapper']} onClick={handleOpen}>
            <TextInput
              name={name}
              value={labeledValue}
              onChange={() => {}}
              disabled={disabled}
              readonly
              size={size}
              width={width}
              className={`${style['selectMenu-input']} ${isInvalid ? 'is-invalid' : ''}`}
            />
            {isLoading && <SvgIcon type="loading-small" width={22} className={style['selectMenu-input-icon-loading']} />}
            {!isLoading && value && (
              <button onClick={handleReset} className={style['selectMenu-input-icon-delete']}>
                <SvgIcon type="cross" fill="#111" width={10} />
              </button>
            )}
            <SvgIcon type="triangle-down" fill="#2896f0" width={12} className={style['selectMenu-input-icon']} />
          </div>

          {isOpen && (
            <div className={style['selectMenu-options-wrapper']}>
              {searchable && (
                <div className={`${style['selectMenu-search-wrapper']}`}>
                  <TextInput name={`${name}_search`} value={searchValue} onChange={handleChangeSearchInput} className={`${style['selectMenu-search']}`} />
                  <SvgIcon type="search" width={16} className={style['selectMenu-search-icon']} />
                </div>
              )}

              <SelectList name={name} list={options} value={value} onChange={handleChange} className={style['selectMenu-options']} />
            </div>
          )}
        </div>
      </OutsideClickHandler>
    ),
    [
      className,
      disabled,
      handleChange,
      isInvalid,
      name,
      searchable,
      isLoading,
      handleOpen,
      isOpen,
      value,
      handleOutsideClick,
      handleChangeSearchInput,
      searchValue,
      options,
      labeledValue,
      handleReset,
      size,
      width,
    ],
  );
};
