import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { isNumber, isRange, isValidDate, isFutureDate } from 'utils';
import { useTranslation } from 'react-i18next';
import { UNDECIDED_VALUE } from 'constants/index';
import type { MeasureParamsProps } from 'types';

export const useValidationSchema = () => {
  const { t } = useTranslation();
  const { measureId } = useParams<MeasureParamsProps>();

  return yup.object().shape({
    measuresName: yup
      .string()
      .required(t('ERR-REQUIRED'))
      .matches(/^\d{2}\.\d{2}\.\d{2}【.+】.+$/, t('ERR-FORMAT', { format: 'yy.mm.dd【ブランド略称】施策名' })),

    inCharge: yup.string().required(t('ERR-REQUIRED')),

    measuresClassification: yup.string().required(t('ERR-REQUIRED')),

    measuresPurpose: yup.string().when('measuresClassification', {
      is: (value: string) => value === '1' || value === '2',
      then: yup.string().required(t('ERR-REQUIRED')),
    }),

    benefitContent: yup.string().when('measuresClassification', {
      is: (value: string) => value === '2',
      then: yup.string().required(t('ERR-REQUIRED')),
    }),

    benefitGrantConditions: yup.string().when('measuresClassification', {
      is: (value: string) => value === '2',
      then: yup.string().required(t('ERR-REQUIRED')),
    }),

    measuresProduct: yup.string().when('measuresClassification', {
      is: (value: string) => value === '1' || value === '2',
      then: yup.string().required(t('ERR-REQUIRED')),
    }),

    monthlySalesPlan: yup.string().when('measuresClassification', {
      is: (value: string) => value === '1' || value === '2',
      then: yup.string().required(t('ERR-REQUIRED')),
    }),

    measuresStartDateYear: yup.lazy((value: string) => {
      if (value === UNDECIDED_VALUE) return yup.string();
      return yup
        .string()
        .required(t('ERR-REQUIRED'))
        .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
          return isNumber(value);
        })
        .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
          return isValidDate({ year: value, month: this.parent.measuresStartDateMonth, date: this.parent.measuresStartDateDay });
        })
        .test('isFutureDate', t('ERR-PAST-DATE'), function (value) {
          if (measureId) return true;
          return isFutureDate({ year: value, month: this.parent.measuresStartDateMonth, date: this.parent.measuresStartDateDay });
        });
    }),

    measuresStartDateMonth: yup.lazy((value: string) => {
      if (value === UNDECIDED_VALUE) return yup.string();
      return yup
        .string()
        .required(t('ERR-REQUIRED'))
        .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
          return isNumber(value);
        })
        .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
          return isValidDate({ year: this.parent.measuresStartDateYear, month: value, date: this.parent.measuresStartDateDay });
        })
        .test('isFutureDate', t('ERR-PAST-DATE'), function (value) {
          if (measureId) return true;
          return isFutureDate({ year: this.parent.measuresStartDateYear, month: value, date: this.parent.measuresStartDateDay });
        });
    }),

    measuresStartDateDay: yup.lazy((value: string) => {
      if (value === UNDECIDED_VALUE) return yup.string();
      return yup
        .string()
        .required(t('ERR-REQUIRED'))
        .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
          return isNumber(value);
        })
        .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
          return isValidDate({ year: this.parent.measuresStartDateYear, month: this.parent.measuresStartDateMonth, date: value });
        })
        .test('isFutureDate', t('ERR-PAST-DATE'), function (value) {
          if (measureId) return true;
          return isFutureDate({ year: this.parent.measuresStartDateYear, month: this.parent.measuresStartDateMonth, date: value });
        });
    }),

    measuresStartTimeHours: yup.lazy((value: string) => {
      if (value === UNDECIDED_VALUE) return yup.string();
      return yup
        .string()
        .required(t('ERR-REQUIRED'))
        .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
          return isNumber(value);
        })
        .test('isRange', t('ERR-INVALID-TIME'), function (value) {
          return isRange({ value, min: 0, max: 23 });
        });
    }),

    measuresStartTimeMinutes: yup.lazy((value: string) => {
      if (value === UNDECIDED_VALUE) return yup.string();
      return yup
        .string()
        .required(t('ERR-REQUIRED'))
        .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
          return isNumber(value);
        })
        .test('isRange', t('ERR-INVALID-TIME'), function (value) {
          return isRange({ value, min: 0, max: 59 });
        });
    }),

    measuresEndConditions: yup.string().required(t('ERR-REQUIRED')),

    measuresEndDateYear: yup.string().when('measuresEndConditions', {
      is: (value: string) => value === '1' || value === '2',
      then: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
            return isValidDate({ year: value, month: this.parent.measuresEndDateMonth, date: this.parent.measuresEndDateDay });
          })
          .test('isFutureDate', t('ERR-PAST-DATE'), function (value) {
            if (measureId) return true;
            return isFutureDate({ year: value, month: this.parent.measuresEndDateMonth, date: this.parent.measuresEndDateDay });
          });
      }),
    }),

    measuresEndDateMonth: yup.string().when('measuresEndConditions', {
      is: (value: string) => value === '1' || value === '2',
      then: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
            return isValidDate({ year: this.parent.measuresEndDateYear, month: value, date: this.parent.measuresEndDateDay });
          })
          .test('isFutureDate', t('ERR-PAST-DATE'), function (value) {
            if (measureId) return true;
            return isFutureDate({ year: this.parent.measuresEndDateYear, month: value, date: this.parent.measuresEndDateDay });
          });
      }),
    }),

    measuresEndDateDay: yup.string().when('measuresEndConditions', {
      is: (value: string) => value === '1' || value === '2',
      then: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
            return isValidDate({ year: this.parent.measuresEndDateYear, month: this.parent.measuresEndDateMonth, date: value });
          })
          .test('isFutureDate', t('ERR-PAST-DATE'), function (value) {
            if (measureId) return true;
            return isFutureDate({ year: this.parent.measuresEndDateYear, month: this.parent.measuresEndDateMonth, date: value });
          });
      }),
    }),

    measuresEndTimeHours: yup.string().when('measuresEndConditions', {
      is: (value: string) => value === '1' || value === '2',
      then: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isRange', t('ERR-INVALID-TIME'), function (value) {
            return isRange({ value, min: 0, max: 23 });
          });
      }),
    }),

    measuresEndTimeMinutes: yup.string().when('measuresEndConditions', {
      is: (value: string) => value === '1' || value === '2',
      then: yup.lazy((value: string) => {
        if (value === UNDECIDED_VALUE) return yup.string();
        return yup
          .string()
          .required(t('ERR-REQUIRED'))
          .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
            return isNumber(value);
          })
          .test('isRange', t('ERR-INVALID-TIME'), function (value) {
            return isRange({ value, min: 0, max: 59 });
          });
      }),
    }),

    olsDocumentCreation: yup.string().required(t('ERR-REQUIRED')),

    webProduction: yup.string().required(t('ERR-REQUIRED')),

    janCodeIssuance: yup.string().required(t('ERR-REQUIRED')),

    osWorkRequest: yup.string().required(t('ERR-REQUIRED')),

    campaignSettings: yup.string().required(t('ERR-REQUIRED')),

    sampleSettings: yup.string().required(t('ERR-REQUIRED')),

    giftSetCodeSettings: yup.string().required(t('ERR-REQUIRED')),

    remarksColumn: yup.string(),

    xPostAspiration: yup.string().required(t('ERR-REQUIRED')),

    xImagePreparation: yup.string(),

    igStoryPostAspiration: yup.string().required(t('ERR-REQUIRED')),

    igStoryImagePreparation: yup.string(),

    lineMessagePostAspiration: yup.string().required(t('ERR-REQUIRED')),

    lineConnectStreaming: yup.string().when('lineMessagePostAspiration', {
      is: (value: string) => value === '1',
      then: yup.string().required(t('ERR-REQUIRED')),
    }),

    streamingConditions: yup.string().when('lineConnectStreaming', {
      is: (value: string) => value === '1',
      then: yup.string().required(t('ERR-REQUIRED')),
    }),

    lineHomePostAspiration: yup.string().required(t('ERR-REQUIRED')),

    snsLineRemarks: yup.string(),
  });
};
