import React from 'react';
import { FormRadioAccordion, FormDate, FormTextbox } from 'components/Business/Form';
import { objectToRadioList } from 'utils';
import { MYPAGEENDCONDITIONS_VALUES } from 'constants/index';

type Props = {
  k: number;
};

export const CampaignOptionsSecret: React.FC<Props> = ({ k }) => (
  <>
    <FormTextbox
      type="fullWidth"
      name={`campaignDetail[${k}].myPageCampaignName`}
      label="マイページへ表示するキャンペーン名称"
      placeholder="「「化粧水＆乳液お手入れ応援キャンペーン」コットンや特別クーポンプレゼント！」等を入力"
      help="【表記ルール】「キャンペーン名称」あおりコピー"
      isRequired
      hasUndecidedButton
    />

    <FormTextbox
      type="fullWidth"
      name={`campaignDetail[${k}].myPageCampaignDetails`}
      label="マイページへ表示するキャンペーン詳細"
      placeholder="入力欄下の注意点をご確認の上、ご記入ください。"
      notes={[
        '※200文字以内で入力してください。',
        '※システム上改行は反映されませんので、改行なしでご記入ください。',
        '※「いつ」「何が」「どういう条件で」つくのかを明記してください。',
        '※必ず、「※「詳しくはこちら」に記載している詳細のご確認を必ずお願いします。」の注釈を記載してください。',
      ]}
      help="【表記ルール】〇ご購入、×お買い物 / 〇対象期間、×キャンペーン期間 / 〇1,000円(税込)、×税込￥1,000"
      isRequired
      hasUndecidedButton
    />

    <FormRadioAccordion
      name={`campaignDetail[${k}].myPageEndConditions`}
      label="マイページ表示終了条件"
      isRequired
      hasUndecidedButton
      list={objectToRadioList(MYPAGEENDCONDITIONS_VALUES, ['1'])}
      defaultValue="1"
      contents={{
        '1': null,
        '2': (
          <FormDate
            names={{
              year: `campaignDetail[${k}].myPageEndDateYear`,
              month: `campaignDetail[${k}].myPageEndDateMonth`,
              date: `campaignDetail[${k}].myPageEndDateDay`,
              hour: `campaignDetail[${k}].myPageEndDateHours`,
              minute: `campaignDetail[${k}].myPageEndDateMinutes`,
            }}
            defaultValues={{
              year: '',
              month: '',
              date: '',
              hour: '23',
              minute: '59',
            }}
            label="マイページ表示終了日/時間"
            isRequired
            hasUndecidedButton
          />
        ),
        '3': null,
      }}
    />

    <FormTextbox
      name={`campaignDetail[${k}].campaignImage`}
      type="url"
      label="キャンペーン画像"
      placeholder="「https://www.shiseido.co.jp/cms/onlineshop/hk/b/promotion2020/」等を入力"
      note="※指定がある場合のみ入力してください。"
      help="マイページに表示するキャンペーン画像のURL"
    />

    <FormTextbox
      name={`campaignDetail[${k}].secretCampaignDescriptionPc`}
      label="シークレットキャンペーンの説明（PCタグ）"
      placeholder="シークレットキャンペーンの説明を入力"
      notes={['※指定がある場合のみ入力してください。', '※4000byte以内で入力してください。']}
      help="マイページの「詳しくはこちら」を押下時に表示されるモーダルダイアログに表示されるコンテンツ"
    />

    <FormTextbox
      name={`campaignDetail[${k}].secretCampaignDescriptionSp`}
      label="シークレットキャンペーンの説明（SPタグ）"
      placeholder="シークレットキャンペーンの説明を入力"
      notes={['※指定がある場合のみ入力してください。', '※4000byte以内で入力してください。']}
      help="マイページの「詳しくはこちら」を押下時に表示されるモーダルダイアログに表示されるコンテンツ"
    />
  </>
);
