import React from 'react';
import { Flex } from 'components/Atoms/Layout';
import { LinkList } from 'components/Atoms/List';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';
import type { TextLinkProps } from 'components/Business/TextLink';

type Props = {
  list: (TextLinkProps & { text: string })[];
  className?: string;
};

export const SortList: React.FC<Props> = ({ list, className = '' }) => {
  return React.useMemo(
    () => (
      <Flex justifyContent="end" className={`${style['sort-list']} ${className}`}>
        <SvgIcon type="sort" fill="#373940" width={16} />
        <LinkList list={list} className={style['sort-list-links']} />
      </Flex>
    ),
    [list, className],
  );
};
