import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Main, Container, Section, SectionInner, ButtonContainer } from 'components/Atoms/Layout';
import { H1, H2, H3, H4, Text } from 'components/Atoms/Typography';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { DataTable } from 'components/Organisms/DataTable';
import { TabMenu } from 'components/Organisms/TabMenu';
import { DESIGNTARGET_VALUES } from 'constants/index';
import { BackButton } from './BackButton';
import { SubmitButton } from './SubmitButton';
import type { MeasureParamsProps, ModuleProps, ModuleAssignmentProps, DesignDetailProps } from 'types';
import type { DataTableProps } from 'components/Organisms/DataTable';
import type { TabDataProps } from 'components/Organisms/TabMenu';

export const AID003: React.FC = () => {
  const history = useHistory();
  const { measureId } = useParams<MeasureParamsProps>();
  const { state } = useLocation<DesignDetailProps>();
  const [requirementData, setRequirementData] = React.useState<DataTableProps[]>([]);
  const [materialData, setMaterialData] = React.useState<TabDataProps[]>([]);
  const [formData, setFormData] = React.useState<DesignDetailProps>();

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    return {
      purpose: formData?.purpose || '',
      targetUsers: formData?.targetUsers || '',
      specialOffer: formData?.specialOffer || '',
      moduleAssignments: (() => {
        return (formData?.moduleAssignments ? formData?.moduleAssignments.concat() : [])
          .filter((elem: ModuleAssignmentProps) => {
            return elem.designTarget === '1' ? true : false;
          })
          .map((elem: ModuleAssignmentProps) => {
            return {
              productType: elem.productType || '',
              productSubType: elem.productSubType || '',
              productNumber: elem.productNumber || '',
              modules: elem?.modules
                ? elem.modules
                    .map((module: ModuleProps) => {
                      return {
                        moduleId: module?.moduleId,
                        fontName: module?.fontName,
                      };
                    })
                    .filter((module) => module)
                : [],
            };
          });
      })(),
    };
  }, [formData]);

  React.useEffect(() => {
    console.log(state);
    // stateがない場合はフォームトップにreplace
    if (!state) history.replace(`/AID002/${measureId}`);
    // routerで受け取ったstateをページのstateとして保存
    setFormData(state);
    // routerで受け取ったstateをを破棄(reload対応)
    history.replace(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const requirementData: DataTableProps[] = [
      {
        type: 'text',
        label: '制作目的',
        value: formData?.purpose,
      },
      {
        type: 'text',
        label: 'ターゲットユーザー層',
        value: formData?.targetUsers,
      },
      {
        type: 'text',
        label: '特典有無/種別',
        value: formData?.specialOffer,
      },
    ];
    setRequirementData(requirementData);

    const materialData: TabDataProps[] =
      formData?.moduleAssignments?.map((moduleAssignment: ModuleAssignmentProps) => {
        const designTarget = moduleAssignment?.modules?.length > 0 ? '1' : '2';

        return {
          label: `${moduleAssignment?.productNumber}: ${moduleAssignment?.productType}`,
          content: (
            <>
              <DataTable
                data={[
                  {
                    type: 'radio',
                    label: 'デザイン生成',
                    value: designTarget,
                    master: DESIGNTARGET_VALUES,
                  },
                  {
                    condition: designTarget === '1' && moduleAssignment?.productType === '外部バナー',
                    type: 'text',
                    label: '制作種別',
                    value: moduleAssignment?.productSubType,
                  },
                ]}
              />

              {designTarget === '1' && (
                <Section>
                  <H3>素材指定情報</H3>

                  {moduleAssignment?.modules?.map((module: ModuleProps, index: number) => (
                    <Section key={index}>
                      <H4>素材{index + 1}</H4>

                      <DataTable
                        data={[
                          {
                            type: 'text',
                            label: '素材データ種別',
                            value: module?.dataType,
                          },
                          {
                            type: 'text',
                            label: '識別番号',
                            value: module?.moduleNum,
                          },

                          {
                            condition: module?.dataType === '画像' || module?.dataType === '動画' || module?.dataType === '他社埋め込みサービス',
                            type: 'text',
                            label: 'URL',
                            value: module?.url,
                          },

                          {
                            condition: module?.dataType === 'テキスト',
                            type: 'text',
                            label: '素材種類',
                            value: module?.attributes?.type,
                          },
                          {
                            condition: module?.dataType === 'テキスト',
                            type: 'text',
                            label: 'テキスト文言',
                            value: module?.text,
                          },
                          {
                            condition: module?.dataType === 'テキスト',
                            type: 'text',
                            label: 'フォント指定',
                            value: module?.fontName,
                          },
                          {
                            condition: module?.dataType === 'テキスト',
                            type: 'text',
                            label: '季節',
                            value: module?.attributes?.season,
                          },
                          {
                            condition: module?.dataType === 'テキスト',
                            type: 'text',
                            label: 'ブランド',
                            value: module?.attributes?.brand,
                          },
                        ]}
                      />
                    </Section>
                  ))}
                </Section>
              )}
            </>
          ),
        };
      }) || [];
    setMaterialData(materialData);
  }, [formData]);

  return (
    <>
      <Header />
      <Main>
        <Container>
          <Breadcrumb
            list={[
              {
                label: 'TOP',
                url: '/CMN001',
              },
              {
                label: 'AI Designer',
                url: '/AID001',
              },
              {
                label: '制作要件・素材指定',
              },
              {
                label: '入力内容確認',
              },
            ]}
          />
          <Section>
            <H1>入力内容確認</H1>
            <Text align="center">入力内容をご確認のうえ、入力完了ボタンをクリックしてください。</Text>

            <Text align="center" size={18}>
              施策名:{formData?.measureName}
            </Text>

            <Section hasBg>
              <H2>制作要件</H2>
              <SectionInner>
                <DataTable data={requirementData} />
              </SectionInner>
            </Section>

            <Section hasBg>
              <H2>素材指定</H2>
              <SectionInner>{materialData.length > 0 && <TabMenu data={materialData} />}</SectionInner>
            </Section>

            <ButtonContainer>
              {formData && <BackButton formData={formData} />}
              {formData && <SubmitButton formData={formData} getRequestData={getRequestData} />}
            </ButtonContainer>
          </Section>
        </Container>
      </Main>
      <Footer />
    </>
  );
};
