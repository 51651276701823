import React from 'react';
import { Tag } from 'components/Atoms/Tag';
import style from './style.module.scss';
import type { ColorsProps } from 'components/Atoms/Tag/Tag';
import type { DesignerStatusProps } from 'types';

type Props = {
  status: DesignerStatusProps;
  ml?: number;
};

type StatusDataProps = {
  label: string;
  color: ColorsProps;
};

export const DesignerStatusTag: React.FC<Props> = ({ status, ml }) => {
  const statusData: StatusDataProps = React.useMemo(() => {
    switch (status) {
      case '1':
        return {
          label: '不要',
          color: 'grey',
        };
      case '2':
        return {
          label: '未実行',
          color: 'red',
        };
      case '3':
        return {
          label: '実行中',
          color: 'blue',
        };
      case '4':
        return {
          label: '完了',
          color: 'green',
        };
      case '5':
        return {
          label: '失敗',
          color: 'black',
        };
    }
  }, [status]);

  return React.useMemo(
    () => (
      <Tag
        className={style['tag-designer-status']}
        color={statusData.color}
        style={{
          ...(ml !== undefined && { marginLeft: `${ml}px` }),
        }}
      >
        {statusData.label}
      </Tag>
    ),
    [statusData, ml],
  );
};
