import React from 'react';
import { useApiCall } from 'hooks/useApiCall';
import { Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Modal } from 'components/Organisms/Modal';
import { Server400ErrorUpdate } from 'context';
import {
  addDateTime2Filename
  ,decodeBase64ToBlob
  ,downloadBlob
} from 'utils';
import style from './style.module.scss';

type Props = {
  title: string;
  formData: { [key: string]: any } | undefined;
  url: string;
  itemCount: number;
  maxDownloads: number;
  contentType: string;
  filename: string;
}

export const DownloadButton: React.FC<Props> = ({
  title
  ,formData
  ,url
  ,itemCount
  ,maxDownloads
  ,contentType
  ,filename
}) => {
  const apiCall = useApiCall();
  const setServer400Error = React.useContext(Server400ErrorUpdate);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ isShowPreviousModal, setIsShowPreviousModal ] = React.useState<boolean>(false);
  const [ isShowWornModal, setIsShowWornModal ] = React.useState<boolean>(false);
  const [ strErrorCode, setStrErrorCode ] = React.useState<string>();

  const handleDownload = React.useCallback(() => {
    if (maxDownloads < itemCount) {
      setStrErrorCode('ERR03003');
      return;
    }

    setIsLoading(true);
    setServer400Error(undefined);

    apiCall({
      method: 'GET',
      url,
      data: formData
    })
    .then((res: any) => {
      if (res.status === 200) {
        const blob = decodeBase64ToBlob(res.data, contentType);
        downloadBlob(blob, addDateTime2Filename(filename));
      }
    })
    .catch((error) => {
      if (!error.response) {
        setStrErrorCode('SESSION TIMEOUT');
      }
      else {
        if (error.response.status === 400) {
          setServer400Error(error.response.data.error);
          if (error.response.data.error.code === 'ERR03003') {
            setStrErrorCode(error.response.data.error.code);
          }
        }
      }
    })
    .finally(() => {
      setIsLoading(false);
      setIsShowPreviousModal(false);
    })
  }, [
    formData
    ,url
    ,itemCount
    ,maxDownloads
    ,contentType
    ,filename
    ,apiCall
    ,setServer400Error
    ,setIsLoading
    ,setIsShowPreviousModal
    ,setStrErrorCode
  ]);

  React.useEffect(() => {
    if (strErrorCode !== "") {
      setIsShowWornModal(true);
    }
  }, [strErrorCode]);

  React.useEffect(() => {
    if (!isShowWornModal) {
      setStrErrorCode("");
    }
    setIsShowPreviousModal(false);
  }, [isShowWornModal]);

  return React.useMemo(
    () => (
      <>
        <Loading isLoading={isLoading} />

        <Button
          type="secondary"
          text="ダウンロード"
          onClick={ () => setIsShowPreviousModal(true) }
          disabled={itemCount==0}
          className={`${style['button-download']}`}
        />

        <Modal
          title={`${itemCount} 件の${title}をダウンロードします`}
          isShow={isShowPreviousModal}
          hasCancel
          hasSubmit
          cancelText="キャンセル"
          onCancel={ () => setIsShowPreviousModal(false) }
          onSubmit={ () => handleDownload() }
        >
          <Text align="center">件数が多い場合、時間がかかる可能性があります。</Text>
        </Modal>

        {strErrorCode !== "" && (
          <Modal
            type="warn"
            title="ダウンロードできませんでした"
            hasSubmit
            isShow={isShowWornModal}
            onSubmit={ () => setIsShowWornModal(false) }
          >
            {strErrorCode === 'ERR03003' && (
              <Text align="center">
                一度にダウンロードできるのは {maxDownloads} 件までとなります。
                <br />
                絞り込み条件を変更のうえダウンロードいただきますよう、お願いします。
              </Text>
            )}
            {strErrorCode === 'SESSION TIMEOUT' && (
              <Text align="center">
                セッションがタイムアウトしました。
                <br />
                絞り込み条件の変更や通信環境の良い場所での操作などをお試しのうえ
                <br />
                再度ダウンロードいただきますよう、お願いします。
              </Text>
            )}
          </Modal>
        )}
      </>
    ),
    [
      title
      ,itemCount
      ,maxDownloads
      ,isLoading
      ,isShowPreviousModal
      ,isShowWornModal
      ,strErrorCode
      ,handleDownload
    ]
  );
}
