import React from 'react';
import { RefCallBack } from 'react-hook-form';
import style from './style.module.scss';

const Size = {
  small: 'small',
  medium: 'medium',
} as const;
export type RadioSizeProps = typeof Size[keyof typeof Size];

type Props = {
  name: string;
  value: string;
  refs?: RefCallBack;
  onChange: (value: string) => void;
  onClick?: () => void;
  label: string;
  checked: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
  className?: string;
  isPlain?: boolean;
  size?: RadioSizeProps;
};

export const Radio: React.FC<Props> = ({
  name,
  value,
  refs,
  label,
  checked,
  disabled = false,
  isInvalid,
  className = '',
  onChange,
  onClick = () => {},
  isPlain = false,
  size = 'medium',
}) => {
  const handleChange = React.useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return React.useMemo(
    () => (
      <div className={`${style.radio} ${className}`}>
        <label
          className={`${style['radio-label']} ${checked ? 'is-checked' : ''} ${disabled ? 'is-disabled' : ''} ${isInvalid ? 'is-invalid' : ''} ${
            isPlain ? 'is-plain' : ''
          } is-${size}`}
        >
          <input
            ref={refs}
            name={name}
            value={value}
            className={`${style['radio-input']}`}
            checked={checked}
            disabled={disabled}
            onChange={handleChange}
            onClick={onClick}
            type="radio"
          />
          <span className={`${style['radio-text']}`}>{label}</span>
        </label>
      </div>
    ),
    [checked, className, disabled, handleChange, onClick, isInvalid, label, name, refs, value, isPlain, size],
  );
};
