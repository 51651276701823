import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsconfig from 'aws-exports';
import { TempCognitoUserUpdate, CognitoUserState, CognitoUserUpdate } from 'context';

Amplify.configure(awsconfig);

export type LoginPageProps = {
  nextPage: string;
};

export const Login: React.FC = () => {
  const history = useHistory();
  const setTempCognitoUser = React.useContext(TempCognitoUserUpdate);
  const userInfo = React.useContext(CognitoUserState);
  const setUserInfo = React.useContext(CognitoUserUpdate);
  const { state } = useLocation<LoginPageProps>();

  const resetUserInfo = React.useCallback(async () => {
    const currentUser = await Amplify.Auth.currentAuthenticatedUser();
    setUserInfo(currentUser);
    setTempCognitoUser(undefined);
  }, [setTempCognitoUser, setUserInfo]);

  React.useEffect(() => {
    if (userInfo) {
      if (state?.nextPage) {
        history.replace(state.nextPage);
      } else {
        history.replace('/CMN001');
      }
    }
  }, [userInfo, history, state]);

  React.useEffect(() => {
    resetUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
