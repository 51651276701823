import { UseFormProps } from 'react-hook-form';

/**
 * React Hook Form Settings
 */
/** Initialize parameters */
export const RHF_SETTINGS: UseFormProps = {
  mode: 'onBlur',
  reValidateMode: 'onChange',
  criteriaMode: 'firstError',
  shouldFocusError: false,
  // shouldUnregister: true,
};

/** Unregister parameters */
export const RHF_UNREGISTER_PARAMS = {
  keepDirty: false,
  keepTouched: false,
  keepIsValid: false,
  keepError: false,
  keepValue: false,
  keepDefaultValue: false,
};

/** Reset parameters */
export const RHF_RESET_PARAMS = {
  keepErrors: false,
  keepDirty: false,
  keepValues: false,
  keepDefaultValues: false,
  keepIsSubmitted: false,
  keepTouched: false,
  keepIsValid: false,
  keepSubmitCount: false,
};

/** Reset parameters(on copy) */
export const RHF_RESET_COPY_PARAMS = {
  keepErrors: true,
  keepDirty: true,
  // keepValues: true,
  // keepDefaultValues: true,
  keepIsSubmitted: true,
  keepTouched: true,
  // keepIsValid: true,
  keepSubmitCount: true,
};

/**
 * API Endpoints
 */
let apiDomain;
let aidModuleApiDomain;
let aidRecommendationApiDomain;
let aidDesignConfigurationEnvName;
let aidDynamoDbEnvName;

console.log(`REACT_APP_ENV: ${process.env.REACT_APP_ENV}`);

switch (process.env.REACT_APP_ENV) {
  case 'dev':
    apiDomain = 'dev-form-api.test-operational-innovation.info';
    aidModuleApiDomain = 'j3dbijd0e7.execute-api.ap-northeast-1.amazonaws.com'; // TODO: Mock
    aidRecommendationApiDomain = 'j3dbijd0e7.execute-api.ap-northeast-1.amazonaws.com'; // TODO: Mock
    aidDesignConfigurationEnvName = 'dev2';
    aidDynamoDbEnvName = 'dev';
    break;
  case 'e2e':
    apiDomain = 'e2e-form-api.test-operational-innovation.info';
    aidModuleApiDomain = 'j3j5gyylu7.execute-api.ap-northeast-1.amazonaws.com';
    aidRecommendationApiDomain = 'eqkkymq970.execute-api.ap-northeast-1.amazonaws.com';
    aidDesignConfigurationEnvName = 'e2e';
    aidDynamoDbEnvName = 'e2e';
    break;
  case 'prd':
    apiDomain = 'prd-form-api.operational-innovation.info';
    aidModuleApiDomain = 'a7lrg1lupe.execute-api.ap-northeast-1.amazonaws.com';
    aidRecommendationApiDomain = 'ht7zekgn1a.execute-api.ap-northeast-1.amazonaws.com';
    aidDesignConfigurationEnvName = 'prd';
    aidDynamoDbEnvName = 'prd';
    break;
  default:
    break;
}

/** 施策一覧取得（パラメータ付きで施策詳細取得） */
export const ENDPOINT_GET_MEASURE_API = `https://${apiDomain}/v1/measure`;

/** 施策一覧ダウンロード */
export const ENDPOINT_GET_MEASURE_DOWNLOAD_API = `https://${apiDomain}/v1/measure/download`;

/** 施策登録 */
export const ENDPOINT_POST_SAVE_MEASURE_API = `https://${apiDomain}/v1/measure/save`;
/** 施策概要保存 */
export const ENDPOINT_PATCH_SAVE_MEASURE_OUTLINE_API = `https://${apiDomain}/v1/measure/save/outline/`;
/** Web制作保存 */
export const ENDPOINT_PATCH_SAVE_MEASURE_WEB_API = `https://${apiDomain}/v1/measure/save/web/`;
/** キャンペーン設定・クーポン発行保存 */
export const ENDPOINT_PATCH_SAVE_MEASURE_CAMPAIGN_API = `https://${apiDomain}/v1/measure/save/campaign/`;
/** サンプル・モニター設定保存 */
export const ENDPOINT_PATCH_SAVE_MEASURE_SAMPLE_API = `https://${apiDomain}/v1/measure/save/sample/`;

/** Web制作下書き保存 */
export const ENDPOINT_PATCH_TEMP_MEASURE_WEB_API = `https://${apiDomain}/v1/measure/temp/web/`;
/** キャンペーン設定・クーポン発行下書き保存 */
export const ENDPOINT_PATCH_TEMP_MEASURE_CAMPAIGN_API = `https://${apiDomain}/v1/measure/temp/campaign/`;
/** サンプル・モニター設定下書き保存 */
export const ENDPOINT_PATCH_TEMP_MEASURE_SAMPLE_API = `https://${apiDomain}/v1/measure/temp/sample/`;

/** 施策複製 */
export const ENDPOINT_COPY_MEASURE_API = `https://${apiDomain}/v1/measure/copy`;

/** 販促品一覧取得（パラメータ付きで販促品詳細取得） */
export const ENDPOINT_GET_PROMOTION_API = `https://${apiDomain}/v1/promotion`;

/** ブランド一覧取得 */
export const ENDPOINT_GET_BRAND_API = `https://${apiDomain}/v1/brand`;

/** ブランド詳細取得 */
export const ENDPOINT_GET_BRAND_DETAIL_API = `https://${apiDomain}/v1/brand_detail`;

/** 納品詳細書アップロード */
export const ENDPOINT_POST_DELIVERY_ITEM_UPLOAD_API = `https://${apiDomain}/v1/promotion/delivery_item_upload`;

/** 販促品一覧ダウンロード */
export const ENDPOINT_GET_PROMOTION_DOWNLOAD_API = `https://${apiDomain}/v1/promotion/download`;

/** アイテム一覧取得 */
export const ENDPOINT_GET_ITEM_API = `https://${apiDomain}/v1/item`;

/** 登録済ユーザ取得 */
export const ENDPOINT_GET_USER_API = `https://${apiDomain}/v1/users`;

/** ユーザ登録 */
export const ENDPOINT_ADDUSER_API = `https://${apiDomain}/v1/addUser`;

/** 祝日一覧取得 */
export const ENDPOINT_GET_HOLIDAYS_API = `https://${apiDomain}/v1/publicHoliday`;

/** AI Designer マスタデータ取得 */
export const ENDPOINT_GET_AID_MASTER_API = `https://${apiDomain}/v1/design/item`;

/** AI Designer 詳細取得 */
export const ENDPOINT_GET_AID_DETAIL_API = `https://${apiDomain}/v1/design`;

/** AI Designer 製作要件・素材指定 保存 */
export const ENDPOINT_POST_AID_DETAIL_API = `https://${apiDomain}/v1/design/save`;

/** AI Designer 素材情報取得・登録 */
export const ENDPOINT_AID_MODULE_API = `https://${aidModuleApiDomain}/Prod/modules`;

/** AI Designer デザイン推論要求リクエスト送信 */
export const ENDPOINT_AID_RECOMMENDATION_API = `https://${aidRecommendationApiDomain}/Prod/recommendation`;

/** AI Designer 構成ファイル格納先ディレクトリ */
export const URL_AID_DESIGN_CONFIGURATION_DIRECTORY = `https://s3.console.aws.amazon.com/s3/buckets/aid-${aidDesignConfigurationEnvName}-s3-creative?region=ap-northeast-1&prefix=inbox/design-configuration/`;

/** AI Designer 構成ファイル格納先ディレクトリ（パラメータ） */
export const URL_AID_DESIGN_CONFIGURATION_PARAMS = '&showversions=false';

/** AI Designer DynamoDB Modules Table URL */
export const URL_AID_DYNAMODB_MODULES = `https://ap-northeast-1.console.aws.amazon.com/dynamodb/home?region=ap-northeast-1#tables:selected=aid-${aidDynamoDbEnvName}-dynamodb-modules;tab=items`;

/**
 * String Constants
 */
/** Site name */
export const SITE_NAME = 'AI Campaign Agent';

/** 運用保守 メールアドレス */
export const EMAIL_OPERATIONS = 'Shiseido_SynOps_Maintenance@accenture.com';

/** DateTime Format */
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_DATEPICKER = 'yyyy-MM-dd';
export const DATETIME_FORMAT_DISPLAY = 'YYYY/MM/DD HH:mm';

/** New item Form Value */
export const NEWITEM_VALUE = '{NewItem}';

/** Undecided Form Value */
export const UNDECIDED_VALUE = '{Undecided}';

/** Undecided Label */
export const UNDECIDED_LABEL = '未定';

/** Empty Label */
export const EMPTY_LABEL = '-';

/** Convert target characters map(w/o alphabet and number) */
export const CONVERT_TARGET_CHAR_MAP: { [key: string]: string } = {
  ｶﾞ: 'ガ',
  ｷﾞ: 'ギ',
  ｸﾞ: 'グ',
  ｹﾞ: 'ゲ',
  ｺﾞ: 'ゴ',
  ｻﾞ: 'ザ',
  ｼﾞ: 'ジ',
  ｽﾞ: 'ズ',
  ｾﾞ: 'ゼ',
  ｿﾞ: 'ゾ',
  ﾀﾞ: 'ダ',
  ﾁﾞ: 'ヂ',
  ﾂﾞ: 'ヅ',
  ﾃﾞ: 'デ',
  ﾄﾞ: 'ド',
  ﾊﾞ: 'バ',
  ﾋﾞ: 'ビ',
  ﾌﾞ: 'ブ',
  ﾍﾞ: 'ベ',
  ﾎﾞ: 'ボ',
  ﾊﾟ: 'パ',
  ﾋﾟ: 'ピ',
  ﾌﾟ: 'プ',
  ﾍﾟ: 'ペ',
  ﾎﾟ: 'ポ',
  ｳﾞ: 'ヴ',
  ﾜﾞ: 'ヷ',
  ｦﾞ: 'ヺ',
  ｱ: 'ア',
  ｲ: 'イ',
  ｳ: 'ウ',
  ｴ: 'エ',
  ｵ: 'オ',
  ｶ: 'カ',
  ｷ: 'キ',
  ｸ: 'ク',
  ｹ: 'ケ',
  ｺ: 'コ',
  ｻ: 'サ',
  ｼ: 'シ',
  ｽ: 'ス',
  ｾ: 'セ',
  ｿ: 'ソ',
  ﾀ: 'タ',
  ﾁ: 'チ',
  ﾂ: 'ツ',
  ﾃ: 'テ',
  ﾄ: 'ト',
  ﾅ: 'ナ',
  ﾆ: 'ニ',
  ﾇ: 'ヌ',
  ﾈ: 'ネ',
  ﾉ: 'ノ',
  ﾊ: 'ハ',
  ﾋ: 'ヒ',
  ﾌ: 'フ',
  ﾍ: 'ヘ',
  ﾎ: 'ホ',
  ﾏ: 'マ',
  ﾐ: 'ミ',
  ﾑ: 'ム',
  ﾒ: 'メ',
  ﾓ: 'モ',
  ﾔ: 'ヤ',
  ﾕ: 'ユ',
  ﾖ: 'ヨ',
  ﾗ: 'ラ',
  ﾘ: 'リ',
  ﾙ: 'ル',
  ﾚ: 'レ',
  ﾛ: 'ロ',
  ﾜ: 'ワ',
  ｦ: 'ヲ',
  ﾝ: 'ン',
  ｧ: 'ァ',
  ｨ: 'ィ',
  ｩ: 'ゥ',
  ｪ: 'ェ',
  ｫ: 'ォ',
  ｯ: 'ッ',
  ｬ: 'ャ',
  ｭ: 'ュ',
  ｮ: 'ョ',
  '｡': '。',
  '､': '、',
  ｰ: 'ー',
  '｢': '「',
  '｣': '」',
  '･': '・',
  ﾞ: '“',
  ﾟ: '゜',
  ' ': '　',
  ' ': '　',
  '	': '　',
  "'": '’',
  '`': '‘',
  '~': '〜',
};

/**
 * 施策概要・依頼内容
 */
/** 施策分類 */
export const MEASURESCLASSIFICATION_VALUES: { [key: string]: string } = {
  '1': '新発売の告知のみ',
  '2': '特典、クーポン、ポイント、チラシなどを同梱・付与',
  '3': 'OLS会議資料なしの制作・オペレーション依頼のみ',
};

/** 施策終了条件 */
export const MEASURESENDCONDITIONS_VALUES: { [key: string]: string } = {
  '1': '期間満了',
  '2': '商品または販促品なくなり次第終了',
};

/** OLS会議資料作成 */
export const OLSDOCUMENTCREATION_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** Web制作 */
export const WEBPRODUCTION_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** JANコード発行 */
export const JANCODEISSUANCE_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** OS作業依頼 */
export const OSWORKREQUEST_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** キャンペーン設定・クーポン発行 */
export const CAMPAIGNSETTINGS_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** サンプル・モニター設定 */
export const SAMPLESETTINGS_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** ギフト・セットコード発行 */
export const GIFTSETCODESETTINGS_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** X(Twitter)投稿希望 */
export const XPOSTASPIRATION_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** X(Twitter)画像手配 */
export const XIMAGEPREPARATION_VALUES: { [key: string]: string } = {
  '1': 'SNSチームお任せ',
  '2': '納品',
};

/** IGストーリーズ投稿希望 */
export const IGSTORYPOSTASPIRATION_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** IGストーリーズ画像手配 */
export const IGSTORYIMAGEPREPARATION_VALUES: { [key: string]: string } = {
  '1': 'SNSチームお任せ',
  '2': '納品',
};

/** LINEメッセージ投稿希望 */
export const LINEMESSAGEPOSTASPIRATION_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** コネクト配信 */
export const LINECONNECTSTREAMING_VALUES: { [key: string]: string } = {
  '1': '必須とする',
  '2': '必須としない',
};

/** LINEホーム投稿希望 */
export const LINEHOMEPOSTASPIRATION_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/**
 * Web制作
 */
/** 制作会社 */
export const PRODUCTIONCOMPANY_VALUES: { [key: string]: string } = {
  '1': 'IMJ',
  '2': 'IMJ以外',
};

/** adhocメール 配信種類 */
export const ADHOCMAIL_DELIVERYTYPE_VALUES: { [key: string]: string } = {
  '1': '【PMadhoc01-1~2】アドホックメール（HTML）＋（TEXT）5営業日前',
  '2': '【PMadhoc01-2】アドホックメール（TEXT）5営業日前',
};

/** 事務局と調整済みの配信確定日 */
export const ADJUSTMENTDESIREDCONFDATE_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** 配信対象リスト連携 */
export const DESIREDLIST_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** CRMメール 制作物内容 */
export const CRMMAIL_DELIVERYTYPE_VALUES: { [key: string]: string } = {
  '1': 'CRMメール(HTML）＋（TEXT）　8営業日前',
  '2': 'CRMメール（TEXT）　8営業日前',
};

/** AFメール 制作物内容 */
export const AFMAIL_DELIVERYTYPE_VALUES: { [key: string]: string } = {
  '1': '【PMtrinal01】トライアルセットアフターフォローメール　10営業日前',
  '2': '【MMtrinal01】トライアルセットアフターフォローメール　10営業日前',
};

/** お気に入りブランドメール 制作物内容 */
export const FAVORITEBRANDMAIL_DELIVERYTYPE_VALUES: { [key: string]: string } = {
  '1': '【PMbrand02-1～2】お気に入りブランドメール（HTML）＋（TEXT）　5営業日前',
  '2': '【PMbrand02-2】お気に入りブランドメール（TEXT）　5営業日前',
};

/** 掲載エリア */
export const POSTINGAREA_VALUES: { [key: string]: string } = {
  '1': '1_商品情報',
  '2': '2_キャンペーン情報',
  '3': '3_ブランド情報',
};

/** 掲載終了条件 */
export const POSTINGENDCONDITIONS_VALUES: { [key: string]: string } = {
  '1': '期間満了',
  '2': '販促品なくなり次第終了',
};

/** その他バナー 制作物内容 */
export const PRODUCTCONTENT_VALUES: { [key: string]: string } = {
  '1': '【PS101-2】＆【SP101-2】W＋トップTOPICS紹介エリア（W+トップ用／SPW＋トップ用） 3営業日前',
  '2': '【PS031-1~2】サンプルバナー＆テキスト 3営業日前',
  '3': '【PS032-1~2】モニター・プレゼントバナー＆説明テキスト  3営業日前',
  '4': '【PS051-31】PC・SP美容トピックスバナー（オンラインショップ用） 3営業日前',
  '5': '【PS051-32】ブランド・施策名画像（オンラインショップ用）3営業日前',
  '6': '【PS053-3】受賞ロゴ（オンラインショップ用） 3営業日前',
  '7': '【PS053-5】その他ロゴ（オンラインショップ用） 3営業日前',
  // '8': '【PS054-1】プロモーションバナー（ショッピングカート用） 3営業日前',
  '9': '【PS101-1】＆【SP101-1】W+トップメインバナー（W+トップ用／SPW+トップ用） 3営業日前',
  '10': '【PMNmem02-2】サブTOPICバナー（会員メール用） 5営業日前',
  '11': '【PS061-27】トップバナー（お店ナビ用）※PC・SP共用 3営業日前',
  '12': '【PS061-28】注目のキャンペーンバナー（お店ナビ用）※PC・SP共用 3営業日前',
  '13': '【PS011-4】新着情報（トップ用・ワタシプラス情報） 3営業日前',
  '14': '【PS051-21】ブランドイメージバナー（オンラインショップ用）※PC・SP共用 3営業日前',
  '15': '【P5051-22】プレゼント施策訴求バナー（オンラインショップ用）※PC・SP共用 3営業日前',
  '16': '【P5051-23】体験コンテンツイメージ画像（オンラインショップ用）※PC・SP共用 3営業日前',
  '17': '【P5051-24】体験コンテンツバナー（オンラインショップ用）※PC・SP共用 3営業日前',
  '99': '【ブランドS専用】ポータル用バナー',
  '100': 'その他',
};

/**
 * キャンペーン設定・クーポン発行
 */
/** 実施区分 */
export const ENFORCEMENTDIVISIONS_VALUES: { [key: string]: string } = {
  '1': 'オンラインショップ',
  '2': 'オンラインショップ以外',
};

/** 購入種別 */
export const CAMPAIGNPURCHASETYPE_LABELS = ['通常', '予約', '定期', '簡易'];

/** 会員種別 */
export const MEMBERTYPE_VALUES: { [key: string]: string } = {
  '1': 'Web会員',
  '2': 'Web会員以外',
  '3': '全て',
};

/** 適用回数 */
export const APPLICATIONSTYPE_VALUES: { [key: string]: string } = {
  '1': '無制限',
  '2': '制限あり',
};

/** 適用会員リストの指定 */
export const MEMBERLIST_VALUES: { [key: string]: string } = {
  '1': '指定しない',
  '2': '対象会員を指定',
  '3': '対象外会員を指定',
};

/** 適用会員ブランド指定 */
export const APPLICATIONSMEMBERBLAND_VALUES: { [key: string]: string } = {
  '1': '指定しない',
  '2': 'MJファンクラブ指定',
};

/** オプション種別 */
export const OPTIONTYPE_VALUES: { [key: string]: string } = {
  '1': 'エントリー型キャンペーンの設定',
  '2': 'エントリー&継続購入キャンペーンの設定',
  '3': 'シークレットキャンペーンの設定',
  '4': '設定しない',
};

/** マイページ表示終了条件 */
export const MYPAGEENDCONDITIONS_VALUES: { [key: string]: string } = {
  '1': '施策終了日＋30日の自動設定',
  '2': 'その他の終了日を指定',
  '3': '商品または販促品なくなり次第終了',
};

/** エントリー上限数 */
export const ENTRYUPPERLIMITSETTING_VALUES: { [key: string]: string } = {
  '1': '無制限',
  '2': '制限あり',
};

/** エントリー登録前のマイページ表示 */
export const BEFOREENTRYSHOW_VALUES: { [key: string]: string } = {
  '1': 'エントリー前でも表示する',
  '2': 'エントリー前には表示しない',
};

/** エントリー時の強制メールパーミッション更新 */
export const ENTRYMAILPERMISSION_VALUES: { [key: string]: string } = {
  '1': '強制的にパーミッションを更新する',
  '2': 'パーミッションを更新しない',
};

/** エントリー時のLINEコネクト */
export const ENTRYLINECONNECT_VALUES: { [key: string]: string } = {
  '1': '必須とする',
  '2': '必須としない',
};

/** 購入金額指定の設定 */
export const AMOUNTSPECIFICATION_VALUES: { [key: string]: string } = {
  '1': '購入金額のみを指定する',
  '2': '他の適用条件と購入金額を組み合わせて設定する',
  '3': '購入金額の条件は設定しない',
};

/** 購入金額の組み合わせ設定条件 */
export const AMOUNTSPECIFICATIONSETTING_VALUES: { [key: string]: string } = {
  '1': 'ショッピングカート内の合計金額',
  '2': '選択した適用条件に合致した商品の合計金額（ブランド、アイテムの掛け合わせのみ設定可）',
};

/** 適用条件 */
export const APPLICATIONSCONDITIONS_VALUES: { [key: string]: string } = {
  '1': 'SKU指定【購入個数分プレゼントがつく】',
  '2': 'プール中指定【1カートにつき1個プレゼントがつく】',
  '3': '購入条件（ブランド×アイテム）指定【1カートにつき1個プレゼントがつく】',
  '4': '会員ランク指定【1カートにつき1個プレゼントがつく】',
  '5': '流入経路指定',
  '6': 'ランディングURL指定【Getクーポン、クーポンコード付与の場合はこちら】',
  '7': '適用条件を指定しない【CRMクーポンの発行のみの場合はこちら】',
};

/** SKU指定条件 */
export const SKUSPECIFIEDCONDITION_VALUES: { [key: string]: string } = {
  '1': 'AND（指定した商品全て購入しないと特典が付与されない）',
  '2': 'OR（いずれか1つを購入すれば特典が付与される）',
};

/** プール中指定条件 */
export const POOLSPECIFIEDCONDITION_VALUES: { [key: string]: string } = {
  '1': 'AND（指定したプール中全て必ず購入しないと特典が付与されない）',
  '2': 'OR（いずれか1つを購入すれば特典が付与される）',
};

/** 購入条件指定条件 */
export const PURCHSESPECIFIEDCONDITION_VALUES: { [key: string]: string } = {
  '1': 'ブランド指定',
  '2': 'アイテム指定',
  '3': 'ブランド×アイテム指定',
};

/** 指定アイテム条件 */
export const PURCHASESPECIFIEDCONDITIONSITEM_VALUES: { [key: string]: string } = {
  '1': 'AND（指定したアイテム全て購入しないと特典が付与されない）',
  '2': 'OR（いずれか1アイテムを購入すれば特典が付与される）',
};

/** 会員ランク（下限） */
export const MEMBERLOWERRANK_VALUES: { [key: string]: string } = {
  '1': 'ワタシプラス会員ランク1',
  '2': 'ワタシプラス会員ランク2',
  '3': 'ワタシプラス会員ランク3',
  '4': 'ワタシプラス会員ランク4',
  '5': 'ワタシプラス会員ランク5',
};

/** 会員ランク（上限） */
export const MEMBERHIGHERRANK_VALUES: { [key: string]: string } = {
  '1': 'ワタシプラス会員ランク1',
  '2': 'ワタシプラス会員ランク2',
  '3': 'ワタシプラス会員ランク3',
  '4': 'ワタシプラス会員ランク4',
  '5': 'ワタシプラス会員ランク5',
};

/** 注文開始時のクーポン付与 */
export const INFLOWROUTECOUPON_VALUES: { [key: string]: string } = {
  '1': 'あり',
  '2': 'なし',
};

/** LINEコネクトとの設定 */
export const URLLINECONNECT_VALUES: { [key: string]: string } = {
  '1': '必須とする',
  '2': '必須としない',
};

/** 特典種別 */
export const BENEFITTYPE_VALUES: { [key: string]: string } = {
  '1': 'サンプル・プレゼント品',
  '2': 'クーポン・クーポンコード',
  '3': 'ポイント',
};

/** プレゼント辞退設定 */
export const SAMPLEPRESENT_DECLINEVALUES: { [key: string]: string } = {
  '1': 'プレゼントの辞退を許容しない',
  '2': 'プレゼントの辞退を許容する',
};

/** プレゼント方法 */
export const SAMPLEPRESENT_VALUES: { [key: string]: string } = {
  '1': '1つまたは複数の特典を付与',
  '2': '1つの特典を付与、なくなったら他の特典を付与',
  '3': '選べる特典を付与',
  '4': '選べる特典を付与、そのうち1つでもなくなったら別の選べる特典を付与',
};

/** 納品予定 */
export const PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES: { [key: string]: string } = {
  '1': '納品あり',
  '2': '納品なし（在庫流用）',
};

/** クーポン種別 */
export const COUPONTYPE_VALUES: { [key: string]: string } = {
  '1': 'キャンペーンクーポン（W+の商品を購入したら付与）',
  '2': 'Get（媒体）クーポン（指定のURLを訪問すると付与）',
  '3': 'CRMクーポン（W+会員の指定対象者に対し事前付与または購入からX日後に付与）',
  '4': 'クーポンコード',
};

/** 有効期限 */
export const COUPONEXPIRATIONDATE_VALUES: { [key: string]: string } = {
  '1': 'クーポン付与後○日間',
  '2': '日付指定',
};

/** クーポン利用条件 */
export const COUPONCOUPONCONDITIONSUSE_VALUES: { [key: string]: string } = {
  '1': 'SKUの購入を利用条件に設定する',
  '2': 'プール中の購入を利用条件に設定する',
  '3': 'ブランド・アイテム指定',
  '4': '設定しない',
};

/** クーポン SKU 指定条件 */
export const USECOUPONSKUSPECIFIEDCONDITIONS_VALUES: { [key: string]: string } = {
  '1': 'AND（指定した商品全て購入しないとクーポンが付与されない）',
  '2': 'OR（いずれか1つを購入すればクーポンが付与される）',
};

/** クーポン プール中数 指定条件 */
export const USECOUPONPOOLSPECIFIEDCONDITIONS_VALUES: { [key: string]: string } = {
  '1': 'AND（指定したプール中全て必ず購入しないとクーポンが付与されない）',
  '2': 'OR（いずれか1つを購入すればクーポンが付与される）',
};

/** クーポン ブランド・アイテム指定 指定条件 */
export const USECOUPONBRANDSPECIFIEDCONDITIONS_VALUES: { [key: string]: string } = {
  '1': 'ブランド指定',
  '2': 'アイテム指定',
  '3': 'ブランド×アイテム指定',
};

/** クーポン ブランド・アイテム指定 指定ブランド条件 */
export const USECOUPONBRANDBRANDCONDITIONS_VALUES: { [key: string]: string } = {
  '1': 'OR（いずれか1ブランドを購入すればクーポンが付与される）',
  '2': 'ブランドクーポン',
};

/** クーポン ブランド・アイテム指定 指定アイテム条件 */
export const USECOUPONBRANDCONDITIONSITEM_VALUES: { [key: string]: string } = {
  '1': 'AND（指定したアイテム全て購入しないとクーポンが付与されない）',
  '2': 'OR（いずれか1アイテムを購入すればクーポンが付与される）',
};

/** 付与形式 */
export const COUPONGRANTFORMAT_VALUES: { [key: string]: string } = {
  '1': '事前付与',
  '2': '事後付与',
};

/** 上限配布数 */
export const COUPONMAXDISTRIBUTIONS_VALUES: { [key: string]: string } = {
  '1': 'なし',
  '2': '最大件数あり',
};

/** 指定方法選択 */
export const POINTSELECTSPECIFY_VALUES: { [key: string]: string } = {
  '1': '倍数を指定',
  '2': '数を指定',
};

/**
 * サンプル・モニター設定
 */
/** サンプル・モニター種類 */
export const SAMPLETYPE_VALUES: { [key: string]: string } = {
  '1': 'サンプル・モニター',
  '2': 'サンプル・モニターアフターアンケート',
};

/** 応募フロー */
export const APPLICATIONFLOW_VALUES: { [key: string]: string } = {
  '3': 'ログイン→アンケート',
};

/** 施策種別 */
export const MEASURETYPE_VALUES: { [key: string]: string } = {
  '1': '抽選',
  '2': '先着',
  '3': '配送なし',
};

/** メールのパーミッション */
export const MAILPERMISSION_VALUES: { [key: string]: string } = {
  '1': '会員メール',
  '2': 'お気に入りブランドメール',
};

/** 配送情報の非表示対応 */
export const DELIVERYHIDDENSUPPORT_VALUES: { [key: string]: string } = {
  '1': '当選の場合、こちらのメールアドレスにご連絡させていただきます。',
  '2': 'ポイントのプレゼントが完了しましたら、こちらのメールアドレス宛にご連絡させていただきます。',
  '3': 'クーポンのプレゼントが完了しましたら、こちらのメールアドレス宛にご連絡させていただきます。',
  '4': 'メッセージなし',
};

/** 制作物 */
export const SAMPLESETTINGPRODUCTION_LABELS = [
  'アンケート　8営業日前',
  '【PMsample01-1】サンプル施策完了画面クーポン付与バナー※EC事業部と事前調整要　5営業日前',
  '【PMsample01-2】サンプル施策完了画面店舗誘引・ブランドバナー　5営業日前',
  '【PMsample02-1~2】サンプル施策アフターフォローメール（HTML）+（TEXT）　3営業日前',
  '【PMsample02-2】サンプル施策アフターフォローメール（TEXT）　3営業日前',
  'サンプル・モニター施策を実施するが制作物なし',
];

/**
 * 登録済み施策一覧
 */
/** キーワード */
export const MEASURESSELECTKEYWORD_VALUES: { [key: string]: string } = {
  '1': '施策名',
  '2': '商品名',
  '3': '販促品名',
  '4': '販促品JANコード',
};

/**
 * 販促品一覧
 */
/** キーワード */
export const ITEMSSELECTKEYWORD_VALUES: { [key: string]: string } = {
  '1': '販促品名',
  '2': '施策名',
  '3': '販促品JANコード',
  '4': '宣伝物コード',
};

/** OS作業依頼 */
export const PROMOTIONITEMLISTOSWORKREQUEST_LABELS = ['あり', 'なし'];

/**
 * 制作要件・素材指定
 */
/** デザイン生成 */
export const DESIGNTARGET_VALUES: { [key: string]: string } = {
  '1': '生成する',
  '2': '生成しない',
};
