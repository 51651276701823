import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { ApiGetMeasuresDetailProvider } from 'components/Business/Api';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { FormTextInput, FormTextbox, FormDate, FormRadioList, FormRadioAccordion, FormCheckboxList } from 'components/Business/Form';
import { ApiSaveMeasureButton } from 'components/Business/Api/ApiSaveMeasureButton';
import { ServerErrorBox } from 'components/Business/ServerErrorBox';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { StickyFooter } from 'components/Organisms/StickyFooter';
import {
  RHF_SETTINGS,
  RHF_RESET_PARAMS,
  SAMPLETYPE_VALUES,
  APPLICATIONFLOW_VALUES,
  MEASURETYPE_VALUES,
  MAILPERMISSION_VALUES,
  DELIVERYHIDDENSUPPORT_VALUES,
  SAMPLESETTINGPRODUCTION_LABELS,
} from 'constants/index';
import { objectToRadioList } from 'utils';
import { useValidationSchema } from './useValidationSchema';
import { ErrorView } from './ErrorView';
import type { MeasureDetailProps } from 'types';

export const CPF004: React.FC = () => {
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, reset } = useFormMethods;
  const [response, setResponse] = React.useState<MeasureDetailProps>();
  const [sampleSettingsValue, setSampleSettingsValue] = React.useState();

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    return {
      sampleSettingsDetail: {
        sampleType: formValues.sampleSettingsDetail.sampleType || '',
        presentName: formValues.sampleSettingsDetail.presentName || '',
        startDateYear: formValues.sampleSettingsDetail.startDateYear || '',
        startDateMonth: formValues.sampleSettingsDetail.startDateMonth || '',
        startDateDay: formValues.sampleSettingsDetail.startDateDay || '',
        endDateYear: formValues.sampleSettingsDetail.endDateYear || '',
        endDateMonth: formValues.sampleSettingsDetail.endDateMonth || '',
        endDateDay: formValues.sampleSettingsDetail.endDateDay || '',
        applicationFlow: formValues.sampleSettingsDetail.applicationFlow || '',
        measuresType: formValues.sampleSettingsDetail.measuresType || '',
        ...(formValues?.sampleSettingsDetail?.measuresType === '2' && {
          maxWinners: formValues.sampleSettingsDetail.maxWinners || '',
        }),
        mailPermission: formValues.sampleSettingsDetail.mailPermission || '',
        notices: formValues.sampleSettingsDetail.notices || '',
        deliveryHiddenSupport: formValues.sampleSettingsDetail.deliveryHiddenSupport || '',
        couponAmount: formValues.sampleSettingsDetail.couponAmount || '',
        couponId: formValues.sampleSettingsDetail.couponId || '',
        questionnaire: formValues.sampleSettingsDetail.questionnaire || '',
        couponBanner: formValues.sampleSettingsDetail.couponBanner || '',
        brandBanner: formValues.sampleSettingsDetail.brandBanner || '',
        afterFollowHtml: formValues.sampleSettingsDetail.afterFollowHtml || '',
        afterFollowText: formValues.sampleSettingsDetail.afterFollowText || '',
        noProduction: formValues.sampleSettingsDetail.noProduction || '',
        imjManagementNumber: formValues.sampleSettingsDetail.imjManagementNumber || '',
      },
    };
  }, [getValues]);

  const apiCallbackHandler = React.useCallback(
    (result) => {
      reset(result, RHF_RESET_PARAMS);
      setResponse(result);
      setSampleSettingsValue(result.sampleSettings);
    },
    [reset],
  );

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <ApiGetMeasuresDetailProvider type="sample" callback={apiCallbackHandler}>
            <Container>
              <Breadcrumb
                list={[
                  {
                    label: 'TOP',
                    url: '/CMN001',
                  },
                  {
                    label: '施策概要・依頼内容',
                  },
                  {
                    label: 'サンプル・モニター設定',
                  },
                ]}
              />

              <ServerErrorBox />

              <Section>
                <H1>サンプル・モニター設定</H1>

                {response?.measuresName && (
                  <Text align="center" size={24} bold={true}>
                    {response.measuresName}
                  </Text>
                )}

                {sampleSettingsValue !== '1' && <ErrorView />}

                {sampleSettingsValue === '1' && (
                  <>
                    <Text align="center">
                      下記の項目を入力してください。
                      <br />
                      [必須]項目は、全て入力いただく必要があります。
                    </Text>

                    <Section hasBg>
                      <FormRadioList
                        name="sampleSettingsDetail.sampleType"
                        label="サンプル・モニター種類"
                        isRequired
                        list={objectToRadioList(SAMPLETYPE_VALUES)}
                      />

                      <FormTextInput
                        name="sampleSettingsDetail.presentName"
                        label="プレゼント名"
                        placeholder="プレゼント名を入力"
                        note="※100文字以内で入力してください。"
                        isRequired
                        hasUndecidedButton
                      />

                      <FormDate
                        names={{
                          year: 'sampleSettingsDetail.startDateYear',
                          month: 'sampleSettingsDetail.startDateMonth',
                          date: 'sampleSettingsDetail.startDateDay',
                        }}
                        label="開始日"
                        isRequired
                        hasUndecidedButton
                      />

                      <FormDate
                        names={{
                          year: 'sampleSettingsDetail.endDateYear',
                          month: 'sampleSettingsDetail.endDateMonth',
                          date: 'sampleSettingsDetail.endDateDay',
                        }}
                        label="終了日"
                        isRequired
                        hasUndecidedButton
                      />

                      <FormRadioList
                        name="sampleSettingsDetail.applicationFlow"
                        label="応募フロー"
                        note="※配送情報を含むフローについては、個人情報の取り扱いが発生するためIMJでは対応できません。こちらへの入力は不要ですので、施策担当者ご自身で申請を行ってください。"
                        isRequired
                        list={objectToRadioList(APPLICATIONFLOW_VALUES)}
                      />

                      <FormRadioAccordion
                        name="sampleSettingsDetail.measuresType"
                        label="施策種別"
                        isRequired
                        list={objectToRadioList(MEASURETYPE_VALUES)}
                        contents={{
                          '1': null,
                          '2': <FormTextInput type="number" name="sampleSettingsDetail.maxWinners" label="上限当選数" placeholder="1000" isRequired />,
                          '3': null,
                        }}
                      />

                      <FormRadioList
                        name="sampleSettingsDetail.mailPermission"
                        label="メールのパーミッション"
                        list={objectToRadioList(MAILPERMISSION_VALUES)}
                      />

                      <FormTextbox name="sampleSettingsDetail.notices" label="特筆事項" placeholder="特筆事項がある場合に入力" />

                      <FormRadioList
                        name="sampleSettingsDetail.deliveryHiddenSupport"
                        label="配送情報の非表示対応"
                        note="※依頼の場合W+システム保守問い合わせへ登録が必要です。"
                        list={objectToRadioList(DELIVERYHIDDENSUPPORT_VALUES)}
                      />

                      <FormTextInput type="number" name="sampleSettingsDetail.couponAmount" label="クーポン（金額指定）" placeholder="300" unit="円" />

                      <FormTextInput name="sampleSettingsDetail.couponId" label="クーポン（ID）" placeholder="「SM007」等を入力" />

                      <FormCheckboxList
                        label="制作物"
                        note="※当てはまる項目をすべて選択してください。"
                        list={[
                          {
                            name: 'sampleSettingsDetail.questionnaire',
                            label: SAMPLESETTINGPRODUCTION_LABELS[0],
                            value: '1',
                          },
                          {
                            name: 'sampleSettingsDetail.couponBanner',
                            label: SAMPLESETTINGPRODUCTION_LABELS[1],
                            value: '1',
                          },
                          {
                            name: 'sampleSettingsDetail.brandBanner',
                            label: SAMPLESETTINGPRODUCTION_LABELS[2],
                            value: '1',
                          },
                          {
                            name: 'sampleSettingsDetail.afterFollowHtml',
                            label: SAMPLESETTINGPRODUCTION_LABELS[3],
                            value: '1',
                          },
                          {
                            name: 'sampleSettingsDetail.afterFollowText',
                            label: SAMPLESETTINGPRODUCTION_LABELS[4],
                            value: '1',
                          },
                          {
                            name: 'sampleSettingsDetail.noProduction',
                            label: SAMPLESETTINGPRODUCTION_LABELS[5],
                            value: '1',
                          },
                        ]}
                      />

                      <FormTextInput name={`sampleSettingsDetail.imjManagementNumber`} label="（IMJ入力）管理番号" placeholder="「w202101110」等を入力" />
                    </Section>

                    <ButtonContainer>
                      <ApiSaveMeasureButton type="sample" getRequestData={getRequestData} />
                    </ButtonContainer>
                  </>
                )}
              </Section>
            </Container>

            <StickyFooter type="sample" getRequestData={getRequestData} disabledSaveTemp={sampleSettingsValue !== '1'} />

            <RHFDevTools />
          </ApiGetMeasuresDetailProvider>
        </FormProvider>
      </Main>
      <Footer mb={80} />
    </>
  );
};
