import React from 'react';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';

export const CMN006: React.FC = () => {
  return (
    <>
      <Header />
      <Main>
        <Container>
          <Section>
            <H1>パスワード再登録完了</H1>
            <Text align="center">
              パスワードの再登録が完了しました。
              <br />
              ログインページへ戻り再度ログインを行ってください。
            </Text>

            <ButtonContainer>
              <Button type="secondary" icon="arrow-left" iconPosition="left" text="ログインページへ戻る" to="/CMN002" />
            </ButtonContainer>
          </Section>
        </Container>
      </Main>
      <Footer />
    </>
  );
};
