import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { FormGrid, FormField } from 'components/Atoms/Layout';
import { Note, ErrorText } from 'components/Atoms/Typography';
import { NoteList } from 'components/Atoms/List';
import { RadioList } from 'components/Atoms/Form';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { UndecidedButton } from 'components/Molecules/UndecidedButton';
import { Modal } from 'components/Organisms/Modal';
import { UNDECIDED_VALUE, RHF_UNREGISTER_PARAMS } from 'constants/index';
import style from './style.module.scss';
import type { RadioSizeProps } from 'components/Atoms/Form/Radio';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';

type ListProps = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  list: Array<ListProps>;
  label?: string;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  isRequired?: boolean;
  help?: string;
  hasUndecidedButton?: boolean;
  defaultValue?: string;
  isHorizontal?: boolean;
  onChange?: (value: string) => void;
  confirmOnChange?: boolean;
  confirmConditionValue?: string;
  confirmTitle?: string;
  confirmChildren?: JSX.Element;
  radioSize?: RadioSizeProps;
  size?: FormGridSizeProps;
};

export const FormRadioList: React.FC<Props> = ({
  name,
  label = '',
  note,
  notes,
  isRequired = false,
  help,
  hasUndecidedButton = false,
  list,
  defaultValue = '',
  isHorizontal = false,
  onChange = () => {},
  confirmOnChange = false,
  confirmConditionValue,
  confirmTitle,
  confirmChildren,
  radioSize = 'medium',
  size = 'large',
}) => {
  const { control, trigger, unregister } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, defaultValue, shouldUnregister: true });
  const [undecided, setUndecided] = React.useState(false);
  const [backupValue, setBackupValue] = React.useState('');
  const [isOpenConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [changedValue, setChangedValue] = React.useState('');

  const componentWillUnmount = React.useCallback(() => {
    console.log(`unregister: ${name}`);
    unregister(name, RHF_UNREGISTER_PARAMS);
  }, [name, unregister]);

  const handleChange = React.useCallback(
    (value) => {
      if (confirmOnChange && value === confirmConditionValue) {
        setChangedValue(value);
        setOpenConfirmModal(true);
        return;
      }
      field.onChange(value);
      onChange(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onChange, onChange],
  );

  const handleUndecided = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setBackupValue(field.value);
        field.onChange(UNDECIDED_VALUE);
        trigger(name); // 初回なぜかバリデーションされないため
        return;
      }
      field.onChange(backupValue);
      setBackupValue('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.value, backupValue, setBackupValue],
  );

  const handleCancelConfirmDialog = React.useCallback(() => {
    setOpenConfirmModal(false);
    setChangedValue('');
  }, []);

  const handleSubmitConfirmDialog = React.useCallback(() => {
    field.onChange(changedValue);
    onChange(changedValue);
    setOpenConfirmModal(false);
    setChangedValue('');
  }, [changedValue, field, onChange]);

  React.useEffect(() => {
    setUndecided(field.value === UNDECIDED_VALUE);
  }, [field.value]);

  React.useEffect(() => {
    return () => componentWillUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormGrid className={style['form-radioList']} size={size}>
        <FormLabelSet label={label} isRequired={isRequired} help={help} size={size} mt={size === 'small' ? 0 : undefined} />

        <FormField>
          <RadioList
            name={field.name}
            value={field.value}
            refs={field.ref}
            onChange={handleChange}
            className={undecided ? 'is-undecided' : ''}
            isInvalid={error ? true : false}
            disabled={undecided}
            isHorizontal={isHorizontal}
            list={list}
            size={radioSize}
          />
          <ErrorText error={error?.message} />
          {note && <Note>{note}</Note>}
          {notes && <NoteList list={notes} />}
        </FormField>

        <UndecidedButton name={name} hasUndecidedButton={hasUndecidedButton} undecided={undecided} onChangeDecided={handleUndecided} />
      </FormGrid>

      <Modal
        type="warn"
        isShow={isOpenConfirmModal}
        title={confirmTitle}
        hasCancel
        hasSubmit
        cancelText="キャンセル"
        onCancel={handleCancelConfirmDialog}
        onSubmit={handleSubmitConfirmDialog}
      >
        {confirmChildren}
      </Modal>
    </>
  );
};
