import React from 'react';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { Button } from 'components/Atoms/Button';
import { H1, Text } from 'components/Atoms/Typography';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { CognitoUserState } from 'context';

export const NotFound: React.FC = () => {
  const userInfo = React.useContext(CognitoUserState);

  return (
    <>
      <Header />
      <Main>
        <Container>
          <Section>
            <H1>ページが見つかりませんでした。</H1>
            <Text align="center">URLをお確かめのうえ再度アクセスしてください。</Text>
            <ButtonContainer>
              <Button type="secondary" icon="arrow-left" iconPosition="left" to={userInfo ? '/CMN001' : '/CMN002'} text="TOPページへ戻る" />
            </ButtonContainer>
          </Section>
        </Container>
      </Main>
      <Footer />
    </>
  );
};
