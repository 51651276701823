import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, FlexRow, FlexCol } from 'components/Atoms/Layout';
import { Text, Note } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { TextInput, RadioList, SelectList } from 'components/Atoms/Form';
import { Loading } from 'components/Atoms/Loading';
import { Modal } from 'components/Organisms/Modal';
import { ENDPOINT_GET_PROMOTION_API, ITEMSSELECTKEYWORD_VALUES } from 'constants/index';
import { useApiCall } from 'hooks/useApiCall';
import { objectToRadioList } from 'utils';
import style from './style.module.scss';
import type { ControllerRenderProps } from 'react-hook-form';
import type { PromotionItemListProps } from 'types';
import type { SelectListProps } from 'components/Atoms/Form/SelectList';

type ActivatedItemModalButtonProps = {
  name: string;
  valueField: ControllerRenderProps<Record<string, any>>;
  labelField: ControllerRenderProps<Record<string, any>>;
  codeField: ControllerRenderProps<Record<string, any>>;
  undecided: boolean;
};

export const ActivatedItemModalButton: React.FC<ActivatedItemModalButtonProps> = ({ name, valueField, labelField, codeField, undecided }) => {
  const { t } = useTranslation();
  const apiCall = useApiCall();
  const [isOpen, setOpen] = React.useState(false);
  const [selectKeyWord, setSelectKeyWord] = React.useState('1');
  const [keyWord, setKeyWord] = React.useState('');
  const [notFoundFlag, setNotFoundFlag] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState<SelectListProps[]>([]);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const callApi = React.useCallback(async () => {
    setIsLoading(true);
    apiCall({
      method: 'GET',
      url: ENDPOINT_GET_PROMOTION_API,
      data: {
        selectKeyWord: selectKeyWord,
        keyWord: keyWord,
        limit: 99999,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          const returnValue = res.data.result.promotionalItemList.map((elem: PromotionItemListProps) => {
            return {
              label: elem.promotionalName,
              value: elem.janCode,
              code: elem.promotionCode,
            };
          });
          if (!returnValue.length) setNotFoundFlag(true);
          setSearchResult(returnValue);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [keyWord, selectKeyWord, apiCall]);

  const resetModal = React.useCallback(() => {
    if (keyWord) setKeyWord('');
    if (notFoundFlag) setNotFoundFlag(false);
    if (searchResult.length) setSearchResult([]);
    if (selectedValue) setSelectedValue('');
  }, [keyWord, notFoundFlag, searchResult, selectedValue, setKeyWord, setSearchResult, setSelectedValue]);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleSubmitModal = React.useCallback(() => {
    valueField.onChange(selectedValue);
    const selectedResult = searchResult.find((elem) => elem.value === selectedValue);
    const selectedLabel = selectedResult?.label;
    const selectedCode = selectedResult?.code;
    labelField.onChange(selectedLabel);
    codeField.onChange(selectedCode);
    setOpen(false);
    resetModal();
  }, [selectedValue, valueField, setOpen, resetModal, labelField, codeField, searchResult]);

  const handleCancelModal = React.useCallback(() => {
    setOpen(false);
    resetModal();
  }, [setOpen, resetModal]);

  const handleSearch = React.useCallback(() => {
    setNotFoundFlag(false);
    if (!keyWord) {
      setSearchResult([]);
      setNotFoundFlag(true);
      return;
    }
    callApi();
  }, [keyWord, setSearchResult, callApi]);

  const handleChangeSelectedResult = React.useCallback((val: string) => {
    setSelectedValue(val);
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />

      <Button
        type="add"
        size="small"
        isFlexible
        icon="plus"
        iconPosition="after"
        text="JANコード発行済みの販促品"
        onClick={handleOpen}
        disabled={undecided || valueField.value}
      />

      <Modal
        isShow={isOpen}
        hasSubmit={true}
        hasCancel={true}
        cancelText="キャンセル"
        onSubmit={handleSubmitModal}
        onCancel={handleCancelModal}
        disabledSubmit={selectedValue ? false : true}
      >
        <Text size={20} bold align="center">
          JANコード発行済みの販促品
        </Text>
        <Stack mt={30}>
          <Text>キーワード検索</Text>
        </Stack>
        <Stack mt={10}>
          <RadioList
            name="selectKeyWord"
            value={selectKeyWord}
            isHorizontal
            size="small"
            list={objectToRadioList(ITEMSSELECTKEYWORD_VALUES)}
            onChange={(val) => {
              setSelectKeyWord(val);
            }}
          />
        </Stack>
        <Stack mt={10}>
          <FlexRow gap="small">
            <FlexCol>
              <TextInput name={`${name}_search-input`} placeholder="キーワードを入力" value={keyWord} onChange={setKeyWord} width={420} />
            </FlexCol>
            <FlexCol>
              <Button type="primary" isFlexible icon="search-white" iconPosition="after" text="検索" onClick={handleSearch} />
            </FlexCol>
          </FlexRow>
          <Note>※キーワードは一部の入力でも絞り込みが可能です。</Note>
        </Stack>

        <Stack mt={30}>
          {notFoundFlag && <Text>{t('MSG-ZERO-RESULT')}</Text>}
          {searchResult.length > 0 && (
            <>
              <Text>検索結果一覧</Text>
              <Stack mt={15}>
                <SelectList
                  name={`${name}_search-results`}
                  list={searchResult}
                  value={selectedValue}
                  onChange={handleChangeSelectedResult}
                  className={style['results']}
                />
              </Stack>
            </>
          )}
        </Stack>
      </Modal>
    </>
  );
};
