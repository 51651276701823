import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/Atoms/Layout';
import { Unit, ErrorText } from 'components/Atoms/Typography';
import { TextInput } from 'components/Atoms/Form';
import { UNDECIDED_VALUE, RHF_UNREGISTER_PARAMS } from 'constants/index';
import type { FieldError } from 'react-hook-form';

type TimeFieldProps = {
  names: {
    hour: string;
    minute: string;
  };
  defaultValues?: {
    hour?: string;
    minute?: string;
  };
  undecided: boolean;
};

export const TimeField = React.forwardRef(({ names, defaultValues = { hour: '', minute: '' }, undecided }: TimeFieldProps, ref) => {
  const { control, trigger, unregister } = useFormContext();
  const { t } = useTranslation();

  const {
    field: hourField,
    fieldState: { error: hourError },
  } = useController({ control, name: names.hour, defaultValue: defaultValues.hour, shouldUnregister: true });
  const {
    field: minuteField,
    fieldState: { error: minuteError },
  } = useController({ control, name: names.minute, defaultValue: defaultValues.minute, shouldUnregister: true });

  const [backupHourValue, setBackupHourValue] = React.useState('');
  const [backupMinuteValue, setBackupMinuteValue] = React.useState('');

  const [timeError, setTimeError] = React.useState<FieldError | undefined>();

  const componentWillUnmount = React.useCallback(() => {
    console.log(`unregister: ${(names.hour, names.minute)}`);
    unregister([names.hour, names.minute], RHF_UNREGISTER_PARAMS);
  }, [names.hour, names.minute, unregister]);

  const handleUndecided = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setBackupHourValue(hourField.value);
        setBackupMinuteValue(minuteField.value);
        hourField.onChange(UNDECIDED_VALUE);
        minuteField.onChange(UNDECIDED_VALUE);
        trigger([names.hour, names.minute]);
        return;
      }
      hourField.onChange(backupHourValue);
      minuteField.onChange(backupMinuteValue);
      setBackupHourValue('');
      setBackupMinuteValue('');
      trigger([names.hour, names.minute]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backupHourValue, backupMinuteValue, hourField.value, minuteField.value, hourField.onChange, minuteField.onChange, names.hour, names.minute, trigger],
  );

  React.useImperativeHandle(ref, () => ({
    handleUndecided,
    hourField,
    minuteField,
  }));

  React.useEffect(() => {
    const hourErrorType = hourError?.type;
    const minuteErrorType = minuteError?.type;
    if (hourErrorType === 'required' || minuteErrorType === 'required') {
      setTimeError({ message: t('ERR-REQUIRED'), type: 'required' });
    } else if (hourErrorType === 'isNumber' || minuteErrorType === 'isNumber') {
      setTimeError({ message: t('ERR-TYPE-NUMBER'), type: 'isNumber' });
    } else if (hourErrorType === 'isRange' || minuteErrorType === 'isRange') {
      setTimeError({ message: t('ERR-INVALID-TIME'), type: 'isRange' });
    } else {
      setTimeError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourError, minuteError]);

  React.useEffect(() => {
    return () => componentWillUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Flex alignItems="center" mt={10}>
        <TextInput
          name={hourField.name}
          value={hourField.value}
          refs={hourField.ref}
          onChange={(value) => {
            hourField.onChange(value);
          }}
          onBlur={() => {
            hourField.onBlur();
            trigger([names.hour, names.minute]);
          }}
          className={undecided ? 'is-undecided' : ''}
          disabled={undecided}
          isInvalid={timeError ? true : false}
          type="hour"
          placeholder="00"
          maxLength={2}
        />
        <Unit text="時" />

        <TextInput
          name={minuteField.name}
          value={minuteField.value}
          refs={minuteField.ref}
          onChange={(value) => {
            minuteField.onChange(value);
          }}
          onBlur={() => {
            minuteField.onBlur();
            trigger([names.hour, names.minute]);
          }}
          className={undecided ? 'is-undecided' : ''}
          disabled={undecided}
          isInvalid={timeError ? true : false}
          type="minute"
          placeholder="00"
          maxLength={2}
        />
        <Unit text="分" />
      </Flex>
      <ErrorText error={timeError?.message} />
    </>
  );
});
