import React from 'react';
import { Tooltip } from 'components/Atoms/Tooltip';
import style from './style.module.scss';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';

type Props = {
  label?: string;
  isRequired?: boolean;
  help?: string;
  size?: FormGridSizeProps;
  mt?: number;
  fontSize?: number;
};

export const FormLabelSet: React.FC<Props> = ({ label, isRequired = false, help, size = 'large', mt, fontSize=14 }) => {
  const [isShowHelp, setIsShowHelp] = React.useState(false);

  return (
    <div className={`${style['formLabelSet']} is-${size}`} style={{ ...(mt !== undefined && { marginTop: `${mt}px` }) }}>
      {label && <span className={`${style['formLabelSet-label']}`} style={{fontSize: `${fontSize}px`}}>{label}</span>}

      {help && (
        <span className={`${style['formLabelSet-icon-help']}`} onMouseEnter={() => setIsShowHelp(true)} onMouseLeave={() => setIsShowHelp(false)}>
          <Tooltip position="top" text={help} isShow={isShowHelp} />
        </span>
      )}

      {isRequired && <span className={`${style['formLabelSet-required']}`}>必須</span>}
    </div>
  );
};
