import React from 'react';
import { useParams } from 'react-router-dom';
import { ButtonContainer } from 'components/Atoms/Layout';
import { Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import type { MeasureParamsProps } from 'types';

export const ErrorView: React.FC = () => {
  const { measureId } = useParams<MeasureParamsProps>();

  return (
    <>
      <Text align="center">
        キャンペーン設定・クーポン発行の詳細を入力するには、「施策概要・依頼内容」ページ内、
        <br />
        「キャンペーン設定・クーポン発行」を「あり」に変更する必要がございます。​
        <br />
        以下リンクより、該当項目を「あり」にご変更ください。​
      </Text>

      <ButtonContainer>
        <Button type="secondary" size="large" icon="arrow-right" iconPosition="right" text="施策概要・依頼内容へ移動する" to={`/CPF001/${measureId}`} />
      </ButtonContainer>
    </>
  );
};
