import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { FormGrid, FormField, Box } from 'components/Atoms/Layout';
import { Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { TextInput } from 'components/Atoms/Form';
import { Loading } from 'components/Atoms/Loading';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { Modal } from 'components/Organisms/Modal';
import { ApiSelectAidType } from 'components/Business/Api/ApiSelectAidType';
import { ApiSelectAidFont } from 'components/Business/Api/ApiSelectAidFont';
import { ApiSelectAidSeason } from 'components/Business/Api/ApiSelectAidSeason';
import { ApiSelectAidBrand } from 'components/Business/Api/ApiSelectAidBrand';
import { useApiCall } from 'hooks/useApiCall';
import { ENDPOINT_AID_MODULE_API } from 'constants/index';
import type { ModuleProps } from 'types';

type AddMaterialButtonProps = {
  append: (data: ModuleProps) => void;
};

export const AddUnregisteredMaterialButton: React.FC<AddMaterialButtonProps> = ({ append }) => {
  const apiCall = useApiCall();
  const defaultDataTypeValue = React.useMemo(() => 'テキスト', []);
  const defaultSeasonValue = React.useMemo(() => 'なし', []);
  const [isOpen, setOpen] = React.useState(false);
  const [isDisabledSubmit, setDisabledSubmit] = React.useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataTypeValue, setDataTypeValue] = React.useState(defaultDataTypeValue);
  const [typeValue, setTypeValue] = React.useState('');
  const [textValue, setTextValue] = React.useState('');
  const [fontValue, setFontValue] = React.useState('');
  const [seasonValue, setSeasonValue] = React.useState(defaultSeasonValue);
  const [brandValue, setBrandValue] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);

  const resetModal = React.useCallback(() => {
    setDisabledSubmit(true);
    // setDataTypeValue('');
    setTypeValue('');
    setTextValue('');
    setFontValue('');
    setSeasonValue(defaultSeasonValue);
    setBrandValue('');
  }, [defaultSeasonValue]);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleCancel = React.useCallback(() => {
    setOpen(false);
    resetModal();
  }, [resetModal]);

  const handleSubmit = React.useCallback(() => {
    setLoading(true);

    apiCall({
      method: 'POST',
      url: `${ENDPOINT_AID_MODULE_API}/`,
      data: {
        modules: [
          {
            fileName: '',
            url: '',
            text: textValue,
            dataType: dataTypeValue,
            attributes: {
              type: typeValue,
              season: seasonValue,
              area: '',
              brand: brandValue,
            },
          },
        ],
      },
    })
      .then((res) => {
        if (res.status !== 200) return;
        const returnValue = cloneDeep(res.data.result[0]);
        console.log(returnValue);
        returnValue['fontName'] = fontValue;
        returnValue['moduleNum'] = returnValue.moduleId.split('_').slice(-1)[0];
        append(returnValue);
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
        resetModal();
      });
  }, [resetModal, apiCall, append, brandValue, dataTypeValue, fontValue, seasonValue, textValue, typeValue]);

  // const handleChangeDataTypeValue = React.useCallback((value: string) => {
  //   setDataTypeValue(value);
  // }, []);

  const handleChangeTypeValue = React.useCallback((value: string) => {
    setTypeValue(value);
  }, []);

  const handleChangeTextValue = React.useCallback((value: string) => {
    setTextValue(value);
  }, []);

  const handleChangeFontValue = React.useCallback((value: string) => {
    setFontValue(value);
  }, []);

  const handleChangeSeasonValue = React.useCallback((value: string) => {
    setSeasonValue(value);
  }, []);

  const handleChangeBrandValue = React.useCallback((value: string) => {
    setBrandValue(value);
  }, []);

  React.useEffect(() => {
    if (seasonValue && brandValue && typeValue && textValue && fontValue) {
      setDisabledSubmit(false);
    } else {
      setDisabledSubmit(true);
    }
  }, [typeValue, textValue, fontValue, seasonValue, brandValue]);

  return React.useMemo(
    () => (
      <>
        <Loading isLoading={isLoading} />

        <Button type="add" size="small" isFlexible icon="plus" iconPosition="after" text="新規素材" onClick={handleOpen} />

        <Modal
          isShow={isOpen}
          hasSubmit={true}
          hasCancel={true}
          cancelText="キャンセル"
          submitText="登録"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          disabledSubmit={isDisabledSubmit}
        >
          <Text size={20} bold align="center">
            新規素材登録
          </Text>
          <Box mt={30}>
            <FormGrid>
              <FormGrid>
                <FormField>
                  <FormLabelSet label="素材種類" isRequired />
                  <ApiSelectAidType value={typeValue} onChange={handleChangeTypeValue} width={540} />
                </FormField>
              </FormGrid>

              <FormGrid>
                <FormField>
                  <FormLabelSet label="テキスト文言" isRequired />
                  <TextInput name="text" value={textValue} onChange={handleChangeTextValue} width={540} />
                </FormField>
              </FormGrid>

              <FormGrid>
                <FormField>
                  <FormLabelSet label="フォント指定" isRequired />
                  <ApiSelectAidFont value={fontValue} onChange={handleChangeFontValue} width={540} />
                </FormField>
              </FormGrid>

              <FormGrid>
                <FormField>
                  <FormLabelSet label="季節" isRequired />
                  <ApiSelectAidSeason value={seasonValue} onChange={handleChangeSeasonValue} width={540} />
                </FormField>
              </FormGrid>

              <FormGrid>
                <FormField>
                  <FormLabelSet label="ブランド" isRequired />
                  <ApiSelectAidBrand value={brandValue} onChange={handleChangeBrandValue} width={540} />
                </FormField>
              </FormGrid>
            </FormGrid>
          </Box>
        </Modal>
      </>
    ),
    [
      brandValue,
      fontValue,
      handleCancel,
      handleChangeBrandValue,
      handleChangeFontValue,
      handleChangeSeasonValue,
      handleChangeTextValue,
      handleChangeTypeValue,
      handleOpen,
      handleSubmit,
      isDisabledSubmit,
      isLoading,
      isOpen,
      seasonValue,
      textValue,
      typeValue,
    ],
  );
};
