import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Main, Container, Section, SectionInner, ButtonContainer, NestBox } from 'components/Atoms/Layout';
import { H1, H2, H3, Text } from 'components/Atoms/Typography';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { ApiGetMeasuresDetailProvider, ApiSearchPromotionItemModal } from 'components/Business/Api';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { FormTextInput, FormTextbox, FormDate, FormRadioList, FormRadioAccordion, FormCheckboxList, FormArray } from 'components/Business/Form';
import { ApiSaveMeasureButton } from 'components/Business/Api/ApiSaveMeasureButton';
import { ServerErrorBox } from 'components/Business/ServerErrorBox';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { StickyFooter } from 'components/Organisms/StickyFooter';
import {
  RHF_SETTINGS,
  RHF_RESET_PARAMS,
  ENFORCEMENTDIVISIONS_VALUES,
  CAMPAIGNPURCHASETYPE_LABELS,
  MEMBERTYPE_VALUES,
  APPLICATIONSTYPE_VALUES,
  MEMBERLIST_VALUES,
  APPLICATIONSMEMBERBLAND_VALUES,
  OPTIONTYPE_VALUES,
  AMOUNTSPECIFICATION_VALUES,
  AMOUNTSPECIFICATIONSETTING_VALUES,
  BENEFITTYPE_VALUES,
  PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES,
  SAMPLEPRESENT_DECLINEVALUES,
  SAMPLEPRESENT_VALUES,
  COUPONTYPE_VALUES,
  POINTSELECTSPECIFY_VALUES,
} from 'constants/index';
import { objectToRadioList, trimArrayEnd } from 'utils';
import { useValidationSchema } from './useValidationSchema';
import { ErrorView } from './ErrorView';
import { CampaignOptionsEntry } from './CampaignOptionsEntry';
import { CampaignOptionsEntryAndRepeatPurChase } from './CampaignOptionsEntryAndRepeatPurChase';
import { CampaignOptionsSecret } from './CampaignOptionsSecret';
import { ApplicableCondition } from './ApplicableCondition';
import { CouponDetailCampaignOrGet } from './CouponDetailCampaignOrGet';
import { CouponDetailCrm } from './CouponDetailCrm';
import { CouponDetailCouponCode } from './CouponDetailCouponCode';
import type { MeasureDetailProps } from 'types';

export const CPF003: React.FC = () => {
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, reset } = useFormMethods;
  const [response, setResponse] = React.useState<MeasureDetailProps>();
  const [campaignSettingsValue, setCampaignSettingsValue] = React.useState();

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = trimArrayEnd(getValues().campaignDetail);
    return {
      campaignDetail: (() => {
        return (formValues ? formValues.concat() : []).map((elem: any, index: number) => {
          if (!elem) return elem;
          return {
            campaignNo: String(index),
            enforcementDivision: elem.enforcementDivision || '',
            settingStartDateYear: elem.settingStartDateYear || '',
            settingStartDateMonth: elem.settingStartDateMonth || '',
            settingStartDateDay: elem.settingStartDateDay || '',
            settingStartDateHours: elem.settingStartDateHours || '',
            settingStartDateMinutes: elem.settingStartDateMinutes || '',
            settingEndDateYear: elem.settingEndDateYear || '',
            settingEndDateMonth: elem.settingEndDateMonth || '',
            settingEndDateDay: elem.settingEndDateDay || '',
            settingEndDateHours: elem.settingEndDateHours || '',
            settingEndDateMinutes: elem.settingEndDateMinutes || '',
            purchaseTypeNormal: elem.purchaseTypeNormal || '',
            purchaseTypeReserve: elem.purchaseTypeReserve || '',
            purchaseTypeRegular: elem.purchaseTypeRegular || '',
            purchaseTypeSimple: elem.purchaseTypeSimple || '',
            memberType: elem.memberType || '',
            applicationsType: elem.applicationsType || '',
            ...(elem?.applicationsType === '2' && {
              applicationsDetails: elem.applicationsDetails || '',
            }),

            // 適用会員を指定
            memberList: elem.memberList || '',
            ...((elem?.memberList === '2' || elem?.memberList === '3') && {
              listConditions: elem.listConditions || '',
            }),
            applicationsMemberBrand: elem.applicationsMemberBrand || '',

            // キャンペーンのオプションを設定
            optionType: elem.optionType || '',
            ...((elem?.optionType === '1' || elem?.optionType === '2' || elem?.optionType === '3') && {
              myPageCampaignName: elem.myPageCampaignName || '',
              myPageCampaignDetails: elem.myPageCampaignDetails || '',
              myPageEndConditions: elem.myPageEndConditions || '',
            }),
            ...(elem?.myPageEndConditions === '2' && {
              myPageEndDateYear: elem.myPageEndDateYear || '',
              myPageEndDateMonth: elem.myPageEndDateMonth || '',
              myPageEndDateDay: elem.myPageEndDateDay || '',
              myPageEndDateHours: elem.myPageEndDateHours || '',
              myPageEndDateMinutes: elem.myPageEndDateMinutes || '',
            }),
            ...((elem?.optionType === '1' || elem?.optionType === '2' || elem?.optionType === '3') && {
              campaignUrl: elem.campaignUrl || '',
              campaignImage: elem.campaignImage || '',
            }),
            ...((elem?.optionType === '1' || elem?.optionType === '2') && {
              entryEndDateYear: elem.entryEndDateYear || '',
              entryEndDateMonth: elem.entryEndDateMonth || '',
              entryEndDateDay: elem.entryEndDateDay || '',
              entryEndDateHours: elem.entryEndDateHours || '',
              entryEndDateMinutes: elem.entryEndDateMinutes || '',
              entryUpperLimitSetting: elem.entryUpperLimitSetting || '',
            }),
            ...(elem?.entryUpperLimitSetting === '2' && {
              entryUpperLimit: elem.entryUpperLimit || '',
            }),
            ...((elem?.optionType === '1' || elem?.optionType === '2') && {
              beforeEntryShow: elem.beforeEntryShow || '',
              entryMailPermission: elem.entryMailPermission || '',
              entryLineConnect: elem.entryLineConnect || '',
            }),
            ...(elem?.optionType === '2' && {
              purchaseAchievementCount: elem.purchaseAchievementCount || '',
            }),
            ...(elem?.optionType === '3' && {
              secretCampaignDescriptionPc: elem.secretCampaignDescriptionPc || '',
              secretCampaignDescriptionSp: elem.secretCampaignDescriptionSp || '',
            }),

            // キャンペーン適用条件設定
            amountSpecification: elem.amountSpecification || '',
            ...((elem?.amountSpecification === '1' || elem?.amountSpecification === '2') && {
              amountSpecificationConditions: elem.amountSpecificationConditions || '',
            }),
            ...(elem?.amountSpecification === '2' && {
              amountSpecificationSetting: elem.amountSpecificationSetting || '',
            }),
            ...((elem?.amountSpecificationSetting === '1' || elem?.amountSpecificationSetting === '2' || elem?.amountSpecification === '3') && {
              applicationsConditions: elem.applicationsConditions || '',
            }),
            ...(elem?.applicationsConditions === '1' && {
              skuSpecifiedCondition: elem.skuSpecifiedCondition || '',
              skuMeasuresNum: (() => {
                if (!elem.skuMeasuresNum) return [];
                if (typeof elem.skuMeasuresNum === 'object') return elem.skuMeasuresNum?.filter((e: {}) => e) || [];
                return elem.skuMeasuresNum;
              })(),
            }),
            ...((elem?.skuSpecifiedCondition === '1' || elem?.skuSpecifiedCondition === '2') && {
              skuPurchase: elem.skuPurchase || '',
            }),
            ...(elem?.applicationsConditions === '2' && {
              poolSpecifiedCondition: elem.poolSpecifiedCondition || '',
              poolMeasuresNum: (() => {
                if (!elem.poolMeasuresNum) return [];
                if (typeof elem.poolMeasuresNum === 'object') return elem.poolMeasuresNum?.filter((e: {}) => e) || [];
                return elem.poolMeasuresNum;
              })(),
            }),
            ...((elem?.poolSpecifiedCondition === '1' || elem?.poolSpecifiedCondition === '2') && {
              poolPurchase: elem.poolPurchase || '',
            }),
            ...(elem?.applicationsConditions === '3' && {
              purchaseSpecifiedConditions: elem.purchaseSpecifiedConditions || '',
            }),
            ...((elem?.purchaseSpecifiedConditions === '1' || elem?.purchaseSpecifiedConditions === '3') && {
              purchaseSelectedBrandNum: (() => {
                if (!elem.purchaseSelectedBrandNum) return [];
                if (typeof elem.purchaseSelectedBrandNum === 'object') return elem.purchaseSelectedBrandNum?.filter((e: {}) => e) || [];
                return elem.purchaseSelectedBrandNum;
              })(),
            }),
            ...((elem?.purchaseSpecifiedConditions === '2' || elem?.purchaseSpecifiedConditions === '3') && {
              purchaseSpecifiedConditionsItem: elem.purchaseSpecifiedConditionsItem || '',
              purchaseSelectedItemNum: (() => {
                if (!elem.purchaseSelectedItemNum) return [];
                if (typeof elem.purchaseSelectedItemNum === 'object') return elem.purchaseSelectedItemNum?.filter((e: {}) => e) || [];
                return elem.purchaseSelectedItemNum;
              })(),
            }),
            ...(elem?.applicationsConditions === '4' && {
              memberLowerRank: elem.memberLowerRank || '',
              memberHigherRank: elem.memberHigherRank || '',
            }),
            ...(elem?.applicationsConditions === '5' && {
              inflowRouteCodeNum: (() => {
                if (!elem.inflowRouteCodeNum) return [];
                if (typeof elem.inflowRouteCodeNum === 'object') return elem.inflowRouteCodeNum?.filter((e: {}) => e) || [];
                return elem.inflowRouteCodeNum;
              })(),
              inflowRouteExpirationDate: elem.inflowRouteExpirationDate || '',
              inflowRouteApplicable: elem.inflowRouteApplicable || '',
              inflowRouteAmount: elem.inflowRouteAmount || '',
              inflowRouteCoupon: elem.inflowRouteCoupon || '',
            }),
            ...(elem?.applicationsConditions === '6' && {
              urlApplicableDays: elem.urlApplicableDays || '',
              urlLineConnect: elem.urlLineConnect || '',
              urlPcTag: elem.urlPcTag || '',
              urlSmartphoneTag: elem.urlSmartphoneTag || '',
            }),

            // キャンペーン特典設定
            benefitType: elem.benefitType || '',
            ...(elem?.benefitType === '1' && {
              presentDecline: elem.presentDecline || '',
            }),
            ...((elem?.presentDecline === '1' || elem?.presentDecline === '2') && {
              samplePresent: elem.samplePresent || '',
            }),
            ...((elem?.samplePresent === '1' || elem?.samplePresent === '2' || elem?.samplePresent === '3' || elem?.samplePresent === '4') && {
              presentSelectPromotionalItemsNum1: (() => {
                if (!elem.presentSelectPromotionalItemsNum1) return [];
                if (typeof elem.presentSelectPromotionalItemsNum1 === 'object') return elem.presentSelectPromotionalItemsNum1?.filter((e: {}) => e) || [];
                return elem.presentSelectPromotionalItemsNum1;
              })(),
            }),
            ...((elem?.samplePresent === '2' || elem?.samplePresent === '4') && {
              presentSelectPromotionalItemsNum2: (() => {
                if (!elem.presentSelectPromotionalItemsNum2) return [];
                if (typeof elem.presentSelectPromotionalItemsNum2 === 'object') return elem.presentSelectPromotionalItemsNum2?.filter((e: {}) => e) || [];
                return elem.presentSelectPromotionalItemsNum2;
              })(),
              presentSelectPromotionalItemsNum3: (() => {
                if (!elem.presentSelectPromotionalItemsNum3) return [];
                if (typeof elem.presentSelectPromotionalItemsNum3 === 'object') {
                  return elem.presentSelectPromotionalItemsNum3?.filter((e: {}) => (e != null) ? Object.values(e).filter((v) => v !== '').length != 0 : e) || [];
                }
                return elem.presentSelectPromotionalItemsNum3;
              })(),
            }),
            ...((elem?.samplePresent === '3' || elem?.samplePresent === '4') && {
              presentCampaignName: elem.presentCampaignName || '',
              presentSelectMaxNum: elem.presentSelectMaxNum || '',
            }),

            ...(elem?.benefitType === '2' && {
              couponType: elem.couponType || '',
            }),
            ...((elem?.couponType === '1' || elem?.couponType === '2' || elem?.couponType === '3' || elem?.couponType === '4') && {
              couponName: elem.couponName || '',
              couponContents: elem.couponContents || '',
              couponExpirationDate: elem.couponExpirationDate || '',
            }),
            ...(elem?.couponExpirationDate === '1' && {
              couponAfterGrantDate: elem.couponAfterGrantDate || '',
            }),
            ...(elem?.couponExpirationDate === '2' && {
              couponSpecifyDateYear: elem.couponSpecifyDateYear || '',
              couponSpecifyDateMonth: elem.couponSpecifyDateMonth || '',
              couponSpecifyDateDay: elem.couponSpecifyDateDay || '',
            }),
            ...((elem?.couponType === '1' || elem?.couponType === '2' || elem?.couponType === '3' || elem?.couponType === '4') && {
              couponAmount: elem.couponAmount || '',
              couponConditionsUse: elem.couponConditionsUse || '',
            }),
            ...((elem?.couponType === '1' || elem?.couponType === '2' || elem?.couponType === '4') && {
              couponCouponConditionsUse: elem.couponCouponConditionsUse || '',
            }),
            ...(elem?.couponCouponConditionsUse === '1' && {
              useCouponSkuSpecifiedConditions: elem.useCouponSkuSpecifiedConditions || '',
              useCouponSkuMeasuresNum: (() => {
                if (!elem.useCouponSkuMeasuresNum) return [];
                if (typeof elem.useCouponSkuMeasuresNum === 'object') return elem.useCouponSkuMeasuresNum?.filter((e: {}) => e) || [];
                return elem.useCouponSkuMeasuresNum;
              })(),
            }),
            ...(elem?.couponCouponConditionsUse === '2' && {
              useCouponPoolSpecifiedConditions: elem.useCouponPoolSpecifiedConditions || '',
              useCouponPoolMeasuresNum: (() => {
                if (!elem.useCouponPoolMeasuresNum) return [];
                if (typeof elem.useCouponPoolMeasuresNum === 'object') return elem.useCouponPoolMeasuresNum?.filter((e: {}) => e) || [];
                return elem.useCouponPoolMeasuresNum;
              })(),
            }),
            ...(elem?.couponCouponConditionsUse === '3' && {
              useCouponBrandSpecifiedConditions: elem.useCouponBrandSpecifiedConditions || '',
            }),
            ...((elem?.useCouponBrandSpecifiedConditions === '1' || elem?.useCouponBrandSpecifiedConditions === '3') && {
              useCouponBrandBrandConditions: elem.useCouponBrandBrandConditions || '',
              useCouponBrandSelectBrandNum: (() => {
                if (!elem.useCouponBrandSelectBrandNum) return [];
                if (typeof elem.useCouponBrandSelectBrandNum === 'object') return elem.useCouponBrandSelectBrandNum?.filter((e: {}) => e) || [];
                return elem.useCouponBrandSelectBrandNum;
              })(),
            }),
            ...((elem?.useCouponBrandSpecifiedConditions === '2' || elem?.useCouponBrandSpecifiedConditions === '3') && {
              useCouponBrandConditionsItem: elem.useCouponBrandConditionsItem || '',
              useCouponBrandSelectItemNum: (() => {
                if (!elem.useCouponBrandSelectItemNum) return [];
                if (typeof elem.useCouponBrandSelectItemNum === 'object') return elem.useCouponBrandSelectItemNum?.filter((e: {}) => e) || [];
                return elem.useCouponBrandSelectItemNum;
              })(),
            }),
            ...(elem?.couponType === '3' && {
              couponGrantFormat: elem.couponGrantFormat || '',
            }),
            ...(elem?.couponGrantFormat === '2' && {
              couponIssueDate: elem.couponIssueDate || '',
            }),
            ...(elem?.couponType === '3' && {
              couponConditionsIssue: elem.couponConditionsIssue || '',
              couponApplicableCondition: elem.couponApplicableCondition || '',
              couponCartCoupon: elem.couponCartCoupon || '',
            }),
            ...(elem?.couponType === '4' && {
              couponCode: elem.couponCode || '',
              couponMaxDistributions: elem.couponMaxDistributions || '',
            }),
            ...(elem?.couponMaxDistributions === '2' && {
              couponMaxNumber: elem.couponMaxNumber || '',
            }),

            ...(elem?.benefitType === '3' && {
              pointSelectSpecify: elem.pointSelectSpecify || '',
              pointSelectNum: elem.pointSelectNum || '',
            }),

            imjCampaignCodeStaging: elem.imjCampaignCodeStaging || '',
            imjCampaignCodeProduction: elem.imjCampaignCodeProduction || '',
          };
        });
      })(),
    };
  }, [getValues]);

  const apiCallbackHandler = React.useCallback(
    (result) => {
      let data = JSON.parse(JSON.stringify(result));
      const { campaignDetail } = data;
      if (campaignDetail) data.campaignDetail = trimArrayEnd(campaignDetail);
      reset(data, RHF_RESET_PARAMS);
      setResponse(data);
      setCampaignSettingsValue(data.campaignSettings);
    },
    [reset],
  );

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <ApiGetMeasuresDetailProvider type="campaign" callback={apiCallbackHandler}>
            <Container>
              <Breadcrumb
                list={[
                  {
                    label: 'TOP',
                    url: '/CMN001',
                  },
                  {
                    label: '施策概要・依頼内容',
                  },
                  {
                    label: 'キャンペーン設定・クーポン発行',
                  },
                ]}
              />

              <ServerErrorBox />

              <Section>
                <H1>キャンペーン設定・クーポン発行</H1>

                {response?.measuresName && (
                  <Text align="center" size={24} bold={true}>
                    {response.measuresName}
                  </Text>
                )}

                {campaignSettingsValue !== '1' && <ErrorView />}

                {campaignSettingsValue === '1' && (
                  <>
                    <Text align="center">
                      下記の項目を入力してください。
                      <br />
                      [必須]項目は、全て入力いただく必要があります。
                    </Text>

                    <Section hasBg>
                      <H2>キャンペーン</H2>
                      <Text align="center">設定するキャンペーン数を選択してください。選択されたキャンペーン数分、入力タブが表示されます。</Text>

                      <SectionInner>
                        <FormArray
                          type="tab"
                          label="設定キャンペーン数"
                          name="campaignDetail"
                          unit="点"
                          isRequired
                          hasDeleteButtonOnTop
                          hasTabOnBottom
                          contentLabel={(key: number) => `${key + 1}:キャンペーン`}
                          content={(key: number) => {
                            return (
                              <React.Fragment key={key}>
                                <FormRadioList
                                  name={`campaignDetail[${key}].enforcementDivision`}
                                  label="実施区分"
                                  isRequired
                                  list={objectToRadioList(ENFORCEMENTDIVISIONS_VALUES)}
                                />

                                <FormDate
                                  names={{
                                    year: `campaignDetail[${key}].settingStartDateYear`,
                                    month: `campaignDetail[${key}].settingStartDateMonth`,
                                    date: `campaignDetail[${key}].settingStartDateDay`,
                                    hour: `campaignDetail[${key}].settingStartDateHours`,
                                    minute: `campaignDetail[${key}].settingStartDateMinutes`,
                                  }}
                                  defaultValues={{
                                    year: response?.measuresStartDateYear || '',
                                    month: response?.measuresStartDateMonth || '',
                                    date: response?.measuresStartDateDay || '',
                                    hour: response?.measuresStartTimeHours || '00',
                                    minute: response?.measuresStartTimeMinutes || '00',
                                  }}
                                  label="設定開始日/時間"
                                  isRequired
                                  hasUndecidedButton
                                />

                                <FormDate
                                  names={{
                                    year: `campaignDetail[${key}].settingEndDateYear`,
                                    month: `campaignDetail[${key}].settingEndDateMonth`,
                                    date: `campaignDetail[${key}].settingEndDateDay`,
                                    hour: `campaignDetail[${key}].settingEndDateHours`,
                                    minute: `campaignDetail[${key}].settingEndDateMinutes`,
                                  }}
                                  defaultValues={{
                                    year: response?.measuresEndDateYear || '',
                                    month: response?.measuresEndDateMonth || '',
                                    date: response?.measuresEndDateDay || '',
                                    hour: response?.measuresEndTimeHours || '23',
                                    minute: response?.measuresEndTimeMinutes || '59',
                                  }}
                                  label="設定終了日/時間"
                                  isRequired
                                  hasUndecidedButton
                                />

                                <FormCheckboxList
                                  label="購入種別"
                                  list={[
                                    {
                                      name: `campaignDetail[${key}].purchaseTypeNormal`,
                                      label: CAMPAIGNPURCHASETYPE_LABELS[0],
                                      value: '1',
                                    },
                                    {
                                      name: `campaignDetail[${key}].purchaseTypeReserve`,
                                      label: CAMPAIGNPURCHASETYPE_LABELS[1],
                                      value: '1',
                                    },
                                    {
                                      name: `campaignDetail[${key}].purchaseTypeRegular`,
                                      label: CAMPAIGNPURCHASETYPE_LABELS[2],
                                      value: '1',
                                    },
                                    {
                                      name: `campaignDetail[${key}].purchaseTypeSimple`,
                                      label: CAMPAIGNPURCHASETYPE_LABELS[3],
                                      value: '1',
                                    },
                                  ]}
                                  isRequired
                                  isHorizontal
                                />

                                <FormRadioList
                                  name={`campaignDetail[${key}].memberType`}
                                  label="会員種別"
                                  isRequired
                                  list={objectToRadioList(MEMBERTYPE_VALUES)}
                                />

                                <FormRadioAccordion
                                  name={`campaignDetail[${key}].applicationsType`}
                                  label="適用回数"
                                  isRequired
                                  hasUndecidedButton
                                  list={objectToRadioList(APPLICATIONSTYPE_VALUES)}
                                  contents={{
                                    '1': null,
                                    '2': (
                                      <>
                                        <FormTextInput
                                          name={`campaignDetail[${key}].applicationsDetails`}
                                          type="number"
                                          label="適用詳細回数"
                                          unit="回"
                                          placeholder="1000"
                                          note="※同一会員に、生涯何度適用させるか入力してください。"
                                          isRequired
                                          hasUndecidedButton
                                        />
                                      </>
                                    ),
                                  }}
                                />

                                <Section>
                                  <H3>適用会員を指定</H3>

                                  <FormRadioAccordion
                                    name={`campaignDetail[${key}].memberList`}
                                    label="適用会員リストの指定"
                                    isRequired
                                    list={objectToRadioList(MEMBERLIST_VALUES)}
                                    contents={{
                                      '1': null,
                                      '2': (
                                        <FormTextbox
                                          name={`campaignDetail[${key}].listConditions`}
                                          label="リスト条件"
                                          placeholder="「2020/1/1～2021/2/28までにEIの化粧水・乳液購入者」等を入力"
                                          help="対象外会員の指定は上限5000人まで可能です"
                                          isRequired
                                        />
                                      ),
                                      '3': (
                                        <FormTextbox
                                          name={`campaignDetail[${key}].listConditions`}
                                          label="リスト条件"
                                          placeholder="「2020/1/1～2021/2/28までにEIの化粧水・乳液購入者」等を入力"
                                          help="対象外会員の指定は上限5000人まで可能です"
                                          isRequired
                                        />
                                      ),
                                    }}
                                  />

                                  <FormRadioList
                                    name={`campaignDetail[${key}].applicationsMemberBrand`}
                                    label="適用会員ブランド指定"
                                    isRequired
                                    list={objectToRadioList(APPLICATIONSMEMBERBLAND_VALUES)}
                                  />
                                </Section>

                                <Section>
                                  <H3>キャンペーンのオプションを設定</H3>

                                  <FormRadioAccordion
                                    name={`campaignDetail[${key}].optionType`}
                                    label="オプション種別"
                                    isRequired
                                    list={objectToRadioList(OPTIONTYPE_VALUES)}
                                    contents={{
                                      '1': <CampaignOptionsEntry k={key} />,
                                      '2': (
                                        <>
                                          <CampaignOptionsEntryAndRepeatPurChase k={key} />
                                        </>
                                      ),
                                      '3': <CampaignOptionsSecret k={key} />,
                                      '4': null,
                                    }}
                                  />
                                </Section>

                                <Section>
                                  <H3>キャンペーン適用条件設定</H3>

                                  <FormRadioAccordion
                                    name={`campaignDetail[${key}].amountSpecification`}
                                    label="購入金額指定の設定"
                                    list={objectToRadioList(AMOUNTSPECIFICATION_VALUES)}
                                    isRequired
                                    contents={{
                                      '1': (
                                        <>
                                          <FormTextInput
                                            name={`campaignDetail[${key}].amountSpecificationConditions`}
                                            type="number"
                                            label="購入金額条件"
                                            unit="円（税抜）以上購入"
                                            placeholder="7000"
                                            isRequired
                                            hasUndecidedButton
                                          />
                                        </>
                                      ),
                                      '2': (
                                        <>
                                          <FormTextInput
                                            name={`campaignDetail[${key}].amountSpecificationConditions`}
                                            type="number"
                                            label="購入金額条件"
                                            unit="円（税抜）以上購入"
                                            placeholder="7000"
                                            isRequired
                                            hasUndecidedButton
                                          />

                                          <FormRadioAccordion
                                            name={`campaignDetail[${key}].amountSpecificationSetting`}
                                            label="購入金額の組み合わせ設定条件"
                                            help="「選択した適用条件に合致した商品の合計金額（ブランド、アイテムの掛け合わせのみ設定可）」の選択は、適用条件が「購入条件（ブランド×アイテム）」の時しか設定できません。"
                                            isRequired
                                            hasUndecidedButton
                                            list={objectToRadioList(AMOUNTSPECIFICATIONSETTING_VALUES)}
                                            contents={{
                                              '1': <ApplicableCondition k={key} selectable={['1', '2', '3', '4', '5', '6']} />,
                                              '2': <ApplicableCondition k={key} selectable={['3']} defaultValue="3" />,
                                            }}
                                          />
                                        </>
                                      ),
                                      '3': <ApplicableCondition k={key} />,
                                    }}
                                  />
                                </Section>

                                <Section>
                                  <H3>キャンペーン特典設定</H3>

                                  <FormRadioAccordion
                                    name={`campaignDetail[${key}].benefitType`}
                                    label="特典種別"
                                    isRequired
                                    list={objectToRadioList(BENEFITTYPE_VALUES)}
                                    contents={{
                                      '1': (
                                        <FormRadioAccordion
                                          name={`campaignDetail][${key}].presentDecline`}
                                          label="プレゼント辞退設定"
                                          isRequired
                                          hasUndecidedButton
                                          list={objectToRadioList(SAMPLEPRESENT_DECLINEVALUES)}
                                          notes={
                                            [
                                              `※プレゼントの辞退を許容する場合、特典が１点のみでも、プレゼント方法は「${SAMPLEPRESENT_VALUES['3']}」を選択し、入力してください。`,
                                              "※2023年12月現在、以下についてはプレゼントの辞退を許容しないものとしますので、登録の際はご注意ください。",
                                              "エントリーキャンペーン／エントリー＆継続購入キャンペーン／複数の特典を同時付与したい場合／セット・キット品（キャンペーン設定でプレゼントを付与する設定を行っているが、お客さまには「セット品・キット品」として訴求しているもの）／LINEマストバイ／定期購入特典／リーフレット（HAKU　継続実感プログラム応募はがき含む）／規約関係"
                                            ]
                                          }
                                          contents={{
                                            '1': (
                                              <FormRadioAccordion
                                                name={`campaignDetail[${key}].samplePresent`}
                                                label="プレゼント方法"
                                                isRequired
                                                hasUndecidedButton
                                                list={objectToRadioList(SAMPLEPRESENT_VALUES)}
                                                contents={{
                                                  '1': (
                                                    <FormArray
                                                      name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1`}
                                                      label="販促品点数"
                                                      unit="点"
                                                      isRequired
                                                      hasUndecidedButton
                                                      contentLabel={(key: number) => `${key + 1}:販促品`}
                                                      content={(k: number) => {
                                                        return (
                                                          <React.Fragment key={k}>
                                                            <ApiSearchPromotionItemModal
                                                              valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItems1`}
                                                              labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsName1`}
                                                              code={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsPromotionCode1`}
                                                              label={`${k + 1}:販促品`}
                                                              isRequired
                                                              hasUndecidedButton
                                                            />
                                                            <FormRadioList
                                                              name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsDelivery1`}
                                                              label="納品予定"
                                                              isRequired
                                                              hasUndecidedButton
                                                              list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                            />
                                                          </React.Fragment>
                                                        );
                                                      }}
                                                    />
                                                  ),
                                                  '2': (
                                                    <>
                                                      <NestBox>
                                                        <ApiSearchPromotionItemModal
                                                          valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[0].presentSelectPromotionalItems1`}
                                                          labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[0].presentSelectPromotionalItemsName1`}
                                                          code={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[0].presentSelectPromotionalItemsPromotionCode1`}
                                                          label="最初に付与する販促品"
                                                          isRequired
                                                          hasUndecidedButton
                                                        />
                                                        <FormRadioList
                                                          name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[0].presentSelectPromotionalItemsDelivery1`}
                                                          label="納品予定"
                                                          isRequired
                                                          hasUndecidedButton
                                                          list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                        />
                                                      </NestBox>

                                                      <NestBox>
                                                        <ApiSearchPromotionItemModal
                                                          valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[0].presentSelectPromotionalItems2`}
                                                          labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[0].presentSelectPromotionalItemsName2`}
                                                          code={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[0].presentSelectPromotionalItemsPromotionCode2`}
                                                          label="2番目に付与する販促品"
                                                          isRequired
                                                          hasUndecidedButton
                                                        />
                                                        <FormRadioList
                                                          name={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[0].presentSelectPromotionalItemsDelivery2`}
                                                          label="納品予定"
                                                          isRequired
                                                          hasUndecidedButton
                                                          list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                        />
                                                      </NestBox>

                                                      <NestBox>
                                                        <ApiSearchPromotionItemModal
                                                          valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[0].presentSelectPromotionalItems3`}
                                                          labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[0].presentSelectPromotionalItemsName3`}
                                                          code={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[0].presentSelectPromotionalItemsPromotionCode3`}
                                                          label="3番目に付与する販促品"
                                                        />
                                                        <FormRadioList
                                                          name={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[0].presentSelectPromotionalItemsDelivery3`}
                                                          label="納品予定"
                                                          list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                        />
                                                      </NestBox>
                                                    </>
                                                  ),
                                                  '3': (
                                                    <>
                                                      <FormTextInput
                                                        name={`campaignDetail[${key}].presentCampaignName`}
                                                        label="キャンペーン名称"
                                                        placeholder="「xxxキャンペーン」等を入力"
                                                        note="※カート画面でお客様に表示する名称です。"
                                                        isRequired
                                                        hasUndecidedButton
                                                      />

                                                      <FormTextInput
                                                        name={`campaignDetail[${key}].presentSelectMaxNum`}
                                                        type="number"
                                                        label="選択可能上限数"
                                                        placeholder="1"
                                                        isRequired
                                                        hasUndecidedButton
                                                      />

                                                      <FormArray
                                                        name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1`}
                                                        label="販促品点数"
                                                        unit="点"
                                                        isRequired
                                                        hasUndecidedButton
                                                        contentLabel={(key: number) => `${key + 1}:販促品`}
                                                        content={(k: number) => {
                                                          return (
                                                            <React.Fragment key={k}>
                                                              <ApiSearchPromotionItemModal
                                                                valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItems1`}
                                                                labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsName1`}
                                                                code={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsPromotionCode1`}
                                                                label={`${k + 1}:販促品`}
                                                                isRequired
                                                                hasUndecidedButton
                                                              />
                                                              <FormRadioList
                                                                name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsDelivery1`}
                                                                label="納品予定"
                                                                isRequired
                                                                hasUndecidedButton
                                                                list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                              />
                                                            </React.Fragment>
                                                          );
                                                        }}
                                                      />
                                                    </>
                                                  ),
                                                  '4': (
                                                    <>
                                                      <FormTextInput
                                                        name={`campaignDetail[${key}].presentCampaignName`}
                                                        label="キャンペーン名称"
                                                        placeholder="「xxxキャンペーン」等を入力"
                                                        note="※カート画面でお客様に表示する名称です。"
                                                        isRequired
                                                        hasUndecidedButton
                                                      />

                                                      <FormTextInput
                                                        name={`campaignDetail[${key}].presentSelectMaxNum`}
                                                        type="number"
                                                        label="選択可能上限数"
                                                        placeholder="1"
                                                        isRequired
                                                        hasUndecidedButton
                                                      />

                                                      <FormArray
                                                        name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1`}
                                                        label="最初に付与する販促品点数"
                                                        unit="点"
                                                        isRequired
                                                        hasUndecidedButton
                                                        contentLabel={(key: number) => `${key + 1}:最初に付与する選べる販促品`}
                                                        content={(k: number) => {
                                                          return (
                                                            <React.Fragment key={k}>
                                                              <ApiSearchPromotionItemModal
                                                                valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItems1`}
                                                                labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsName1`}
                                                                code={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsPromotionCode1`}
                                                                label={`${k + 1}:最初に付与する選べる販促品`}
                                                                isRequired
                                                                hasUndecidedButton
                                                              />
                                                              <FormRadioList
                                                                name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsDelivery1`}
                                                                label="納品予定"
                                                                isRequired
                                                                hasUndecidedButton
                                                                list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                              />
                                                            </React.Fragment>
                                                          );
                                                        }}
                                                      />

                                                      <FormArray
                                                        name={`campaignDetail[${key}].presentSelectPromotionalItemsNum2`}
                                                        label="2番目に付与する販促品点数"
                                                        unit="点"
                                                        isRequired
                                                        hasUndecidedButton
                                                        contentLabel={(key: number) => `${key + 1}:2番目に付与する選べる販促品`}
                                                        content={(k: number) => {
                                                          return (
                                                            <React.Fragment key={k}>
                                                              <ApiSearchPromotionItemModal
                                                                valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[${k}].presentSelectPromotionalItems2`}
                                                                labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[${k}].presentSelectPromotionalItemsName2`}
                                                                code={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[${k}].presentSelectPromotionalItemsPromotionCode2`}
                                                                label={`${k + 1}:2番目に付与する選べる販促品`}
                                                                isRequired
                                                                hasUndecidedButton
                                                              />
                                                              <FormRadioList
                                                                name={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[${k}].presentSelectPromotionalItemsDelivery2`}
                                                                label="納品予定"
                                                                isRequired
                                                                hasUndecidedButton
                                                                list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                              />
                                                            </React.Fragment>
                                                          );
                                                        }}
                                                      />

                                                      <FormArray
                                                        name={`campaignDetail[${key}].presentSelectPromotionalItemsNum3`}
                                                        label="3番目に付与する販促品点数"
                                                        unit="点"
                                                        contentLabel={(key: number) => `${key + 1}:3番目に付与する選べる販促品`}
                                                        content={(k: number) => {
                                                          return (
                                                            <React.Fragment key={k}>
                                                              <ApiSearchPromotionItemModal
                                                                valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[${k}].presentSelectPromotionalItems3`}
                                                                labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[${k}].presentSelectPromotionalItemsName3`}
                                                                code={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[${k}].presentSelectPromotionalItemsPromotionCode3`}
                                                                label={`${k + 1}:3番目に付与する選べる販促品`}
                                                              />
                                                              <FormRadioList
                                                                name={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[${k}].presentSelectPromotionalItemsDelivery3`}
                                                                label="納品予定"
                                                                list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                              />
                                                            </React.Fragment>
                                                          );
                                                        }}
                                                      />
                                                    </>
                                                  ),
                                                }}
                                              />
                                            ),
                                            '2': (
                                              <FormRadioAccordion
                                                name={`campaignDetail[${key}].samplePresent`}
                                                label="プレゼント方法"
                                                isRequired
                                                hasUndecidedButton
                                                list={objectToRadioList(SAMPLEPRESENT_VALUES, ['3', '4'])}
                                                contents={{
                                                  '3': (
                                                    <>
                                                      <FormTextInput
                                                        name={`campaignDetail[${key}].presentCampaignName`}
                                                        label="キャンペーン名称"
                                                        placeholder="「xxxキャンペーン」等を入力"
                                                        note="※カート画面でお客様に表示する名称です。"
                                                        isRequired
                                                        hasUndecidedButton
                                                      />

                                                      <FormTextInput
                                                        name={`campaignDetail[${key}].presentSelectMaxNum`}
                                                        type="number"
                                                        label="選択可能上限数"
                                                        placeholder="1"
                                                        isRequired
                                                        hasUndecidedButton
                                                      />

                                                      <FormArray
                                                        name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1`}
                                                        label="販促品点数"
                                                        unit="点"
                                                        isRequired
                                                        hasUndecidedButton
                                                        contentLabel={(key: number) => `${key + 1}:販促品`}
                                                        content={(k: number) => {
                                                          return (
                                                            <React.Fragment key={k}>
                                                              <ApiSearchPromotionItemModal
                                                                valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItems1`}
                                                                labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsName1`}
                                                                code={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsPromotionCode1`}
                                                                label={`${k + 1}:販促品`}
                                                                isRequired
                                                                hasUndecidedButton
                                                              />
                                                              <FormRadioList
                                                                name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsDelivery1`}
                                                                label="納品予定"
                                                                isRequired
                                                                hasUndecidedButton
                                                                list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                              />
                                                            </React.Fragment>
                                                          );
                                                        }}
                                                      />
                                                    </>
                                                  ),
                                                  '4': (
                                                    <>
                                                      <FormTextInput
                                                        name={`campaignDetail[${key}].presentCampaignName`}
                                                        label="キャンペーン名称"
                                                        placeholder="「xxxキャンペーン」等を入力"
                                                        note="※カート画面でお客様に表示する名称です。"
                                                        isRequired
                                                        hasUndecidedButton
                                                      />

                                                      <FormTextInput
                                                        name={`campaignDetail[${key}].presentSelectMaxNum`}
                                                        type="number"
                                                        label="選択可能上限数"
                                                        placeholder="1"
                                                        isRequired
                                                        hasUndecidedButton
                                                      />

                                                      <FormArray
                                                        name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1`}
                                                        label="最初に付与する販促品点数"
                                                        unit="点"
                                                        isRequired
                                                        hasUndecidedButton
                                                        contentLabel={(key: number) => `${key + 1}:最初に付与する選べる販促品`}
                                                        content={(k: number) => {
                                                          return (
                                                            <React.Fragment key={k}>
                                                              <ApiSearchPromotionItemModal
                                                                valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItems1`}
                                                                labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsName1`}
                                                                code={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsPromotionCode1`}
                                                                label={`${k + 1}:最初に付与する選べる販促品`}
                                                                isRequired
                                                                hasUndecidedButton
                                                              />
                                                              <FormRadioList
                                                                name={`campaignDetail[${key}].presentSelectPromotionalItemsNum1[${k}].presentSelectPromotionalItemsDelivery1`}
                                                                label="納品予定"
                                                                isRequired
                                                                hasUndecidedButton
                                                                list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                              />
                                                            </React.Fragment>
                                                          );
                                                        }}
                                                      />

                                                      <FormArray
                                                        name={`campaignDetail[${key}].presentSelectPromotionalItemsNum2`}
                                                        label="2番目に付与する販促品点数"
                                                        unit="点"
                                                        isRequired
                                                        hasUndecidedButton
                                                        contentLabel={(key: number) => `${key + 1}:2番目に付与する選べる販促品`}
                                                        content={(k: number) => {
                                                          return (
                                                            <React.Fragment key={k}>
                                                              <ApiSearchPromotionItemModal
                                                                valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[${k}].presentSelectPromotionalItems2`}
                                                                labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[${k}].presentSelectPromotionalItemsName2`}
                                                                code={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[${k}].presentSelectPromotionalItemsPromotionCode2`}
                                                                label={`${k + 1}:2番目に付与する選べる販促品`}
                                                                isRequired
                                                                hasUndecidedButton
                                                              />
                                                              <FormRadioList
                                                                name={`campaignDetail[${key}].presentSelectPromotionalItemsNum2[${k}].presentSelectPromotionalItemsDelivery2`}
                                                                label="納品予定"
                                                                isRequired
                                                                hasUndecidedButton
                                                                list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                              />
                                                            </React.Fragment>
                                                          );
                                                        }}
                                                      />

                                                      <FormArray
                                                        name={`campaignDetail[${key}].presentSelectPromotionalItemsNum3`}
                                                        label="3番目に付与する販促品点数"
                                                        unit="点"
                                                        contentLabel={(key: number) => `${key + 1}:3番目に付与する選べる販促品`}
                                                        content={(k: number) => {
                                                          return (
                                                            <React.Fragment key={k}>
                                                              <ApiSearchPromotionItemModal
                                                                valueName={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[${k}].presentSelectPromotionalItems3`}
                                                                labelName={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[${k}].presentSelectPromotionalItemsName3`}
                                                                code={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[${k}].presentSelectPromotionalItemsPromotionCode3`}
                                                                label={`${k + 1}:3番目に付与する選べる販促品`}
                                                              />
                                                              <FormRadioList
                                                                name={`campaignDetail[${key}].presentSelectPromotionalItemsNum3[${k}].presentSelectPromotionalItemsDelivery3`}
                                                                label="納品予定"
                                                                list={objectToRadioList(PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES)}
                                                              />
                                                            </React.Fragment>
                                                          );
                                                        }}
                                                      />
                                                    </>
                                                  ),
                                                }}
                                              />
                                            )
                                          }}
                                        />
                                      ),
                                      '2': (
                                        <FormRadioAccordion
                                          name={`campaignDetail[${key}].couponType`}
                                          label="クーポン種別"
                                          isRequired
                                          list={objectToRadioList(COUPONTYPE_VALUES)}
                                          contents={{
                                            '1': <CouponDetailCampaignOrGet k={key} />,
                                            '2': <CouponDetailCampaignOrGet k={key} />,
                                            '3': <CouponDetailCrm k={key} />,
                                            '4': <CouponDetailCouponCode k={key} />,
                                          }}
                                        />
                                      ),
                                      '3': (
                                        <>
                                          <FormRadioList
                                            name={`campaignDetail[${key}].pointSelectSpecify`}
                                            label="指定方法選択"
                                            isRequired
                                            hasUndecidedButton
                                            list={objectToRadioList(POINTSELECTSPECIFY_VALUES)}
                                          />

                                          <FormTextInput
                                            name={`campaignDetail[${key}].pointSelectNum`}
                                            type="number"
                                            label="指定数"
                                            placeholder="100"
                                            isRequired
                                            hasUndecidedButton
                                          />
                                        </>
                                      ),
                                    }}
                                  />

                                  <FormTextInput
                                    name={`campaignDetail[${key}].imjCampaignCodeStaging`}
                                    label="（IMJ入力）キャンペーンコード（ステージング）"
                                    placeholder="「5000011111」等を入力"
                                  />

                                  <FormTextInput
                                    name={`campaignDetail[${key}].imjCampaignCodeProduction`}
                                    label="（IMJ入力）キャンペーンコード（本番）"
                                    placeholder="「5000011111」等を入力"
                                  />
                                </Section>
                              </React.Fragment>
                            );
                          }}
                        />
                      </SectionInner>
                    </Section>

                    <ButtonContainer>
                      <ApiSaveMeasureButton type="campaign" getRequestData={getRequestData} />
                    </ButtonContainer>
                  </>
                )}
              </Section>
            </Container>

            <StickyFooter type="campaign" getRequestData={getRequestData} disabledSaveTemp={campaignSettingsValue !== '1'} />

            <RHFDevTools />
          </ApiGetMeasuresDetailProvider>
        </FormProvider>
      </Main>
      <Footer mb={80} />
    </>
  );
};
