import React from 'react';
import { ApiSelectMultipleBrand, ApiSelectMultipleItem } from 'components/Business/Api';
import { FormTextInput, FormRadioList, FormRadioAccordion, FormArray } from 'components/Business/Form';
import {
  COUPONCOUPONCONDITIONSUSE_VALUES,
  USECOUPONSKUSPECIFIEDCONDITIONS_VALUES,
  USECOUPONPOOLSPECIFIEDCONDITIONS_VALUES,
  USECOUPONBRANDSPECIFIEDCONDITIONS_VALUES,
  USECOUPONBRANDBRANDCONDITIONS_VALUES,
  USECOUPONBRANDCONDITIONSITEM_VALUES,
} from 'constants/index';
import { objectToRadioList } from 'utils';

type Props = {
  k: number;
};

export const CouponDetailCommonChildren4: React.FC<Props> = ({ k }) => {
  const SelectBrandComponent: React.FC = () => {
    return (
      <>
        <FormRadioList
          name={`campaignDetail[${k}].useCouponBrandBrandConditions`}
          label="指定ブランド条件"
          help="複数ブランドをAND条件とする場合はシステム開発ベンダーへの依頼が必要です。ブランドクーポンの場合は、１つのブランドのみ指定可能です。"
          isRequired
          hasUndecidedButton
          list={objectToRadioList(USECOUPONBRANDBRANDCONDITIONS_VALUES)}
        />

        <ApiSelectMultipleBrand
          name={`campaignDetail[${k}].useCouponBrandSelectBrandNum`}
          valueName="useCouponBrandSelectBrand"
          labelName="useCouponBrandSelectBrandName"
          isRequired
          hasUndecidedButton
        />
      </>
    );
  };

  const SelectItemComponent: React.FC = () => {
    return (
      <>
        <FormRadioList
          name={`campaignDetail[${k}].useCouponBrandConditionsItem`}
          label="指定アイテム条件"
          isRequired
          hasUndecidedButton
          list={objectToRadioList(USECOUPONBRANDCONDITIONSITEM_VALUES)}
        />

        <ApiSelectMultipleItem
          name={`campaignDetail[${k}].useCouponBrandSelectItemNum`}
          valueName="useCouponBrandSelectItem"
          labelName="useCouponBrandSelectItemName"
          isRequired
          hasUndecidedButton
        />
      </>
    );
  };

  return (
    <FormRadioAccordion
      name={`campaignDetail[${k}].couponCouponConditionsUse`}
      label="クーポン利用条件"
      isRequired
      hasUndecidedButton
      list={objectToRadioList(COUPONCOUPONCONDITIONSUSE_VALUES)}
      contents={{
        '1': (
          <>
            <FormRadioList
              name={`campaignDetail[${k}].useCouponSkuSpecifiedConditions`}
              label="指定条件"
              isRequired
              hasUndecidedButton
              list={objectToRadioList(USECOUPONSKUSPECIFIEDCONDITIONS_VALUES)}
            />

            <FormArray
              name={`campaignDetail[${k}].useCouponSkuMeasuresNum`}
              label="施策対象SKU数"
              isRequired
              hasUndecidedButton
              contentLabel={(key: number) => `${key + 1}:施策対象SKU`}
              content={(k2: number) => {
                return (
                  <React.Fragment key={k2}>
                    <FormTextInput
                      type="number"
                      name={`campaignDetail[${k}].useCouponSkuMeasuresNum[${k2}].useCouponSkuMeasures`}
                      label={`${k2 + 1}:施策対象SKU`}
                      placeholder="「4514254977767」等を入力"
                      isRequired
                      hasUndecidedButton
                    />
                  </React.Fragment>
                );
              }}
            />
          </>
        ),
        '2': (
          <>
            <FormRadioList
              name={`campaignDetail[${k}].useCouponPoolSpecifiedConditions`}
              label="指定条件"
              isRequired
              hasUndecidedButton
              list={objectToRadioList(USECOUPONPOOLSPECIFIEDCONDITIONS_VALUES)}
            />

            <FormArray
              name={`campaignDetail[${k}].useCouponPoolMeasuresNum`}
              label="施策対象プール中数"
              isRequired
              hasUndecidedButton
              contentLabel={(key: number) => `${key + 1}:施策対象プール中`}
              content={(k2: number) => {
                return (
                  <React.Fragment key={k2}>
                    <FormTextInput
                      type="alphaNumeric"
                      name={`campaignDetail[${k}].useCouponPoolMeasuresNum[${k2}].useCouponPoolMeasures`}
                      label={`${k2 + 1}:施策対象プール中`}
                      placeholder="「E10100」等を入力"
                      isRequired
                      hasUndecidedButton
                    />
                  </React.Fragment>
                );
              }}
            />
          </>
        ),
        '3': (
          <>
            <FormRadioAccordion
              name={`campaignDetail[${k}].useCouponBrandSpecifiedConditions`}
              label="指定条件"
              help="ブランドを複数指定する場合、アイテムとの組み合わせ設定できません。"
              isRequired
              hasUndecidedButton
              list={objectToRadioList(USECOUPONBRANDSPECIFIEDCONDITIONS_VALUES)}
              note="※[複数ブランド×複数アイテム]は指定できません。"
              contents={{
                '1': <SelectBrandComponent />,
                '2': <SelectItemComponent />,
                '3': (
                  <>
                    <SelectBrandComponent />
                    <SelectItemComponent />
                  </>
                ),
              }}
            />
          </>
        ),
      }}
    />
  );
};
