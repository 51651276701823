import { Login } from 'components/Pages/Login';
import { CMN002 } from 'components/Pages/CMN002';
import { CMN003 } from 'components/Pages/CMN003';
import { CMN004 } from 'components/Pages/CMN004';
import { CMN006 } from 'components/Pages/CMN006';
import { CMN009 } from 'components/Pages/CMN009';
import { CMN011 } from 'components/Pages/CMN011';
import { CMN013 } from 'components/Pages/CMN013';

export const routes = [
  { path: '/Login', component: Login },
  { path: '/CMN002', component: CMN002 },
  { path: '/CMN003', component: CMN003 },
  { path: '/CMN004', component: CMN004 },
  { path: '/CMN006', component: CMN006 },
  { path: '/CMN009', component: CMN009 },
  { path: '/CMN011', component: CMN011 },
  { path: '/CMN013', component: CMN013 },
];
