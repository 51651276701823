import React from 'react';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

export const TabAddButton: React.FC<Props> = ({ onClick = () => {}, disabled = false }) =>
  React.useMemo(
    () => (
      <li className={style['tabMenu-addbutton']}>
        <button onClick={onClick} disabled={disabled}>
          <SvgIcon type="plus" fill="#a0a4ad" width={14} />
        </button>
      </li>
    ),
    [disabled, onClick],
  );
