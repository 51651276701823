import React from 'react';
import style from './style.module.scss';

const Align = {
  right: 'right',
  center: 'center',
  left: 'left',
} as const;
type AlignProps = typeof Align[keyof typeof Align];

const Color = {
  black: 'black',
  grey: 'grey',
  blue: 'blue',
  red: 'red',
} as const;
type ColorProps = typeof Color[keyof typeof Color];

type Props = {
  size?: number;
  bold?: boolean;
  align?: AlignProps;
  color?: ColorProps;
  className?: string;
  mt?: number;
  ml?: number;
  mr?: number;
  nowrap?: boolean;
};

export const Text: React.FC<Props> = ({ children, size = 16, bold = false, align = 'left', color = 'black', className = '', mt, ml, mr, nowrap }) => (
  <p
    className={`${style['typography-text']} is-align-${align} ${bold ? 'is-bold' : ''} is-${color} ${className} ${nowrap ? 'is-nowrap' : ''}`}
    style={{
      fontSize: `${size}px`,
      ...(mt !== undefined && { marginTop: `${mt}px` }),
      ...(ml !== undefined && { marginLeft: `${ml}px` }),
      ...(mr !== undefined && { marginRight: `${mr}px` }),
    }}
  >
    {children}
  </p>
);
