import { easings } from 'utils';
import type { EasingProps } from 'utils/easings';

type Props = {
  to: number | string | HTMLElement;
  duration?: number;
  easing?: EasingProps;
  offset?: number;
};

const getElementOffset = (t: HTMLElement) => {
  return t.getBoundingClientRect().top + (window.scrollY || window.pageYOffset);
};

export const smoothScroll = ({ to, duration = 500, easing = 'linear', offset = 0 }: Props) => {
  let targetPosition: number = 0;
  if (typeof to === 'number') {
    targetPosition = to + offset;
  } else if (typeof to === 'string') {
    const targetElement = (document.getElementById(to) as HTMLElement) || (document.getElementsByName(to)[0] as HTMLElement);
    if (!targetElement) return;
    targetPosition = getElementOffset(targetElement) + offset;
  } else if (to instanceof HTMLElement) {
    targetPosition = getElementOffset(to) + offset;
  } else {
    return;
  }
  const pageElement = document.scrollingElement || document.documentElement;
  const start = pageElement.scrollTop;
  const change = targetPosition - start;
  const startTime = +new Date();

  const animateScroll = () => {
    const currentTime = +new Date();
    const elapsedTime = Math.min(currentTime - startTime, duration);
    const progress = elapsedTime / duration;

    pageElement.scrollTop = start + easings[easing](progress) * change;

    if (elapsedTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      pageElement.scrollTop = targetPosition;
    }
  };

  animateScroll();
};
