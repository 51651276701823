import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isIncludeNumber, isIncludeUppercase, isIncludeLowercase, isIncludeSpecialChars } from 'utils';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    verificationCode: yup.string().required(t('ERR-REQUIRED')),

    newPassword: yup
      .string()
      .required(t('ERR-REQUIRED'))
      .min(8, t('ERR-LENGTH-MIN', { min: 8 }))
      .test('isIncludeNumber', t('ERR-FORMAT-PASSWORD'), function (value) {
        return isIncludeNumber(value);
      })
      .test('isIncludeUppercase', t('ERR-FORMAT-PASSWORD'), function (value) {
        return isIncludeUppercase(value);
      })
      .test('isIncludeLowercase', t('ERR-FORMAT-PASSWORD'), function (value) {
        return isIncludeLowercase(value);
      })
      .test('isIncludeSpecialChars', t('ERR-FORMAT-PASSWORD'), function (value) {
        return isIncludeSpecialChars(value);
      }),

    newPasswordConfirm: yup
      .string()
      .required(t('ERR-REQUIRED'))
      .min(8, t('ERR-LENGTH-MIN', { min: 8 }))
      .test('isIncludeNumber', t('ERR-FORMAT-PASSWORD'), function (value) {
        return isIncludeNumber(value);
      })
      .test('isIncludeUppercase', t('ERR-FORMAT-PASSWORD'), function (value) {
        return isIncludeUppercase(value);
      })
      .test('isIncludeLowercase', t('ERR-FORMAT-PASSWORD'), function (value) {
        return isIncludeLowercase(value);
      })
      .test('isIncludeSpecialChars', t('ERR-FORMAT-PASSWORD'), function (value) {
        return isIncludeSpecialChars(value);
      })
      .test('matchString', t('ERR-MISMATCH', { target: 'パスワード' }), function (value) {
        return value === this.parent.newPassword;
      }),
  });
};
