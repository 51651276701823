import * as yup from 'yup';
import { isNumber, isAlphanumeric, isRange, isValidDate, isAnotherDate, isDayOfWeek } from 'utils';
import { useTranslation } from 'react-i18next';
import { UNDECIDED_VALUE } from 'constants/index';
import { useIsNotHoliday } from 'hooks/useIsNotHoliday';

export const useValidationSchema = () => {
  const { t } = useTranslation();
  const isNotHoliday = useIsNotHoliday();

  return yup.object().shape({
    webProductionDetail: yup.object().shape({
      productionCompany: yup.string().required(t('ERR-REQUIRED')),

      companyDetail: yup.array(
        yup.object().shape({
          companyName: yup.string().required(t('ERR-REQUIRED')),

          producer: yup.string().required(t('ERR-REQUIRED')),

          producerMailAddress: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup.string().required(t('ERR-REQUIRED')).email(t('ERR-FORMAT-EMAIL'));
          }),
        }),
      ),

      lpPc: yup.array(
        yup.object().shape({
          lpReleaseDateYear: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: value, month: this.parent.lpReleaseDateMonth, date: this.parent.lpReleaseDateDay });
              });
          }),

          lpReleaseDateMonth: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.lpReleaseDateYear, month: value, date: this.parent.lpReleaseDateDay });
              });
          }),

          lpReleaseDateDay: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.lpReleaseDateYear, month: this.parent.lpReleaseDateMonth, date: value });
              });
          }),

          lpReleaseDateHours: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                return isRange({ value, min: 0, max: 23 });
              });
          }),

          lpReleaseDateMinutes: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                return isRange({ value, min: 0, max: 59 });
              });
          }),

          lpReleaseEndDateYear: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: value, month: this.parent.lpReleaseEndDateMonth, date: this.parent.lpReleaseEndDateDay });
              });
          }),

          lpReleaseEndDateMonth: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.lpReleaseEndDateYear, month: value, date: this.parent.lpReleaseEndDateDay });
              });
          }),

          lpReleaseEndDateDay: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.lpReleaseEndDateYear, month: this.parent.lpReleaseEndDateMonth, date: value });
              });
          }),

          lpReleaseEndDateHours: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                return isRange({ value, min: 0, max: 23 });
              });
          }),

          lpReleaseEndDateMinutes: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                return isRange({ value, min: 0, max: 59 });
              });
          }),

          pcUrl: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup.string().required(t('ERR-REQUIRED')).url(t('ERR-FORMAT-URL'));
          }),

          imjManagementNumber: yup.string(),
        }),
      ),

      lpSp: yup.array(
        yup.object().shape({
          lpReleaseDateYear: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: value, month: this.parent.lpReleaseDateMonth, date: this.parent.lpReleaseDateDay });
              });
          }),

          lpReleaseDateMonth: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.lpReleaseDateYear, month: value, date: this.parent.lpReleaseDateDay });
              });
          }),

          lpReleaseDateDay: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.lpReleaseDateYear, month: this.parent.lpReleaseDateMonth, date: value });
              });
          }),

          lpReleaseDateHours: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                return isRange({ value, min: 0, max: 23 });
              });
          }),

          lpReleaseDateMinutes: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                return isRange({ value, min: 0, max: 59 });
              });
          }),

          lpReleaseEndDateYear: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: value, month: this.parent.lpReleaseEndDateMonth, date: this.parent.lpReleaseEndDateDay });
              });
          }),

          lpReleaseEndDateMonth: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.lpReleaseEndDateYear, month: value, date: this.parent.lpReleaseEndDateDay });
              });
          }),

          lpReleaseEndDateDay: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.lpReleaseEndDateYear, month: this.parent.lpReleaseEndDateMonth, date: value });
              });
          }),

          lpReleaseEndDateHours: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                return isRange({ value, min: 0, max: 23 });
              });
          }),

          lpReleaseEndDateMinutes: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                return isRange({ value, min: 0, max: 59 });
              });
          }),

          spUrl: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup.string().required(t('ERR-REQUIRED')).url(t('ERR-FORMAT-URL'));
          }),

          imjManagementNumber: yup.string(),
        }),
      ),

      adhocMail: yup.array(
        yup
          .object()
          .nullable()
          .shape({
            deliveryType: yup.string().required(t('ERR-REQUIRED')),

            adjustmentDesiredConfDate: yup.string().required(t('ERR-REQUIRED')),

            desiredConfDateYear: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '1',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.desiredConfDateMonth, date: this.parent.desiredConfDateDay });
                }),
            }),

            desiredConfDateMonth: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '1',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredConfDateYear, month: value, date: this.parent.desiredConfDateDay });
                }),
            }),

            desiredConfDateDay: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '1',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredConfDateYear, month: this.parent.desiredConfDateMonth, date: value });
                }),
            }),

            desiredDeliveryDate1Year: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.desiredDeliveryDate1Month, date: this.parent.desiredDeliveryDate1Day });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: value,
                    month: this.parent.desiredDeliveryDate1Month,
                    date: this.parent.desiredDeliveryDate1Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: value, month: this.parent.desiredDeliveryDate1Month, date: this.parent.desiredDeliveryDate1Day });
                }),
            }),

            desiredDeliveryDate1Month: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate1Year, month: value, date: this.parent.desiredDeliveryDate1Day });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate1Year,
                    month: value,
                    date: this.parent.desiredDeliveryDate1Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate1Year, month: value, date: this.parent.desiredDeliveryDate1Day });
                }),
            }),

            desiredDeliveryDate1Day: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate1Year, month: this.parent.desiredDeliveryDate1Month, date: value });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate1Year,
                    month: this.parent.desiredDeliveryDate1Month,
                    date: value,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate1Year, month: this.parent.desiredDeliveryDate1Month, date: value });
                }),
            }),

            desiredDeliveryDate2Year: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.desiredDeliveryDate2Month, date: this.parent.desiredDeliveryDate2Day });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: value, month: this.parent.desiredDeliveryDate2Month, date: this.parent.desiredDeliveryDate2Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: value,
                    month: this.parent.desiredDeliveryDate2Month,
                    date: this.parent.desiredDeliveryDate2Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: value, month: this.parent.desiredDeliveryDate2Month, date: this.parent.desiredDeliveryDate2Day });
                }),
            }),

            desiredDeliveryDate2Month: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate2Year, month: value, date: this.parent.desiredDeliveryDate2Day });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate2Year, month: value, date: this.parent.desiredDeliveryDate2Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate2Year,
                    month: value,
                    date: this.parent.desiredDeliveryDate2Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate2Year, month: value, date: this.parent.desiredDeliveryDate2Day });
                }),
            }),

            desiredDeliveryDate2Day: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate2Year, month: this.parent.desiredDeliveryDate2Month, date: value });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate2Year, month: this.parent.desiredDeliveryDate2Month, date: value },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate2Year,
                    month: this.parent.desiredDeliveryDate2Month,
                    date: value,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate2Year, month: this.parent.desiredDeliveryDate2Month, date: value });
                }),
            }),

            desiredDeliveryDate3Year: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.desiredDeliveryDate3Month, date: this.parent.desiredDeliveryDate3Day });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: value, month: this.parent.desiredDeliveryDate3Month, date: this.parent.desiredDeliveryDate3Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第2希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: value, month: this.parent.desiredDeliveryDate3Month, date: this.parent.desiredDeliveryDate3Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate2Year,
                      month: this.parent.desiredDeliveryDate2Month,
                      date: this.parent.desiredDeliveryDate2Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: value,
                    month: this.parent.desiredDeliveryDate3Month,
                    date: this.parent.desiredDeliveryDate3Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: value, month: this.parent.desiredDeliveryDate3Month, date: this.parent.desiredDeliveryDate3Day });
                }),
            }),

            desiredDeliveryDate3Month: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate3Year, month: value, date: this.parent.desiredDeliveryDate3Day });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate3Year, month: value, date: this.parent.desiredDeliveryDate3Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第2希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate3Year, month: value, date: this.parent.desiredDeliveryDate3Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate2Year,
                      month: this.parent.desiredDeliveryDate2Month,
                      date: this.parent.desiredDeliveryDate2Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate3Year,
                    month: value,
                    date: this.parent.desiredDeliveryDate3Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate3Year, month: value, date: this.parent.desiredDeliveryDate3Day });
                }),
            }),

            desiredDeliveryDate3Day: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate3Year, month: this.parent.desiredDeliveryDate3Month, date: value });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate3Year, month: this.parent.desiredDeliveryDate3Month, date: value },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第2希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate3Year, month: this.parent.desiredDeliveryDate3Month, date: value },
                    date2: {
                      year: this.parent.desiredDeliveryDate2Year,
                      month: this.parent.desiredDeliveryDate2Month,
                      date: this.parent.desiredDeliveryDate2Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate3Year,
                    month: this.parent.desiredDeliveryDate3Month,
                    date: value,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate3Year, month: this.parent.desiredDeliveryDate3Month, date: value });
                }),
            }),

            desiredList: yup.string().required(t('ERR-REQUIRED')),

            desiredConditions: yup.string().required(t('ERR-REQUIRED')),

            imjManagementNumber: yup.string(),
          }),
      ),

      crmMail: yup.array(
        yup.object().shape({
          deliveryType: yup.string().required(t('ERR-REQUIRED')),

          desiredStartDateYear: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: value, month: this.parent.desiredStartDateMonth, date: this.parent.desiredStartDateDay });
              })
              .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                return isDayOfWeek({
                  year: value,
                  month: this.parent.desiredStartDateMonth,
                  date: this.parent.desiredStartDateDay,
                });
              })
              .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                return isNotHoliday({ year: value, month: this.parent.desiredStartDateMonth, date: this.parent.desiredStartDateDay });
              });
          }),

          desiredStartDateMonth: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.desiredStartDateYear, month: value, date: this.parent.desiredStartDateDay });
              })
              .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                return isDayOfWeek({
                  year: this.parent.desiredStartDateYear,
                  month: value,
                  date: this.parent.desiredStartDateDay,
                });
              })
              .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                return isNotHoliday({ year: this.parent.desiredStartDateYear, month: value, date: this.parent.desiredStartDateDay });
              });
          }),

          desiredStartDateDay: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.desiredStartDateYear, month: this.parent.desiredStartDateMonth, date: value });
              })
              .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                return isDayOfWeek({
                  year: this.parent.desiredStartDateYear,
                  month: this.parent.desiredStartDateMonth,
                  date: value,
                });
              })
              .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                return isNotHoliday({ year: this.parent.desiredStartDateYear, month: this.parent.desiredStartDateMonth, date: value });
              });
          }),

          desiredConditions: yup.string().required(t('ERR-REQUIRED')),

          imjManagementNumber: yup.string(),
        }),
      ),

      afMail: yup.array(
        yup.object().shape({
          deliveryType: yup.string().required(t('ERR-REQUIRED')),

          desiredStartDateYear: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: value, month: this.parent.desiredStartDateMonth, date: this.parent.desiredStartDateDay });
              })
              .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                return isDayOfWeek({
                  year: value,
                  month: this.parent.desiredStartDateMonth,
                  date: this.parent.desiredStartDateDay,
                });
              })
              .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                return isNotHoliday({ year: value, month: this.parent.desiredStartDateMonth, date: this.parent.desiredStartDateDay });
              });
          }),

          desiredStartDateMonth: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.desiredStartDateYear, month: value, date: this.parent.desiredStartDateDay });
              })
              .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                return isDayOfWeek({
                  year: this.parent.desiredStartDateYear,
                  month: value,
                  date: this.parent.desiredStartDateDay,
                });
              })
              .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                return isNotHoliday({ year: this.parent.desiredStartDateYear, month: value, date: this.parent.desiredStartDateDay });
              });
          }),

          desiredStartDateDay: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup
              .string()
              .required(t('ERR-REQUIRED'))
              .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              })
              .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                return isValidDate({ year: this.parent.desiredStartDateYear, month: this.parent.desiredStartDateMonth, date: value });
              })
              .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                return isDayOfWeek({
                  year: this.parent.desiredStartDateYear,
                  month: this.parent.desiredStartDateMonth,
                  date: value,
                });
              })
              .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                return isNotHoliday({ year: this.parent.desiredStartDateYear, month: this.parent.desiredStartDateMonth, date: value });
              });
          }),

          desiredConditions: yup.string().required(t('ERR-REQUIRED')),

          imjManagementNumber: yup.string(),
        }),
      ),

      favoriteBrandMail: yup.array(
        yup
          .object()
          .nullable()
          .shape({
            deliveryType: yup.string().required(t('ERR-REQUIRED')),

            adjustmentDesiredConfDate: yup.string().required(t('ERR-REQUIRED')),

            desiredConfDateYear: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '1',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.desiredConfDateMonth, date: this.parent.desiredConfDateDay });
                }),
            }),

            desiredConfDateMonth: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '1',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredConfDateYear, month: value, date: this.parent.desiredConfDateDay });
                }),
            }),

            desiredConfDateDay: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '1',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredConfDateYear, month: this.parent.desiredConfDateMonth, date: value });
                }),
            }),

            desiredDeliveryDate1Year: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.desiredDeliveryDate1Month, date: this.parent.desiredDeliveryDate1Day });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: value,
                    month: this.parent.desiredDeliveryDate1Month,
                    date: this.parent.desiredDeliveryDate1Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: value, month: this.parent.desiredDeliveryDate1Month, date: this.parent.desiredDeliveryDate1Day });
                }),
            }),

            desiredDeliveryDate1Month: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate1Year, month: value, date: this.parent.desiredDeliveryDate1Day });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate1Year,
                    month: value,
                    date: this.parent.desiredDeliveryDate1Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate1Year, month: value, date: this.parent.desiredDeliveryDate1Day });
                }),
            }),

            desiredDeliveryDate1Day: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate1Year, month: this.parent.desiredDeliveryDate1Month, date: value });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate1Year,
                    month: this.parent.desiredDeliveryDate1Month,
                    date: value,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate1Year, month: this.parent.desiredDeliveryDate1Month, date: value });
                }),
            }),

            desiredDeliveryDate2Year: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.desiredDeliveryDate2Month, date: this.parent.desiredDeliveryDate2Day });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: value, month: this.parent.desiredDeliveryDate2Month, date: this.parent.desiredDeliveryDate2Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: value,
                    month: this.parent.desiredDeliveryDate2Month,
                    date: this.parent.desiredDeliveryDate2Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: value, month: this.parent.desiredDeliveryDate2Month, date: this.parent.desiredDeliveryDate2Day });
                }),
            }),

            desiredDeliveryDate2Month: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate2Year, month: value, date: this.parent.desiredDeliveryDate2Day });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate2Year, month: value, date: this.parent.desiredDeliveryDate2Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate2Year,
                    month: value,
                    date: this.parent.desiredDeliveryDate2Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate2Year, month: value, date: this.parent.desiredDeliveryDate2Day });
                }),
            }),

            desiredDeliveryDate2Day: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate2Year, month: this.parent.desiredDeliveryDate2Month, date: value });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate2Year, month: this.parent.desiredDeliveryDate2Month, date: value },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate2Year,
                    month: this.parent.desiredDeliveryDate2Month,
                    date: value,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate2Year, month: this.parent.desiredDeliveryDate2Month, date: value });
                }),
            }),

            desiredDeliveryDate3Year: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.desiredDeliveryDate3Month, date: this.parent.desiredDeliveryDate3Day });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: value, month: this.parent.desiredDeliveryDate3Month, date: this.parent.desiredDeliveryDate3Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第2希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: value, month: this.parent.desiredDeliveryDate3Month, date: this.parent.desiredDeliveryDate3Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate2Year,
                      month: this.parent.desiredDeliveryDate2Month,
                      date: this.parent.desiredDeliveryDate2Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: value,
                    month: this.parent.desiredDeliveryDate3Month,
                    date: this.parent.desiredDeliveryDate3Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: value, month: this.parent.desiredDeliveryDate3Month, date: this.parent.desiredDeliveryDate3Day });
                }),
            }),

            desiredDeliveryDate3Month: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate3Year, month: value, date: this.parent.desiredDeliveryDate3Day });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate3Year, month: value, date: this.parent.desiredDeliveryDate3Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第2希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate3Year, month: value, date: this.parent.desiredDeliveryDate3Day },
                    date2: {
                      year: this.parent.desiredDeliveryDate2Year,
                      month: this.parent.desiredDeliveryDate2Month,
                      date: this.parent.desiredDeliveryDate2Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate3Year,
                    month: value,
                    date: this.parent.desiredDeliveryDate3Day,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate3Year, month: value, date: this.parent.desiredDeliveryDate3Day });
                }),
            }),

            desiredDeliveryDate3Day: yup.string().when('adjustmentDesiredConfDate', {
              is: (value: string) => value === '2',
              then: yup
                .string()
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.desiredDeliveryDate3Year, month: this.parent.desiredDeliveryDate3Month, date: value });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第1希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate3Year, month: this.parent.desiredDeliveryDate3Month, date: value },
                    date2: {
                      year: this.parent.desiredDeliveryDate1Year,
                      month: this.parent.desiredDeliveryDate1Month,
                      date: this.parent.desiredDeliveryDate1Day,
                    },
                  });
                })
                .test('isAnotherDate', t('ERR-SAME-DATE', { target: '第2希望日' }), function (value) {
                  return isAnotherDate({
                    date1: { year: this.parent.desiredDeliveryDate3Year, month: this.parent.desiredDeliveryDate3Month, date: value },
                    date2: {
                      year: this.parent.desiredDeliveryDate2Year,
                      month: this.parent.desiredDeliveryDate2Month,
                      date: this.parent.desiredDeliveryDate2Day,
                    },
                  });
                })
                .test('isDayOfWeek', t('ERR-WEEKDAYS'), function (value) {
                  return isDayOfWeek({
                    year: this.parent.desiredDeliveryDate3Year,
                    month: this.parent.desiredDeliveryDate3Month,
                    date: value,
                  });
                })
                .test('isNotHoliday', t('ERR-WEEKDAYS'), function (value) {
                  return isNotHoliday({ year: this.parent.desiredDeliveryDate3Year, month: this.parent.desiredDeliveryDate3Month, date: value });
                }),
            }),

            desiredList: yup.string().required(t('ERR-REQUIRED')),

            desiredConditions: yup.string().required(t('ERR-REQUIRED')),

            imjManagementNumber: yup.string(),
          }),
      ),

      freeArea: yup.array(
        yup
          .object()
          .nullable()
          .shape({
            postingArea: yup.string().required(t('ERR-REQUIRED')),

            postingCode: yup.mixed().when('postingArea', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      postingPool: yup.lazy((value: string) => {
                        if (value === UNDECIDED_VALUE) return yup.string();
                        return yup
                          .string()
                          .required(t('ERR-REQUIRED'))
                          .test('isAlphanumeric', t('ERR-TYPE-ALPHANUMERIC'), function (value) {
                            return isAlphanumeric(value);
                          })
                          .length(6, t('ERR-LENGTH-ALPHANUMERIC', { length: 6 }));
                      }),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            postingSelectedBrandNum: yup.mixed().when('postingArea', {
              is: (value: string) => value === '3',
              then: yup.lazy((value: any) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      postingSelectedBrands: yup.string(),
                      postingSelectedBrandsName: yup.string(),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            postingEndConditions: yup.string().required(t('ERR-REQUIRED')),

            postingEndDateYear: yup.string().when('postingEndConditions', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: value, month: this.parent.postingEndDateMonth, date: this.parent.postingEndDateDay });
                  });
              }),
            }),

            postingEndDateMonth: yup.string().when('postingEndConditions', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: this.parent.postingEndDateYear, month: value, date: this.parent.postingEndDateDay });
                  });
              }),
            }),

            postingEndDateDay: yup.string().when('postingEndConditions', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: this.parent.postingEndDateYear, month: this.parent.postingEndDateMonth, date: value });
                  });
              }),
            }),

            imjManagementNumber: yup.string(),
          }),
      ),

      bigBanner: yup.array(
        yup.object().shape({
          pcUrl: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup.string().required(t('ERR-REQUIRED')).url(t('ERR-FORMAT-URL'));
          }),

          spUrl: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup.string().required(t('ERR-REQUIRED')).url(t('ERR-FORMAT-URL'));
          }),

          imjManagementNumber: yup.string(),
        }),
      ),

      purposeBanner: yup.array(
        yup.object().shape({
          pcUrl: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup.string().required(t('ERR-REQUIRED')).url(t('ERR-FORMAT-URL'));
          }),

          spUrl: yup.lazy((value: string) => {
            if (value === UNDECIDED_VALUE) return yup.string();
            return yup.string().required(t('ERR-REQUIRED')).url(t('ERR-FORMAT-URL'));
          }),

          imjManagementNumber: yup.string(),
        }),
      ),

      myPageBanner: yup.array(
        yup.object().shape({
          imjManagementNumber: yup.string(),
        }),
      ),

      imageBanner: yup.string(),

      externalBanner: yup.string(),

      cartImage: yup.array(
        yup.object().shape({
          imjManagementNumber: yup.string(),
        }),
      ),

      giftSetImage: yup.string(),

      lineMessage: yup.string(),

      lineCarousel: yup.string(),

      lineHome: yup.string(),

      lineRich: yup.string(),

      lineRichSplit: yup.string(),

      twitterBanner: yup.string(),

      rToasterBanner: yup.string(),

      colorSimulator: yup.string(),

      otherBanner: yup.array(
        yup.object().shape({
          productContent: yup.string().required(t('ERR-REQUIRED')),

          otherDetail: yup.string().when('productContent', {
            is: (value: string) => value === '15',
            then: yup.string(),
          }),

          imjManagementNumber: yup.string(),
        }),
      ),
    }),
  });
};
