type RadioListProps = {
  label: string;
  value: string;
};

type ConstProps = {
  [key: string]: string;
};

export const objectToRadioList = (obj: ConstProps, target?: string[]): RadioListProps[] => {
  if (target) {
    return Object.entries(obj)
      .filter(([key, value]) => target.includes(key))
      .map(([key, value]) => {
        return {
          label: value,
          value: key,
        };
      });
  }
  return Object.entries(obj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });
};
