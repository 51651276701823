import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsconfig from 'aws-exports';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, Box, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { FormTextInput } from 'components/Business/Form';
import { LoginSessionErrorModal } from 'components/Business/LoginSessionErrorModal';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { RHF_SETTINGS } from 'constants/index';
import { TempCognitoUserState, NetworkErrorUpdate } from 'context';
import { scrollToFirstError } from 'utils';
import { useValidationSchema } from './useValidationSchema';

Amplify.configure(awsconfig);

export const CMN013: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { t } = useTranslation();
  const tempCognitoUser = React.useContext(TempCognitoUserState);
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, handleSubmit, setError } = useFormMethods;
  const [isLoading, setLoading] = React.useState(false);
  const [isSessionError, setSessionError] = React.useState(false);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    const data = {
      verificationCode: formValues.verificationCode || '',
    };
    return data;
  }, [getValues]);

  const confirmSignIn = React.useCallback(async () => {
    setLoading(true);
    const data = getRequestData();
    if (!tempCognitoUser) return;
    try {
      const user = await Amplify.Auth.confirmSignIn(
        tempCognitoUser, // signInの返り値のuserData
        data.verificationCode, // 認証コード
        tempCognitoUser.challengeName, // 自身が設定している認証設定
      );
      console.log(user);
      history.push('/Login', state);
    } catch (error) {
      console.error(error);
      if (error.code === 'CodeMismatchException' || error.code === 'EnableSoftwareTokenMFAException') {
        setError('verificationCode', {
          type: 'custom',
          message: t('ERR-MISMATCH', { target: 'ワンタイムパスワード' }),
        });
        scrollToFirstError();
      } else if (error.code === 'NotAuthorizedException') {
        setSessionError(true);
      } else {
        setNetworkError(true);
      }
      setLoading(false);
    }
  }, [getRequestData, setError, t, tempCognitoUser, setNetworkError, history, state]);

  const onSuccess = React.useCallback(() => {
    confirmSignIn();
  }, [confirmSignIn]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  return (
    <>
      <LoginSessionErrorModal isShow={isSessionError} />
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />
          <Container type="login">
            <Section>
              <H1>ワンタイムパスワード入力</H1>
              <Text align="center">初回ログイン時に登録した端末でワンタイムパスワードを確認し、入力してください。</Text>

              <FormTextInput type="number" name="verificationCode" label="ワンタイムパスワード" maxLength={6} size="medium" inputSize="large" />

              <ButtonContainer>
                <Button type="primary" text="ログイン" onClick={() => handleSubmit(onSuccess, onError)()} />
              </ButtonContainer>

              <Box color="grey" mt={40}>
                <Text size={18} bold>
                  パソコンで登録された方
                </Text>
                <Text mt={0}>ブラウザ（EdgeまたはChrome）の拡張機能を開き、表示されているワンタイムパスワード（数字6桁）を入力してください。</Text>

                <Text size={18} bold>
                  スマートフォンで登録された方
                </Text>
                <Text mt={0}>GoogleAuthenticatorまたはAuthyを起動して、表示されているワンタイムパスワード（数字6桁）を入力してください。</Text>
              </Box>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
