import React from 'react';
import { Collapse } from 'react-collapse';
import { NestBox } from 'components/Atoms/Layout';
import style from './style.module.scss';

type Props = {
  children: React.ReactNode;
  isShow: boolean;
  className?: string;
  unmountOnClosed?: boolean;
};

export const AccordionContent: React.FC<Props> = ({ children, isShow = false, className = '', unmountOnClosed = false }) => {
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (isShow) {
      setTimeout(() => setOpen(true), 0);
    } else {
      setOpen(false);
    }
  }, [isShow]);

  return React.useMemo(
    () => (
      <>
        {!unmountOnClosed ? (
          <Collapse isOpened={isOpen}>
            <NestBox className={`${style['accordion-content']} ${className}`}>{children}</NestBox>
          </Collapse>
        ) : (
          <>
            {isOpen && (
              <>
                <NestBox className={`${style['accordion-content']} ${className}`}>{children}</NestBox>
              </>
            )}
          </>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );
};
