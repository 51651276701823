import { DATETIME_FORMAT_DISPLAY } from 'constants/index';
import dayjs from 'dayjs';

export const formatDate = (dateString: string, format: string = DATETIME_FORMAT_DISPLAY): string => {
  const dateObject = dayjs(dateString);
  if (dateObject.isValid()) {
    return dateObject.format(format);
  }
  return '';
};
