import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isNumber, isAlphanumeric, isValidDate } from 'utils';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    selectKeyWord: yup.string(),

    keyWord: yup.string().when('selectKeyWord', {
      is: (value: string) => value === '3' || value === '4',
      then: yup.string().test('isAlphanumeric', t('ERR-TYPE-ALPHANUMERIC'), function (value) {
        return isAlphanumeric(value);
      }),
    }),

    ylcDeliveryDateFromYear: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: value, month: this.parent.ylcDeliveryDateFromMonth, date: this.parent.ylcDeliveryDateFromDay });
      }),

    ylcDeliveryDateFromMonth: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: this.parent.ylcDeliveryDateFromYear, month: value, date: this.parent.ylcDeliveryDateFromDay });
      }),

    ylcDeliveryDateFromDay: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: this.parent.ylcDeliveryDateFromYear, month: this.parent.ylcDeliveryDateFromMonth, date: value });
      }),

    ylcDeliveryDateToYear: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: value, month: this.parent.ylcDeliveryDateToMonth, date: this.parent.ylcDeliveryDateToDay });
      }),

    ylcDeliveryDateToMonth: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: this.parent.ylcDeliveryDateToYear, month: value, date: this.parent.ylcDeliveryDateToDay });
      }),

    ylcDeliveryDateToDay: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: this.parent.ylcDeliveryDateToYear, month: this.parent.ylcDeliveryDateToMonth, date: value });
      }),

    brandCode: yup.string(),

    inCharge: yup.string(),

    measuresDateStartFromYear: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: value, month: this.parent.measuresDateStartFromMonth, date: this.parent.measuresDateStartFromDay });
      }),

    measuresDateStartFromMonth: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: this.parent.measuresDateStartFromYear, month: value, date: this.parent.measuresDateStartFromDay });
      }),

    measuresDateStartFromDay: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: this.parent.measuresDateStartFromYear, month: this.parent.measuresDateStartFromMonth, date: value });
      }),

    measuresDateStartToYear: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: value, month: this.parent.measuresDateStartToMonth, date: this.parent.measuresDateStartToDay });
      }),

    measuresDateStartToMonth: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: this.parent.measuresDateStartToYear, month: value, date: this.parent.measuresDateStartToDay });
      }),

    measuresDateStartToDay: yup
      .string()
      .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
        return isNumber(value);
      })
      .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
        return isValidDate({ year: this.parent.measuresDateStartToYear, month: this.parent.measuresDateStartToMonth, date: value });
      }),

    osWorkRequestYes: yup.string(),

    osWorkRequestNo: yup.string(),
  });
};
