import React from 'react';
import style from './style.module.scss';

const Gap = {
  large: 'large',
  medium: 'medium',
  small: 'small',
} as const;
type GapProps = typeof Gap[keyof typeof Gap];

type Props = {
  isVertical?: boolean;
  isLeft?: boolean;
  gap?: GapProps;
  className?: string;
  mt?: number;
};

export const ButtonContainer: React.FC<Props> = ({ children, isVertical, isLeft, gap = 'medium', className = '', mt }) => (
  <div
    className={`${style['layout-buttonContainer']} ${isVertical ? 'is-vertical' : ''} ${isLeft ? 'is-left' : ''} is-gap-${gap} ${className}`}
    style={{ ...(mt !== undefined && { marginTop: `${mt}px` }) }}
  >
    {children}
  </div>
);
