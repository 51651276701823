import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from 'aws-exports';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { FormTextInput } from 'components/Business/Form';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { RHF_SETTINGS } from 'constants/index';
import { NetworkErrorUpdate } from 'context';
import { scrollToFirstError } from 'utils';
import { useValidationSchema } from './useValidationSchema';
import type { StateProps } from 'components/Pages/CMN003';

Amplify.configure(awsconfig);

export const CMN004: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<StateProps>();
  const { t } = useTranslation();
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, handleSubmit, setError } = useFormMethods;
  const [isLoading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState('');

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    const data = {
      verificationCode: formValues.verificationCode || '',
      newPassword: formValues.newPassword || '',
      newPasswordConfirm: formValues.newPasswordConfirm || '',
    };
    return data;
  }, [getValues]);

  const forgotPasswordSubmit = React.useCallback(async () => {
    setLoading(true);
    const data = getRequestData();
    try {
      await Auth.forgotPasswordSubmit(username, data.verificationCode, data.newPassword);
      history.push('/CMN006');
    } catch (error) {
      console.error(error);
      if (error.code === 'CodeMismatchException') {
        // 認証コードが一致しない
        setError('verificationCode', {
          type: 'custom',
          message: t('ERR-MISMATCH', { target: '認証コード' }),
        });
      } else if (error.code === 'ExpiredCodeException') {
        // 認証コードの期限切れ
        setError('verificationCode', {
          type: 'custom',
          message: t('ERR-EXPIRED-VERIFICATION-CODE'),
        });
      } else if (error.code === 'InvalidPasswordException') {
        // パスワードが数字、特殊文字、大文字、小文字を含まない
        setError('newPassword', {
          type: 'custom',
          message: t('ERR-FORMAT-PASSWORD'),
        });
      } else if (error.code === 'InvalidParameterException') {
        // 最小長未満
        setError('newPassword', {
          type: 'custom',
          message: t('ERR-LENGTH-MIN', { min: 8 }),
        });
      } else {
        setNetworkError(true);
      }

      scrollToFirstError();
      setLoading(false);
    }
  }, [getRequestData, setError, t, history, username, setNetworkError]);

  const onSuccess = React.useCallback(() => {
    forgotPasswordSubmit();
  }, [forgotPasswordSubmit]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  React.useEffect(() => {
    if (!state?.username) {
      setNetworkError(true);
      return;
    }
    setUsername(state.username);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.username]);

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />
          <Container type="login">
            <Section>
              <H1>認証コード入力・パスワード再登録</H1>
              <Text align="center">登録されたメールアドレスに送信された認証コードを入力してください。</Text>

              <FormTextInput name="verificationCode" label="認証コード" size="medium" inputSize="large" />

              <Text align="center" mt={50}>
                パスワードを再登録してください。
              </Text>

              <FormTextInput
                type="password"
                name="newPassword"
                label="再登録用パスワード"
                note="※大文字、小文字、数字、記号を全て使用し、8字以上で入力してください。"
                size="medium"
                inputSize="large"
              />

              <FormTextInput type="password" name="newPasswordConfirm" label="再登録用パスワード（確認用）" size="medium" inputSize="large" />

              <ButtonContainer>
                <Button type="primary" text="登録" onClick={() => handleSubmit(onSuccess, onError)()} />
              </ButtonContainer>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
