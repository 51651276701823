import React from 'react';
import { Flex } from 'components/Atoms/Layout';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { PageTop } from 'components/Organisms/PageTop';
import style from './style.module.scss';
import { ReactComponent as LogoWatashiPlus } from 'images/logo_watashiplus.svg';
import { ReactComponent as LogoImj } from 'images/logo_imj.svg';

const Type = {
  basic: 'basic',
  top: 'top',
} as const;
type TypeProps = typeof Type[keyof typeof Type];

type Props = {
  type?: TypeProps;
  hasPageTop?: boolean;
  mb?: number;
};

export const Footer: React.FC<Props> = ({ type = 'basic', hasPageTop = true, mb }) => {
  return React.useMemo(
    () => (
      <footer className={`${style.footer} type-${type}`} style={{ ...(mb !== undefined && { marginBottom: `${mb}px` }) }}>
        <Flex alignItems="center" justifyContent="center" className={style['footer-inner']}>
          <LogoWatashiPlus width="99px" className={style['footer-logo-watashiplus']} />
          <SvgIcon type="cross" fill="#141414" width={22} className={style['footer-icon-cross']} />
          <LogoImj width="156px" className={style['footer-logo-imj']} />
        </Flex>

        {hasPageTop && <PageTop />}
      </footer>
    ),
    [type, hasPageTop, mb],
  );
};
