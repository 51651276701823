import React from 'react';
import style from './style.module.scss';

const Positions = {
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
} as const;
export type TooltipPositionsProps = typeof Positions[keyof typeof Positions];

type Props = {
  position: TooltipPositionsProps;
  text: string;
  className?: string;
  isShow: boolean;
};

export const Tooltip: React.FC<Props> = ({ children, position, text, isShow = false }) =>
  React.useMemo(
    () => (
      <div className={style.tooltip}>
        <span className={style['tooltip-icon']}>?</span>
        {isShow && (
          <div className={`${style['tooltip-balloon']} ${style['tooltip-balloon-' + position]}`}>
            <p>{text}</p>
          </div>
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isShow],
  );
