import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { Flex, FormGrid, FormField } from 'components/Atoms/Layout';
import { Note, ErrorText, Unit } from 'components/Atoms/Typography';
import { NoteList } from 'components/Atoms/List';
import { TextInput } from 'components/Atoms/Form';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { UndecidedButton } from 'components/Molecules/UndecidedButton';
import { UNDECIDED_VALUE, RHF_UNREGISTER_PARAMS } from 'constants/index';
import style from './style.module.scss';
import type { TextInputTypesProps } from 'components/Atoms/Form/TextInput';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';
import type { TextInputSizeProps } from 'components/Atoms/Form/TextInput';

type Props = {
  name: string;
  type?: TextInputTypesProps;
  label?: string;
  unit?: string;
  placeholder?: string;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  isRequired?: boolean;
  disabled?: boolean;
  maxLength?: number;
  help?: string;
  hasUndecidedButton?: boolean;
  defaultValue?: string;
  onChange?: (value: string, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  size?: FormGridSizeProps;
  inputSize?: TextInputSizeProps;
  formFieldClassName?: string;
};

export const FormTextInput: React.FC<Props> = ({
  name,
  type = 'text',
  label = '',
  unit,
  placeholder = '',
  note,
  notes,
  isRequired = false,
  maxLength,
  help,
  hasUndecidedButton = false,
  defaultValue = '',
  onChange = () => {},
  onBlur = () => {},
  size = 'large',
  inputSize = 'medium',
  formFieldClassName = '',
}) => {
  const { control, trigger, unregister } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, defaultValue, shouldUnregister: true });
  const [undecided, setUndecided] = React.useState(false);
  const [backupValue, setBackupValue] = React.useState('');

  const componentWillUnmount = React.useCallback(() => {
    console.log(`unregister: ${name}`);
    unregister(name, RHF_UNREGISTER_PARAMS);
  }, [name, unregister]);

  const handleChange = React.useCallback(
    (value, name) => {
      field.onChange(value);
      onChange(value, name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onChange, onChange],
  );

  const handleBlur = React.useCallback(
    (value, name) => {
      field.onBlur();
      onBlur(value, name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onBlur, onBlur],
  );

  const handleUndecided = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setBackupValue(field.value);
        field.onChange(UNDECIDED_VALUE);
        trigger(name); // 初回なぜかバリデーションされないため
        return;
      }
      field.onChange(backupValue);
      setBackupValue('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.value, backupValue, setBackupValue],
  );

  React.useEffect(() => {
    setUndecided(field.value === UNDECIDED_VALUE);
  }, [field.value]);

  React.useEffect(() => {
    return () => componentWillUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormGrid className={style['form-textinput']} size={size}>
      <FormLabelSet label={label} isRequired={isRequired} help={help} size={size} />

      <FormField className={formFieldClassName}>
        <Flex alignItems="center">
          <TextInput
            name={field.name}
            value={field.value}
            refs={field.ref}
            onChange={handleChange}
            onBlur={handleBlur}
            className={undecided ? 'is-undecided' : ''}
            isInvalid={error ? true : false}
            disabled={undecided}
            type={type}
            placeholder={placeholder}
            maxLength={maxLength}
            size={inputSize}
          />
          {unit && <Unit text={unit} />}
        </Flex>
        <ErrorText error={error?.message} />
        {note && <Note>{note}</Note>}
        {notes && <NoteList list={notes}></NoteList>}
      </FormField>

      <UndecidedButton name={name} hasUndecidedButton={hasUndecidedButton} undecided={undecided} onChangeDecided={handleUndecided} />
    </FormGrid>
  );
};
