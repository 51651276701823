import React from 'react';
import { Radio } from 'components/Atoms/Form';
import style from './style.module.scss';

export type SelectListProps = {
  label: string;
  value: string;
  code?: string;
};

type Props = {
  name: string;
  value: string;
  onChange: (value: string) => void;
  list: Array<SelectListProps>;
  className?: string;
};

export const SelectList: React.FC<Props> = ({ name, value, onChange, list, className }) => {
  const handleChange = React.useCallback(
    (value) => {
      onChange(value);
    },
    [onChange],
  );

  return React.useMemo(
    () => (
      <div className={`${style.selectList} ${className}`}>
        {list.map((elem, index) => (
          <Radio
            key={`${name}-${index}`}
            name={name}
            value={elem.value}
            label={elem.label}
            checked={value === elem.value}
            className={`${style['selectList-item']}`}
            onChange={handleChange}
            isPlain
          />
        ))}
      </div>
    ),
    [className, list, name, value, handleChange],
  );
};
