import React from 'react';
import { FormSelectMenuMultiple } from 'components/Business/Form/FormSelectMenuMultiple';
import { ENDPOINT_GET_ITEM_API } from 'constants/index';
import { useApiCall } from 'hooks/useApiCall';
import type { RadioListProps } from 'components/Atoms/Form/RadioList';
import type { ItemProps } from 'types';

type Props = {
  name: string;
  valueName: string;
  labelName: string;
  isRequired?: boolean;
  hasUndecidedButton?: boolean;
};

export const ApiSelectMultipleItem: React.FC<Props> = ({ name, valueName, labelName, isRequired = false, hasUndecidedButton = false }) => {
  const apiCall = useApiCall();
  const [data, setData] = React.useState<RadioListProps[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    setIsLoading(true);

    (async () => {
      apiCall({
        method: 'GET',
        url: ENDPOINT_GET_ITEM_API,
      })
        .then((res) => {
          if (res.status === 200) {
            const returnValue = res.data.result.itemList.map((elem: ItemProps) => {
              return {
                label: elem.itemName,
                value: elem.itemCode,
              };
            });
            if (!unmounted) setData(returnValue);
          }
        })
        .finally(() => {
          if (!unmounted) setIsLoading(false);
        });
    })();

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormSelectMenuMultiple
      name={name}
      valueName={valueName}
      labelName={labelName}
      data={data}
      label="指定アイテム名"
      isRequired={isRequired}
      hasUndecidedButton={hasUndecidedButton}
      isLoading={isLoading}
      searchable
    />
  );
};
