import React from 'react';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';

type Props = {
  className?: string;
  isActive: boolean;
  isInvalid?: boolean;
  onClick: () => void;
};

export const Tab: React.FC<Props> = ({ children, className = '', isActive, isInvalid, onClick = () => {} }) => {
  return React.useMemo(
    () => (
      <li className={`${style['tabmenu-tab']} ${isActive ? 'is-active' : ''} ${isInvalid ? 'is-invalid-tab' : ''} ${className}`} onClick={onClick}>
        {children}
        {isInvalid && <SvgIcon type="warn" width={18} className={style['tabmenu-tab-icon-invalid']} />}
      </li>
    ),
    [children, className, isActive, isInvalid, onClick],
  );
};
