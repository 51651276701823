import dayjs from 'dayjs';

type Props = {
  futureYear?: string;
  futureMonth?: string;
  futureDate?: string;
  pastYear?: string;
  pastMonth?: string;
  pastDate?: string;
};

export const isFutureDateCompared = ({ futureYear, futureMonth, futureDate, pastYear, pastMonth, pastDate }: Props) => {
  if (!futureYear || !futureMonth || !futureDate || !pastYear || !pastMonth || !pastDate) return true;

  const futureDateObject = dayjs()
    .year(Number(futureYear))
    .month(Number(futureMonth) - 1)
    .date(Number(futureDate))
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  const pastDateObject = dayjs()
    .year(Number(pastYear))
    .month(Number(pastMonth) - 1)
    .date(Number(pastDate))
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  if (pastDateObject.isSameOrAfter(futureDateObject)) return false;
  return true;
};
