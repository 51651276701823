import React from 'react';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';

type Props = {
  isLoading: boolean;
};

export const Loading: React.FC<Props> = ({ isLoading }) =>
  React.useMemo(
    () => (
      <>
        {isLoading && (
          <div className={style['loading']}>
            <div className={style['loading-inner']}>
              <SvgIcon type="loading" width={70} className={style['loading-icon']} />
              <p className={style['loading-text']}>Loading...</p>
            </div>
          </div>
        )}
      </>
    ),
    [isLoading],
  );
