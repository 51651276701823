import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constants/index';

type Props = {
  year?: string;
  month?: string;
  date?: string;
};

export const isValidDate = ({ year, month, date }: Props) => {
  if (!year && !month && !date) return true;
  const dayJsObject = dayjs()
    .year(Number(year))
    .month(Number(month) - 1)
    .date(Number(date))
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  if (!dayJsObject.isValid()) return false;
  const yearValue = year;
  const monthValue = month?.padStart(2, '0');
  const dateValue = date?.padStart(2, '0');
  const formattedDateFromDayjs = dayJsObject.format(DATE_FORMAT);
  const formattedDateFromConcat = `${yearValue}-${monthValue}-${dateValue}`;
  return formattedDateFromDayjs === formattedDateFromConcat;
};
