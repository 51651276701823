import React from 'react';
import { Flex } from 'components/Atoms/Layout';
import { Text } from 'components/Atoms/Typography';
import { Loading } from 'components/Atoms/Loading';
import { BorderList } from 'components/Atoms/List';
import { Modal } from 'components/Organisms/Modal';
import { useApiCall } from 'hooks/useApiCall';
import { ENDPOINT_GET_BRAND_API } from 'constants/index';
import type { BrandProps } from 'types';
import type { SelectMenuListProps } from 'components/Atoms/Form/SelectMenu';

type Props = {
  isShow: boolean;
  onClose: () => void;
};

export const BrandListModal: React.FC<Props> = ({ isShow, onClose }) => {
  const apiCall = useApiCall();
  const [brands, setBrands] = React.useState<SelectMenuListProps[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getBrands = React.useCallback(async () => {
    setIsLoading(true);
    apiCall({
      method: 'GET',
      url: ENDPOINT_GET_BRAND_API,
    })
      .then((res: { status: number; data: { result: { brandList: BrandProps[] } } }) => {
        if (res.status === 200) {
          const brandList: BrandProps[] = res.data.result.brandList;
          const result =
            brandList.map((elem: BrandProps) => {
              return {
                label: elem.brandName,
                value: elem.brandCode,
              };
            }) || [];
          setBrands(result);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [apiCall]);

  React.useEffect(() => {
    if (isShow) {
      getBrands();
    } else {
      setBrands([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  return React.useMemo(
    () => (
      <>
        <Loading isLoading={isLoading} />
        <Modal isShow={isShow} hasCancel onSubmit={onClose} onCancel={onClose}>
          <Text size={20} bold align="center">
            登録可能なブランド略称一覧
          </Text>
          <Text align="center">下記のブランド略称をご確認の上、施策名に入力してください。</Text>
          <BorderList split={4} mt={20}>
            {brands?.map((elem, index) => (
              <div key={index}>
                <Flex justifyContent="space-between" alignItems="center" height="100%">
                  <Text size={14}>{elem.label}</Text>
                </Flex>
              </div>
            ))}
          </BorderList>
        </Modal>
      </>
    ),
    [isShow, onClose, isLoading, brands],
  );
};
