import React from 'react';
import { FormTextInput } from 'components/Business/Form';
import { CampaignOptionsEntry } from '../CampaignOptionsEntry';

type Props = {
  k: number;
};

export const CampaignOptionsEntryAndRepeatPurChase: React.FC<Props> = ({ k }) => (
  <>
    <CampaignOptionsEntry k={k} />

    <FormTextInput
      name={`campaignDetail[${k}].purchaseAchievementCount`}
      type="number"
      label="継続購入達成回数"
      placeholder="10"
      unit="回"
      isRequired
      hasUndecidedButton
    />
  </>
);
