import React from 'react';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';

type Props = {
  className?: string;
  isBottom?: boolean;
};

export const TabList: React.FC<Props> = ({ children, className = '', isBottom = false }) => {
  const ref = React.useRef<HTMLUListElement>(null);
  const [intervalId, setIntervalId] = React.useState<number>();
  const [positionX, setPositionX] = React.useState<number>();
  const [isShowScrollButton, setShowScrollButton] = React.useState(false);

  const startScrollLeft = React.useCallback(
    (e) => {
      if (e.nativeEvent.which !== 1) return;
      const id = window.setInterval(() => {
        if (!ref.current) return;
        setPositionX(ref.current.scrollLeft - 10);
      }, 20);
      setIntervalId(id);
    },
    [setIntervalId],
  );

  const endScrollLeft = React.useCallback(() => {
    clearInterval(intervalId);
  }, [intervalId]);

  const startScrollRight = React.useCallback(
    (e) => {
      if (e.nativeEvent.which !== 1) return;

      if (!ref.current) return;
      // 一番右側までスクロールしている状態でタブ数を増やした直後に右側にスクロールできない問題の対応
      setPositionX(ref?.current.scrollLeft - 1);

      const id = window.setInterval(() => {
        if (!ref.current) return;
        setPositionX(ref.current.scrollLeft + 10);
      }, 20);
      setIntervalId(id);
    },
    [setIntervalId],
  );

  const endScrollRight = React.useCallback(() => {
    clearInterval(intervalId);
  }, [intervalId]);

  React.useEffect(() => {
    if (!ref.current) return;
    if (positionX === undefined) return;
    ref.current.scrollLeft = positionX;
  }, [positionX]);

  React.useEffect(() => {
    if (ref.current?.clientWidth && ref.current.clientWidth >= 708) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.clientWidth]);

  return React.useMemo(() => {
    return (
      <div className={`${style['tabmenu-tablist']} ${isBottom ? 'is-bottom' : ''} ${className}`}>
        <ul className={`${style['tabmenu-tablist-inner']}`} ref={ref}>
          {children}
        </ul>
        <div className={`${style['tabmenu-tablist-button-wrapper']} ${isShowScrollButton ? 'is-show' : ''}`}>
          <button onMouseDown={startScrollLeft} onMouseUp={endScrollLeft} className={style['tabmenu-tablist-button-scroll']}>
            <SvgIcon type="triangle-left" fill="#fff" width={8} />
          </button>
          <button onMouseDown={startScrollRight} onMouseUp={endScrollRight} className={style['tabmenu-tablist-button-scroll']}>
            <SvgIcon type="triangle-right" fill="#fff" width={8} />
          </button>
        </div>
      </div>
    );
  }, [children, endScrollLeft, endScrollRight, isBottom, startScrollLeft, startScrollRight, isShowScrollButton, className]);
};
