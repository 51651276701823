const Easing = {
  linear: 'linear',
  easeInOutCirc: 'easeInOutCirc',
  easeInOutBack: 'easeInOutBack',
} as const;
export type EasingProps = typeof Easing[keyof typeof Easing];

export const easings = {
  linear: (x: number): number => {
    return x;
  },

  easeInOutCirc: (x: number): number => {
    return x < 0.5 ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2 : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
  },

  easeInOutBack: (x: number): number => {
    const c1 = 1.70158;
    const c2 = c1 * 1.525;
    return x < 0.5 ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2 : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
  },
};
