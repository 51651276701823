import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    verificationCode: yup
      .string()
      .required(t('ERR-REQUIRED'))
      .required(t('ERR-REQUIRED'))
      .length(6, t('ERR-LENGTH-NUMBER', { length: 6 })),
  });
};
