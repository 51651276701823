import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'messages/i18n';
import 'styles/index.scss';
import { Provider } from 'context';
import { PrivateRoutes } from 'router/PrivateRoutes';
import { GuestRoutes } from 'router/GuestRoutes';
import { Authorization } from 'components/Business/Authorization';
import { NetworkErrorModal } from 'components/Business/NetworkErrorModal';
import { BeforeUnload } from 'components/Business/BeforeUnload';

process.env.NODE_ENV !== 'development' && (console.log = () => {});

const App: React.FC = () => {
  return (
    <>
      <Router>
        <Authorization privateRoutes={<PrivateRoutes />} guestRoutes={<GuestRoutes />} />
        <BeforeUnload />
      </Router>

      <NetworkErrorModal />
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
