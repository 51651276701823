import React from 'react';
import { useParams } from 'react-router-dom';
import { Main, Container, Section, SectionInner, ButtonContainer, PositionContainer, PositionItem, Flex, FlexRow, FlexCol } from 'components/Atoms/Layout';
import { H1, H2, H3, H4, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { LinkList } from 'components/Atoms/List';
import { Box } from 'components/Atoms/Layout/Box';
import { Header } from 'components/Business/Header';
import { CampaignStatusTag } from 'components/Molecules/Tag';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { DataTable } from 'components/Organisms/DataTable';
import { TabMenu } from 'components/Organisms/TabMenu';
import { ApiGetMeasuresDetailProvider } from 'components/Business/Api';
import {
  NEWITEM_VALUE,
  ENFORCEMENTDIVISIONS_VALUES,
  CAMPAIGNPURCHASETYPE_LABELS,
  MEMBERTYPE_VALUES,
  APPLICATIONSTYPE_VALUES,
  MEMBERLIST_VALUES,
  APPLICATIONSMEMBERBLAND_VALUES,
  OPTIONTYPE_VALUES,
  MYPAGEENDCONDITIONS_VALUES,
  ENTRYUPPERLIMITSETTING_VALUES,
  BEFOREENTRYSHOW_VALUES,
  ENTRYMAILPERMISSION_VALUES,
  ENTRYLINECONNECT_VALUES,
  AMOUNTSPECIFICATION_VALUES,
  AMOUNTSPECIFICATIONSETTING_VALUES,
  APPLICATIONSCONDITIONS_VALUES,
  SKUSPECIFIEDCONDITION_VALUES,
  POOLSPECIFIEDCONDITION_VALUES,
  PURCHSESPECIFIEDCONDITION_VALUES,
  PURCHASESPECIFIEDCONDITIONSITEM_VALUES,
  MEMBERLOWERRANK_VALUES,
  MEMBERHIGHERRANK_VALUES,
  INFLOWROUTECOUPON_VALUES,
  URLLINECONNECT_VALUES,
  BENEFITTYPE_VALUES,
  SAMPLEPRESENT_VALUES,
  PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES,
  COUPONTYPE_VALUES,
  COUPONEXPIRATIONDATE_VALUES,
  COUPONCOUPONCONDITIONSUSE_VALUES,
  USECOUPONSKUSPECIFIEDCONDITIONS_VALUES,
  USECOUPONPOOLSPECIFIEDCONDITIONS_VALUES,
  USECOUPONBRANDSPECIFIEDCONDITIONS_VALUES,
  USECOUPONBRANDBRANDCONDITIONS_VALUES,
  USECOUPONBRANDCONDITIONSITEM_VALUES,
  COUPONGRANTFORMAT_VALUES,
  COUPONMAXDISTRIBUTIONS_VALUES,
  POINTSELECTSPECIFY_VALUES,
  UNDECIDED_VALUE,
  SAMPLEPRESENT_DECLINEVALUES,
} from 'constants/index';
import type { MeasureParamsProps, MeasureDetailProps } from 'types';
import type { DataTableProps } from 'components/Organisms/DataTable';
import type { TabDataProps } from 'components/Organisms/TabMenu';

export const CPF010: React.FC = () => {
  const { measureId } = useParams<MeasureParamsProps>();
  const [response, setResponse] = React.useState<MeasureDetailProps>();
  const [campaignDetailNumberData, setCampaignDetailNumberData] = React.useState<DataTableProps[]>([]);
  const [campaignDetailData, setCampaignDetailData] = React.useState<TabDataProps[]>([]);

  const apiCallbackHandler = React.useCallback((result) => {
    setResponse(result);
  }, []);

  React.useEffect(() => {
    if (!response) return;

    const campaignDetailNumberData: DataTableProps[] = [
      {
        type: 'arrayNum',
        label: '設定キャンペーン数',
        unit: '点',
        value: response?.campaignDetail,
      },
    ];
    setCampaignDetailNumberData(campaignDetailNumberData);

    const campaignDetailData: (TabDataProps | null)[] =
      response?.campaignDetail
        // .filter((elem) => elem)
        ?.map((elem, index) => {
          return elem
            ? {
                label: `${index + 1}:キャンペーン`,
                content: (
                  <>
                    <DataTable
                      data={[
                        {
                          type: 'radio',
                          label: '実施区分',
                          value: elem?.enforcementDivision,
                          master: ENFORCEMENTDIVISIONS_VALUES,
                        },
                        {
                          type: 'datetime',
                          label: '設定開始日/時間',
                          value: {
                            year: elem?.settingStartDateYear,
                            month: elem?.settingStartDateMonth,
                            date: elem?.settingStartDateDay,
                            hour: elem?.settingStartDateHours,
                            minute: elem?.settingStartDateMinutes,
                          },
                        },
                        {
                          type: 'datetime',
                          label: '設定終了日/時間',
                          value: {
                            year: elem?.settingEndDateYear,
                            month: elem?.settingEndDateMonth,
                            date: elem?.settingEndDateDay,
                            hour: elem?.settingEndDateHours,
                            minute: elem?.settingEndDateMinutes,
                          },
                        },
                        {
                          type: 'checkbox',
                          label: '購入種別',
                          value: [elem?.purchaseTypeNormal, elem?.purchaseTypeReserve, elem?.purchaseTypeRegular, elem?.purchaseTypeSimple],
                          master: CAMPAIGNPURCHASETYPE_LABELS,
                        },
                        {
                          type: 'radio',
                          label: '会員種別',
                          value: elem?.memberType,
                          master: MEMBERTYPE_VALUES,
                        },
                        {
                          type: 'radio',
                          label: '適用回数',
                          value: elem?.applicationsType,
                          master: APPLICATIONSTYPE_VALUES,
                        },
                        {
                          condition: elem?.applicationsType === '2',
                          type: 'text',
                          label: '適用詳細回数',
                          unit: '回',
                          value: elem?.applicationsDetails,
                        },
                      ]}
                    />

                    <Section>
                      <H4>適用会員を指定</H4>

                      <DataTable
                        data={[
                          {
                            type: 'radio',
                            label: '会員種別',
                            value: elem?.memberList,
                            master: MEMBERLIST_VALUES,
                          },
                          {
                            condition: elem?.memberList === '2' || elem?.memberList === '3',
                            type: 'text',
                            label: 'リスト条件',
                            value: elem?.listConditions,
                          },
                          {
                            type: 'radio',
                            label: '適用会員ブランド指定',
                            value: elem?.applicationsMemberBrand,
                            master: APPLICATIONSMEMBERBLAND_VALUES,
                          },
                        ]}
                      />
                    </Section>

                    <Section>
                      <H4>キャンペーンのオプションを設定</H4>

                      <DataTable
                        data={[
                          {
                            type: 'radio',
                            label: 'オプション種別',
                            value: elem?.optionType,
                            master: OPTIONTYPE_VALUES,
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2' || elem?.optionType === '3',
                            type: 'text',
                            label: 'マイページへ表示するキャンペーン名称',
                            value: elem?.myPageCampaignName,
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2' || elem?.optionType === '3',
                            type: 'text',
                            label: 'マイページへ表示するキャンペーン詳細',
                            value: elem?.myPageCampaignDetails,
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2' || elem?.optionType === '3',
                            type: 'radio',
                            label: 'マイページ表示終了条件',
                            value: elem?.myPageEndConditions,
                            master: MYPAGEENDCONDITIONS_VALUES,
                          },
                          {
                            condition: elem?.myPageEndConditions === '2',
                            type: 'datetime',
                            label: 'マイページ表示終了日/時間',
                            value: {
                              year: elem?.myPageEndDateYear,
                              month: elem?.myPageEndDateMonth,
                              date: elem?.myPageEndDateDay,
                              hour: elem?.myPageEndDateHours,
                              minute: elem?.myPageEndDateMinutes,
                            },
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2',
                            type: 'text',
                            label: 'キャンペーンURL',
                            value: elem?.campaignUrl,
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2' || elem?.optionType === '3',
                            type: 'text',
                            label: 'キャンペーン画像',
                            value: elem?.campaignImage,
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2',
                            type: 'datetime',
                            label: 'エントリー受付終了日/時間',
                            value: {
                              year: elem?.entryEndDateYear,
                              month: elem?.entryEndDateMonth,
                              date: elem?.entryEndDateDay,
                              hour: elem?.entryEndDateHours,
                              minute: elem?.entryEndDateMinutes,
                            },
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2',
                            type: 'radio',
                            label: 'エントリー上限数',
                            value: elem?.entryUpperLimitSetting,
                            master: ENTRYUPPERLIMITSETTING_VALUES,
                          },
                          {
                            condition: elem?.entryUpperLimitSetting === '2',
                            type: 'text',
                            label: '詳細上限数',
                            unit: '件',
                            value: elem?.entryUpperLimit,
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2',
                            type: 'radio',
                            label: 'エントリー登録前のマイページ表示',
                            value: elem?.beforeEntryShow,
                            master: BEFOREENTRYSHOW_VALUES,
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2',
                            type: 'radio',
                            label: 'エントリー時の強制メールパーミッション更新',
                            value: elem?.entryMailPermission,
                            master: ENTRYMAILPERMISSION_VALUES,
                          },
                          {
                            condition: elem?.optionType === '1' || elem?.optionType === '2',
                            type: 'radio',
                            label: 'エントリー時のLINEコネクト',
                            value: elem?.entryLineConnect,
                            master: ENTRYLINECONNECT_VALUES,
                          },
                          {
                            condition: elem?.optionType === '2',
                            type: 'text',
                            label: '継続購入達成回数',
                            unit: '回',
                            value: elem?.purchaseAchievementCount,
                          },
                          {
                            condition: elem?.optionType === '3',
                            type: 'text',
                            label: 'シークレットキャンペーンの説明（PCタグ）',
                            value: elem?.secretCampaignDescriptionPc,
                          },
                          {
                            condition: elem?.optionType === '3',
                            type: 'text',
                            label: 'シークレットキャンペーンの説明（SPタグ）',
                            value: elem?.secretCampaignDescriptionSp,
                          },
                        ]}
                      />
                    </Section>

                    <Section>
                      <H4>キャンペーン適用条件設定</H4>

                      <DataTable
                        data={[
                          {
                            type: 'radio',
                            label: '購入金額指定の設定',
                            value: elem?.amountSpecification,
                            master: AMOUNTSPECIFICATION_VALUES,
                          },
                          {
                            condition: elem?.amountSpecification === '1' || elem?.amountSpecification === '2',
                            type: 'text',
                            label: '購入金額条件',
                            unit: '円（税抜）以上購入',
                            value: elem?.amountSpecificationConditions,
                          },
                          {
                            condition: elem?.amountSpecification === '2',
                            type: 'radio',
                            label: '購入金額の組み合わせ設定条件',
                            value: elem?.amountSpecificationSetting,
                            master: AMOUNTSPECIFICATIONSETTING_VALUES,
                          },
                          {
                            condition:
                              elem?.amountSpecificationSetting === '1' || elem?.amountSpecificationSetting === '2' || elem?.amountSpecification === '3',
                            type: 'radio',
                            label: '適用条件',
                            value: elem?.applicationsConditions,
                            master: APPLICATIONSCONDITIONS_VALUES,
                          },
                          {
                            condition: elem?.applicationsConditions === '1',
                            type: 'radio',
                            label: '指定条件',
                            value: elem?.skuSpecifiedCondition,
                            master: SKUSPECIFIEDCONDITION_VALUES,
                          },
                          {
                            condition: elem?.skuSpecifiedCondition === '1' || elem?.skuSpecifiedCondition === '2',
                            type: 'text',
                            label: '指定商品に対してCP適用する購入個数',
                            unit: '個',
                            value: elem?.skuPurchase,
                          },
                          {
                            condition: elem?.applicationsConditions === '1',
                            type: 'arrayNum',
                            label: '施策対象SKU数',
                            value: elem?.skuMeasuresNum,
                          },
                          {
                            condition: elem?.applicationsConditions === '1',
                            type: 'array',
                            value: elem?.skuMeasuresNum,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:施策対象SKU`,
                                  value: elem?.skuMeasuresNum?.[key]?.skuMeasures,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.applicationsConditions === '2',
                            type: 'radio',
                            label: '指定条件',
                            value: elem?.poolSpecifiedCondition,
                            master: POOLSPECIFIEDCONDITION_VALUES,
                          },
                          {
                            condition: elem?.poolSpecifiedCondition === '1' || elem?.poolSpecifiedCondition === '2',
                            type: 'text',
                            label: '指定商品に対してCP適用する購入個数',
                            unit: '個',
                            value: elem?.poolPurchase,
                          },
                          {
                            condition: elem?.applicationsConditions === '2',
                            type: 'arrayNum',
                            label: '施策対象プール中数',
                            value: elem?.poolMeasuresNum,
                          },
                          {
                            condition: elem?.applicationsConditions === '2',
                            type: 'array',
                            value: elem?.poolMeasuresNum,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:施策対象プール中`,
                                  value: elem?.poolMeasuresNum?.[key]?.poolMeasures,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.applicationsConditions === '3',
                            type: 'radio',
                            label: '指定条件',
                            value: elem?.purchaseSpecifiedConditions,
                            master: PURCHSESPECIFIEDCONDITION_VALUES,
                          },
                          {
                            condition: elem?.purchaseSpecifiedConditions === '1' || elem?.purchaseSpecifiedConditions === '3',
                            type: 'array',
                            label: '指定ブランド名',
                            value: elem?.purchaseSelectedBrandNum,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:指定ブランド名`,
                                  value: elem?.purchaseSelectedBrandNum?.[key]?.purchaseSelectedBrandsName,
                                },
                              ];
                            },
                          },
                          {
                            condition: elem?.purchaseSpecifiedConditions === '2' || elem?.purchaseSpecifiedConditions === '3',
                            type: 'radio',
                            label: '指定アイテム条件',
                            value: elem?.purchaseSpecifiedConditionsItem,
                            master: PURCHASESPECIFIEDCONDITIONSITEM_VALUES,
                          },
                          {
                            condition: elem?.purchaseSpecifiedConditions === '2' || elem?.purchaseSpecifiedConditions === '3',
                            type: 'array',
                            label: '指定アイテム名',
                            value: elem?.purchaseSelectedItemNum,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:指定アイテム名`,
                                  value: elem?.purchaseSelectedItemNum?.[key]?.purchaseSelectedItemName,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.applicationsConditions === '4',
                            type: 'radio',
                            label: '会員ランク（下限）',
                            value: elem?.memberLowerRank,
                            master: MEMBERLOWERRANK_VALUES,
                          },
                          {
                            condition: elem?.applicationsConditions === '4',
                            type: 'radio',
                            label: '会員ランク（上限）',
                            value: elem?.memberHigherRank,
                            master: MEMBERHIGHERRANK_VALUES,
                          },

                          {
                            condition: elem?.applicationsConditions === '5',
                            type: 'arrayNum',
                            label: '指定媒体コード数',
                            value: elem?.inflowRouteCodeNum,
                          },
                          {
                            condition: elem?.applicationsConditions === '5',
                            type: 'array',
                            value: elem?.inflowRouteCodeNum,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:指定媒体コード`,
                                  value: elem?.inflowRouteCodeNum?.[key]?.inflowRouteCode,
                                },
                              ];
                            },
                          },
                          {
                            condition: elem?.applicationsConditions === '5',
                            type: 'text',
                            label: '特定媒体流入後の有効期間の日数',
                            unit: '日',
                            value: elem?.inflowRouteExpirationDate,
                          },
                          {
                            condition: elem?.applicationsConditions === '5',
                            type: 'text',
                            label: '新規会員登録からの適用日数指定',
                            unit: '日',
                            value: elem?.inflowRouteApplicable,
                          },
                          {
                            condition: elem?.applicationsConditions === '5',
                            type: 'text',
                            label: '購入金額（税抜）',
                            unit: '円',
                            value: elem?.inflowRouteAmount,
                          },
                          {
                            condition: elem?.applicationsConditions === '5',
                            type: 'radio',
                            label: '注文開始時のクーポン付与',
                            value: elem?.inflowRouteCoupon,
                            master: INFLOWROUTECOUPON_VALUES,
                          },

                          {
                            condition: elem?.applicationsConditions === '6',
                            type: 'text',
                            label: '新規会員登録からの適用日数指定',
                            unit: '日',
                            value: elem?.urlApplicableDays,
                          },
                          {
                            condition: elem?.applicationsConditions === '6',
                            type: 'radio',
                            label: 'LINEコネクトとの設定',
                            value: elem?.urlLineConnect,
                            master: URLLINECONNECT_VALUES,
                          },
                          {
                            condition: elem?.applicationsConditions === '6',
                            type: 'text',
                            label: 'PCタグ／Getクーポンの場合はクーポンGet後の遷移先URL',
                            value: elem?.urlPcTag,
                          },
                          {
                            condition: elem?.applicationsConditions === '6',
                            type: 'text',
                            label: 'SPタグ／Getクーポンの場合はクーポンGet後の遷移先URL',
                            value: elem?.urlSmartphoneTag,
                          },
                        ]}
                      />
                    </Section>

                    <Section>
                      <H4>キャンペーン特典設定</H4>

                      <DataTable
                        data={[
                          {
                            type: 'radio',
                            label: '特典種別',
                            value: elem?.benefitType,
                            master: BENEFITTYPE_VALUES,
                          },

                          {
                            condition: elem?.benefitType === '1',
                            type: 'radio',
                            label: 'プレゼント辞退設定',
                            value: elem?.presentDecline,
                            master: SAMPLEPRESENT_DECLINEVALUES,
                          },

                          {
                            condition: elem?.presentDecline === '1' || elem?.presentDecline === '2',
                            type: 'radio',
                            label: 'プレゼント方法',
                            value: elem?.samplePresent,
                            master: SAMPLEPRESENT_VALUES,
                          },

                          {
                            condition: elem?.samplePresent === '3' || elem?.samplePresent === '4',
                            type: 'text',
                            label: 'キャンペーン名称',
                            value: elem?.presentCampaignName,
                          },

                          {
                            condition: elem?.samplePresent === '3' || elem?.samplePresent === '4',
                            type: 'text',
                            label: '選択可能上限数',
                            value: elem?.presentSelectMaxNum,
                          },

                          {
                            condition: elem?.samplePresent === '1' || elem?.samplePresent === '3',
                            type: 'arrayNum',
                            label: '販促品点数',
                            unit: '点',
                            value: elem?.presentSelectPromotionalItemsNum1,
                          },
                          {
                            condition: elem?.samplePresent === '1' || elem?.samplePresent === '3',
                            type: 'array',
                            value: elem?.presentSelectPromotionalItemsNum1,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:販促品`,
                                  before:
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== UNDECIDED_VALUE
                                      ? `【${
                                          elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 === NEWITEM_VALUE
                                            ? 'JANコード未発行の販促品'
                                            : 'JANコード発行済みの販促品'
                                        }】`
                                      : undefined,
                                  value: elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItemsName1,
                                  after:
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== NEWITEM_VALUE
                                      ? `（宣伝物コード: ${elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItemsPromotionCode1}）`
                                      : undefined,
                                  nextAfter:
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== NEWITEM_VALUE
                                      ? `（JANコード: ${elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1}）`
                                      : undefined,
                                },
                                {
                                  type: 'radio',
                                  label: '納品予定',
                                  value: elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItemsDelivery1,
                                  master: PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.samplePresent === '2',
                            type: 'arrayNum',
                            label: '最初に付与する販促品点数',
                            unit: '点',
                            value: elem?.presentSelectPromotionalItemsNum1,
                          },
                          {
                            condition: elem?.samplePresent === '2',
                            type: 'array',
                            value: elem?.presentSelectPromotionalItemsNum1,
                            content: () => {
                              return [
                                {
                                  type: 'text',
                                  label: '最初に付与する販促品',
                                  before:
                                    elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1 &&
                                    elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1 !== UNDECIDED_VALUE
                                      ? `【${
                                          elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1 === NEWITEM_VALUE
                                            ? 'JANコード未発行の販促品'
                                            : 'JANコード発行済みの販促品'
                                        }】`
                                      : undefined,
                                  value: elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItemsName1,
                                  after:
                                    elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1 &&
                                    elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1 !== NEWITEM_VALUE
                                      ? `（宣伝物コード: ${elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItemsPromotionCode1}）`
                                      : undefined,
                                  nextAfter:
                                    elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1 &&
                                    elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1 !== NEWITEM_VALUE
                                      ? `（JANコード: ${elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItems1}）`
                                      : undefined,
                                },
                                {
                                  type: 'radio',
                                  label: '納品予定',
                                  value: elem?.presentSelectPromotionalItemsNum1?.[0]?.presentSelectPromotionalItemsDelivery1,
                                  master: PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.samplePresent === '2',
                            type: 'arrayNum',
                            label: '2番目に付与する販促品点数',
                            unit: '点',
                            value: elem?.presentSelectPromotionalItemsNum2,
                          },
                          {
                            condition: elem?.samplePresent === '2',
                            type: 'array',
                            value: elem?.presentSelectPromotionalItemsNum2,
                            content: () => {
                              return [
                                {
                                  type: 'text',
                                  label: '2番目に付与する販促品',
                                  before:
                                    elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2 &&
                                    elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2 !== UNDECIDED_VALUE
                                      ? `【${
                                          elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2 === NEWITEM_VALUE
                                            ? 'JANコード未発行の販促品'
                                            : 'JANコード発行済みの販促品'
                                        }】`
                                      : undefined,
                                  value: elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItemsName2,
                                  after:
                                    elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2 &&
                                    elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2 !== NEWITEM_VALUE
                                      ? `（宣伝物コード: ${elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItemsPromotionCode2}）`
                                      : undefined,
                                  nextAfter:
                                    elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2 &&
                                    elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2 !== NEWITEM_VALUE
                                      ? `（JANコード: ${elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItems2}）`
                                      : undefined,
                                },
                                {
                                  type: 'radio',
                                  label: '納品予定',
                                  value: elem?.presentSelectPromotionalItemsNum2?.[0]?.presentSelectPromotionalItemsDelivery2,
                                  master: PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.samplePresent === '2',
                            type: 'arrayNum',
                            label: '3番目に付与する販促品点数',
                            unit: '点',
                            value: elem?.presentSelectPromotionalItemsNum3,
                          },
                          {
                            condition: elem?.samplePresent === '2',
                            type: 'array',
                            value: elem?.presentSelectPromotionalItemsNum3,
                            content: () => {
                              return [
                                {
                                  condition: elem?.samplePresent === '2',
                                  type: 'text',
                                  label: '3番目に付与する販促品',
                                  before: elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItems3
                                    ? `【${
                                        elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItems3 === NEWITEM_VALUE
                                          ? 'JANコード未発行の販促品'
                                          : 'JANコード発行済みの販促品'
                                      }】`
                                    : undefined,
                                  value: elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItemsName3,
                                  after:
                                    elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItems3 &&
                                    elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItems3 !== NEWITEM_VALUE
                                      ? `（宣伝物コード: ${elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItemsPromotionCode3}）`
                                      : undefined,
                                  nextAfter:
                                    elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItems3 &&
                                    elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItems3 !== NEWITEM_VALUE
                                      ? `（JANコード: ${elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItems3}）`
                                      : undefined,
                                },
                                {
                                  type: 'radio',
                                  label: '納品予定',
                                  value: elem?.presentSelectPromotionalItemsNum3?.[0]?.presentSelectPromotionalItemsDelivery3,
                                  master: PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES,
                                },
                              ];
                            }
                          },

                          {
                            condition: elem?.samplePresent === '4',
                            type: 'arrayNum',
                            label: '最初に付与する販促品点数',
                            unit: '点',
                            value: elem?.presentSelectPromotionalItemsNum1,
                          },
                          {
                            condition: elem?.samplePresent === '4',
                            type: 'array',
                            value: elem?.presentSelectPromotionalItemsNum1,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:最初に付与する選べる販促品`,
                                  before:
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== UNDECIDED_VALUE
                                      ? `【${
                                          elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 === NEWITEM_VALUE
                                            ? 'JANコード未発行の販促品'
                                            : 'JANコード発行済みの販促品'
                                        }】`
                                      : undefined,
                                  value: elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItemsName1,
                                  after:
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== NEWITEM_VALUE
                                      ? `（宣伝物コード: ${elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItemsPromotionCode1}）`
                                      : undefined,
                                  nextAfter:
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1 !== NEWITEM_VALUE
                                      ? `（JANコード: ${elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItems1}）`
                                      : undefined,
                                },
                                {
                                  type: 'radio',
                                  label: '納品予定',
                                  value: elem?.presentSelectPromotionalItemsNum1?.[key]?.presentSelectPromotionalItemsDelivery1,
                                  master: PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.samplePresent === '4',
                            type: 'arrayNum',
                            label: '2番目に付与する販促品点数',
                            unit: '点',
                            value: elem?.presentSelectPromotionalItemsNum2,
                          },
                          {
                            condition: elem?.samplePresent === '4',
                            type: 'array',
                            value: elem?.presentSelectPromotionalItemsNum2,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:2番目に付与する選べる販促品`,
                                  before:
                                    elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2 &&
                                    elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2 !== UNDECIDED_VALUE
                                      ? `【${
                                          elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2 === NEWITEM_VALUE
                                            ? 'JANコード未発行の販促品'
                                            : 'JANコード発行済みの販促品'
                                        }】`
                                      : undefined,
                                  value: elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItemsName2,
                                  after:
                                    elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2 &&
                                    elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2 !== NEWITEM_VALUE
                                      ? `（宣伝物コード: ${elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItemsPromotionCode2}）`
                                      : undefined,
                                  nextAfter:
                                    elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2 &&
                                    elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2 !== UNDECIDED_VALUE &&
                                    elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2 !== NEWITEM_VALUE
                                      ? `（JANコード: ${elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItems2}）`
                                      : undefined,
                                },
                                {
                                  type: 'radio',
                                  label: '納品予定',
                                  value: elem?.presentSelectPromotionalItemsNum2?.[key]?.presentSelectPromotionalItemsDelivery2,
                                  master: PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.samplePresent === '4',
                            type: 'arrayNum',
                            label: '3番目に付与する販促品点数',
                            unit: '点',
                            value: elem?.presentSelectPromotionalItemsNum3,
                          },
                          {
                            condition: elem?.samplePresent === '4',
                            type: 'array',
                            value: elem?.presentSelectPromotionalItemsNum3,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:3番目に付与する選べる販促品`,
                                  before: elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItems3
                                    ? `【${
                                        elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItems3 === NEWITEM_VALUE
                                          ? 'JANコード未発行の販促品'
                                          : 'JANコード発行済みの販促品'
                                      }】`
                                    : undefined,
                                  value: elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItemsName3,
                                  after:
                                    elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItems3 &&
                                    elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItems3 !== NEWITEM_VALUE
                                      ? `（宣伝物コード: ${elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItemsPromotionCode3}）`
                                      : undefined,
                                  nextAfter:
                                    elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItems3 &&
                                    elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItems3 !== NEWITEM_VALUE
                                      ? `（JANコード: ${elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItems3}）`
                                      : undefined,
                                },
                                {
                                  type: 'radio',
                                  label: '納品予定',
                                  value: elem?.presentSelectPromotionalItemsNum3?.[key]?.presentSelectPromotionalItemsDelivery3,
                                  master: PRESENTSELECTPROMOTIONALITEMSDELIVERY_VALUES,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.benefitType === '2',
                            type: 'radio',
                            label: 'クーポン種別',
                            value: elem?.couponType,
                            master: COUPONTYPE_VALUES,
                          },

                          {
                            condition: elem?.couponType === '1' || elem?.couponType === '2' || elem?.couponType === '3' || elem?.couponType === '4',
                            type: 'text',
                            label: 'クーポン名称',
                            value: elem?.couponName,
                          },
                          {
                            condition: elem?.couponType === '1' || elem?.couponType === '2' || elem?.couponType === '3' || elem?.couponType === '4',
                            type: 'text',
                            label: 'クーポン内容',
                            value: elem?.couponContents,
                          },

                          {
                            condition: elem?.couponType === '4',
                            type: 'text',
                            label: 'クーポンコード',
                            value: elem?.couponCode,
                          },
                          {
                            condition: elem?.couponType === '4',
                            type: 'radio',
                            label: '上限配布数',
                            value: elem?.couponMaxDistributions,
                            master: COUPONMAXDISTRIBUTIONS_VALUES,
                          },
                          {
                            condition: elem?.couponMaxDistributions === '2',
                            type: 'text',
                            label: '最大件数',
                            unit: '件',
                            value: elem?.couponMaxNumber,
                          },

                          {
                            condition: elem?.couponType === '1' || elem?.couponType === '2' || elem?.couponType === '3' || elem?.couponType === '4',
                            type: 'radio',
                            label: '有効期限',
                            value: elem?.couponExpirationDate,
                            master: COUPONEXPIRATIONDATE_VALUES,
                          },
                          {
                            condition: elem?.couponExpirationDate === '1',
                            type: 'text',
                            label: 'クーポン付与後○日間',
                            value: elem?.couponAfterGrantDate,
                          },
                          {
                            condition: elem?.couponExpirationDate === '2',
                            type: 'date',
                            label: '日付指定',
                            value: {
                              year: elem?.couponSpecifyDateYear,
                              month: elem?.couponSpecifyDateMonth,
                              date: elem?.couponSpecifyDateDay,
                            },
                          },

                          {
                            condition: elem?.couponType === '3',
                            type: 'radio',
                            label: '付与形式',
                            value: elem?.couponGrantFormat,
                            master: COUPONGRANTFORMAT_VALUES,
                          },
                          {
                            condition: elem?.couponGrantFormat === '2',
                            type: 'text',
                            label: 'クーポン発行日',
                            value: elem?.couponIssueDate,
                          },
                          {
                            condition: elem?.couponType === '3',
                            type: 'text',
                            label: 'クーポン発行条件',
                            value: elem?.couponConditionsIssue,
                          },
                          {
                            condition: elem?.couponType === '3',
                            type: 'text',
                            label: '適用条件',
                            value: elem?.couponApplicableCondition,
                          },
                          {
                            condition: elem?.couponType === '3',
                            type: 'text',
                            label: 'お買物カートのクーポン利用画面テキスト',
                            value: elem?.couponCartCoupon,
                          },

                          {
                            condition: elem?.couponType === '1' || elem?.couponType === '2' || elem?.couponType === '3' || elem?.couponType === '4',
                            type: 'text',
                            label: 'クーポン金額',
                            unit: '円',
                            value: elem?.couponAmount,
                          },
                          {
                            condition: elem?.couponType === '1' || elem?.couponType === '2' || elem?.couponType === '3' || elem?.couponType === '4',
                            type: 'text',
                            label: '利用条件（最低購入金額/税込）',
                            unit: '円',
                            value: elem?.couponConditionsUse,
                          },
                          {
                            condition: elem?.couponType === '1' || elem?.couponType === '2' || elem?.couponType === '4',
                            type: 'radio',
                            label: 'クーポン利用条件',
                            value: elem?.couponCouponConditionsUse,
                            master: COUPONCOUPONCONDITIONSUSE_VALUES,
                          },
                          {
                            condition: elem?.couponCouponConditionsUse === '1',
                            type: 'radio',
                            label: '指定条件',
                            value: elem?.useCouponSkuSpecifiedConditions,
                            master: USECOUPONSKUSPECIFIEDCONDITIONS_VALUES,
                          },
                          {
                            condition: elem?.couponCouponConditionsUse === '1',
                            type: 'arrayNum',
                            label: '施策対象SKU数',
                            value: elem?.useCouponSkuMeasuresNum,
                          },
                          {
                            condition: elem?.couponCouponConditionsUse === '1',
                            type: 'array',
                            value: elem?.useCouponSkuMeasuresNum,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:施策対象SKU`,
                                  value: elem?.useCouponSkuMeasuresNum?.[key]?.useCouponSkuMeasures,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.couponCouponConditionsUse === '2',
                            type: 'radio',
                            label: '指定条件',
                            value: elem?.useCouponPoolSpecifiedConditions,
                            master: USECOUPONPOOLSPECIFIEDCONDITIONS_VALUES,
                          },
                          {
                            condition: elem?.couponCouponConditionsUse === '2',
                            type: 'arrayNum',
                            label: '施策対象プール中数',
                            value: elem?.useCouponPoolMeasuresNum,
                          },
                          {
                            condition: elem?.couponCouponConditionsUse === '2',
                            type: 'array',
                            value: elem?.useCouponPoolMeasuresNum,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:施策対象プール中`,
                                  value: elem?.useCouponPoolMeasuresNum?.[key]?.useCouponPoolMeasures,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.couponCouponConditionsUse === '3',
                            type: 'radio',
                            label: '指定条件',
                            value: elem?.useCouponBrandSpecifiedConditions,
                            master: USECOUPONBRANDSPECIFIEDCONDITIONS_VALUES,
                          },

                          {
                            condition: elem?.useCouponBrandSpecifiedConditions === '1' || elem?.useCouponBrandSpecifiedConditions === '3',
                            type: 'radio',
                            label: '指定ブランド条件',
                            value: elem?.useCouponBrandBrandConditions,
                            master: USECOUPONBRANDBRANDCONDITIONS_VALUES,
                          },
                          {
                            condition: elem?.useCouponBrandSpecifiedConditions === '1' || elem?.useCouponBrandSpecifiedConditions === '3',
                            type: 'array',
                            label: '指定ブランド名',
                            value: elem?.useCouponBrandSelectBrandNum,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:指定ブランド名`,
                                  value: elem?.useCouponBrandSelectBrandNum?.[key]?.useCouponBrandSelectBrandName,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.useCouponBrandSpecifiedConditions === '2' || elem?.useCouponBrandSpecifiedConditions === '3',
                            type: 'radio',
                            label: '指定アイテム条件',
                            value: elem?.useCouponBrandConditionsItem,
                            master: USECOUPONBRANDCONDITIONSITEM_VALUES,
                          },
                          {
                            condition: elem?.useCouponBrandSpecifiedConditions === '2' || elem?.useCouponBrandSpecifiedConditions === '3',
                            type: 'array',
                            label: '指定アイテム名',
                            value: elem?.useCouponBrandSelectItemNum,
                            content: (key: number) => {
                              return [
                                {
                                  type: 'text',
                                  label: `${key + 1}:指定アイテム名`,
                                  value: elem?.useCouponBrandSelectItemNum?.[key]?.useCouponBrandSelectItemName,
                                },
                              ];
                            },
                          },

                          {
                            condition: elem?.benefitType === '3',
                            type: 'radio',
                            label: '指定方法選択',
                            value: elem?.pointSelectSpecify,
                            master: POINTSELECTSPECIFY_VALUES,
                          },
                          {
                            condition: elem?.benefitType === '3',
                            type: 'text',
                            label: '指定数',
                            value: elem?.pointSelectNum,
                          },
                          {
                            type: 'text',
                            label: '（IMJ入力）キャンペーンコード（ステージング）',
                            value: elem?.imjCampaignCodeStaging,
                          },
                          {
                            type: 'text',
                            label: '（IMJ入力）キャンペーンコード（本番）',
                            value: elem?.imjCampaignCodeProduction,
                          },
                        ]}
                      />
                    </Section>
                  </>
                ),
              }
            : null;
        }) || [];
    setCampaignDetailData(campaignDetailData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <>
      <Header />
      <Main>
        <ApiGetMeasuresDetailProvider callback={apiCallbackHandler}>
          <Container>
            <Breadcrumb
              list={[
                {
                  label: 'TOP',
                  url: '/CMN001',
                },
                {
                  label: '登録済み施策一覧',
                },
                {
                  label: response?.measuresName || '',
                },
              ]}
            />

            <Section>
              <H1>{response?.measuresName}</H1>

              <LinkList
                list={[
                  {
                    text: '施策概要・依頼内容',
                    to: `/CPF008/${measureId}`,
                  },
                  {
                    text: 'Web制作',
                    to: `/CPF009/${measureId}`,
                  },
                  {
                    text: 'キャンペーン設定・クーポン発行',
                    to: `/CPF010/${measureId}`,
                    current: true,
                    disabled: true,
                  },
                  {
                    text: 'サンプル・モニター設定',
                    to: `/CPF011/${measureId}`,
                  },
                ]}
              />

              <Section hasBg>
                <PositionContainer>
                  <H2>キャンペーン設定・クーポン発行</H2>
                  <PositionItem top="0" right="0">
                    <Button type="primary" size="small" text="編集" to={`/CPF003/${measureId}`} />
                  </PositionItem>
                </PositionContainer>

                <SectionInner>
                  {response && (
                    <Flex justifyContent="center">
                      <Box color="grey" size="xsmall" width="fit-content">
                        <FlexRow justifyContent="center" alignItems="center" separator={true}>
                          <FlexCol>
                            <Text bold>入力状況</Text>
                          </FlexCol>
                          <FlexCol>
                            <CampaignStatusTag status={response?.campaignStatus} />
                          </FlexCol>
                        </FlexRow>
                      </Box>
                    </Flex>
                  )}

                  {response?.campaignStatus !== '3' && (
                    <Section>
                      <H3>キャンペーン</H3>
                      <DataTable data={campaignDetailNumberData} />
                      {campaignDetailData.length > 0 && <TabMenu data={campaignDetailData} />}
                    </Section>
                  )}

                  <PositionContainer mt={40}>
                    <ButtonContainer>
                      <PositionItem left="0" centerY>
                        <Button type="secondary" icon="arrow-left" iconPosition="before" size="small" isFlexible text="施策一覧へ戻る" to="/CPF007" />
                      </PositionItem>
                      <Button type="primary" text="編集" to={`/CPF003/${measureId}`} />
                    </ButtonContainer>
                  </PositionContainer>
                </SectionInner>
              </Section>
            </Section>
          </Container>
        </ApiGetMeasuresDetailProvider>
      </Main>
      <Footer />
    </>
  );
};
