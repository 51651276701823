import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    username: yup.string().required(t('ERR-REQUIRED')),
  });
};
