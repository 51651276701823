import React from 'react';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';

export const CMN012: React.FC = () => {
  return (
    <>
      <Header />
      <Main>
        <Container>
          <Section>
            <H1>初回ログイン完了</H1>
            <Text align="center">
              パスワードの本登録、2段階認証を行う端末の登録が完了しました。
              <br />
              ワンタイムパスワードは、2回目以降のログインの際にも入力が必要になります。ブラウザ（EdgeまたはChrome）の拡張機能または、アプリを削除しないでください。
            </Text>

            <ButtonContainer>
              <Button type="primary" text="TOPページへ移動する" to="/CMN001" />
            </ButtonContainer>
          </Section>
        </Container>
      </Main>
      <Footer />
    </>
  );
};
