import React from 'react';
import style from './style.module.scss';

const Color = {
  white: 'white',
  grey: 'grey',
  red: 'red',
} as const;
type ColorProps = typeof Color[keyof typeof Color];

const Size = {
  medium: 'medium',
  small: 'small',
  xsmall: 'xsmall',
} as const;
type SizeProps = typeof Size[keyof typeof Size];

const Width = {
  'fit-content': 'fit-content',
} as const;
type WidthProps = typeof Width[keyof typeof Width];

type Props = {
  id?: string;
  color?: ColorProps;
  size?: SizeProps;
  width?: WidthProps;
  mt?: number;
  height?: string;
  scrollY?: boolean;
  maxHeight?: number;
  className?: string;
};

export const Box: React.FC<Props> = ({ children, color, size = 'medium', width, mt, height, id, className = '', scrollY = false, maxHeight }) => {
  return (
    <div
      {...(id && { id: id })}
      className={`${style['layout-box']} layout-box ${color ? 'is-' + color : ''} is-${size} ${width ? 'is-' + width : ''} ${
        scrollY ? 'is-scrollY' : ''
      } ${className}`}
      style={{
        ...(mt !== undefined && { marginTop: `${mt}px` }),
        ...(height !== undefined && { height: height }),
        ...(maxHeight !== undefined && { maxHeight: `${maxHeight}px` }),
      }}
    >
      {children}
    </div>
  );
};
