import React from 'react';
import style from './style.module.scss';

const Size = {
  basic: 'basic',
  login: 'login',
} as const;
type SizeProps = typeof Size[keyof typeof Size];

type Props = {
  type?: SizeProps;
  className?: string;
};

export const Container: React.FC<Props> = ({ children, type = 'basic', className = '' }) => (
  <div className={`${style['layout-container']} layout-container type-${type} ${className}`}>{children}</div>
);
