import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonContainer } from 'components/Atoms/Layout';
import { Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Modal } from 'components/Organisms/Modal';
import { CognitoUserState, DirtyUpdate, Server400ErrorUpdate, NetworkErrorUpdate } from 'context';
import {
  RHF_RESET_PARAMS,
  ENDPOINT_POST_SAVE_MEASURE_API,
  ENDPOINT_PATCH_SAVE_MEASURE_OUTLINE_API,
  ENDPOINT_PATCH_SAVE_MEASURE_WEB_API,
  ENDPOINT_PATCH_SAVE_MEASURE_CAMPAIGN_API,
  ENDPOINT_PATCH_SAVE_MEASURE_SAMPLE_API,
  ENDPOINT_GET_MEASURE_API,
  ENDPOINT_COPY_MEASURE_API,
} from 'constants/index';
import { scrollToFirstError } from 'utils';
import { useValidationDuplicateCoupon } from 'hooks/useValidationDuplicateCoupon';
import { useApiCall } from 'hooks/useApiCall';
import style from './style.module.scss';
import type { AxiosRequestConfig } from 'axios/index.d';
import type { MeasureParamsProps, MeasureCopyStateProps, MeasureStatusesProps } from 'types';

const Types = ['outline', 'web', 'campaign', 'sample'] as const;

type TypeProps = typeof Types[number];

type Props = {
  type: TypeProps;
  getRequestData: () => { [key: string]: any };
};

export const ApiSaveMeasureButton: React.FC<Props> = ({ type, getRequestData }) => {
  const { t } = useTranslation();
  const apiCall = useApiCall();
  const setDirty = React.useContext(DirtyUpdate);
  const userInfo = React.useContext(CognitoUserState);
  const setServer400Error = React.useContext(Server400ErrorUpdate);
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  const { measureId } = useParams<MeasureParamsProps>();
  const history = useHistory();
  const { state } = useLocation<MeasureCopyStateProps>();
  const validationDuplicateCoupon = useValidationDuplicateCoupon();
  const { handleSubmit, setError, reset, getValues } = useFormContext();
  const { isDirty } = useFormState();
  const [openSaveModal, setOpenSaveModal] = React.useState(false);
  const [newMeasuresId, setNewMeasuresId] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [statuses, setStatuses] = React.useState<MeasureStatusesProps>();

  const campaignCouponValidation = React.useCallback(
    (data) => {
      if (type !== 'campaign') return;
      data.campaignDetail.forEach((obj: any, index: number) => {
        if (!obj?.couponCode) return;
        validationDuplicateCoupon(`campaignDetail[${index}].couponCode`, obj.couponCode);
      });
    },
    [type, validationDuplicateCoupon],
  );

  const getData = React.useCallback(() => {
    const data = getRequestData();
    data.updateInCharge = userInfo?.username;
    return data;
  }, [getRequestData, userInfo]);

  const getStatuses = React.useCallback(
    async (measureId) => {
      apiCall({
        method: 'GET',
        url: `${ENDPOINT_GET_MEASURE_API}/${measureId}`,
      })
        .then((res) => {
          console.log(res.data.result);
          setStatuses({
            measuresStatus: res.data.result.measuresStatus,
            webStatus: res.data.result.webStatus,
            campaignStatus: res.data.result.campaignStatus,
            sampleStatus: res.data.result.sampleStatus,
          });
        })
        .catch((error) => {
          if (error.response?.status === 400) setNetworkError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [apiCall, setNetworkError],
  );

  const save = React.useCallback(() => {
    const data = getData();

    let method: AxiosRequestConfig['method'];
    let url: string;

    if (!measureId) {
      method = 'POST';
      if (type === 'outline' && state?.originalMeasureId) {
        data.copyId = state.originalMeasureId;
        url = ENDPOINT_COPY_MEASURE_API;
      } else {
        url = ENDPOINT_POST_SAVE_MEASURE_API;
      }
    } else {
      method = 'PATCH';
      switch (type) {
        case 'outline':
          url = `${ENDPOINT_PATCH_SAVE_MEASURE_OUTLINE_API}${measureId}`;
          break;
        case 'web':
          url = `${ENDPOINT_PATCH_SAVE_MEASURE_WEB_API}${measureId}`;
          break;
        case 'campaign':
          url = `${ENDPOINT_PATCH_SAVE_MEASURE_CAMPAIGN_API}${measureId}`;
          break;
        case 'sample':
          url = `${ENDPOINT_PATCH_SAVE_MEASURE_SAMPLE_API}${measureId}`;
          break;
      }
    }

    apiCall({
      method: method,
      url: url,
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          if (!measureId) setNewMeasuresId(res.data.result.id);
          getStatuses(measureId || res.data.result.id);
          setDirty(false);
        }
      })
      .catch((error) => {
        if (!error.response) return;
        console.log(error.response.data);
        if (error.response.status === 400) {
          if (error.response.data.error.code === 'ERR00001') {
            setError(error.response.data.error.content, {
              type: 'manual',
              message: t('ERR-USED-COUPON'),
            });
            scrollToFirstError();
          } else if (error.response.data.error.code === 'ERR00015') {
            setError(error.response.data.error.content, {
              type: 'manual',
              message: t('ERR-INVALID-BRAND'),
            });
            scrollToFirstError();
          } else {
            setServer400Error(error.response.data.error);
          }
        }
        setIsLoading(false);
      });
  }, [apiCall, getData, getStatuses, measureId, setDirty, setError, setServer400Error, t, type, state]);

  const onSuccess = React.useCallback(async () => {
    setIsLoading(true);
    setServer400Error(undefined);
    save();
  }, [save, setServer400Error]);

  const onError = React.useCallback(
    (errors: any, _e: any) => {
      setServer400Error(undefined);
      const data = getRequestData();
      campaignCouponValidation(data);
      console.log(errors);
      console.log(data);
      scrollToFirstError();
    },
    [getRequestData, campaignCouponValidation, setServer400Error],
  );

  const onCloseModal = React.useCallback(() => {
    if (!measureId) {
      history.push(`${history.location.pathname}/${newMeasuresId}`);
    } else {
      // reset(getRequestData(), RHF_RESET_PARAMS);
      reset(getValues(), RHF_RESET_PARAMS);
    }
    setOpenSaveModal(false);
  }, [history, measureId, newMeasuresId, setOpenSaveModal, reset, getValues]);

  React.useEffect(() => {
    setDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  React.useEffect(() => {
    if (statuses) {
      setOpenSaveModal(true);
    }
  }, [statuses]);

  return (
    <>
      <Loading isLoading={isLoading} />

      <Button type="primary" size="large" text="入力完了" onClick={() => handleSubmit(onSuccess, onError)()} className={`${style['api-save-measure']}`} />

      <Modal
        type="success"
        title={
          statuses?.measuresStatus === '2' || statuses?.webStatus === '2' || statuses?.campaignStatus === '2' || statuses?.sampleStatus === '2'
            ? '入力内容の保存が完了しました'
            : '全ての登録が完了しました'
        }
        hasSubmit
        isShow={openSaveModal}
        onCancel={onCloseModal}
        onSubmit={onCloseModal}
      >
        {(statuses?.measuresStatus === '2' || statuses?.webStatus === '2' || statuses?.campaignStatus === '2' || statuses?.sampleStatus === '2') && (
          <Text align="center">
            下記ページに未定、または未入力項目があります。
            <br />
            内容が確定している場合、続けて入力をお願いします。
          </Text>
        )}

        <ButtonContainer isVertical mt={40}>
          {statuses?.measuresStatus === '2' && (
            <Button
              type="secondary"
              size="large"
              icon="arrow-right"
              iconPosition="right"
              text="施策概要・依頼内容"
              to={type !== 'outline' ? (measureId ? `/CPF001/${measureId}` : `/CPF001/${newMeasuresId}`) : undefined}
              onClick={type === 'outline' ? onCloseModal : undefined}
            />
          )}

          {statuses?.webStatus === '2' && (
            <Button
              type="secondary"
              size="large"
              icon="arrow-right"
              iconPosition="right"
              text="Web制作"
              to={type !== 'web' ? (measureId ? `/CPF002/${measureId}` : `/CPF002/${newMeasuresId}`) : undefined}
              onClick={type === 'web' ? onCloseModal : undefined}
            />
          )}

          {statuses?.campaignStatus === '2' && (
            <Button
              type="secondary"
              size="large"
              icon="arrow-right"
              iconPosition="right"
              text="キャンペーン設定・クーポン発行"
              to={type !== 'campaign' ? (measureId ? `/CPF003/${measureId}` : `/CPF003/${newMeasuresId}`) : undefined}
              onClick={type === 'campaign' ? onCloseModal : undefined}
            />
          )}

          {statuses?.sampleStatus === '2' && (
            <Button
              type="secondary"
              size="large"
              icon="arrow-right"
              iconPosition="right"
              text="サンプル・モニター設定"
              to={type !== 'sample' ? (measureId ? `/CPF004/${measureId}` : `/CPF004/${newMeasuresId}`) : undefined}
              onClick={type === 'sample' ? onCloseModal : undefined}
            />
          )}

          {statuses?.measuresStatus !== '2' && statuses?.webStatus !== '2' && statuses?.campaignStatus !== '2' && statuses?.sampleStatus !== '2' && (
            <Button type="secondary" size="large" icon="arrow-left" iconPosition="left" text="TOPページへ戻る" to="/CMN001" />
          )}
        </ButtonContainer>
      </Modal>
    </>
  );
};
