import React from 'react';
import style from './style.module.scss';

type Props = {
  text: string;
  mt?: number;
  className?: string;
};

export const Unit: React.FC<Props> = ({ text, mt, className = '' }) =>
  React.useMemo(
    () => (
      <span
        className={`${style['typography-unit']} typography-unit ${className}`}
        style={{
          ...(mt !== undefined && { marginTop: `${mt}px` }),
        }}
      >
        {text}
      </span>
    ),
    [text, mt, className],
  );
