import React from 'react';
import { ButtonContainer } from 'components/Atoms/Layout';
import { Button } from 'components/Atoms/Button';
import style from './style.module.scss';

type DeleteButtonProps = {
  deleteKeys: number[];
  showDeleteModal: (deleteKeys: number[]) => void;
  className?: string;
};

export const DeleteButton: React.FC<DeleteButtonProps> = ({ deleteKeys, showDeleteModal, className = '' }) => (
  <ButtonContainer className={`${style['delete-button']} ${className}`}>
    <Button type="delete" size="small" isFlexible icon="dustbox" iconPosition="after" text="削除" onClick={() => showDeleteModal(deleteKeys)} />
  </ButtonContainer>
);
