import React from 'react';
import { EMPTY_LABEL } from 'constants/index';
import style from './style.module.scss';

type TableHeaderProps = {
  label: string;
  width: string;
};

type TableRowProps = (string | JSX.Element)[];

export type TableDataProps = TableRowProps[];

type Props = {
  header: TableHeaderProps[];
  data?: TableDataProps;
  mt?: number;
};

export const Table: React.FC<Props> = ({ header, data, mt }) =>
  React.useMemo(
    () => (
      <table className={style.table} style={{ ...(mt !== undefined && { marginTop: `${mt}px` }) }}>
        <colgroup>
          {header.map((elem, headerIndex) => (
            <col key={headerIndex} width={elem.width} />
          ))}
        </colgroup>

        <thead>
          <tr className={style['table-thead-row']}>
            {header.map((elem, headerIndex) => (
              <th key={headerIndex} className={style['table-thead-th']}>
                {elem.label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data &&
            data.map((row, rowIndex) => (
              <tr key={rowIndex} className={style['table-tbody-row']}>
                {row.map((column, columnIndex) => (
                  <td key={columnIndex} className={style['table-tbody-td']}>
                    {column || EMPTY_LABEL}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    ),
    [header, data, mt],
  );
