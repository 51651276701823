import React from 'react';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { smoothScroll } from 'utils';
import style from './style.module.scss';

export const PageTop: React.FC = () => {
  const handleClick = React.useCallback(() => {
    smoothScroll({
      to: 0,
      easing: 'easeInOutCirc',
    });
  }, []);

  return React.useMemo(
    () => (
      <button className={style['pagetop']} onClick={handleClick}>
        <SvgIcon type="arrow-top" fill="#2896f0" width={30} className={style['footer-icon-cross']} />
      </button>
    ),
    [handleClick],
  );
};
