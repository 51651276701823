import React from 'react';

type Props = {
  mt?: number;
  mb?: number;
};

export const Stack: React.FC<Props> = ({ mt, mb, children }) => (
  <div style={{ ...(mt !== undefined && { marginTop: `${mt}px` }), ...(mb !== undefined && { marginBottom: `${mb}px` }) }}>{children}</div>
);
