import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FileWithPath } from 'react-dropzone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, Flex, FlexRow, FlexCol, Box, ButtonContainer, FormGrid } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button, DownloadButton } from 'components/Atoms/Button';
import { InlineList } from 'components/Atoms/List';
import { Loading } from 'components/Atoms/Loading';
import { Hr } from 'components/Atoms/Hr';
import { Note } from 'components/Atoms/Typography/Note';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { FormRadioList, FormTextInput, FormDatePeriod, FormCheckboxList } from 'components/Business/Form';
import { ApiSelectBrand, ApiSelectUser } from 'components/Business/Api';
import { ApiUploadPromotionDeliveryInfoButton } from 'components/Business/Api/ApiUploadPromotionDeliveryInfoButton';
import { TextLink } from 'components/Business/TextLink';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { Pager } from 'components/Molecules/Pager';
import { Dropzone } from 'components/Molecules/Dropzone'
import {
  RHF_SETTINGS
  ,ITEMSSELECTKEYWORD_VALUES
  ,PROMOTIONITEMLISTOSWORKREQUEST_LABELS
  ,ENDPOINT_GET_PROMOTION_API
  ,ENDPOINT_GET_PROMOTION_DOWNLOAD_API
  ,EMPTY_LABEL
} from 'constants/index';
import {
  objectToRadioList
  ,smoothScroll
  ,scrollToFirstError
} from 'utils';
import { useApiCall } from 'hooks/useApiCall';
import { useValidationSchema } from './useValidationSchema';
import { DirectionProps, PromotionItemMeasuresProps, PromotionItemYlcDeliveryProps, PromotionItemListProps } from 'types';

export const PRF001: React.FC = () => {
  const { t } = useTranslation();
  const apiCall = useApiCall();
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, trigger, handleSubmit } = useFormMethods;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = React.useState('10');
  const [offset, setOffset] = React.useState('0');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [direction, setDirection] = React.useState<DirectionProps>('DESC');
  const [response, setResponse] = React.useState<PromotionItemListProps[]>([]);
  const [searchFlag, setSearchFlag] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [promotionalItemCount, setPromotionalItemCount] = React.useState(0);
  const [currentFiles, setCurrentFiles] = React.useState<FileWithPath[]>([]);
  const [previousFormValues, setPreviousFormValues] = React.useState<{ [key: string]: any }>();
  const [isMultiFileSelected, setIsMultiFileSelected] = React.useState(false);
  const [maxDownloads, setMaxDownloads] = React.useState<number>(999);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    return {
      selectKeyWord: formValues.selectKeyWord || '',
      keyWord: formValues.keyWord || '',
      ylcDeliveryDateFromYear: formValues.ylcDeliveryDateFromYear || '',
      ylcDeliveryDateFromMonth: formValues.ylcDeliveryDateFromMonth || '',
      ylcDeliveryDateFromDay: formValues.ylcDeliveryDateFromDay || '',
      ylcDeliveryDateToYear: formValues.ylcDeliveryDateToYear || '',
      ylcDeliveryDateToMonth: formValues.ylcDeliveryDateToMonth || '',
      ylcDeliveryDateToDay: formValues.ylcDeliveryDateToDay || '',
      brandCode: formValues.brandCode || '',
      inCharge: formValues.inCharge || '',
      measuresDateStartFromYear: formValues.measuresDateStartFromYear || '',
      measuresDateStartFromMonth: formValues.measuresDateStartFromMonth || '',
      measuresDateStartFromDay: formValues.measuresDateStartFromDay || '',
      measuresDateStartToYear: formValues.measuresDateStartToYear || '',
      measuresDateStartToMonth: formValues.measuresDateStartToMonth || '',
      measuresDateStartToDay: formValues.measuresDateStartToDay || '',
      osWorkRequestYes: formValues.osWorkRequestYes || '',
      osWorkRequestNo: formValues.osWorkRequestNo || '',
      limit: limit,
      offset: offset,
      direction: direction,
    };
  }, [limit, offset, direction, getValues]);

  const handleSearch = React.useCallback(
    async ({ scrollOnComplete = false }) => {
      const data = getRequestData();
      setPreviousFormValues({...data});

      setIsLoading(true);
      apiCall({
        method: 'GET',
        url: ENDPOINT_GET_PROMOTION_API,
        data,
      })
      .then((res: { status: number; data: { result: { promotionalItemList: PromotionItemListProps[]; count: number; max: number } } }) => {
        if (res.status === 200) {
          const promotionalItemList: PromotionItemListProps[] = res.data.result.promotionalItemList;
          setResponse(promotionalItemList);
          setPageCount(Math.ceil(Number(res.data.result.count) / Number(limit)));
          setSearchFlag(true);
          setMaxDownloads(Number(res.data.result.max));
        }
        setPromotionalItemCount(res.data.result.count);
      })
      .finally(() => {
        setIsLoading(false);
        if (scrollOnComplete) {
          smoothScroll({
            to: 'result',
            easing: 'easeInOutCirc',
            offset: -50,
          });
        }
      });
    },
    [limit, setPreviousFormValues, getRequestData, apiCall],
  );

  const onSuccess = React.useCallback(() => {
    handleSearch({ scrollOnComplete: true });
  }, [handleSearch]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  const handlePageChange = React.useCallback(
    (selected: number) => {
      setOffset(String(Number(limit) * selected));
      setPageNumber(selected);
    },
    [limit],
  );

  const handleClickSubmit = React.useCallback(() => {
    if (offset === '0') {
      handleSubmit(onSuccess, onError)();
      return;
    }
    handlePageChange(0);
  }, [handleSubmit, offset, onError, onSuccess, handlePageChange]);

  React.useEffect(() => {
    if (searchFlag) handleSubmit(onSuccess, onError)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  React.useEffect(() => {
    if (searchFlag) handleSubmit(onSuccess, onError)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  React.useEffect(() => {
    handleSearch({ scrollOnComplete: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // react-dropzone onDrop handler
  const onDrop = React.useCallback((acceptedFiles: FileWithPath[]) => {
    if (0 < currentFiles.length) {
      setIsMultiFileSelected(true);
      return;
    }
    setCurrentFiles([...currentFiles, ...acceptedFiles]);
    setIsMultiFileSelected(false);
  }, [currentFiles]);

  // react-dropzone onDropRejected handler
  const onDropRejected = React.useCallback((rejectedFiles) => {
    if (1 < rejectedFiles.length) {
      setIsMultiFileSelected(true);
    }
  }, [setIsMultiFileSelected]);

  // onRemeve handler
  const onRemove = React.useCallback((file: FileWithPath) => {
    const newFiles = [...currentFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setCurrentFiles(newFiles);
    setIsMultiFileSelected(false);
  }, [currentFiles, setCurrentFiles]);

  const files = currentFiles.map((file: FileWithPath) => {
    return (
      <li key={file.path}>
        {file.path} <button style={{ padding: "0px 0px 0px 16px" }} onClick={ () => onRemove(file) }>&#10006;</button>
      </li>
    );
  });

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />

          <Container>
            <Breadcrumb
              list={[
                {
                  label: 'TOP',
                  url: '/CMN001',
                },
                {
                  label: '販促品一覧',
                },
              ]}
            />

            <Section>
              <H1>販促品一覧</H1>
              <Text align="center">
                登録されている販促品情報を確認できます。
                <br />
                詳細な情報を見るには「詳細を見る」をクリックしてください。
                <br />
                必要な項目を入力すると、絞り込みが可能です。
              </Text>

              <Section hasBg>
                <Box color="grey">
                  <FormRadioList
                    name="selectKeyWord"
                    label="キーワード"
                    defaultValue="1"
                    isHorizontal
                    size="small"
                    radioSize="small"
                    list={objectToRadioList(ITEMSSELECTKEYWORD_VALUES)}
                    onChange={() => {
                      trigger('keyWord');
                    }}
                  />

                  <FormTextInput name="keyWord" label="" placeholder="キーワードを入力" note="※キーワードは一部の入力でも絞り込みが可能です。" size="small" />

                  <FormDatePeriod
                    names={{
                      start: {
                        year: 'ylcDeliveryDateFromYear',
                        month: 'ylcDeliveryDateFromMonth',
                        date: 'ylcDeliveryDateFromDay',
                      },
                      end: {
                        year: 'ylcDeliveryDateToYear',
                        month: 'ylcDeliveryDateToMonth',
                        date: 'ylcDeliveryDateToDay',
                      },
                    }}
                    size="small"
                    label="YLC納品日（東）"
                    labelFontSize={12.5}
                  />

                  <FormGrid size="small">
                    <FlexRow>
                      <FlexCol col={6}>
                        <ApiSelectBrand size="small" inputSize="small" />
                      </FlexCol>
                      <FlexCol col={6}>
                        <ApiSelectUser label="施策担当者名" size="small" inputSize="small" />
                      </FlexCol>
                    </FlexRow>
                  </FormGrid>

                  <FormDatePeriod
                    names={{
                      start: {
                        year: 'measuresDateStartFromYear',
                        month: 'measuresDateStartFromMonth',
                        date: 'measuresDateStartFromDay',
                      },
                      end: {
                        year: 'measuresDateStartToYear',
                        month: 'measuresDateStartToMonth',
                        date: 'measuresDateStartToDay',
                      },
                    }}
                    label="施策開始日"
                    size="small"
                  />

                  <FormCheckboxList
                    label="OS作業依頼"
                    list={[
                      {
                        name: `osWorkRequestYes`,
                        label: PROMOTIONITEMLISTOSWORKREQUEST_LABELS[0],
                        value: '1',
                      },
                      {
                        name: `osWorkRequestNo`,
                        label: PROMOTIONITEMLISTOSWORKREQUEST_LABELS[1],
                        value: '1',
                      },
                    ]}
                    isHorizontal
                    size="small"
                  />

                  <ButtonContainer>
                    <Button type="secondary" text="絞り込む" onClick={handleClickSubmit} />
                  </ButtonContainer>
                </Box>

                <Box color="grey">
                  <aside>
                    <Text size={18} align={'left'} bold>ファイルのアップロード</Text>
                    <ul>{files}</ul>
                    {isMultiFileSelected && (
                      <Text size={16} align={'left'} color="red" mt={0}>1度に複数のファイルを選択することはできません</Text>
                    )}
                  </aside>
                  <Dropzone
                    accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] }}
                    onDrop={ onDrop }
                    onDropRejected={ onDropRejected }
                  />
                  <Note>※1度に選択できるファイルは1点のみです。</Note>
                  <ButtonContainer mt={20}>
                    <ApiUploadPromotionDeliveryInfoButton file={currentFiles[0]} disabled={currentFiles.length !== 1} />
                  </ButtonContainer>
                </Box>

                <Flex justifyContent='end' mt={40}>
                  <Text size={24} bold mr={5}>{promotionalItemCount}</Text>
                  <FlexRow alignItems="center" mt={-2}>
                    <FlexCol>
                      <Text size={20} mr={-20}>件</Text>
                    </FlexCol>
                    <FlexCol>
                      <DownloadButton
                        title="販促品情報"
                        formData={previousFormValues}
                        url={ENDPOINT_GET_PROMOTION_DOWNLOAD_API}
                        itemCount={promotionalItemCount}
                        maxDownloads={maxDownloads}
                        contentType="text/csv"
                        filename="販促品一覧.csv"
                      />
                    </FlexCol>
                  </FlexRow>
                </Flex>

                <Box id="result" mt={10}>
                  {response.length === 0 && searchFlag && <Text mt={0}>{t('MSG-ZERO-RESULT')}</Text>}

                  {response.map((elem: PromotionItemListProps, index: number) => (
                    <Box key={index} color="white" mt={10}>
                      <Flex justifyContent="space-between">
                        <Text size={18} bold>
                          {elem.promotionalName || EMPTY_LABEL}
                        </Text>
                        <TextLink to={`/PRF002/${elem.janCode}`} icon="arrow-right" iconPosition="after" nowrap ml={10}>
                          詳細を見る
                        </TextLink>
                      </Flex>

                      <InlineList mt={15}>
                        <Flex alignItems="center">
                          <Text size={14} color="grey" nowrap>
                            販促品JANコード
                          </Text>
                          <Text mt={0} ml={20}>
                            {elem.janCode || EMPTY_LABEL}
                          </Text>
                        </Flex>
                        <Flex alignItems="center">
                          <Text size={14} color="grey" nowrap>
                            宣伝物コード
                          </Text>
                          <Text mt={0} ml={20}>
                            {elem.promotionCode || EMPTY_LABEL}
                          </Text>
                        </Flex>
                        <Flex alignItems="center">
                          <Text size={14} color="grey" nowrap>
                            OS作業依頼
                          </Text>
                          <Text mt={0} ml={20} nowrap>
                            {elem.osWorkRequest || EMPTY_LABEL}
                          </Text>
                        </Flex>
                      </InlineList>

                      <Hr mt={20} mb={20} />

                      <FlexRow>
                        <FlexCol col={2}>
                          <Text size={14} color="grey">
                            施策名
                          </Text>
                        </FlexCol>
                        <FlexCol col={10}>
                          {elem.measures && elem.measures.length > 0 ? (
                            <ul>
                              {elem.measures.map((measure: PromotionItemMeasuresProps, i: number) => (
                                <li key={i}>{measure.measuresName || EMPTY_LABEL}</li>
                              ))}
                            </ul>
                          ) : (
                            <Text>{EMPTY_LABEL}</Text>
                          )}
                        </FlexCol>
                      </FlexRow>

                      <Hr mt={20} mb={20} />

                      <FlexRow>
                        <FlexCol col={2}>
                          <Text size={12.5} color="grey">
                            YLC納品日（東）
                          </Text>
                        </FlexCol>
                        <FlexCol col={10}>
                          <Flex>
                            {elem.ylcDelivery && elem.ylcDelivery.length > 0 ? (
                              elem.ylcDelivery.map((ylc: PromotionItemYlcDeliveryProps, i: number) => (
                                <Text key={i} mt={0} mr={20}>
                                  {ylc.ylcDeliveryDateEast || EMPTY_LABEL}
                                </Text>
                              ))
                            ) : (
                              <Text>{EMPTY_LABEL}</Text>
                            )}
                          </Flex>
                        </FlexCol>
                      </FlexRow>
                    </Box>
                  ))}
                </Box>

                <Pager pageCount={pageCount} pageNumber={pageNumber} onPageChange={handlePageChange} />
              </Section>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
