import React from 'react';
import { FormSelectMenu } from 'components/Business/Form';
import { ENDPOINT_GET_AID_MASTER_API } from 'constants/index';
import { useApiCall } from 'hooks/useApiCall';
import type { SelectMenuListProps } from 'components/Atoms/Form/SelectMenu';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';
import type { TextInputSizeProps } from 'components/Atoms/Form/TextInput';

type Props = {
  size?: FormGridSizeProps;
  inputSize?: TextInputSizeProps;
};

export const ApiSelectAidTargetUsers: React.FC<Props> = ({ size = 'large', inputSize = 'medium' }) => {
  const apiCall = useApiCall();
  const [list, setList] = React.useState<SelectMenuListProps[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    setIsLoading(true);

    (async () => {
      apiCall({
        method: 'GET',
        url: `${ENDPOINT_GET_AID_MASTER_API}?type=target_users`,
      })
        .then((res: { status: number; data: { result: { nameList: any[] } } }) => {
          if (res.status === 200) {
            const nameList: any[] = res.data.result.nameList;
            const result =
              nameList.map((elem: any) => {
                return {
                  label: elem,
                  value: elem,
                };
              }) || [];
            if (!unmounted) setList(result);
          }
        })
        .finally(() => {
          if (!unmounted) setIsLoading(false);
        });
    })();

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormSelectMenu
      name="targetUsers"
      label="ターゲットユーザー層"
      help="「Z世代」は1997~2006年生まれ 「ミレニアル世代」は1987~1996年生まれ 「X世代」は1977~1986年生まれ 「団塊ジュニア&amp;バブル世代」は1962~1976年生まれ 「エイジレス」は1947~1961年生まれの人を表します。"
      list={list}
      isRequired
      isLoading={isLoading}
      size={size}
      inputSize={inputSize}
    />
  );
};
