import React from 'react';
import { SelectMenu } from 'components/Atoms/Form';
import { ENDPOINT_GET_AID_MASTER_API } from 'constants/index';
import { useApiCall } from 'hooks/useApiCall';
import type { SelectMenuListProps } from 'components/Atoms/Form/SelectMenu';
import type { TextInputSizeProps } from 'components/Atoms/Form/TextInput';

type Props = {
  value: string;
  onChange: (value: string) => void;
  size?: TextInputSizeProps;
  width?: number;
};

export const ApiSelectAidDataType: React.FC<Props> = ({ value, onChange, size = 'medium', width }) => {
  const apiCall = useApiCall();
  const [list, setList] = React.useState<SelectMenuListProps[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    setIsLoading(true);

    (async () => {
      apiCall({
        method: 'GET',
        url: `${ENDPOINT_GET_AID_MASTER_API}?type=data_type`,
      })
        .then((res: { status: number; data: { result: { nameList: any[] } } }) => {
          if (res.status === 200) {
            const nameList: any[] = res.data.result.nameList;
            const result =
              nameList.map((elem: any) => {
                return {
                  label: elem,
                  value: elem,
                };
              }) || [];
            if (!unmounted) setList(result);
          }
        })
        .finally(() => {
          if (!unmounted) setIsLoading(false);
        });
    })();

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SelectMenu name="dataType" list={list} isLoading={isLoading} value={value} onChange={onChange} size={size} width={width} />;
};
