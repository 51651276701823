import React from 'react';
// import { useLocation, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, Flex, FlexRow, FlexCol, Box, ButtonContainer, FormGrid } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { DesignerStatusTag } from 'components/Molecules/Tag';
import { SortList } from 'components/Molecules/SortList';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { FormTextInput, FormDatePeriod } from 'components/Business/Form';
import { ApiSelectBrand, ApiSelectUser } from 'components/Business/Api';
import { TextLink } from 'components/Business/TextLink';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { Pager } from 'components/Molecules/Pager';
import { RHF_SETTINGS, ENDPOINT_GET_MEASURE_API } from 'constants/index';
import {
  smoothScroll,
  scrollToFirstError,
  // convertSearchParamsToJson, convertJsonToSearchParams
} from 'utils';
import { useApiCall } from 'hooks/useApiCall';
import { useValidationSchema } from './useValidationSchema';
import { MeasuresListProps, DirectionProps } from 'types';

export const AID001: React.FC = () => {
  // const location = useLocation();
  // const history = useHistory();
  const { t } = useTranslation();
  const apiCall = useApiCall();
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const {
    getValues,
    handleSubmit,
    // reset
  } = useFormMethods;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = React.useState('10');
  const [offset, setOffset] = React.useState('0');
  const [direction, setDirection] = React.useState<DirectionProps>('DESC');
  const [response, setResponse] = React.useState<MeasuresListProps[]>([]);
  const [searchFlag, setSearchFlag] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    return {
      selectKeyWord: '1',
      keyWord: formValues.keyWord || '',
      brandCode: formValues.brandCode || '',
      inCharge: formValues.inCharge || '',
      measuresDateStartFromYear: formValues.measuresDateStartFromYear || '',
      measuresDateStartFromMonth: formValues.measuresDateStartFromMonth || '',
      measuresDateStartFromDay: formValues.measuresDateStartFromDay || '',
      measuresDateStartToYear: formValues.measuresDateStartToYear || '',
      measuresDateStartToMonth: formValues.measuresDateStartToMonth || '',
      measuresDateStartToDay: formValues.measuresDateStartToDay || '',
      measuresDateEndFromYear: formValues.measuresDateEndFromYear || '',
      measuresDateEndFromMonth: formValues.measuresDateEndFromMonth || '',
      measuresDateEndFromDay: formValues.measuresDateEndFromDay || '',
      measuresDateEndToYear: formValues.measuresDateEndToYear || '',
      measuresDateEndToMonth: formValues.measuresDateEndToMonth || '',
      measuresDateEndToDay: formValues.measuresDateEndToDay || '',
      limit: limit,
      offset: offset,
      direction: direction,
    };
  }, [limit, offset, direction, getValues]);

  const handleSearch = React.useCallback(
    async ({ scrollOnComplete = false }) => {
      const data = getRequestData();
      setIsLoading(true);
      apiCall({
        method: 'GET',
        url: ENDPOINT_GET_MEASURE_API,
        data,
      })
        .then((res: { status: number; data: { result: { measuresMap: MeasuresListProps[]; count: string } } }) => {
          console.log(res);
          if (res.status === 200) {
            const measuresMap: MeasuresListProps[] = res.data.result.measuresMap;
            setResponse(measuresMap);
            setPageCount(Math.ceil(Number(res.data.result.count) / Number(limit)));
            setSearchFlag(true);
          }
        })
        .finally(() => {
          // history.replace({
          //   search: convertJsonToSearchParams(data),
          // });
          setIsLoading(false);
          if (scrollOnComplete) {
            smoothScroll({
              to: 'result',
              easing: 'easeInOutCirc',
              offset: -50,
            });
          }
        });
    },
    [
      limit,
      getRequestData,
      apiCall,
      // history
    ],
  );

  const onSuccess = React.useCallback(() => {
    handleSearch({ scrollOnComplete: true });
  }, [handleSearch]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  const handlePageChange = React.useCallback(
    (selected: number) => {
      setOffset(String(Number(limit) * selected));
      setPageNumber(selected);
    },
    [limit],
  );

  const handleClickSubmit = React.useCallback(() => {
    if (offset === '0') {
      handleSubmit(onSuccess, onError)();
      return;
    }
    handlePageChange(0);
  }, [handleSubmit, offset, onError, onSuccess, handlePageChange]);

  React.useEffect(() => {
    if (searchFlag) {
      if (offset === '0') {
        handleSubmit(onSuccess, onError)();
      } else {
        handlePageChange(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  React.useEffect(() => {
    if (searchFlag) handleSubmit(onSuccess, onError)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  React.useEffect(() => {
    // if (location.search) {
    //   const params = convertSearchParamsToJson(location.search);
    //   if (params?.offset && Number.isInteger(params.offset)) {
    //     setOffset(params.offset);
    //   }
    //   if (params?.direction && (params.direction === 'ASC' || params.direction === 'DESC')) {
    //     setDirection(params.direction);
    //   }
    // reset(params);
    // }
    // setTimeout(() => {
    handleSearch({ scrollOnComplete: false });
    // }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />

          <Container>
            <Breadcrumb
              list={[
                {
                  label: 'TOP',
                  url: '/CMN001',
                },
                {
                  label: 'AI Designer',
                },
              ]}
            />

            <Section>
              <H1>AI Designer</H1>
              <Text align="center">
                各施策のデザイン生成または、編集ができます。
                <br />
                必要な項目を入力すると、絞り込みが可能です。
              </Text>

              <Section hasBg>
                <Box color="grey">
                  <FormTextInput
                    name="keyWord"
                    label="施策名"
                    placeholder="キーワードを入力"
                    note="※キーワードは一部の入力でも絞り込みが可能です。"
                    size="small"
                  />

                  <FormGrid size="small">
                    <FlexRow>
                      <FlexCol col={6}>
                        <ApiSelectBrand size="small" inputSize="small" />
                      </FlexCol>
                      <FlexCol col={6}>
                        <ApiSelectUser label="施策担当者名" size="small" inputSize="small" />
                      </FlexCol>
                    </FlexRow>
                  </FormGrid>

                  <FormDatePeriod
                    names={{
                      start: {
                        year: 'measuresDateStartFromYear',
                        month: 'measuresDateStartFromMonth',
                        date: 'measuresDateStartFromDay',
                      },
                      end: {
                        year: 'measuresDateStartToYear',
                        month: 'measuresDateStartToMonth',
                        date: 'measuresDateStartToDay',
                      },
                    }}
                    label="施策開始日"
                    size="small"
                  />

                  <FormDatePeriod
                    names={{
                      start: {
                        year: 'measuresDateEndFromYear',
                        month: 'measuresDateEndFromMonth',
                        date: 'measuresDateEndFromDay',
                      },
                      end: {
                        year: 'measuresDateEndToYear',
                        month: 'measuresDateEndToMonth',
                        date: 'measuresDateEndToDay',
                      },
                    }}
                    label="施策終了日"
                    size="small"
                  />

                  <ButtonContainer>
                    <Button type="secondary" text="絞り込む" onClick={handleClickSubmit} />
                  </ButtonContainer>
                </Box>

                {response.length > 0 && (
                  <SortList
                    list={[
                      {
                        text: '施策開始日の新しい順',
                        onClick: () => {
                          setDirection('DESC');
                        },
                        current: direction === 'DESC',
                      },
                      {
                        text: '施策開始日の古い順',
                        onClick: () => {
                          setDirection('ASC');
                        },
                        current: direction === 'ASC',
                      },
                    ]}
                  />
                )}

                <Box id="result" mt={10}>
                  {response.length === 0 && searchFlag && <Text mt={40}>{t('MSG-ZERO-RESULT')}</Text>}

                  {response.map((elem: MeasuresListProps, index: number) => (
                    <Box key={index} color="white" mt={10}>
                      <Flex justifyContent="space-between">
                        <Text size={18} bold>
                          {elem.measuresName || '-'}
                        </Text>
                        <TextLink to={`/AID005/${elem.measuresId}`} icon="arrow-right" iconPosition="after" nowrap ml={10}>
                          デザイン生成概要
                        </TextLink>
                      </Flex>

                      <FlexRow mt={15} alignItems="center" justifyContent="start">
                        <FlexCol>
                          <Flex alignItems="center">
                            <Text size={14} mt={0}>
                              施策担当者名
                            </Text>
                            <Text mt={0} ml={20}>
                              {elem.inCharge || '-'}
                            </Text>
                          </Flex>
                        </FlexCol>

                        <FlexCol>
                          <Flex alignItems="center">
                            <Text size={14} mt={0}>
                              推論状況
                            </Text>
                            <DesignerStatusTag status={elem.recommendationStatus} ml={20} />
                          </Flex>
                        </FlexCol>
                      </FlexRow>

                      <ButtonContainer mt={20}>
                        <Button type="primary" to={`/AID002/${elem.measuresId}`} text="デザイン生成" />
                      </ButtonContainer>
                    </Box>
                  ))}
                </Box>

                <Pager pageCount={pageCount} pageNumber={pageNumber} onPageChange={handlePageChange} />
              </Section>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
