import dayjs from 'dayjs';

type Props = {
  year?: string;
  month?: string;
  date?: string;
  days: number[];
};

export const isMatchDayOfWeek = ({ year, month, date, days }: Props) => {
  if (!year && !month && !date) return true;
  const dateObject = dayjs()
    .year(Number(year))
    .month(Number(month) - 1)
    .date(Number(date))
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  if (!dateObject.isValid()) return false;

  const dayOfWeek = dateObject.day();

  return days.includes(dayOfWeek);
};
