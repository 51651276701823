import React from 'react';
import { FormTextInput, FormArray } from 'components/Business/Form';

type Props = {
  k: number;
};

export const FreeAreaPostingCode: React.FC<Props> = ({ k }) => {
  return (
    <>
      <FormArray
        name={`webProductionDetail.freeArea[${k}].postingCode`}
        label="掲載対象プール中コード数"
        isRequired
        hasUndecidedButton
        contentLabel={(k2: number) => `${k2 + 1}:掲載対象プール中`}
        content={(k2: number) => {
          return (
            <React.Fragment key={k2}>
              <FormTextInput
                type="number"
                name={`webProductionDetail.freeArea[${k}].postingCode[${k2}].postingPool`}
                label={`${k2 + 1}:掲載対象プール中`}
                placeholder="「E10100」等を入力"
                isRequired
                hasUndecidedButton
              />
            </React.Fragment>
          );
        }}
      />
    </>
  );
};
