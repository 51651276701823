import React from 'react';
import { TextLink } from 'components/Business/TextLink';
import style from './style.module.scss';
import { SvgIcon } from 'components/Atoms/SvgIcon';

type BreadcrumbListProps = {
  label: string;
  url?: string;
};

type Props = {
  list: BreadcrumbListProps[];
};

export const Breadcrumb: React.FC<Props> = ({ list }) => {
  return React.useMemo(
    () => (
      <ul className={`${style.breadcrumb} breadcrumb`} itemScope itemType="http://schema.org/BreadcrumbList">
        {list.map((elem, index) => (
          <li key={index} itemProp="itemListElement" itemScope itemType="http://schema.org/Listitem">
            {elem.url && (
              <TextLink to={elem.url} itemProp="item">
                <span itemProp="name">{elem.label}</span>
              </TextLink>
            )}
            {!elem.url && <span itemProp="name">{elem.label}</span>}
            <meta itemProp="position" content={String(index + 1)} />
            {index < list.length - 1 && <SvgIcon type="arrow-right" fill="#222" width={6} className={style['breadcrumb-icon-arrow']} />}
          </li>
        ))}
      </ul>
    ),
    [list],
  );
};
