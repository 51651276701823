import React from 'react';
import { Text } from 'components/Atoms/Typography';
import { Box } from 'components/Atoms/Layout';
import { Server400ErrorState, Server400ErrorUpdate } from 'context';
import { smoothScroll } from 'utils';

export const ServerErrorBox: React.FC = () => {
  const server400Error = React.useContext(Server400ErrorState);
  const setServer400Error = React.useContext(Server400ErrorUpdate);

  React.useEffect(() => {
    setServer400Error(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (server400Error) {
      smoothScroll({
        to: 0,
        easing: 'easeInOutCirc',
      });
    }
  }, [server400Error]);

  return React.useMemo(
    () => (
      <>
        {server400Error && (
          <Box color="red" size="small">
            <Text color="red">
              問題が発生しました。もう一度入力をし直しても解決しない場合は、システム管理者に「code」と「content」をお知らせください。
              <br />
              code: {server400Error.code}
              <br />
              content: {server400Error.content}
            </Text>
          </Box>
        )}
      </>
    ),
    [server400Error],
  );
};
