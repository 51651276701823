import React from 'react';
import { TextLink } from 'components/Business/TextLink';
import style from './style.module.scss';
import type { TextLinkProps } from 'components/Business/TextLink';

type Props = {
  list: (TextLinkProps & { text: string })[];
  className?: string;
};

export const LinkList: React.FC<Props> = ({ list, className = '' }) => {
  return React.useMemo(
    () => (
      <ul className={`${style['list-link']} ${className}`}>
        {list.map((link, index) => (
          <li key={index} className={`${style['list-link-item']} ${link.current ? 'is-current' : ''}`}>
            <TextLink
              to={link.to}
              onClick={link.onClick}
              current={link.current}
              disabled={link.disabled}
              isExternal={link.isExternal}
              isSync={link.isSync}
              itemProp={link.itemProp}
              className={`${style['list-link-item-link']} ${link.className}`}
              color={link.color}
            >
              {link.text}
            </TextLink>
          </li>
        ))}
      </ul>
    ),
    [list, className],
  );
};
