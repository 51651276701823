import React from 'react';
import { useParams } from 'react-router-dom';
import { Main, Container, Section, SectionInner, ButtonContainer, PositionContainer, PositionItem, FlexRow, FlexCol, Flex, Box } from 'components/Atoms/Layout';
import { H1, H2, H3, H4, H5, Text, MultiLineText } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Table } from 'components/Atoms/Table';
import { Header } from 'components/Business/Header';
import { DesignerStatusTag } from 'components/Molecules/Tag';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { DataTable } from 'components/Organisms/DataTable';
import { TabMenu } from 'components/Organisms/TabMenu';
import { ApiGetAidDetailProvider } from 'components/Business/Api/ApiGetAidDetailProvider';
import { TextLink } from 'components/Business/TextLink';
import { DESIGNTARGET_VALUES, EMPTY_LABEL, URL_AID_DESIGN_CONFIGURATION_DIRECTORY, URL_AID_DESIGN_CONFIGURATION_PARAMS } from 'constants/index';
import type { MeasureParamsProps, DesignDetailProps, RecommendationResultProps, ModuleProps, ModuleAssignmentProps } from 'types';
import type { TableDataProps } from 'components/Atoms/Table';
import type { DataTableProps } from 'components/Organisms/DataTable';
import type { TabDataProps } from 'components/Organisms/TabMenu';

export const AID005: React.FC = () => {
  const { measureId } = useParams<MeasureParamsProps>();
  const [response, setResponse] = React.useState<DesignDetailProps>();
  const [tableData, setTableData] = React.useState<TableDataProps>();
  const [requirementData, setRequirementData] = React.useState<DataTableProps[]>([]);
  const [materialData, setMaterialData] = React.useState<TabDataProps[]>([]);
  const tableHeader = React.useMemo(
    () => [
      { label: '制作種別', width: '25%' },
      { label: '構成ファイル名', width: '35%' },
      { label: '構成ファイル', width: '40%' },
    ],
    [],
  );

  const apiCallbackHandler = React.useCallback((result) => {
    if (!result) return;
    setResponse(result);
  }, []);

  React.useEffect(() => {
    if (!response) return;
    console.log(response);

    if (response?.recommendationStatus !== '1') {
      const tableData =
        response?.recommendationResults && typeof response?.recommendationResults === 'object' && response?.recommendationResults.length > 0
          ? response?.recommendationResults?.map((elem: RecommendationResultProps) => {
              return [
                `${elem?.productNumber && elem?.productType ? elem.productNumber + ': ' + elem.productType : EMPTY_LABEL}`,
                elem?.designConfFileName || EMPTY_LABEL,
                elem?.designConfFileUrl ? (
                  <TextLink to={elem.designConfFileUrl} isExternal>
                    {elem.designConfFileUrl}
                  </TextLink> // TODO: ファイルへの直リンクができる場合設定
                ) : (
                  EMPTY_LABEL
                ),
              ];
            })
          : [[EMPTY_LABEL, EMPTY_LABEL, EMPTY_LABEL]];
      setTableData(tableData);

      const requirementData: DataTableProps[] = [
        {
          type: 'text',
          label: '制作目的',
          value: response?.purpose,
        },
        {
          type: 'text',
          label: 'ターゲットユーザー層',
          value: response?.targetUsers,
        },
        {
          type: 'text',
          label: '特典有無/種別',
          value: response?.specialOffer,
        },
      ];
      setRequirementData(requirementData);

      const materialData: TabDataProps[] =
        response?.moduleAssignments?.map((moduleAssignment: ModuleAssignmentProps) => {
          const designTarget = moduleAssignment?.modules?.length > 0 ? '1' : '2';

          return {
            label: `${moduleAssignment?.productNumber}: ${moduleAssignment?.productType}`,
            content: (
              <>
                <DataTable
                  data={[
                    {
                      type: 'radio',
                      label: 'デザイン生成',
                      value: designTarget,
                      master: DESIGNTARGET_VALUES,
                    },
                    {
                      condition: designTarget === '1' && moduleAssignment?.productType === '外部バナー',
                      type: 'text',
                      label: '制作種別',
                      value: moduleAssignment?.productSubType,
                    },
                  ]}
                />

                {designTarget === '1' && (
                  <Section>
                    <H4>素材指定情報</H4>

                    {moduleAssignment?.modules?.map((module: ModuleProps, index: number) => (
                      <Section key={index}>
                        <H5>素材{index + 1}</H5>

                        <DataTable
                          data={[
                            {
                              type: 'text',
                              label: '素材データ種別',
                              value: module?.dataType,
                            },
                            {
                              type: 'text',
                              label: '識別番号',
                              value: module?.moduleNum,
                            },

                            {
                              condition: module?.dataType === '画像' || module?.dataType === '動画' || module?.dataType === '他社埋め込みサービス',
                              type: 'text',
                              label: 'URL',
                              value: module?.url,
                            },

                            {
                              condition: module?.dataType === 'テキスト',
                              type: 'text',
                              label: '素材種類',
                              value: module?.attributes?.type,
                            },
                            {
                              condition: module?.dataType === 'テキスト',
                              type: 'text',
                              label: 'テキスト文言',
                              value: module?.text,
                            },
                            {
                              condition: module?.dataType === 'テキスト',
                              type: 'text',
                              label: 'フォント指定',
                              value: module?.fontName,
                            },
                            {
                              condition: module?.dataType === 'テキスト',
                              type: 'text',
                              label: '季節',
                              value: module?.attributes?.season,
                            },
                            {
                              condition: module?.dataType === 'テキスト',
                              type: 'text',
                              label: 'ブランド',
                              value: module?.attributes?.brand,
                            },
                          ]}
                        />
                      </Section>
                    ))}
                  </Section>
                )}
              </>
            ),
          };
        }) || [];
      setMaterialData(materialData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <>
      <Header />
      <Main>
        <ApiGetAidDetailProvider callback={(result) => apiCallbackHandler(result)}>
          <Container>
            <Breadcrumb
              list={[
                {
                  label: 'TOP',
                  url: '/CMN001',
                },
                {
                  label: 'AI Designer',
                  url: '/AID001',
                },
                {
                  label: response?.measureName || '',
                },
              ]}
            />

            {response?.recommendationErrorMessage && (
              <Box color="red">
                <MultiLineText color="red" value={response?.recommendationErrorMessage}></MultiLineText>
              </Box>
            )}

            <Section>
              <H1>{response?.measureName}</H1>

              <Section hasBg>
                <PositionContainer>
                  <H2>デザイン生成概要</H2>
                  {response?.recommendationStatus !== '1' && (
                    <PositionItem top="0" right="0">
                      <Button type="primary" size="small" text="編集" to={`/AID002/${measureId}`} />
                    </PositionItem>
                  )}
                </PositionContainer>

                <SectionInner>
                  {response && (
                    <>
                      <Flex justifyContent="center">
                        <Box color="grey" size="xsmall" width="fit-content">
                          <FlexRow justifyContent="center" alignItems="center" separator={true}>
                            <FlexCol>
                              <Text bold>デザイン推論</Text>
                            </FlexCol>
                            <FlexCol>
                              <DesignerStatusTag status={response?.recommendationStatus} />
                            </FlexCol>
                          </FlexRow>
                        </Box>
                      </Flex>

                      {response?.recommendationStatus === '1' && (
                        <>
                          <Text align="center">
                            AI Designerでの下記、推論要件を満たしていません。 <br />
                            情報に不備がある場合は、施策担当者にご確認ください。
                          </Text>

                          <Text align="center">推論要件：デザイン推論対象バナーの制作点数が1点以上である。</Text>
                        </>
                      )}

                      {response?.recommendationStatus !== '1' && (
                        <>
                          <Text align="center">
                            構成ファイルの格納先URLから遷移できない場合があります。
                            <br />
                            下記のページへ遷移し、目的のフォルダを開いてください。
                            <br />
                            <TextLink
                              to={`${URL_AID_DESIGN_CONFIGURATION_DIRECTORY}${measureId}/${URL_AID_DESIGN_CONFIGURATION_PARAMS}`}
                              isExternal
                              icon="arrow-right"
                              iconPosition="before"
                            >
                              施策別構成ファイルの格納ディレクトリ
                            </TextLink>
                          </Text>

                          <Table header={tableHeader} data={tableData} mt={30} />

                          <Section>
                            <H3>制作要件</H3>
                            <DataTable data={requirementData} />
                          </Section>

                          <Section>
                            <H3>素材指定</H3>
                            {materialData.length > 0 && <TabMenu data={materialData} />}
                          </Section>
                        </>
                      )}
                    </>
                  )}

                  <PositionContainer mt={40}>
                    <ButtonContainer>
                      <PositionItem left="0" centerY>
                        <Button type="secondary" icon="arrow-left" iconPosition="before" size="small" isFlexible text="AI Designer一覧へ戻る" to="/AID001" />
                      </PositionItem>

                      {response?.recommendationStatus !== '1' && <Button type="primary" text="編集" to={`/AID002/${measureId}`} />}
                    </ButtonContainer>
                  </PositionContainer>
                </SectionInner>
              </Section>
            </Section>
          </Container>
        </ApiGetAidDetailProvider>
      </Main>
      <Footer />
    </>
  );
};
