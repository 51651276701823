import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Modal } from 'components/Organisms/Modal';
import { CognitoUserState, Server400ErrorUpdate } from 'context';
import { ENDPOINT_POST_DELIVERY_ITEM_UPLOAD_API } from 'constants/index';
import { useApiCall } from 'hooks/useApiCall';
import style from './style.module.scss';
import { asyncEncodeFileToBase64 } from 'utils'

type Props = {
  file: File;
  disabled?: boolean;
};

export const ApiUploadPromotionDeliveryInfoButton: React.FC<Props> = ({ file, disabled = false }) => {
  const apiCall = useApiCall();
  const userInfo = React.useContext(CognitoUserState);
  const setServer400Error = React.useContext(Server400ErrorUpdate);
  const { handleSubmit } = useFormContext();
  const [openSaveModal, setOpenSaveModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState("");

  const onUpload = React.useCallback(async () => {
    const data = {
      fileName: file.name,
      file: await asyncEncodeFileToBase64(file),
      updateInCharge: userInfo?.username
    };

    apiCall({
      method: 'POST',
      url: ENDPOINT_POST_DELIVERY_ITEM_UPLOAD_API,
      data
    })
    .then((res: { status: number; data: { result: { janCodeList: string[] }}}) => {
      setErrorCode('')
    })
    .catch((error) => {
      if (!error.response) {
        return;
      }

      if (error.response.status === 400) {
        setServer400Error(error.response.data.error);
        if (['ERR00004','ERR00016','ERR00017','ERR01002'].includes(error.response.data.error.code)) {
          setErrorCode(error.response.data.error.code);
        }
        else {
          setErrorCode('ERR99001');
        }
      }
      else {
        setErrorCode('ERR99001');
      }
    })
    .finally(() => {
      setOpenSaveModal(true)
      setIsLoading(false);
    });
  }, [file, userInfo, apiCall, setErrorCode, setServer400Error, setOpenSaveModal, setIsLoading]);

  const onSuccess = React.useCallback(async () => {
    setIsLoading(true);
    setServer400Error(undefined);
    onUpload();
  }, [onUpload, setServer400Error]);

  const onError = React.useCallback((errors: any, _e: any) => {
    setServer400Error(undefined);
  }, [setServer400Error]);

  const onCloseModal = React.useCallback(() => {
    setOpenSaveModal(false);
  }, [setOpenSaveModal]);

  return (
    <>
      <Loading isLoading={isLoading} />

      <Button
        type="secondary"
        text="アップロード"
        onClick={() => handleSubmit(onSuccess, onError)()}
        className={`${style['api-upload-promotion-delivery-info']}`}
        disabled={disabled}
      />

      <Modal
        type={errorCode === "" ? "success" : 'warn'}
        title={errorCode === "" ? "アップロードが完了しました" : "アップロードできませんでした"}
        hasSubmit
        isShow={openSaveModal}
        onCancel={onCloseModal}
        onSubmit={onCloseModal}
      >
        {['ERR00004','ERR00016','ERR01002'].includes(errorCode) && (
          <Text align="center">
            指定されたファイルの内容が正しくない可能性があります。
            <br />
            記述内容をご確認のうえアップロードいただきますよう、お願いします。
          </Text>
        )}
        {errorCode === 'ERR00017' && (
          <Text align="center">
            指定されたファイル名は既にアップロードされています。
            <br />
            ファイルの重複をご確認いただき、必要な場合はファイル名を変更のうえ
            <br />
            アップロードいただきますよう、お願いします。
          </Text>
        )}
        {['ERR99001'].includes(errorCode) && (
          <Text align="center">
            通信に失敗しました。
            <br />
            もう一度お試しください。
          </Text>
        )}
      </Modal>
    </>
  );
};
