import React from 'react';
import QRCode from 'qrcode.react';
import style from './style.module.scss';

type Props = {
  url: string;
  size: number;
  mt?: number;
  ml?: number;
};

export const Qr: React.FC<Props> = ({ url, size, mt, ml }) =>
  React.useMemo(
    () => (
      <QRCode
        value={url}
        size={size - 10}
        className={style['qr']}
        style={{
          ...(mt !== undefined && { marginTop: `${mt}px` }),
          ...(ml !== undefined && { marginLeft: `${ml}px` }),
        }}
      />
    ),
    [url, size, mt, ml],
  );
