import React from 'react';
import style from './style.module.scss';

const Align = {
  right: 'right',
  center: 'center',
  left: 'left',
} as const;
type AlignProps = typeof Align[keyof typeof Align];

type Props = {
  align?: AlignProps;
};

export const Note: React.FC<Props> = ({ children, align = 'left' }) => <p className={`${style['typography-note']} is-align-${align}`}>{children}</p>;
