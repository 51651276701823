import React from 'react';
import { useParams } from 'react-router-dom';
import { Main, Container, Section, SectionInner, ButtonContainer, PositionContainer, PositionItem, Flex, FlexRow, FlexCol } from 'components/Atoms/Layout';
import { H1, H2, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { LinkList } from 'components/Atoms/List';
import { Box } from 'components/Atoms/Layout/Box';
import { Header } from 'components/Business/Header';
import { CampaignStatusTag } from 'components/Molecules/Tag';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { DataTable } from 'components/Organisms/DataTable';
import { ApiGetMeasuresDetailProvider } from 'components/Business/Api';
import {
  SAMPLETYPE_VALUES,
  APPLICATIONFLOW_VALUES,
  MEASURETYPE_VALUES,
  MAILPERMISSION_VALUES,
  DELIVERYHIDDENSUPPORT_VALUES,
  SAMPLESETTINGPRODUCTION_LABELS,
} from 'constants/index';
import type { MeasureParamsProps, MeasureDetailProps } from 'types';
import type { DataTableProps } from 'components/Organisms/DataTable';

export const CPF011: React.FC = () => {
  const { measureId } = useParams<MeasureParamsProps>();
  const [response, setResponse] = React.useState<MeasureDetailProps>();
  const [sampleSettingsData, setSampleSettingsData] = React.useState<DataTableProps[]>([]);

  const apiCallbackHandler = React.useCallback((result) => {
    setResponse(result);
  }, []);

  React.useEffect(() => {
    if (!response) return;

    const sampleSettingsData: DataTableProps[] = [
      {
        type: 'radio',
        label: 'サンプル・モニター種類',
        value: response?.sampleSettingsDetail?.sampleType,
        master: SAMPLETYPE_VALUES,
      },
      {
        type: 'text',
        label: 'プレゼント名',
        value: response?.sampleSettingsDetail?.presentName,
      },
      {
        type: 'date',
        label: '開始日',
        value: {
          year: response?.sampleSettingsDetail?.startDateYear,
          month: response?.sampleSettingsDetail?.startDateMonth,
          date: response?.sampleSettingsDetail?.startDateDay,
        },
      },
      {
        type: 'date',
        label: '終了日',
        value: {
          year: response?.sampleSettingsDetail?.endDateYear,
          month: response?.sampleSettingsDetail?.endDateMonth,
          date: response?.sampleSettingsDetail?.endDateDay,
        },
      },
      {
        type: 'radio',
        label: '応募フロー',
        value: response?.sampleSettingsDetail?.applicationFlow,
        master: APPLICATIONFLOW_VALUES,
      },
      {
        type: 'radio',
        label: '施策種別',
        value: response?.sampleSettingsDetail?.measuresType,
        master: MEASURETYPE_VALUES,
      },
      {
        condition: response?.sampleSettingsDetail?.measuresType === '2',
        type: 'text',
        label: '上限当選数',
        value: response?.sampleSettingsDetail?.maxWinners,
      },
      {
        type: 'radio',
        label: 'メールのパーミッション',
        value: response?.sampleSettingsDetail?.mailPermission,
        master: MAILPERMISSION_VALUES,
      },
      {
        type: 'text',
        label: '特筆事項',
        value: response?.sampleSettingsDetail?.notices,
      },
      {
        type: 'radio',
        label: '配送情報の非表示対応',
        value: response?.sampleSettingsDetail?.deliveryHiddenSupport,
        master: DELIVERYHIDDENSUPPORT_VALUES,
      },
      {
        type: 'text',
        label: 'クーポン（金額指定）',
        value: response?.sampleSettingsDetail?.couponAmount,
        unit: '円',
      },
      {
        type: 'text',
        label: 'クーポン（ID）',
        value: response?.sampleSettingsDetail?.couponId,
      },
      {
        type: 'checkbox',
        label: '制作物',
        value: [
          response?.sampleSettingsDetail?.questionnaire,
          response?.sampleSettingsDetail?.couponBanner,
          response?.sampleSettingsDetail?.brandBanner,
          response?.sampleSettingsDetail?.afterFollowHtml,
          response?.sampleSettingsDetail?.afterFollowText,
          response?.sampleSettingsDetail?.noProduction,
        ],
        master: SAMPLESETTINGPRODUCTION_LABELS,
      },
      {
        type: 'text',
        label: '（IMJ入力）管理番号',
        value: response?.sampleSettingsDetail?.imjManagementNumber,
      },
    ];
    setSampleSettingsData(sampleSettingsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <>
      <Header />
      <Main>
        <ApiGetMeasuresDetailProvider callback={apiCallbackHandler}>
          <Container>
            <Breadcrumb
              list={[
                {
                  label: 'TOP',
                  url: '/CMN001',
                },
                {
                  label: '登録済み施策一覧',
                },
                {
                  label: response?.measuresName || '',
                },
              ]}
            />

            <Section>
              <H1>{response?.measuresName}</H1>

              <LinkList
                list={[
                  {
                    text: '施策概要・依頼内容',
                    to: `/CPF008/${measureId}`,
                  },
                  {
                    text: 'Web制作',
                    to: `/CPF009/${measureId}`,
                  },
                  {
                    text: 'キャンペーン設定・クーポン発行',
                    to: `/CPF010/${measureId}`,
                  },
                  {
                    text: 'サンプル・モニター設定',
                    to: `/CPF011/${measureId}`,
                    current: true,
                    disabled: true,
                  },
                ]}
              />

              <Section hasBg>
                <PositionContainer>
                  <H2>サンプル・モニター設定</H2>
                  <PositionItem top="0" right="0">
                    <Button type="primary" size="small" text="編集" to={`/CPF004/${measureId}`} />
                  </PositionItem>
                </PositionContainer>

                <SectionInner>
                  {response && (
                    <Flex justifyContent="center">
                      <Box color="grey" size="xsmall" width="fit-content">
                        <FlexRow justifyContent="center" alignItems="center" separator={true}>
                          <FlexCol>
                            <Text bold>入力状況</Text>
                          </FlexCol>
                          <FlexCol>
                            <CampaignStatusTag status={response?.sampleStatus} />
                          </FlexCol>
                        </FlexRow>
                      </Box>
                    </Flex>
                  )}

                  <Section>{response?.sampleStatus !== '3' && <DataTable data={sampleSettingsData} />}</Section>

                  <PositionContainer mt={40}>
                    <ButtonContainer>
                      <PositionItem left="0" centerY>
                        <Button type="secondary" icon="arrow-left" iconPosition="before" size="small" isFlexible text="施策一覧へ戻る" to="/CPF007" />
                      </PositionItem>
                      <Button type="primary" text="編集" to={`/CPF004/${measureId}`} />
                    </ButtonContainer>
                  </PositionContainer>
                </SectionInner>
              </Section>
            </Section>
          </Container>
        </ApiGetMeasuresDetailProvider>
      </Main>
      <Footer />
    </>
  );
};
