import React from 'react';
import { FormSelectMenu } from 'components/Business/Form';
import { ENDPOINT_GET_BRAND_API } from 'constants/index';
import { useApiCall } from 'hooks/useApiCall';
import type { BrandProps } from 'types';
import type { SelectMenuListProps } from 'components/Atoms/Form/SelectMenu';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';
import type { TextInputSizeProps } from 'components/Atoms/Form/TextInput';

type Props = {
  size?: FormGridSizeProps;
  inputSize?: TextInputSizeProps;
};

export const ApiSelectBrand: React.FC<Props> = ({ size = 'large', inputSize = 'medium' }) => {
  const apiCall = useApiCall();
  const [brands, setBrands] = React.useState<SelectMenuListProps[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    setIsLoading(true);

    (async () => {
      apiCall({
        method: 'GET',
        url: ENDPOINT_GET_BRAND_API,
      })
        .then((res: { status: number; data: { result: { brandList: BrandProps[] } } }) => {
          if (res.status === 200) {
            const brandList: BrandProps[] = res.data.result.brandList;
            const result =
              brandList.map((elem: BrandProps) => {
                return {
                  label: elem.brandName,
                  value: elem.brandCode,
                };
              }) || [];
            if (!unmounted) setBrands(result);
          }
        })
        .finally(() => {
          if (!unmounted) setIsLoading(false);
        });
    })();

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FormSelectMenu name="brandCode" label="ブランド" list={brands} isLoading={isLoading} size={size} inputSize={inputSize} />;
};
