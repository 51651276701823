export const createNumberOptions = (min: number, max: number) => {
  return [...Array(max + 1)]
    .map((_, index) => {
      return {
        label: String(index),
        value: String(index),
      };
    })
    .filter((_, index) => min <= index);
};
