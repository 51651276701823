import React from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import OutsideClickHandler from 'react-outside-click-handler';
import get from 'lodash/get';
import { FormGrid } from 'components/Atoms/Layout';
import { Checkbox, TextInput } from 'components/Atoms/Form';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { Tag } from 'components/Atoms/Tag';
import { FormArray, FormHiddenInput, FormTextReadOnly } from 'components/Business/Form';
import { escapeRegExp } from 'utils';
import style from './style.module.scss';
import type { TextInputSizeProps } from 'components/Atoms/Form/TextInput';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';

type OptionsProps = {
  label: string;
  value: string;
  active: boolean;
  checked: boolean;
};

type Props = {
  name: string;
  valueName: string;
  labelName: string;
  data: { label: string; value: string }[];
  label?: string;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  isRequired?: boolean;
  help?: string;
  hasUndecidedButton?: boolean;
  isLoading: boolean;
  searchable?: boolean;
  size?: FormGridSizeProps;
  inputSize?: TextInputSizeProps;
};

export const FormSelectMenuMultiple: React.FC<Props> = ({
  name,
  valueName,
  labelName,
  data,
  label = '',
  isRequired = false,
  help,
  hasUndecidedButton = false,
  isLoading,
  searchable = false,
}) => {
  const { errors } = useFormState();
  const ref = React.useRef<any>();
  const watchedValue = useWatch({ name });
  const [isOpenDropdown, setOpenDropdown] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [options, setOptions] = React.useState<OptionsProps[]>([]);
  const error = get(errors, name);

  const closeDropdown = React.useCallback(() => {
    if (!isOpenDropdown) return;
    setOpenDropdown(false);
  }, [isOpenDropdown]);

  const openDropdown = React.useCallback(() => {
    setOpenDropdown(true);
  }, []);

  const handleOutsideClick = React.useCallback(() => {
    closeDropdown();
  }, [closeDropdown]);

  const handleSelected = React.useCallback(
    (event) => {
      event.stopPropagation();
      console.log('handleSelected', isOpenDropdown);
      if (isOpenDropdown) {
        closeDropdown();
        return;
      }
      openDropdown();
    },
    [isOpenDropdown, closeDropdown, openDropdown],
  );

  const removeTarget = React.useCallback(
    (event, index: number) => {
      event.stopPropagation();

      // const removeTargetValue = watchedValue[index][valueName];
      // const newOptions = options.map((elem) => {
      //   const returnValue = elem;
      //   if (returnValue.value === removeTargetValue) {
      //     returnValue.checked = false;
      //   }
      //   return returnValue;
      // });
      // setOptions(newOptions);

      ref.current && ref.current.remove(index);
    },
    [
      // valueName, options, watchedValue
    ],
  );

  const handleChangeSearchInput = React.useCallback((val) => {
    setSearchValue(val);
  }, []);

  const handleChangeCheckbox = React.useCallback(
    (checked: boolean, value: string) => {
      const newOptions = options.map((elem) => {
        const returnValue = elem;
        if (returnValue.value === value) {
          returnValue.checked = checked;
        }
        return returnValue;
      });
      // setOptions(newOptions);

      if (checked) {
        const labelValue = newOptions.find((option) => option.value === value)?.label;
        ref.current &&
          ref.current.append({
            [labelName]: labelValue,
            [valueName]: value,
          });
      } else {
        const removeIndex = watchedValue?.findIndex((field: { [x: string]: string }) => {
          return field?.[valueName as keyof Record<'id', string>] === value;
        });
        ref.current && ref.current.remove(removeIndex);
        // remove(removeIndex);
      }
    },
    [options, watchedValue, labelName, valueName],
  );

  const filterOptions = React.useCallback(() => {
    const newOptions = options.map((option) => {
      const returnValue = option;
      if (returnValue.label.match(new RegExp(escapeRegExp(searchValue), 'gi'))) {
        returnValue.active = true;
      } else {
        returnValue.active = false;
      }
      return returnValue;
    });
    setOptions(newOptions);
  }, [options, searchValue]);

  const init = React.useCallback(() => {
    const defaultValues =
      watchedValue && typeof watchedValue === 'object'
        ? watchedValue?.map((field: { [x: string]: any }) => field[valueName as keyof Record<'id', string>])
        : [];
    const defaultOptions = data.map((option) => {
      return {
        label: option.label,
        value: option.value,
        active: true,
        checked: defaultValues.includes(option.value),
      };
    });
    setOptions(defaultOptions);
  }, [data, valueName, watchedValue]);

  React.useEffect(() => {
    const selectedValues = watchedValue && typeof watchedValue === 'object' ? watchedValue?.map((value: { [x: string]: any }) => value[valueName]) : [];
    const newOptions = options.map((option) => {
      const returnValue = option;
      returnValue.checked = selectedValues?.includes(option.value);
      return returnValue;
    });
    setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValue]);

  React.useEffect(() => {
    filterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  React.useEffect(() => {
    if (data) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return React.useMemo(
    () => (
      <FormGrid>
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <FormArray
            ref={ref}
            type="select"
            name={name}
            label={label}
            isRequired={isRequired}
            help={help}
            hasUndecidedButton={hasUndecidedButton}
            className={`${error ? 'is-invalid' : ''}`}
            hasSelect={false}
            hasAddButton={false}
            onClickSelect={handleSelected}
            isLoading={isLoading}
            contentLabel={(key: number) => `${key + 1}: ${label}`}
            content={(key: number) => {
              return (
                <React.Fragment key={key}>
                  <FormHiddenInput name={`${name}[${key}].${valueName}`} />

                  <Tag color="grey" className={style.tag}>
                    <FormTextReadOnly name={`${name}[${key}].${labelName}`} />
                    <button
                      className={style['tag-icon-delete']}
                      onClick={(event) => {
                        removeTarget(event, key);
                      }}
                    >
                      <SvgIcon type="cross" fill="#fff" width={8} />
                    </button>
                  </Tag>
                </React.Fragment>
              );
            }}
            dropdown={
              <>
                {isOpenDropdown && (
                  <div className={style['dropdown']}>
                    {searchable && (
                      <div className={`${style['input-search-wrapper']}`}>
                        <TextInput name={`${name}_search`} value={searchValue} onChange={handleChangeSearchInput} className={`${style['input-search']}`} />
                        <SvgIcon type="search" width={16} className={style['input-search-icon']} />
                      </div>
                    )}

                    <div className={style['options']}>
                      {options.length > 0 &&
                        options.map((elem, index) => (
                          <React.Fragment key={index}>
                            {elem.active && (
                              <Checkbox
                                name={`${name}_${index}`}
                                label={elem.label}
                                checked={elem.checked}
                                onChange={(checked: boolean) => {
                                  handleChangeCheckbox(checked, elem.value);
                                }}
                                isPlain
                              />
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                )}
              </>
            }
          />
        </OutsideClickHandler>
      </FormGrid>
    ),
    [
      handleChangeCheckbox,
      handleSelected,
      handleOutsideClick,
      handleChangeSearchInput,
      removeTarget,
      isOpenDropdown,
      searchValue,
      error,
      options,
      isLoading,
      name,
      labelName,
      valueName,
      searchable,
      help,
      isRequired,
      label,
      hasUndecidedButton,
    ],
  );
};
