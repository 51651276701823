import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    purpose: yup.string().required(t('ERR-REQUIRED')),

    targetUsers: yup.string().required(t('ERR-REQUIRED')),

    specialOffer: yup.string().required(t('ERR-REQUIRED')),

    moduleAssignments: yup.array(
      yup.object().shape({
        designTarget: yup.string().required(t('ERR-REQUIRED')),

        productSubType: yup.string().when('designTarget', {
          is: (value: string) => value === '1',
          then: yup.string().when('productType', {
            is: (value: string) => value === '外部バナー',
            then: yup.string().required(t('ERR-REQUIRED')),
          }),
        }),

        modules: yup.array().when('designTarget', {
          is: (value: string) => value === '1',
          then: yup
            .array
            // yup.object().shape({
            //   moduleId: yup.string().required(t('ERR-REQUIRED')),

            //   dataType: yup.string().required(t('ERR-REQUIRED')),

            //   moduleNum: yup.string().required(t('ERR-REQUIRED')),

            //   url: yup.string().when('dataType', {
            //     is: (value: string) => value === '画像' || value === '動画' || value === '他社埋め込みサービス',
            //     then: yup.string().required(t('ERR-REQUIRED')),
            //   }),

            //   attributes: yup.object().shape({
            //     type: yup.string().when('dataType', {
            //       is: (value: string) => value === 'テキスト',
            //       then: yup.string().required(t('ERR-REQUIRED')),
            //     }),

            //     season: yup.string().when('dataType', {
            //       is: (value: string) => value === 'テキスト',
            //       then: yup.string().required(t('ERR-REQUIRED')),
            //     }),

            //     brand: yup.string().when('dataType', {
            //       is: (value: string) => value === 'テキスト',
            //       then: yup.string().required(t('ERR-REQUIRED')),
            //     }),
            //   }),

            //   text: yup.string().when('dataType', {
            //     is: (value: string) => value === 'テキスト',
            //     then: yup.string().required(t('ERR-REQUIRED')),
            //   }),

            //   fontName: yup.string().when('dataType', {
            //     is: (value: string) => value === 'テキスト',
            //     then: yup.string().required(t('ERR-REQUIRED')),
            //   }),
            // }),
            ()
            .required(t('ERR-REQUIRED'))
            .min(1, t('ERR-REQUIRED')),
        }),
      }),
    ),
  });
};
