import React from 'react';
import { useDropzone, Accept, FileRejection, DropEvent, FileError } from 'react-dropzone';
import { ButtonContainer } from 'components/Atoms/Layout';
import { Button } from 'components/Atoms/Button'
import { Text } from 'components/Atoms/Typography';
import style from './style.module.scss';

type Options = {
  accept?: Accept;
  maxFiles?: number;
  noClick?: boolean;
  noKeyboard?: boolean;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRegections: FileRejection[],
    event: DropEvent
  ) => void;
  onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
  validator?: <T extends File>(file: T) => FileError | FileError[] | null;
};

export const Dropzone: React.FC<Options> = ({
  accept,
  maxFiles = 1,
  noClick = true,
  noKeyboard = true,
  onDrop,
  onDropRejected,
  validator,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone ({
    accept, noClick, noKeyboard, maxFiles, onDrop, onDropRejected, validator
  });

  return (
    <>
      <div {...getRootProps({ className: `${style['dropzone']}` })}>
        <input {...getInputProps()} />
        <Text size={18} mt={0} align={'center'} color="black" bold>ファイルをここにドラッグアンドドロップ</Text>
        <Text mt={0} align={'center'}>もしくは</Text>
        <ButtonContainer mt={10}>
          <Button type="secondary" text='ファイル選択' onClick={ open } />
        </ButtonContainer>
      </div>
    </>
  );
};
