import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const useValidationDuplicateCoupon = () => {
  const { t } = useTranslation();
  const { getValues, setError } = useFormContext();

  return (name: string, value: string) => {
    if (!value) return;
    setTimeout(() => {
      const names = getValues()
        .campaignDetail.map((_: any, index: number) => {
          return `campaignDetail[${index}].couponCode`;
        })
        .filter((n: any) => n !== name);
      const otherValues = names
        .map((elem: any) => {
          return getValues(elem);
        })
        .filter((elem: any) => elem);
      if (otherValues.includes(value)) {
        // console.log('重複', name);
        setError(name, {
          type: 'custom',
          message: t('ERR-DUPLICATE-COUPON'),
        });
      }
    }, 0);
  };
};
