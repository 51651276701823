import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { Flex, FormGrid, FormField } from 'components/Atoms/Layout';
import { Note, ErrorText, Unit } from 'components/Atoms/Typography';
import { NoteList } from 'components/Atoms/List';
import { SelectMenu } from 'components/Atoms/Form';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { UndecidedButton } from 'components/Molecules/UndecidedButton';
import { UNDECIDED_VALUE, RHF_UNREGISTER_PARAMS } from 'constants/index';
import style from './style.module.scss';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';
import type { TextInputSizeProps } from 'components/Atoms/Form/TextInput';

type ListProps = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  unit?: string;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  isRequired?: boolean;
  help?: string;
  hasUndecidedButton?: boolean;
  list: Array<ListProps>;
  searchable?: boolean;
  defaultValue?: string;
  isLoading?: boolean;
  onChange?: (value: string) => void;
  size?: FormGridSizeProps;
  inputSize?: TextInputSizeProps;
};

export const FormSelectMenu: React.FC<Props> = ({
  name,
  label = '',
  placeholder = '',
  unit,
  note,
  notes,
  isRequired = false,
  help,
  hasUndecidedButton = false,
  list,
  searchable = false,
  defaultValue = '',
  isLoading = false,
  onChange = () => {},
  size = 'large',
  inputSize = 'medium',
}) => {
  const { control, unregister, setValue } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, defaultValue, shouldUnregister: true });
  const [undecided, setUndecided] = React.useState(false);
  const [backupValue, setBackupValue] = React.useState('');

  const componentWillUnmount = React.useCallback(() => {
    console.log(`unregister: ${name}`);
    unregister(name, RHF_UNREGISTER_PARAMS);
  }, [name, unregister]);

  const handleChange = React.useCallback(
    (value) => {
      field.onChange(value);
      onChange(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onChange, onChange],
  );

  const handleUndecided = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setBackupValue(field.value);
        field.onChange(UNDECIDED_VALUE);
        return;
      }
      field.onChange(backupValue);
      setBackupValue('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.value, backupValue, setBackupValue],
  );

  const init = React.useCallback(() => {
    if (list.length === 0) return;
    const listValues = list.map((item) => item.value);
    if (!listValues.includes(field.value)) {
      setValue(name, '');
    }
  }, [field.value, name, list, setValue]);

  React.useEffect(() => {
    setUndecided(field.value === UNDECIDED_VALUE);
  }, [field.value]);

  React.useEffect(() => {
    init();
  }, [init, list]);

  React.useEffect(() => {
    return () => componentWillUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormGrid className={style['form-selectMenu']} size={size}>
      <FormLabelSet label={label} isRequired={isRequired} help={help} size={size} />

      <FormField>
        <Flex alignItems="center">
          <SelectMenu
            name={field.name}
            value={field.value}
            refs={field.ref}
            onChange={handleChange}
            className={undecided ? 'is-undecided' : ''}
            isInvalid={error ? true : false}
            disabled={undecided}
            placeholder={placeholder}
            list={list}
            searchable={searchable}
            isLoading={isLoading}
            size={inputSize}
          />
          {unit && <Unit text={unit} />}
        </Flex>
        <ErrorText error={error?.message} />
        {note && <Note>{note}</Note>}
        {notes && <NoteList list={notes} />}
      </FormField>

      <UndecidedButton name={name} hasUndecidedButton={hasUndecidedButton} undecided={undecided} onChangeDecided={handleUndecided} />
    </FormGrid>
  );
};
