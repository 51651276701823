import React from 'react';
import { FormTextInput, FormTextbox } from 'components/Business/Form';

type Props = {
  k: number;
};

export const CouponDetailCommonChildren1: React.FC<Props> = ({ k }) => {
  return (
    <>
      <FormTextInput
        name={`campaignDetail[${k}].couponName`}
        label="クーポン名称"
        placeholder="「春のキレイアップクーポン」等を入力"
        note="※全角20文字以内で入力してください。"
        isRequired
        hasUndecidedButton
      />

      <FormTextbox
        name={`campaignDetail[${k}].couponContents`}
        label="クーポン内容"
        placeholder="「7,700円（税込）以上購入で使える1,000円クーポン」等を入力"
        note="※対象者や告知方法を入力してください。"
        isRequired
        hasUndecidedButton
      />
    </>
  );
};
