import React from 'react';
import { useParams } from 'react-router-dom';
import { Main, Container, Section, SectionInner, ButtonContainer, PositionContainer, PositionItem, Flex, FlexRow, FlexCol } from 'components/Atoms/Layout';
import { H1, H2, H3, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { LinkList } from 'components/Atoms/List';
import { Box } from 'components/Atoms/Layout/Box';
import { Header } from 'components/Business/Header';
import { CampaignStatusTag } from 'components/Molecules/Tag';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { DataTable } from 'components/Organisms/DataTable';
import { ApiGetMeasuresDetailProvider } from 'components/Business/Api';
import {
  MEASURESCLASSIFICATION_VALUES,
  MEASURESENDCONDITIONS_VALUES,
  OLSDOCUMENTCREATION_VALUES,
  WEBPRODUCTION_VALUES,
  JANCODEISSUANCE_VALUES,
  OSWORKREQUEST_VALUES,
  CAMPAIGNSETTINGS_VALUES,
  SAMPLESETTINGS_VALUES,
  GIFTSETCODESETTINGS_VALUES,
} from 'constants/index';
import type { MeasureParamsProps, MeasureDetailProps } from 'types';
import type { DataTableProps } from 'components/Organisms/DataTable';

export const CPF008: React.FC = () => {
  const { measureId } = useParams<MeasureParamsProps>();
  const [response, setResponse] = React.useState<MeasureDetailProps>();
  const [outlineData, setOutlineData] = React.useState<DataTableProps[]>([]);
  const [requestDetailData, setRequestDetailData] = React.useState<DataTableProps[]>([]);

  const apiCallbackHandler = React.useCallback((result) => {
    setResponse(result);
  }, []);

  React.useEffect(() => {
    if (!response) return;

    const outlineData: DataTableProps[] = [
      {
        type: 'text',
        label: '施策名',
        value: response?.measuresName,
      },
      {
        type: 'text',
        label: '施策担当者名',
        value: response?.inCharge,
      },
      {
        type: 'arrayNum',
        label: 'その他担当者数',
        unit: '人',
        value: response?.inChargeSubNum,
      },
      {
        type: 'array',
        label: 'その他担当者',
        value: response?.inChargeSubNum,
        content: (key: number) => {
          return [
            {
              type: 'text',
              label: `${key + 1}:その他担当者名`,
              value: response?.inChargeSubNum?.[key]?.inChargeSub,
            },
          ];
        },
      },
      {
        type: 'radio',
        label: '施策分類',
        value: response?.measuresClassification,
        master: MEASURESCLASSIFICATION_VALUES,
      },
      {
        condition: response?.measuresClassification === '1' || response?.measuresClassification === '2',
        type: 'text',
        label: '施策目的',
        value: response?.measuresPurpose,
      },
      {
        condition: response?.measuresClassification === '2',
        type: 'text',
        label: '特典内容',
        value: response?.benefitContent,
      },
      {
        condition: response?.measuresClassification === '2',
        type: 'text',
        label: '特典付与条件',
        value: response?.benefitGrantConditions,
      },
      {
        condition: response?.measuresClassification === '1' || response?.measuresClassification === '2',
        type: 'text',
        label: '施策対象商品',
        value: response?.measuresProduct,
      },
      {
        condition: response?.measuresClassification === '1' || response?.measuresClassification === '2',
        type: 'text',
        label: '月別計画販売数量',
        value: response?.monthlySalesPlan,
      },
      {
        type: 'datetime',
        label: '施策開始日/時間',
        value: {
          year: response?.measuresStartDateYear,
          month: response?.measuresStartDateMonth,
          date: response?.measuresStartDateDay,
          hour: response?.measuresStartTimeHours,
          minute: response?.measuresStartTimeMinutes,
        },
      },
      {
        type: 'radio',
        label: '施策終了条件',
        value: response?.measuresEndConditions,
        master: MEASURESENDCONDITIONS_VALUES,
      },
      {
        condition: response?.measuresEndConditions === '1' || response?.measuresEndConditions === '2',
        type: 'datetime',
        label: '施策終了条件',
        value: {
          year: response?.measuresEndDateYear,
          month: response?.measuresEndDateMonth,
          date: response?.measuresEndDateDay,
          hour: response?.measuresEndTimeHours,
          minute: response?.measuresEndTimeMinutes,
        },
      },
    ];
    setOutlineData(outlineData);

    const requestDetailData: DataTableProps[] = [
      {
        type: 'radio',
        label: 'OLS会議資料',
        value: response?.olsDocumentCreation,
        master: OLSDOCUMENTCREATION_VALUES,
      },
      {
        type: 'radio',
        label: 'Web制作',
        value: response?.webProduction,
        master: WEBPRODUCTION_VALUES,
      },
      {
        type: 'radio',
        label: 'JANコード発行',
        value: response?.janCodeIssuance,
        master: JANCODEISSUANCE_VALUES,
      },
      {
        type: 'radio',
        label: 'OS作業依頼',
        value: response?.osWorkRequest,
        master: OSWORKREQUEST_VALUES,
      },
      {
        type: 'radio',
        label: 'キャンペーン設定・クーポン発行',
        value: response?.campaignSettings,
        master: CAMPAIGNSETTINGS_VALUES,
      },
      {
        type: 'radio',
        label: 'サンプル・モニター設定',
        value: response?.sampleSettings,
        master: SAMPLESETTINGS_VALUES,
      },
      {
        type: 'radio',
        label: 'ギフト・セットコード発行',
        value: response?.giftSetCodeSettings,
        master: GIFTSETCODESETTINGS_VALUES,
      },
      {
        type: 'text',
        label: '備考記入欄',
        value: response?.remarksColumn,
      },
    ];
    setRequestDetailData(requestDetailData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <>
      <Header />
      <Main>
        <ApiGetMeasuresDetailProvider callback={apiCallbackHandler}>
          <Container>
            <Breadcrumb
              list={[
                {
                  label: 'TOP',
                  url: '/CMN001',
                },
                {
                  label: '登録済み施策一覧',
                },
                {
                  label: response?.measuresName || '',
                },
              ]}
            />

            <Section>
              <H1>{response?.measuresName}</H1>

              <LinkList
                list={[
                  {
                    text: '施策概要・依頼内容',
                    to: `/CPF008/${measureId}`,
                    current: true,
                    disabled: true,
                  },
                  {
                    text: 'Web制作',
                    to: `/CPF009/${measureId}`,
                  },
                  {
                    text: 'キャンペーン設定・クーポン発行',
                    to: `/CPF010/${measureId}`,
                  },
                  {
                    text: 'サンプル・モニター設定',
                    to: `/CPF011/${measureId}`,
                  },
                ]}
              />

              <Section hasBg>
                <PositionContainer>
                  <H2>施策概要・依頼内容</H2>
                  <PositionItem top="0" right="0">
                    <Button type="primary" size="small" text="編集" to={`/CPF001/${measureId}`} />
                  </PositionItem>
                </PositionContainer>

                <SectionInner>
                  {response && (
                    <Flex justifyContent="center">
                      <Box color="grey" size="xsmall" width="fit-content">
                        <FlexRow justifyContent="center" alignItems="center" separator={true}>
                          <FlexCol>
                            <Text bold>入力状況</Text>
                          </FlexCol>
                          <FlexCol>
                            <CampaignStatusTag status={response?.measuresStatus} />
                          </FlexCol>
                        </FlexRow>
                      </Box>
                    </Flex>
                  )}

                  {response?.measuresStatus !== '3' && (
                    <>
                      <Section>
                        <H3>施策概要</H3>
                        <DataTable data={outlineData} />
                      </Section>

                      <Section>
                        <H3>依頼内容</H3>
                        <DataTable data={requestDetailData} />
                      </Section>
                    </>
                  )}

                  <PositionContainer mt={40}>
                    <ButtonContainer>
                      <PositionItem left="0" centerY>
                        <Button type="secondary" icon="arrow-left" iconPosition="before" size="small" isFlexible text="施策一覧へ戻る" to="/CPF007" />
                      </PositionItem>
                      <Button type="primary" text="編集" to={`/CPF001/${measureId}`} />
                    </ButtonContainer>
                  </PositionContainer>
                </SectionInner>
              </Section>
            </Section>
          </Container>
        </ApiGetMeasuresDetailProvider>
      </Main>
      <Footer />
    </>
  );
};
