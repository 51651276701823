import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { FormGrid, FormField, ButtonContainer } from 'components/Atoms/Layout';
import { Note, ErrorText } from 'components/Atoms/Typography';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { UndecidedButton } from 'components/Molecules/UndecidedButton';
import { NEWITEM_VALUE, UNDECIDED_VALUE } from 'constants/index';
import { ActivatedItemModalButton } from './ActivatedItemModalButton';
import { NewItemModalButton } from './NewItemModalButton';
import style from './style.module.scss';

type Props = {
  valueName: string;
  labelName: string;
  code: string;
  label?: string;
  help?: string;
  isRequired?: boolean;
  hasUndecidedButton?: boolean;
};

export const ApiSearchPromotionItemModal: React.FC<Props> = ({
  valueName,
  labelName,
  code,
  label = '',
  help,
  isRequired = false,
  hasUndecidedButton = false,
}) => {
  const { control, unregister } = useFormContext();
  const {
    field: valueField,
    fieldState: { error },
  } = useController({ control, name: valueName, defaultValue: '', shouldUnregister: true });
  const { field: labelField } = useController({ control, name: labelName, defaultValue: '', shouldUnregister: true });
  const { field: codeField } = useController({ control, name: code, defaultValue: '', shouldUnregister: true });
  const [undecided, setUndecided] = React.useState(false);
  const [backupValue, setBackupValue] = React.useState('');
  const [backupLabelValue, setBackupLabelValue] = React.useState('');
  const [backupCodeValue, setBackupCodeValue] = React.useState('');

  const componentWillUnmount = React.useCallback(() => {
    console.log(`unregister: ${valueName}`);
    console.log(`unregister: ${labelName}`);
    console.log(`unregister: ${code}`);
    unregister([valueName, labelName, code]);
  }, [valueName, labelName, code, unregister]);

  const reset = React.useCallback(() => {
    valueField.onChange('');
    labelField.onChange('');
    codeField.onChange('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueField.onChange, labelField.onChange, codeField.onChange]);

  const handleUndecided = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setBackupValue(valueField.value);
        setBackupLabelValue(labelField.value);
        setBackupCodeValue(codeField.value);
        valueField.onChange(UNDECIDED_VALUE);
        labelField.onChange(UNDECIDED_VALUE);
        codeField.onChange(UNDECIDED_VALUE);
        return;
      }
      valueField.onChange(backupValue);
      labelField.onChange(backupLabelValue);
      codeField.onChange(backupCodeValue);
      setBackupValue('');
      setBackupLabelValue('');
      setBackupCodeValue('');
    },
    [valueField, labelField, codeField, backupValue, backupLabelValue, backupCodeValue],
  );

  React.useEffect(() => {
    setUndecided(valueField.value === UNDECIDED_VALUE);
  }, [valueField.value]);

  React.useEffect(() => {
    return () => componentWillUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormGrid>
      <FormLabelSet label={label} isRequired={isRequired} help={help} />

      <FormField>
        <div className={`${error ? 'is-invalid' : ''}`}>
          {valueField.value && valueField.value !== UNDECIDED_VALUE && (
            <p>
              【{valueField.value === NEWITEM_VALUE ? 'JANコード未発行の販促品' : 'JANコード発行済みの販促品'}】
              <br />
              {labelField.value}
              <button onClick={reset} className={style['button-delete']}>
                <SvgIcon type="cross" fill="#111" width={10} />
              </button>
              {valueField.value !== NEWITEM_VALUE && (
                <>
                  <br />
                  （宣伝物コード: {codeField.value}）
                  <br />
                  （JANコード: {valueField.value}）
                </>
              )}
            </p>
          )}
        </div>

        <ErrorText error={error?.message} />

        <ButtonContainer isVertical isLeft gap="small" mt={15}>
          <ActivatedItemModalButton name={valueName} valueField={valueField} labelField={labelField} codeField={codeField} undecided={undecided} />

          <NewItemModalButton name={valueName} undecided={undecided} valueField={valueField} labelField={labelField} />
        </ButtonContainer>

        <Note>
          ※JANコード発行済の場合は、「販促品を選択する」から選択してください。
          <br />
          JANコード未発行の場合は、入力欄に発行希望する販促品名を入力してください。
        </Note>
      </FormField>

      <UndecidedButton name={valueName} hasUndecidedButton={hasUndecidedButton} undecided={undecided} onChangeDecided={handleUndecided} />
    </FormGrid>
  );
};
