import React from 'react';
import { useHistory } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsconfig from 'aws-exports';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, FlexRow, FlexCol, Flex, ButtonContainer, Box } from 'components/Atoms/Layout';
import { H1, Text, Note } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Qr } from 'components/Atoms/Qr';
import { Hr } from 'components/Atoms/Hr';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { TabMenu } from 'components/Organisms/TabMenu';
import { FormTextInput } from 'components/Business/Form';
import { TextLink } from 'components/Business/TextLink';
import { LoginSessionErrorModal } from 'components/Business/LoginSessionErrorModal';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { RHF_SETTINGS, SITE_NAME } from 'constants/index';
import { TempCognitoUserState, NetworkErrorUpdate } from 'context';
import { scrollToFirstError } from 'utils';
import { useValidationSchema } from './useValidationSchema';
import type { LoginPageProps } from 'components/Pages/Login';
import ImageChromeExtension1 from 'images/image_chrome_extension_1.png';
import ImageChromeExtension2 from 'images/image_chrome_extension_2.png';

Amplify.configure(awsconfig);

export const CMN011: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const tempCognitoUser = React.useContext(TempCognitoUserState);
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, handleSubmit, setError } = useFormMethods;
  const [isLoading, setLoading] = React.useState(false);
  const [totpToken, setTotpToken] = React.useState<string>();
  const [totpSecretCode, setTotpSecretCode] = React.useState<string>();
  const [isSessionError, setSessionError] = React.useState(false);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    const data = {
      verificationCode: formValues.verificationCode || '',
    };
    return data;
  }, [getValues]);

  const verifyTotpToken = React.useCallback(async () => {
    setLoading(true);
    const data = getRequestData();
    if (!tempCognitoUser) return;
    try {
      const returnValue = await Amplify.Auth.verifyTotpToken(tempCognitoUser, data.verificationCode);
      console.log(returnValue);
      const state: LoginPageProps = {
        nextPage: '/CMN012',
      };
      history.push('/Login', state);
    } catch (error) {
      console.error(error);
      if (error.code === 'CodeMismatchException') {
        setError('verificationCode', {
          type: 'custom',
          message: t('ERR-MISMATCH', { target: 'ワンタイムパスワード' }),
        });
        scrollToFirstError();
      } else if (error.code === 'NotAuthorizedException') {
        setSessionError(true);
      } else {
        setNetworkError(true);
      }
      setLoading(false);
    }
  }, [getRequestData, setError, t, history, tempCognitoUser, setNetworkError]);

  const onSuccess = React.useCallback(() => {
    verifyTotpToken();
  }, [verifyTotpToken]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  const setUpBarcode = React.useCallback(async () => {
    if (!tempCognitoUser) return;
    const totpData = await Amplify.Auth.setupTOTP(
      tempCognitoUser, // signIn時の返り値のuserData
    );
    const token = `otpauth://totp/AWSCognito:${tempCognitoUser.username}?secret=${totpData}&issuer=${encodeURI(SITE_NAME)}`;
    setTotpToken(token);
    setTotpSecretCode(totpData);
  }, [tempCognitoUser]);

  React.useEffect(() => {
    if (!tempCognitoUser) {
      setNetworkError(true);
      return;
    }
    setUpBarcode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoginSessionErrorModal isShow={isSessionError} />
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />
          <Container>
            <Section>
              <H1>2段階認証</H1>
              <Text align="center">
                2段階認証を行う端末を登録してください。
                <br />
                Webブラウザの拡張機能またはアプリを使用してコードを確認し、表示されているワンタイムパスワード（数字6桁）を入力してください。
              </Text>

              <Section hasBg>
                <TabMenu
                  data={[
                    {
                      label: '1:パソコンから登録する方',
                      content: (
                        <>
                          <Flex mt={40}>
                            <Text size={18} bold color="blue">
                              STEP1
                            </Text>
                            <Text size={18} bold mt={0} ml={20}>
                              EdgeまたはChromeへ拡張機能を追加
                            </Text>
                          </Flex>
                          <Text mt={15}>
                            下記URLより拡張機能を追加してください。（Edge、Chrome共通）
                            <br />
                            <TextLink to="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai" isExternal>
                              https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai
                            </TextLink>
                          </Text>

                          <Text bold mt={25}>
                            拡張機能の追加位置
                          </Text>
                          <Text mt={20}>追加した拡張機能は、下図①のエリアに表示されます。</Text>
                          <Box mt={10}>
                            <img src={ImageChromeExtension1} alt="拡張機能の追加位置" />
                          </Box>

                          <Text>上図①のエリアに表示されない場合は、パズルマークをクリック（下図②）し、拡張機能一覧の中から「認証」を選択してください。</Text>
                          <Box mt={10}>
                            <img src={ImageChromeExtension2} alt="拡張機能一覧の位置" />
                          </Box>

                          <Hr mt={40} />

                          <Flex mt={40}>
                            <Text size={18} bold color="blue">
                              STEP2
                            </Text>
                            <Text size={18} bold mt={0} ml={20}>
                              端末登録
                            </Text>
                          </Flex>

                          <Text mt={15}>STEP1で追加した拡張機能を利用して、端末を登録してください。</Text>

                          <Text bold mt={25}>
                            登録方法（Edge、Chrome共通）
                          </Text>

                          <Text mt={20}>
                            ①右上の「鉛筆アイコン」をクリック
                            <br />
                            ②「+アイコン」をクリック
                            <br />
                            ③「手動入力」をクリック
                            <br />
                            ④以下コードを「シークレット」の欄に入力し、「OK」をクリック
                          </Text>

                          <Box color="grey" size="small" mt={20}>
                            <Text>{totpSecretCode}</Text>
                          </Box>

                          <Hr mt={40} />

                          <Flex mt={40}>
                            <Text size={18} bold color="blue">
                              STEP3
                            </Text>
                            <Text size={18} bold mt={0} ml={20}>
                              ワンタイムパスワードの確認、入力
                            </Text>
                          </Flex>

                          <Text mt={15}>表示されたワンタイムパスワード（数字6桁）を下記の欄に入力し、「次へ進む」をクリックしてください。</Text>
                        </>
                      ),
                    },
                    {
                      label: '2:スマートフォンから登録する方',
                      content: (
                        <>
                          <FlexRow alignItems="center">
                            <FlexCol col={6}>
                              <Flex>
                                <Text bold size={18} color="blue">
                                  STEP1
                                </Text>
                                <Text bold size={18} mt={0} ml={20}>
                                  アプリのダウンロード
                                </Text>
                              </Flex>
                              <Text mt={15}>右記QRコードを読み取りGoogle AuthenticatorまたはAuthyをダウンロードしてください。</Text>
                            </FlexCol>
                            <FlexCol col={6}>
                              <Box color="grey" size="small">
                                <Text align="center" bold>
                                  Google Authenticator
                                </Text>
                                <Note align="center">※Google社が提供する2段階認証アプリ</Note>

                                <FlexRow mt={15} justifyContent="space-between" gap="small">
                                  <FlexCol col={6}>
                                    <Flex alignItems="center">
                                      <Text size={14} bold>
                                        iOS
                                      </Text>
                                      <Qr url="https://apps.apple.com/jp/app/google-authenticator/id388497605" size={80} ml={10} />
                                    </Flex>
                                  </FlexCol>
                                  <FlexCol col={6}>
                                    <Flex alignItems="center">
                                      <Text size={14} bold>
                                        Android
                                      </Text>
                                      <Qr
                                        url="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=ja&amp;gl=US"
                                        size={80}
                                        ml={10}
                                      />
                                    </Flex>
                                  </FlexCol>
                                </FlexRow>

                                <Hr mt={30} />

                                <Text align="center" bold>
                                  Authy
                                </Text>
                                <Note align="center">※twilio社が提供する2段階認証アプリ</Note>

                                <FlexRow mt={15} justifyContent="space-between" gap="small">
                                  <FlexCol col={6}>
                                    <Flex alignItems="center">
                                      <Text size={14} bold>
                                        iOS
                                      </Text>
                                      <Qr url="https://apps.apple.com/jp/app/twilio-authy/id494168017" size={80} ml={10} />
                                    </Flex>
                                  </FlexCol>
                                  <FlexCol col={6}>
                                    <Flex alignItems="center">
                                      <Text size={14} bold>
                                        Android
                                      </Text>
                                      <Qr url="https://play.google.com/store/apps/details?id=com.authy.authy&amp;hl=ja&amp;gl=US" size={80} ml={10} />
                                    </Flex>
                                  </FlexCol>
                                </FlexRow>
                              </Box>
                            </FlexCol>
                          </FlexRow>

                          <Hr mt={40} />

                          <Flex mt={40}>
                            <Text size={18} bold color="blue">
                              STEP2
                            </Text>
                            <Text size={18} bold mt={0} ml={20}>
                              アプリのセットアップ
                            </Text>
                          </Flex>

                          <Text mt={15}>STEP1でダウンロードしたアプリのセットアップを行ってください。</Text>

                          <Text bold mt={25}>
                            登録方法
                          </Text>

                          <FlexRow mt={20} gap="small">
                            <FlexCol col={5}>
                              <Box color="grey" size="small" height="100%">
                                <Text bold>Google Authenticator</Text>
                                <Text mt={15}>
                                  セットアップは必要ありません。
                                  <br />
                                  STEP3に進んでください。
                                </Text>
                              </Box>
                            </FlexCol>
                            <FlexCol col={7}>
                              <Box color="grey" size="small">
                                <Text bold>Authy</Text>
                                <Text mt={15}>
                                  ①携帯番号を入力（+codeはjapan（＋81）を選択）
                                  <br />
                                  ②認証方法（Phone CallかSMS）を選択
                                  <br />
                                  ③Registration Codeに認証コードを入力
                                </Text>
                              </Box>
                            </FlexCol>
                          </FlexRow>

                          <Hr mt={40} />

                          <Flex mt={40}>
                            <Text size={18} bold color="blue">
                              STEP3
                            </Text>
                            <Text size={18} bold mt={0} ml={20}>
                              QRコード読み取り
                            </Text>
                          </Flex>

                          <Text bold mt={25}>
                            読み取り方法
                          </Text>

                          <FlexRow mt={20} gap="small">
                            <FlexCol>
                              <Box color="grey" size="small" height="100%">
                                <Text bold>Google Authenticator</Text>
                                <Text mt={15}>
                                  ①「開始」をタップ
                                  <br />
                                  ②QRコードをスキャンをタップ
                                  <br />
                                  ③右記QRコードを読み取る
                                </Text>
                              </Box>
                            </FlexCol>
                            <FlexCol>
                              <Box color="grey" size="small" height="100%">
                                <Text bold>Authy</Text>
                                <Text mt={15}>
                                  ①Add Accountをタップ
                                  <br />
                                  ②Scan QR Codeをタップ
                                  <br />
                                  ③右記QRコードを読み取る
                                  <br />
                                  ④次の画面でSaveをタップ
                                </Text>
                              </Box>
                            </FlexCol>
                            <FlexCol>
                              <Box color="grey" size="small" height="100%">
                                <Text bold>QRコード</Text>
                                {totpToken && <Qr url={totpToken} size={110} mt={15} />}
                              </Box>
                            </FlexCol>
                          </FlexRow>

                          <Hr mt={40} />

                          <Flex mt={40}>
                            <Text size={18} bold color="blue">
                              STEP4
                            </Text>
                            <Text size={18} bold mt={0} ml={20}>
                              ワンタイムパスワードの確認・入力
                            </Text>
                          </Flex>

                          <Text mt={15}>GoogleAuthenticatorまたはAuthyに表示されているワンタイムパスワード（数字6桁）を下記入力欄に入力してください。</Text>
                        </>
                      ),
                    },
                  ]}
                />

                <FormTextInput type="number" name="verificationCode" label="ワンタイムパスワード" maxLength={6} size="medium" inputSize="large" />

                <ButtonContainer>
                  <Button type="secondary" icon="arrow-right" iconPosition="right" text="次へ進む" onClick={() => handleSubmit(onSuccess, onError)()} />
                </ButtonContainer>
              </Section>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
