import React from 'react';
import { SelectMenu } from 'components/Atoms/Form';
import { ENDPOINT_GET_BRAND_API } from 'constants/index';
import { useApiCall } from 'hooks/useApiCall';
import type { BrandProps } from 'types';
import type { SelectMenuListProps } from 'components/Atoms/Form/SelectMenu';
import type { TextInputSizeProps } from 'components/Atoms/Form/TextInput';

type Props = {
  value: string;
  onChange: (value: string) => void;
  size?: TextInputSizeProps;
  width?: number;
};

export const ApiSelectAidBrand: React.FC<Props> = ({ value, onChange, size = 'medium', width }) => {
  const apiCall = useApiCall();
  const [brands, setBrands] = React.useState<SelectMenuListProps[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    setIsLoading(true);

    (async () => {
      apiCall({
        method: 'GET',
        url: ENDPOINT_GET_BRAND_API,
        data: {
          existNone: '1',
        },
      })
        .then((res: { status: number; data: { result: { brandList: BrandProps[] } } }) => {
          if (res.status === 200) {
            const brandList: BrandProps[] = res.data.result.brandList;
            const result =
              brandList.map((elem: BrandProps) => {
                return {
                  label: elem.brandName,
                  value: elem.brandName,
                };
              }) || [];
            if (!unmounted) setBrands(result);
          }
        })
        .finally(() => {
          if (!unmounted) setIsLoading(false);
        });
    })();

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SelectMenu name="brand" list={brands} isLoading={isLoading} value={value} onChange={onChange} size={size} width={width} />;
};
