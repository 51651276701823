import React from 'react';
import ReactPaginate from 'react-paginate';
import { Flex } from 'components/Atoms/Layout';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';

type Props = {
  pageCount: number;
  pageNumber: number;
  onPageChange: (selected: number) => void;
};

export const Pager: React.FC<Props> = ({ pageCount, pageNumber, onPageChange }) => {
  // const [pageNumber, setPageNumber] = React.useState(0);

  const handlePageChange = React.useCallback(
    (selected: number) => {
      onPageChange(selected);
      // setPageNumber(selected);
    },
    [onPageChange],
  );

  const handlePageChangeFirst = React.useCallback(() => {
    // setPageNumber(0);
    onPageChange(0);
  }, [onPageChange]);

  const handlePageChangeLast = React.useCallback(() => {
    // setPageNumber(pageCount - 1);
    onPageChange(pageCount - 1);
  }, [pageCount, onPageChange]);

  return React.useMemo(
    () => (
      <>
        {pageCount > 0 && (
          <Flex justifyContent="center" alignItems="center" mt={40} className={style['pager-wrapper']}>
            <button className={style['pager-item-first']} onClick={handlePageChangeFirst} disabled={pageNumber === 0}>
              <SvgIcon type="arrow-double-left" fill="#373940" width={16} />
            </button>

            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={4}
              marginPagesDisplayed={0}
              onPageChange={(selectedItem: { selected: number }) => {
                handlePageChange(selectedItem.selected);
              }}
              forcePage={pageNumber}
              containerClassName={style['pager']}
              pageClassName={style['pager-item']}
              pageLinkClassName={style['pager-link']}
              activeClassName="is-active"
              activeLinkClassName="is-active"
              previousClassName={style['pager-item-previous']}
              previousLinkClassName={style['pager-link-previous']}
              nextClassName={style['pager-item-next']}
              nextLinkClassName={style['pager-link-next']}
              breakClassName={style['pager-item-break']}
              breakLinkClassName={style['pager-link-break']}
              previousLabel={<SvgIcon type="arrow-left" fill="#373940" width={10} />}
              nextLabel={<SvgIcon type="arrow-right" fill="#373940" width={10} />}
              disabledClassName="is-disabled"
            />

            <button className={style['pager-item-last']} onClick={handlePageChangeLast} disabled={pageNumber >= pageCount - 1}>
              <SvgIcon type="arrow-double-right" fill="#373940" width={16} />
            </button>
          </Flex>
        )}
      </>
    ),
    [handlePageChange, handlePageChangeFirst, handlePageChangeLast, pageCount, pageNumber],
  );
};
