import React from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import { FormGrid, FormField, NestBox, Box, FlexRow, FlexCol } from 'components/Atoms/Layout';
import { Text, Note, ErrorText } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { TextInput } from 'components/Atoms/Form';
import { Loading } from 'components/Atoms/Loading';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { Modal } from 'components/Organisms/Modal';
import { ApiSelectAidDataType } from 'components/Business/Api/ApiSelectAidDataType';
import { ApiSelectAidFont } from 'components/Business/Api/ApiSelectAidFont';
import { TextLink } from 'components/Business/TextLink';
import { useApiCall } from 'hooks/useApiCall';
import { ENDPOINT_AID_MODULE_API, URL_AID_DYNAMODB_MODULES } from 'constants/index';
import type { ModuleProps } from 'types';

type AddMaterialButtonProps = {
  append: (data: ModuleProps) => void;
};

export const AddRegisteredMaterialButton: React.FC<AddMaterialButtonProps> = ({ append }) => {
  const { t } = useTranslation();
  const apiCall = useApiCall();
  const defaultDataTypeValue = React.useMemo(() => '画像', []);
  const [isDisabledSubmit, setDisabledSubmit] = React.useState(true);
  const [isOpen, setOpen] = React.useState(false);
  const [dataTypeValue, setDataTypeValue] = React.useState(defaultDataTypeValue);
  const [moduleNumValue, setModuleNumValue] = React.useState('');
  const [moduleId, setModuleId] = React.useState('');
  const [isSearched, setSearched] = React.useState(false);
  const [response, setResponse] = React.useState<ModuleProps>();
  const [isLoading, setLoading] = React.useState(false);
  const [fontValue, setFontValue] = React.useState('');
  const [error, setError] = React.useState('');
  const [isDirty, setIsDirty] = React.useState(false);

  const validation = React.useCallback(async () => {
    if (!dataTypeValue || !moduleNumValue) {
      setError(t('ERR-REQUIRED'));
      return false;
    }
    if (!moduleNumValue.match(/^\d{6}$/)) {
      setError(t('ERR-LENGTH-NUMBER', { length: 6 }));
      return false;
    }
    setError('');
    return true;
  }, [dataTypeValue, moduleNumValue, t]);

  const resetModal = React.useCallback(() => {
    setDisabledSubmit(true);
    setDataTypeValue(defaultDataTypeValue);
    setModuleNumValue('');
    setModuleId('');
    setSearched(false);
    setResponse(undefined);
    setFontValue('');
    setError('');
    setIsDirty(false);
  }, [defaultDataTypeValue]);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleCancel = React.useCallback(() => {
    setOpen(false);
    resetModal();
  }, [resetModal]);

  const handleSubmit = React.useCallback(() => {
    if (!response) return;
    const returnValue = cloneDeep(response);
    returnValue['fontName'] = fontValue;
    returnValue['moduleNum'] = returnValue.moduleId.split('_').slice(-1)[0];
    append(returnValue);
    setOpen(false);
    resetModal();
  }, [resetModal, append, response, fontValue]);

  const handleChangeDataType = React.useCallback((value: string) => {
    setIsDirty(true);
    setDataTypeValue(value);
  }, []);

  const handleChangeModuleNumValue = React.useCallback((value: string) => {
    setIsDirty(true);
    setModuleNumValue(value);
  }, []);

  const handleBlurModuleNumValue = React.useCallback(() => {
    validation();
  }, [validation]);

  const handleSearch = React.useCallback(async () => {
    const validationResult = await validation();
    if (!validationResult) return;

    setLoading(true);

    apiCall({
      method: 'GET',
      url: `${ENDPOINT_AID_MODULE_API}?moduleId=${moduleId}`,
    })
      .then((res) => {
        console.log(res);
        setResponse(res.data.result[0]);
      })
      .finally(() => {
        setSearched(true);
        setLoading(false);
      });
  }, [apiCall, moduleId, validation]);

  const handleChangeFontValue = React.useCallback((value: string) => {
    setFontValue(value);
  }, []);

  const handleSetModuleId = React.useCallback(() => {
    let dataTypeIdentifier = '';
    // TODO: constants管理
    switch (dataTypeValue) {
      case '画像':
        dataTypeIdentifier = 'I';
        break;
      case 'テキスト':
        dataTypeIdentifier = 'T';
        break;
      case '動画':
        dataTypeIdentifier = 'V';
        break;
      case '他社サービス埋め込み':
        dataTypeIdentifier = 'E';
        break;
      default:
        break;
    }
    setModuleId(`MODULE_${dataTypeIdentifier}_${moduleNumValue}`);
  }, [dataTypeValue, moduleNumValue]);

  React.useEffect(() => {
    if (isDirty) validation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTypeValue]);

  React.useEffect(() => {
    handleSetModuleId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTypeValue, moduleNumValue]);

  React.useEffect(() => {
    if (response) {
      if (response.dataType === 'テキスト') {
        if (fontValue) {
          setDisabledSubmit(false);
        } else {
          setDisabledSubmit(true);
        }
      } else {
        setDisabledSubmit(false);
      }
    } else {
      setDisabledSubmit(true);
    }
  }, [response, fontValue]);

  return React.useMemo(
    () => (
      <>
        <Loading isLoading={isLoading} />

        <Button type="add" size="small" isFlexible icon="plus" iconPosition="after" text="登録済み素材" onClick={handleOpen} />

        <Modal
          isShow={isOpen}
          hasSubmit={true}
          hasCancel={true}
          cancelText="キャンセル"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          disabledSubmit={isDisabledSubmit}
        >
          <Text size={20} bold align="center">
            登録済み素材
          </Text>
          <Box mt={30}>
            <FormGrid>
              <FormField>
                <FormLabelSet label="素材データ種別・識別番号" />
                <FlexRow gap="small" nowrap>
                  <FlexCol>
                    <ApiSelectAidDataType value={dataTypeValue} onChange={handleChangeDataType} size="small" />
                  </FlexCol>
                  <FlexCol>
                    <TextInput
                      type="number"
                      name="moduleNum"
                      value={moduleNumValue}
                      onChange={handleChangeModuleNumValue}
                      size="small"
                      placeholder="012345"
                      width={180}
                      onBlur={handleBlurModuleNumValue}
                    />
                  </FlexCol>
                  <FlexCol>
                    <Button
                      type="primary"
                      isFlexible
                      icon="search-white"
                      iconPosition="after"
                      text="検索"
                      onClick={handleSearch}
                      // disabled={!dataTypeValue || !moduleNumValue.match(/\d{6}/)}
                    />
                  </FlexCol>
                </FlexRow>

                <ErrorText error={error}></ErrorText>
                {isSearched && !response && <ErrorText error={t('ERR-MATERIAL-NOTFOUND')}></ErrorText>}
                <Note>※識別番号は素材マスタ上の素材ID（6桁数字）</Note>

                <TextLink to={URL_AID_DYNAMODB_MODULES} icon="arrow-right" iconPosition="before" isExternal>
                  識別番号確認
                </TextLink>

                {response?.dataType === 'テキスト' && (
                  <>
                    <Text>テキスト文言: {response?.text}</Text>
                    <NestBox>
                      <FormGrid>
                        <FormField>
                          <FormLabelSet label="フォント指定" isRequired />
                          <ApiSelectAidFont value={fontValue} onChange={handleChangeFontValue} width={500} />
                        </FormField>
                      </FormGrid>
                    </NestBox>
                  </>
                )}

                {(response?.dataType === '画像' || response?.dataType === '動画' || response?.dataType === '他社埋め込みサービス') && (
                  <Text>URL: {response?.url}</Text>
                )}
              </FormField>
            </FormGrid>
          </Box>
        </Modal>
      </>
    ),
    [
      dataTypeValue,
      fontValue,
      handleCancel,
      handleChangeDataType,
      handleChangeFontValue,
      handleChangeModuleNumValue,
      handleBlurModuleNumValue,
      handleOpen,
      handleSearch,
      handleSubmit,
      isDisabledSubmit,
      isLoading,
      isOpen,
      isSearched,
      moduleNumValue,
      response,
      t,
      error,
    ],
  );
};
