import React from 'react';
import { Tag } from 'components/Atoms/Tag';
import style from './style.module.scss';
import type { ColorsProps } from 'components/Atoms/Tag/Tag';
import type { CampaignStatusProps } from 'types';

type Props = {
  status: CampaignStatusProps;
};

type StatusDataProps = {
  label: string;
  color: ColorsProps;
};

export const CampaignStatusTag: React.FC<Props> = ({ status }) => {
  const statusData: StatusDataProps = React.useMemo(() => {
    switch (status) {
      case '1':
        return {
          label: '完了',
          color: 'green',
        };
      case '2':
        return {
          label: '未完了',
          color: 'red',
        };
      case '3':
        return {
          label: '入力不要',
          color: 'grey',
        };
    }
  }, [status]);

  return React.useMemo(
    () => (
      <Tag className={style['tag-campaign-status']} color={statusData.color}>
        {statusData.label}
      </Tag>
    ),
    [statusData],
  );
};
