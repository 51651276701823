import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from 'components/Atoms/Layout';
import { Text, Note, ErrorText } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { TextInput } from 'components/Atoms/Form';
import { Modal } from 'components/Organisms/Modal';
import { NEWITEM_VALUE } from 'constants/index';
import type { ControllerRenderProps } from 'react-hook-form';

type NewItemModalButtonProps = {
  name: string;
  undecided: boolean;
  valueField: ControllerRenderProps<Record<string, any>>;
  labelField: ControllerRenderProps<Record<string, any>>;
};

export const NewItemModalButton: React.FC<NewItemModalButtonProps> = ({ name, undecided, valueField, labelField }) => {
  const { t } = useTranslation();
  const maxLength = 50;
  const [isOpen, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState('');
  const [isDisabledSubmit, setDisabledSubmit] = React.useState(true);

  const validation = React.useCallback(async () => {
    if (value.length > maxLength) {
      setError(t('ERR-LENGTH-MAX', { length: maxLength }));
      setDisabledSubmit(true);
      return false;
    }
    if (!value.length) {
      setDisabledSubmit(true);
      return false;
    }
    setError('');
    setDisabledSubmit(false);
    return true;
  }, [value, t]);

  const resetModal = React.useCallback(() => {
    setValue('');
    setError('');
    setDisabledSubmit(true);
  }, [setValue]);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleSubmitModal = React.useCallback(async () => {
    const validationResult = await validation();
    if (!validationResult) return;

    valueField.onChange(NEWITEM_VALUE);
    labelField.onChange(value);
    setOpen(false);
    resetModal();
  }, [value, valueField, labelField, resetModal, validation]);

  const handleCancelModal = React.useCallback(() => {
    setOpen(false);
    resetModal();
  }, [resetModal]);

  React.useEffect(() => {
    validation();
  }, [value, validation]);

  return (
    <>
      <Button
        type="add"
        size="small"
        isFlexible
        icon="plus"
        iconPosition="after"
        text="JANコード未発行の販促品"
        onClick={handleOpen}
        disabled={undecided || valueField.value}
      />

      <Modal
        isShow={isOpen}
        hasSubmit={true}
        hasCancel={true}
        cancelText="キャンセル"
        onSubmit={handleSubmitModal}
        onCancel={handleCancelModal}
        disabledSubmit={isDisabledSubmit}
      >
        <Text size={20} bold align="center">
          JANコード未発行の販促品
        </Text>
        <Stack mt={30}>
          <TextInput
            name={`${name}_search-input`}
            placeholder="「SHISEIDO　アルティミューンサンプル（1日分）」等を入力"
            value={value}
            onChange={setValue}
            size="large"
            width={540}
          />
          <ErrorText error={error}></ErrorText>
          <Note>※{maxLength}文字以内で入力してください。</Note>
        </Stack>
      </Modal>
    </>
  );
};
