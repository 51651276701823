import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsconfig from 'aws-exports';
import { ApiGetHolidaysProvider } from 'components/Business/Api';
import { CognitoUserState, CognitoUserUpdate } from 'context';
import { useScrollToTop } from 'hooks/useScrollToTop';

Amplify.configure(awsconfig);

type Props = {
  privateRoutes: React.ReactElement;
  guestRoutes: React.ReactElement;
};

export const Authorization: React.FC<Props> = ({ privateRoutes, guestRoutes }) => {
  useScrollToTop();
  const history = useHistory();
  const { pathname } = useLocation();
  const userInfo = React.useContext(CognitoUserState);
  const setUserInfo = React.useContext(CognitoUserUpdate);
  const [isLoading, setLoading] = React.useState(true);

  const init = React.useCallback(async () => {
    setLoading(true);
    try {
      const currentUser = await Amplify.Auth.currentAuthenticatedUser();
      setUserInfo(currentUser);
      console.log(currentUser);
    } catch (error) {
      console.log(error);
      const state = {
        nextPage: pathname === '/CMN002' ? '/CMN001' : pathname,
      };
      history.replace('/CMN002', state);
    } finally {
      setLoading(false);
    }
  }, [history, pathname, setUserInfo]);

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isLoading && (
        <>
          {userInfo ? (
            <>
              <ApiGetHolidaysProvider />
              {privateRoutes}
            </>
          ) : (
            <>{guestRoutes}</>
          )}
        </>
      )}
    </>
  );
};
