import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { Container } from 'components/Atoms/Layout';
import { TextLink } from 'components/Business/TextLink';
import { Button } from 'components/Atoms/Button';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { CognitoUserState, CognitoUserUpdate } from 'context';
import style from './style.module.scss';

const NavigationComponent: React.FC = () => {
  const { pathname } = useLocation();

  return React.useMemo(
    () => (
      <nav className={style['header-nav']}>
        <ul>
          <li className={pathname.match(/(CPF001|CPF002|CPF003|CPF004)/) ? 'is-current' : ''}>
            <TextLink to="/CPF001" color="white" className={style['header-nav-link']}>
              施策情報登録
            </TextLink>
          </li>
          <li className={pathname.match(/(CPF007|CPF008|CPF009|CPF010|CPF011)/) ? 'is-current' : ''}>
            <TextLink to="/CPF007" color="white" className={style['header-nav-link']}>
              登録済み施策一覧
            </TextLink>
          </li>
          <li className={pathname.match(/(PRF001|PRF002)/) ? 'is-current' : ''}>
            <TextLink to="/PRF001" color="white" className={style['header-nav-link']}>
              販促品一覧
            </TextLink>
          </li>
        </ul>
      </nav>
    ),
    [pathname],
  );
};

export const Header: React.FC = () => {
  const history = useHistory();
  const userInfo = React.useContext(CognitoUserState);
  const setUserInfo = React.useContext(CognitoUserUpdate);

  const UserInfoComponent: React.FC = () => {
    const [openFlag, setOpenFlag] = React.useState(false);

    const signOut = React.useCallback(async () => {
      try {
        await Amplify.Auth.signOut();
        setUserInfo(undefined);
        history.push('/CMN002');
      } catch (error) {
        console.error(error);
      }
    }, []);

    return React.useMemo(
      () => (
        <div className={style['header-userInfo']}>
          <button onClick={() => setOpenFlag(!openFlag)}>
            {userInfo?.username}
            <SvgIcon type="triangle-down" fill="#fff" width={8} className={style['header-userInfo-icon']} />
          </button>
          {openFlag && (
            <div className={style['header-userInfo-logout']}>
              <Button type="delete" size="small" isFlexible onClick={signOut} text="ログアウト" />
              <button
                className={style['header-useInfo-logout-button-close']}
                onClick={() => {
                  setOpenFlag(!openFlag);
                }}
              >
                <SvgIcon type="cross-bold" fill="#373940" width={9} />
              </button>
            </div>
          )}
        </div>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [userInfo, openFlag],
    );
  };

  return React.useMemo(
    () => (
      <header className={style.header}>
        <Container className={style['header-inner']}>
          <div className={style['header-layer']}>
            <h1 className={style['header-siteName']}>AI Campaign Agent</h1>
            {userInfo && <NavigationComponent />}
          </div>
          {userInfo && <UserInfoComponent />}
        </Container>
      </header>
    ),
    [userInfo],
  );
};
