import React from 'react';
import { useFormState } from 'react-hook-form';
import get from 'lodash/get';
import { ErrorText } from 'components/Atoms/Typography';
import { Tab, TabList, TabAddButton } from 'components/Atoms/TabMenu';
import { smoothScroll } from 'utils';

type CustomTabListProps = {
  isBottom?: boolean;
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  selectName: string;
  name: string;
  contentLabel: (key: number) => string;
  keys: number[];
  getTabName: (k?: number) => string;
  hasAddButton: boolean;
  addContent: (numToAdd: number) => void;
  max: number;
};

export const CustomTabList: React.FC<CustomTabListProps> = ({
  isBottom = false,
  currentTab,
  setCurrentTab,
  selectName,
  name,
  contentLabel,
  keys,
  getTabName,
  hasAddButton,
  addContent,
  max,
}) => {
  const { errors } = useFormState();
  const [errorText, setErrorText] = React.useState('');

  const handleClickTab = React.useCallback(
    (elem) => {
      setCurrentTab(elem);
      if (!isBottom) return;
      smoothScroll({
        to: selectName,
        easing: 'easeInOutCirc',
        offset: -50,
      });
    },
    [isBottom, selectName, setCurrentTab],
  );

  React.useEffect(() => {
    const errorsArray = get(errors, name);
    if (errorsArray) {
      const errorLabels = errorsArray
        .map((elem: any, index: number) => `「${contentLabel(index)}」`)
        .filter((elem: any) => elem)
        .join('');
      setErrorText(`下記タブの入力内容に誤りがあります。タブが表示されていない場合、矢印を押下してください。\n${errorLabels}`);
    } else {
      setErrorText('');
    }
  }, [contentLabel, errors, name]);

  return React.useMemo(
    () => (
      <>
        <ErrorText error={errorText} mt={30} />

        <TabList isBottom={isBottom}>
          {keys.map((elem, index) => {
            return (
              <Tab key={index} isActive={currentTab === elem} isInvalid={get(errors, getTabName(elem)) ? true : false} onClick={() => handleClickTab(elem)}>
                {contentLabel(elem)}
              </Tab>
            );
          })}
          {hasAddButton && <TabAddButton onClick={() => addContent(1)} disabled={keys.length >= max} />}
        </TabList>
      </>
    ),
    [errorText, handleClickTab, isBottom, addContent, contentLabel, currentTab, errors, getTabName, hasAddButton, keys, max],
  );
};
