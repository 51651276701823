import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Loading } from 'components/Atoms/Loading';
import { ENDPOINT_GET_AID_DETAIL_API } from 'constants/index';
import { NetworkErrorUpdate } from 'context';
import { useApiCall } from 'hooks/useApiCall';
import type { MeasureParamsProps, ModuleAssignmentProps, DesignDetailProps } from 'types';

type Props = {
  callback?: (result?: {}, isTransaction?: boolean) => void;
};

export const ApiGetAidDetailProvider: React.FC<Props> = ({ callback = () => {}, children }) => {
  const history = useHistory();
  const { pathname, state }: { pathname: string; state: DesignDetailProps } = useLocation();
  const apiCall = useApiCall();
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  const { measureId } = useParams<MeasureParamsProps>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isShow, setShow] = React.useState(false);

  const init = React.useCallback(() => {
    setShow(false);
    setIsLoading(true);

    if (!state) {
      apiCall({
        method: 'GET',
        url: `${ENDPOINT_GET_AID_DETAIL_API}/${measureId}`,
      })
        .then((res) => {
          console.log(res.data.result);

          res.data.result.moduleAssignments.forEach((elem: ModuleAssignmentProps) => {
            elem.designTarget = elem.productSubType || (elem.modules && elem.modules.length > 0) ? '1' : '2';
          });

          callback(res.data.result);
          setShow(true);
        })
        .catch((error) => {
          // meadureIdが無効の場合
          if (error.response?.status === 400) setNetworkError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      callback(state, true);

      setShow(true);
      setIsLoading(false);

      // routerで受け取ったstateをを破棄(reload対応)
      history.replace(history.location.pathname);
    }
  }, [apiCall, callback, measureId, setNetworkError, state, history]);

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && isShow && children}
    </>
  );
};
