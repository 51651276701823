import React from 'react';
import { Text } from 'components/Atoms/Typography';
import { Modal } from 'components/Organisms/Modal';
import { NetworkErrorState, NetworkErrorUpdate } from 'context';

export const NetworkErrorModal: React.FC = () => {
  const networkError = React.useContext(NetworkErrorState);
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  const [isShow, setShow] = React.useState(false);

  const handleClose = React.useCallback(() => {
    setShow(false);
    setNetworkError(false);
  }, [setNetworkError]);

  React.useEffect(() => {
    if (networkError) setShow(true);
  }, [networkError]);

  return React.useMemo(
    () => (
      <Modal type="warn" title="通信に失敗しました" isShow={isShow} hasSubmit onSubmit={handleClose} onCancel={handleClose}>
        <Text align="center">もう一度お試しください。</Text>
      </Modal>
    ),
    [handleClose, isShow],
  );
};
