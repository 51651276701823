import { CONVERT_TARGET_CHAR_MAP } from 'constants/index';

export const toHalfWIdthChars = (str: string) => {
  const regexp = new RegExp('(' + Object.values(CONVERT_TARGET_CHAR_MAP).join('|') + ')', 'g');

  return str
    .replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (match) => String.fromCharCode(match.charCodeAt(0) - 0xfee0))
    .replace(regexp, (match) => Object.keys(CONVERT_TARGET_CHAR_MAP).find((key) => CONVERT_TARGET_CHAR_MAP[key] === match) || match)
    .replace(/[―‐‑]/g, '-');
};
