import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { AddUserStateProps } from 'components/Pages/CMN007';

export const CMN008: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<AddUserStateProps>();

  React.useEffect(() => {
    if (!state || !state?.isSubmitted) history.push('/CMN001');
  }, [history, state]);

  return (
    <>
      <Header />
      <Main>
        <Container>
          <Section>
            <H1>ユーザー登録完了</H1>
            <Text align="center">
              登録されたメールアドレスに仮パスワードを送信しました。
              <br />
              登録したユーザーに、ログインページでユーザー名と仮パスワードを入力し初回ログインを行う必要がある旨をお伝えください。
            </Text>

            <ButtonContainer isVertical>
              <Button type="primary" text="別のユーザーを登録する" to="/CMN007" />
              <Button type="secondary" icon="arrow-right" iconPosition="right" text="TOPページへ移動する" to="/CMN001" />
            </ButtonContainer>
          </Section>
        </Container>
      </Main>
      <Footer />
    </>
  );
};
