import * as yup from 'yup';
import { isNumber, isRange, isValidDate, isAlphanumeric, isWithinMaxBytes, isFullWidthChars, isNewLineChars, isFutureDateCompared } from 'utils';
import { useTranslation } from 'react-i18next';
import { UNDECIDED_VALUE } from 'constants/index';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    campaignDetail: yup
      .array(
        yup
          .object()
          .nullable()
          .shape({
            enforcementDivision: yup.string().required(t('ERR-REQUIRED')),

            settingStartDateYear: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.settingStartDateMonth, date: this.parent.settingStartDateDay });
                });
            }),

            settingStartDateMonth: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.settingStartDateYear, month: value, date: this.parent.settingStartDateDay });
                });
            }),

            settingStartDateDay: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.settingStartDateYear, month: this.parent.settingStartDateMonth, date: value });
                });
            }),

            settingStartDateHours: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                  return isRange({ value, min: 0, max: 23 });
                });
            }),

            settingStartDateMinutes: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                  return isRange({ value, min: 0, max: 59 });
                });
            }),

            settingEndDateYear: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: value, month: this.parent.settingEndDateMonth, date: this.parent.settingEndDateDay });
                });
            }),

            settingEndDateMonth: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.settingEndDateYear, month: value, date: this.parent.settingEndDateDay });
                });
            }),

            settingEndDateDay: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                  return isValidDate({ year: this.parent.settingEndDateYear, month: this.parent.settingEndDateMonth, date: value });
                });
            }),

            settingEndDateHours: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                  return isRange({ value, min: 0, max: 23 });
                });
            }),

            settingEndDateMinutes: yup.lazy((value: string) => {
              if (value === UNDECIDED_VALUE) return yup.string();
              return yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                  return isNumber(value);
                })
                .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                  return isRange({ value, min: 0, max: 59 });
                });
            }),

            purchaseTypeNormal: yup.string().test('requiredAny', t('ERR-REQUIRED'), function (value) {
              if (!value && !this.parent.purchaseTypeReserve && !this.parent.purchaseTypeRegular && !this.parent.purchaseTypeSimple) return false;
              return true;
            }),

            purchaseTypeReserve: yup.string().test('requiredAny', t('ERR-REQUIRED'), function (value) {
              if (!this.parent.purchaseTypeNormal && !value && !this.parent.purchaseTypeRegular && !this.parent.purchaseTypeSimple) return false;
              return true;
            }),

            purchaseTypeRegular: yup.string().test('requiredAny', t('ERR-REQUIRED'), function (value) {
              if (!this.parent.purchaseTypeNormal && !this.parent.purchaseTypeReserve && !value && !this.parent.purchaseTypeSimple) return false;
              return true;
            }),

            purchaseTypeSimple: yup.string().test('requiredAny', t('ERR-REQUIRED'), function (value) {
              if (!this.parent.purchaseTypeNormal && !this.parent.purchaseTypeReserve && !this.parent.purchaseTypeRegular && !value) return false;
              return true;
            }),

            memberType: yup.string().required(t('ERR-REQUIRED')),

            applicationsType: yup.string().required(t('ERR-REQUIRED')),

            applicationsDetails: yup.string().when('applicationsType', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            // 適用会員リストの指定
            memberList: yup.string().required(t('ERR-REQUIRED')),

            listConditions: yup.string().when('memberList', {
              is: (value: string) => value === '2' || value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            applicationsMemberBrand: yup.string().required(t('ERR-REQUIRED')),

            // キャンペーンのオプションを設定
            optionType: yup.string().required(t('ERR-REQUIRED')),

            myPageCampaignName: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2' || value === '3',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isFullWidthChars', t('ERR-LENGTH-MAX-FULLWIDTH', { length: 200 }), function (value) {
                    return isFullWidthChars(value);
                  })
                  .test('isNewLineChars', t('ERR-INVALID-CHAR-NEWLINE'), function (value) {
                    return isNewLineChars(value);
                  })
                  .max(200, t('ERR-LENGTH-MAX-FULLWIDTH', { length: 200 }));
              }),
            }),

            myPageCampaignDetails: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2' || value === '3',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isFullWidthChars', t('ERR-LENGTH-MAX-FULLWIDTH', { length: 200 }), function (value) {
                    return isFullWidthChars(value);
                  })
                  .test('isNewLineChars', t('ERR-INVALID-CHAR-NEWLINE'), function (value) {
                    return isNewLineChars(value);
                  })
                  .max(200, t('ERR-LENGTH-MAX-FULLWIDTH', { length: 200 }));
              }),
            }),

            myPageEndConditions: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2' || value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            myPageEndDateYear: yup.string().when('myPageEndConditions', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: value, month: this.parent.myPageEndDateMonth, date: this.parent.myPageEndDateDay });
                  });
              }),
            }),

            myPageEndDateMonth: yup.string().when('myPageEndConditions', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: this.parent.myPageEndDateYear, month: value, date: this.parent.myPageEndDateDay });
                  });
              }),
            }),

            myPageEndDateDay: yup.string().when('myPageEndConditions', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: this.parent.myPageEndDateYear, month: this.parent.myPageEndDateMonth, date: value });
                  });
              }),
            }),

            myPageEndDateHours: yup.string().when('myPageEndConditions', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                    return isRange({ value, min: 0, max: 23 });
                  });
              }),
            }),

            myPageEndDateMinutes: yup.string().when('myPageEndConditions', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                    return isRange({ value, min: 0, max: 59 });
                  });
              }),
            }),

            campaignUrl: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2' || value === '3',
              then: yup.string().url(t('ERR-FORMAT-URL')),
            }),

            campaignImage: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2' || value === '3',
              then: yup.string().url(t('ERR-FORMAT-URL')),
            }),

            entryEndDateYear: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: value, month: this.parent.entryEndDateMonth, date: this.parent.entryEndDateDay });
                  });
              }),
            }),

            entryEndDateMonth: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: this.parent.entryEndDateYear, month: value, date: this.parent.entryEndDateDay });
                  });
              }),
            }),

            entryEndDateDay: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: this.parent.entryEndDateYear, month: this.parent.entryEndDateMonth, date: value });
                  });
              }),
            }),

            entryEndDateHours: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                    return isRange({ value, min: 0, max: 23 });
                  });
              }),
            }),

            entryEndDateMinutes: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isRange', t('ERR-INVALID-TIME'), function (value) {
                    return isRange({ value, min: 0, max: 59 });
                  });
              }),
            }),

            entryUpperLimitSetting: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            entryUpperLimit: yup.string().when('entryUpperLimitSetting', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            beforeEntryShow: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            entryMailPermission: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            entryLineConnect: yup.string().when('optionType', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            purchaseAchievementCount: yup.string().when('optionType', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            secretCampaignDescriptionPc: yup.string().when('optionType', {
              is: (value: string) => value === '3',
              then: yup.string().test('isWithinMaxBytes', t('ERR-MAXBYTES', { maxBytes: 4000 }), function (value) {
                return isWithinMaxBytes(value, 4000);
              }),
            }),

            secretCampaignDescriptionSp: yup.string().when('optionType', {
              is: (value: string) => value === '3',
              then: yup.string().test('isWithinMaxBytes', t('ERR-MAXBYTES', { maxBytes: 4000 }), function (value) {
                return isWithinMaxBytes(value, 4000);
              }),
            }),

            // キャンペーン適用条件設定
            amountSpecification: yup.string().required(t('ERR-REQUIRED')),

            amountSpecificationConditions: yup.string().when('amountSpecification', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            amountSpecificationSetting: yup.string().when('amountSpecification', {
              is: (value: string) => value === '2',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            applicationsConditions: yup
              .string()
              .when('amountSpecificationSetting', {
                is: (value: string) => value === '1' || value === '2',
                then: yup.string().required(t('ERR-REQUIRED')),
              })
              .when('amountSpecification', {
                is: (value: string) => value === '3',
                then: yup.string().required(t('ERR-REQUIRED')),
              }),

            skuSpecifiedCondition: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '1',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            skuPurchase: yup.string().when('skuSpecifiedCondition', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-NUMBER-RANGE', { min: 1, max: 99 }), function (value) {
                    return isNumber(value);
                  })
                  .test('isRange', t('ERR-NUMBER-RANGE', { min: 1, max: 99 }), function (value) {
                    return isRange({ value, min: 1, max: 99 });
                  });
              }),
            }),

            skuMeasuresNum: yup.mixed().when('applicationsConditions', {
              is: (value: string) => value === '1',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      skuMeasures: yup.lazy((value: string) => {
                        if (value === UNDECIDED_VALUE) return yup.string();
                        return yup
                          .string()
                          .required(t('ERR-REQUIRED'))
                          .test('isNumber', t('ERR-LENGTH-NUMBER', { length: 13 }), function (value) {
                            return isNumber(value);
                          })
                          .length(13, t('ERR-LENGTH-NUMBER', { length: 13 }));
                      }),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            poolSpecifiedCondition: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '2',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            poolPurchase: yup.string().when('poolSpecifiedCondition', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-NUMBER-RANGE', { min: 1, max: 99 }), function (value) {
                    return isNumber(value);
                  })
                  .test('isRange', t('ERR-NUMBER-RANGE', { min: 1, max: 99 }), function (value) {
                    return isRange({ value, min: 1, max: 99 });
                  });
              }),
            }),

            poolMeasuresNum: yup.mixed().when('applicationsConditions', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      poolMeasures: yup.lazy((value: string) => {
                        if (value === UNDECIDED_VALUE) return yup.string();
                        return yup
                          .string()
                          .required(t('ERR-REQUIRED'))
                          .test('isAlphanumeric', t('ERR-LENGTH-ALPHANUMERIC', { length: 6 }), function (value) {
                            return isAlphanumeric(value);
                          })
                          .length(6, t('ERR-LENGTH-ALPHANUMERIC', { length: 6 }));
                      }),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            purchaseSpecifiedConditions: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '3',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isMultipleBrandsAndItems', t('ERR-MULTIPLE-BRANDS-AND-ITEMS'), function (value) {
                  if (
                    // ブランド×アイテム指定時、どちらも複数指定した場合にエラーとする
                    value === '3' &&
                    Array.isArray(this.parent.purchaseSelectedBrandNum) &&
                    Array.isArray(this.parent.purchaseSelectedItemNum) &&
                    (this.parent.purchaseSelectedBrandNum.filter((v: any) => v).length - 1) *
                      (this.parent.purchaseSelectedItemNum.filter((v: any) => v).length - 1) !==
                      0
                  )
                    return false;
                  return true;
                }),
            }),

            purchaseSelectedBrandNum: yup.mixed().when('purchaseSpecifiedConditions', {
              is: (value: string) => value === '1' || value === '3',
              then: yup.lazy((value: any) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      purchaseSelectedBrands: yup.string(),
                      purchaseSelectedBrandsName: yup.string(),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            purchaseSpecifiedConditionsItem: yup.string().when('purchaseSpecifiedConditions', {
              is: (value: string) => value === '2' || value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            purchaseSelectedItemNum: yup.mixed().when('purchaseSpecifiedConditions', {
              is: (value: string) => value === '2' || value === '3',
              then: yup.lazy((value: any) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      purchaseSelectedItem: yup.string(),
                      purchaseSelectedItemName: yup.string(),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            memberLowerRank: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '4',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            memberHigherRank: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '4',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            inflowRouteCodeNum: yup.mixed().when('applicationsConditions', {
              is: (value: string) => value === '5',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      inflowRouteCode: yup.string().required(t('ERR-REQUIRED')),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            inflowRouteExpirationDate: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '5',
              then: yup.string().test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              }),
            }),

            inflowRouteApplicable: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '5',
              then: yup.string().test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              }),
            }),

            inflowRouteAmount: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '5',
              then: yup.string().test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              }),
            }),

            inflowRouteCoupon: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '5',
              then: yup.string(),
            }),

            urlApplicableDays: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '6',
              then: yup.string().test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                return isNumber(value);
              }),
            }),

            urlLineConnect: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '6',
              then: yup.string(),
            }),

            urlPcTag: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '6',
              then: yup.string(),
            }),

            urlSmartphoneTag: yup.string().when('applicationsConditions', {
              is: (value: string) => value === '6',
              then: yup.string(),
            }),

            // キャンペーン特典設定
            benefitType: yup.string().required(t('ERR-REQUIRED')),

            presentDecline: yup.string().when('benefitType', {
              is: (value: string) => value === '1',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            samplePresent: yup.string().when('presentDecline', {
              is: (value: string) => value === '1' || value === '2',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            presentSelectPromotionalItemsNum1: yup.mixed().when('samplePresent', {
              is: (value: string) => value === '1' || value === '2' || value === '3' || value === '4',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      presentSelectPromotionalItems1: yup.string().required(t('ERR-REQUIRED')),
                      presentSelectPromotionalItemsName1: yup.string().required(t('ERR-REQUIRED')),
                      presentSelectPromotionalItemsDelivery1: yup.string().required(t('ERR-REQUIRED')),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            presentSelectPromotionalItemsNum2: yup.mixed().when('samplePresent', {
              is: (value: string) => value === '2' || value === '4',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      presentSelectPromotionalItems2: yup.string().required(t('ERR-REQUIRED')),
                      presentSelectPromotionalItemsName2: yup.string().required(t('ERR-REQUIRED')),
                      presentSelectPromotionalItemsDelivery2: yup.string().required(t('ERR-REQUIRED')),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            presentSelectPromotionalItemsNum3: yup.mixed().when('samplePresent', {
              is: (value: string) => value === '2' || value === '4',
              then: yup.array(
                yup.object().shape({
                  presentSelectPromotionalItems3: yup.string(),
                  presentSelectPromotionalItemsName3: yup.string(),
                  presentSelectPromotionalItemsDelivery3: yup.string(),
                }),
              ),
            }),

            presentCampaignName: yup.string().when('samplePresent', {
              is: (value: string) => value === '3' || value === '4',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNewLineChars', t('ERR-INVALID-CHAR-NEWLINE'), function (value) {
                    return isNewLineChars(value);
                  })
                  .test('isWithinMaxBytes', t('ERR-LENGTH-MAX-FULLWIDTH', { length: 50 }), function (value) {
                    return isWithinMaxBytes(value, 100);
                  });
              }),
            }),

            presentSelectMaxNum: yup.mixed().when('samplePresent', {
              is: (value: string) => value === '3' || value === '4',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            couponType: yup.string().when('benefitType', {
              is: (value: string) => value === '2',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            couponName: yup.string().when('couponType', {
              is: (value: string) => value === '1' || value === '2' || value === '3' || value === '4',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNewLineChars', t('ERR-INVALID-CHAR-NEWLINE'), function (value) {
                    return isNewLineChars(value);
                  })
                  .test('isWithinMaxBytes', t('ERR-LENGTH-MAX-FULLWIDTH', { length: 20 }), function (value) {
                    return isWithinMaxBytes(value, 40);
                  });
              }),
            }),

            couponContents: yup.string().when('couponType', {
              is: (value: string) => value === '1' || value === '2' || value === '3' || value === '4',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            //
            couponExpirationDate: yup.string().when('couponType', {
              is: (value: string) => value === '1' || value === '2' || value === '3' || value === '4',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            couponAfterGrantDate: yup.string().when('couponExpirationDate', {
              is: (value: string) => value === '1',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isRange', t('ERR-NUMBER-MIN', { min: 30 }), function (value) {
                    return isRange({ value, min: 30 });
                  });
              }),
            }),

            couponSpecifyDateYear: yup.string().when('couponExpirationDate', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: value, month: this.parent.couponSpecifyDateMonth, date: this.parent.couponSpecifyDateDay });
                  })
                  .test('isFutureDateCompared', t('ERR-COMPARED-PAST-DATE', { target: 'キャンペーン設定終了日' }), function (value) {
                    return isFutureDateCompared({
                      futureYear: value,
                      futureMonth: this.parent.couponSpecifyDateMonth,
                      futureDate: this.parent.couponSpecifyDateDay,
                      pastYear: this.parent.settingEndDateYear,
                      pastMonth: this.parent.settingEndDateMonth,
                      pastDate: this.parent.settingEndDateDay,
                    });
                  });
              }),
            }),

            couponSpecifyDateMonth: yup.string().when('couponExpirationDate', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: this.parent.couponSpecifyDateYear, month: value, date: this.parent.couponSpecifyDateDay });
                  })
                  .test('isFutureDateCompared', t('ERR-COMPARED-PAST-DATE', { target: 'キャンペーン設定終了日' }), function (value) {
                    return isFutureDateCompared({
                      futureYear: this.parent.couponSpecifyDateYear,
                      futureMonth: value,
                      futureDate: this.parent.couponSpecifyDateDay,
                      pastYear: this.parent.settingEndDateYear,
                      pastMonth: this.parent.settingEndDateMonth,
                      pastDate: this.parent.settingEndDateDay,
                    });
                  });
              }),
            }),

            couponSpecifyDateDay: yup.string().when('couponExpirationDate', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  })
                  .test('isValidDate', t('ERR-INVALID-DATE'), function (value) {
                    return isValidDate({ year: this.parent.couponSpecifyDateYear, month: this.parent.couponSpecifyDateMonth, date: value });
                  })
                  .test('isFutureDateCompared', t('ERR-COMPARED-PAST-DATE', { target: 'キャンペーン設定終了日' }), function (value) {
                    return isFutureDateCompared({
                      futureYear: this.parent.couponSpecifyDateYear,
                      futureMonth: this.parent.couponSpecifyDateMonth,
                      futureDate: value,
                      pastYear: this.parent.settingEndDateYear,
                      pastMonth: this.parent.settingEndDateMonth,
                      pastDate: this.parent.settingEndDateDay,
                    });
                  });
              }),
            }),

            couponAmount: yup.string().when('couponType', {
              is: (value: string) => value === '1' || value === '2' || value === '3' || value === '4',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            couponConditionsUse: yup.string().when('couponType', {
              is: (value: string) => value === '1' || value === '2' || value === '3' || value === '4',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            couponCouponConditionsUse: yup.string().when('couponType', {
              is: (value: string) => value === '1' || value === '2' || value === '4',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            useCouponSkuSpecifiedConditions: yup.string().when('couponCouponConditionsUse', {
              is: (value: string) => value === '1',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            useCouponSkuMeasuresNum: yup.mixed().when('couponCouponConditionsUse', {
              is: (value: string) => value === '1',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      useCouponSkuMeasures: yup.lazy((value: string) => {
                        if (value === UNDECIDED_VALUE) return yup.string();
                        return yup
                          .string()
                          .test('isNumber', t('ERR-LENGTH-NUMBER', { length: 13 }), function (value) {
                            return isNumber(value);
                          })
                          .length(13, t('ERR-LENGTH-NUMBER', { length: 13 }));
                      }),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            useCouponPoolSpecifiedConditions: yup.string().when('couponCouponConditionsUse', {
              is: (value: string) => value === '2',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            useCouponPoolMeasuresNum: yup.mixed().when('couponCouponConditionsUse', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      useCouponPoolMeasures: yup.lazy((value: string) => {
                        if (value === UNDECIDED_VALUE) return yup.string();
                        return yup
                          .string()
                          .test('isAlphanumeric', t('ERR-LENGTH-ALPHANUMERIC', { length: 6 }), function (value) {
                            return isAlphanumeric(value);
                          })
                          .length(6, t('ERR-LENGTH-ALPHANUMERIC', { length: 6 }));
                      }),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            useCouponBrandSpecifiedConditions: yup.string().when('couponCouponConditionsUse', {
              is: (value: string) => value === '3',
              then: yup
                .string()
                .required(t('ERR-REQUIRED'))
                .test('isMultipleBrandsAndItems', t('ERR-MULTIPLE-BRANDS-AND-ITEMS'), function (value) {
                  if (
                    // ブランド×アイテム指定時、どちらも複数指定した場合にエラーとする
                    value === '3' &&
                    Array.isArray(this.parent.useCouponBrandSelectBrandNum) &&
                    Array.isArray(this.parent.useCouponBrandSelectItemNum) &&
                    (this.parent.useCouponBrandSelectBrandNum.filter((v: any) => v).length - 1) *
                      (this.parent.useCouponBrandSelectItemNum.filter((v: any) => v).length - 1) !==
                      0
                  )
                    return false;
                  return true;
                }),
            }),

            useCouponBrandBrandConditions: yup.string().when('useCouponBrandSpecifiedConditions', {
              is: (value: string) => value === '1' || value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            useCouponBrandSelectBrandNum: yup.mixed().when('useCouponBrandSpecifiedConditions', {
              is: (value: string) => value === '1' || value === '3',
              then: yup.lazy((value: any) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      useCouponBrandSelectBrand: yup.string(),
                      useCouponBrandSelectBrandName: yup.string(),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            useCouponBrandConditionsItem: yup.string().when('useCouponBrandSpecifiedConditions', {
              is: (value: string) => value === '2' || value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            useCouponBrandSelectItemNum: yup.mixed().when('useCouponBrandSpecifiedConditions', {
              is: (value: string) => value === '2' || value === '3',
              then: yup.lazy((value: any) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .array(
                    yup.object().shape({
                      useCouponBrandSelectItem: yup.string(),
                      useCouponBrandSelectItemName: yup.string(),
                    }),
                  )
                  .required(t('ERR-REQUIRED'))
                  .min(1, t('ERR-REQUIRED-SELECT-NUMBER'));
              }),
            }),

            couponGrantFormat: yup.string().when('couponType', {
              is: (value: string) => value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            couponIssueDate: yup.string().when('couponGrantFormat', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            couponConditionsIssue: yup.string().when('couponType', {
              is: (value: string) => value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            couponApplicableCondition: yup.string().when('couponType', {
              is: (value: string) => value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            couponCartCoupon: yup.string().when('couponType', {
              is: (value: string) => value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            couponCode: yup.string().when('couponType', {
              is: (value: string) => value === '4',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isAlphanumeric', t('ERR-LENGTH-MAX-ALPHANUMERIC', { length: 20 }), function (value) {
                    return isAlphanumeric(value);
                  })
                  .max(20, t('ERR-LENGTH-MAX-ALPHANUMERIC', { length: 20 }));
                // 重複判定はhookで対応
              }),
            }),

            couponMaxDistributions: yup.string().when('couponType', {
              is: (value: string) => value === '4',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            couponMaxNumber: yup.string().when('couponMaxDistributions', {
              is: (value: string) => value === '2',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            pointSelectSpecify: yup.string().when('benefitType', {
              is: (value: string) => value === '3',
              then: yup.string().required(t('ERR-REQUIRED')),
            }),

            pointSelectNum: yup.mixed().when('benefitType', {
              is: (value: string) => value === '3',
              then: yup.lazy((value: string) => {
                if (value === UNDECIDED_VALUE) return yup.string();
                return yup
                  .string()
                  .required(t('ERR-REQUIRED'))
                  .test('isNumber', t('ERR-TYPE-NUMBER'), function (value) {
                    return isNumber(value);
                  });
              }),
            }),

            imjCampaignCodeStaging: yup.string(),

            imjCampaignCodeProduction: yup.string(),
          }),
      )
      .required(t('ERR-REQUIRED'))
      .min(1, t('ERR-REQUIRED-SELECT-NUMBER')),
  });
};
