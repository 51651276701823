import dayjs from 'dayjs';

type Props = {
  year?: string;
  month?: string;
  date?: string;
};

export const isFutureDate = ({ year, month, date }: Props) => {
  if (!year && !month && !date) return true;

  const dateObject = dayjs()
    .year(Number(year))
    .month(Number(month) - 1)
    .date(Number(date))
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  const todayObject = dayjs().hour(0).minute(0).second(0).millisecond(0);

  if (dateObject.isBefore(todayObject)) return false;
  return true;
};
