import { Login } from 'components/Pages/Login';
import { CMN001 } from 'components/Pages/CMN001';
import { CMN007 } from 'components/Pages/CMN007';
import { CMN008 } from 'components/Pages/CMN008';
import { CMN012 } from 'components/Pages/CMN012';
import { CPF001 } from 'components/Pages/CPF001';
import { CPF002 } from 'components/Pages/CPF002';
import { CPF003 } from 'components/Pages/CPF003';
import { CPF004 } from 'components/Pages/CPF004';
import { CPF007 } from 'components/Pages/CPF007';
import { CPF008 } from 'components/Pages/CPF008';
import { CPF009 } from 'components/Pages/CPF009';
import { CPF010 } from 'components/Pages/CPF010';
import { CPF011 } from 'components/Pages/CPF011';
import { PRF001 } from 'components/Pages/PRF001';
import { PRF002 } from 'components/Pages/PRF002';
import { AID001 } from 'components/Pages/AID001';
import { AID002 } from 'components/Pages/AID002';
import { AID003 } from 'components/Pages/AID003';
import { AID004 } from 'components/Pages/AID004';
import { AID005 } from 'components/Pages/AID005';

export const routes = [
  { path: '/Login', component: Login },

  { path: '/CMN001', component: CMN001 },
  { path: '/CMN007', component: CMN007 },
  { path: '/CMN008', component: CMN008 },
  { path: '/CMN012', component: CMN012 },

  { path: '/CPF001/:measureId?', component: CPF001 },
  { path: '/CPF002/:measureId', component: CPF002 },
  { path: '/CPF003/:measureId', component: CPF003 },
  { path: '/CPF004/:measureId', component: CPF004 },

  { path: '/CPF007', component: CPF007 },
  { path: '/CPF008/:measureId', component: CPF008 },
  { path: '/CPF009/:measureId', component: CPF009 },
  { path: '/CPF009/:measureId', component: CPF009 },
  { path: '/CPF010/:measureId', component: CPF010 },
  { path: '/CPF011/:measureId', component: CPF011 },

  { path: '/PRF001', component: PRF001 },
  { path: '/PRF002/:janCode', component: PRF002 },

  { path: '/AID001', component: AID001 },
  { path: '/AID002/:measureId', component: AID002 },
  { path: '/AID003/:measureId', component: AID003 },
  { path: '/AID004/:measureId', component: AID004 },
  { path: '/AID005/:measureId', component: AID005 },
];
