import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Text } from 'components/Atoms/Typography';
import { Loading } from 'components/Atoms/Loading';
import { ModalAuto } from 'components/Organisms/Modal';
import { ENDPOINT_GET_MEASURE_API } from 'constants/index';
import { NetworkErrorUpdate, DirtyUpdate } from 'context';
import { useApiCall } from 'hooks/useApiCall';
import type { MeasureParamsProps, MeasureCopyStateProps } from 'types';

const Types = ['outline', 'web', 'campaign', 'sample'] as const;

type TypeProps = typeof Types[number];

type Props = {
  type?: TypeProps;
  callback?: (result?: {}) => void;
  defaultValues?: { [key: string]: any };
};

export const ApiGetMeasuresDetailProvider: React.FC<Props> = ({ type, callback = () => {}, children, defaultValues }) => {
  const { pathname, state } = useLocation<MeasureCopyStateProps>();
  const apiCall = useApiCall();
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  const setDirty = React.useContext(DirtyUpdate);
  const { measureId } = useParams<MeasureParamsProps>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isShow, setShow] = React.useState(false);
  const [isTemp, setTemp] = React.useState(false);
  const [isCopy, setCopy] = React.useState(false);

  const setTempFlag = React.useCallback(
    (result) => {
      if (!type) return;
      switch (type) {
        case 'outline':
          setTemp(result.measuresSaveType === '2');
          break;
        case 'web':
          setTemp(result.webSaveType === '2');
          break;
        case 'campaign':
          setTemp(result.campaignSaveType === '2');
          break;
        case 'sample':
          setTemp(result.sampleSaveType === '2');
          break;
      }
    },
    [type],
  );

  const ajax = React.useCallback(
    (measureId, isCopyMode) => {
      apiCall({
        method: 'GET',
        url: `${ENDPOINT_GET_MEASURE_API}/${measureId}`,
      })
        .then((res) => {
          callback(res.data.result);
          setShow(true);
          if (!isCopyMode) {
            setTempFlag(res.data.result);
          } else {
            setCopy(true);
            setTimeout(() => setDirty(true), 0);
          }
        })
        .catch((error) => {
          if (error.response?.status === 400) setNetworkError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [apiCall, callback, setNetworkError, setTempFlag, setDirty],
  );

  const init = React.useCallback(() => {
    setShow(false);
    setIsLoading(true);

    if (measureId) {
      // 更新モード
      ajax(measureId, false);
    } else if (type === 'outline' && state?.originalMeasureId) {
      // コピーモード
      ajax(state.originalMeasureId, true);
    } else {
      // 新規モード
      setShow(true);
      setIsLoading(false);
      callback(defaultValues);
    }
  }, [ajax, callback, defaultValues, measureId, state, type]);

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {isTemp && (
        <ModalAuto type="warn" title="現在下書き保存の状態です">
          <Text align="center">
            こちらのページは、まだ入力が完了しておりません。​
            <br />
            すべての項目を入力後、「入力完了」をクリックして、​
            <br />
            施策情報をご登録いただきますよう、お願いします。​
          </Text>
        </ModalAuto>
      )}
      {isCopy && (
        <ModalAuto type="warn" title="複製した施策は未登録の状態です">
          <Text align="center">
            各項目を確認・修正後、「入力完了」をクリックして、
            <br />
            施策情報をご登録いただきますよう、お願いします。
          </Text>
        </ModalAuto>
      )}
      {!isLoading && isShow && children}
    </>
  );
};
