import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext, useFormState } from 'react-hook-form';
import { Button } from 'components/Atoms/Button';
import { DirtyUpdate } from 'context';
import { scrollToFirstError } from 'utils';
import type { MeasureParamsProps, DesignDetailProps } from 'types';

export const SubmitButton: React.FC = () => {
  const { measureId } = useParams<MeasureParamsProps>();
  const history = useHistory();
  const setDirty = React.useContext(DirtyUpdate);
  const { handleSubmit, getValues } = useFormContext();
  const { isDirty } = useFormState();

  const onSuccess = React.useCallback(() => {
    const state = getValues() as DesignDetailProps;
    history.push(`/AID003/${measureId}`, state);
  }, [history, measureId, getValues]);

  const onError = React.useCallback(
    (errors: any, _e: any) => {
      console.log(getValues());
      console.log(errors);
      scrollToFirstError();
    },
    [getValues],
  );

  React.useEffect(() => {
    setDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <>
      <Button type="primary" size="large" onClick={() => handleSubmit(onSuccess, onError)()} text="入力内容確認" />
    </>
  );
};
