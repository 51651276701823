import React from 'react';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import { toHalfWIdthChars, toFullWIdthChars } from 'utils';
import style from './style.module.scss';
import { RefCallBack } from 'react-hook-form';

const Types = {
  text: 'text',
  number: 'number',
  year: 'year',
  month: 'month',
  date: 'date',
  hour: 'hour',
  minute: 'minute',
  url: 'url',
  email: 'email',
  tel: 'tel',
  fullWidth: 'fullWidth',
  alphaNumeric: 'alphaNumeric',
  password: 'password',
} as const;
export type TextInputTypesProps = typeof Types[keyof typeof Types];

const Size = {
  large: 'large',
  medium: 'medium',
  small: 'small',
} as const;
export type TextInputSizeProps = typeof Size[keyof typeof Size];

type Props = {
  name: string;
  value: string;
  refs?: RefCallBack;
  onChange: (value: string, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  onClick?: () => void;
  onFocus?: () => void;
  type?: TextInputTypesProps;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  isInvalid?: boolean;
  className?: string;
  readonly?: boolean;
  size?: TextInputSizeProps;
  width?: number;
};

export const TextInput: React.FC<Props> = ({
  name,
  value,
  refs,
  onChange,
  onBlur,
  onClick = () => {},
  onFocus = () => {},
  type = 'text',
  placeholder = '',
  disabled = false,
  maxLength,
  isInvalid,
  className = '',
  readonly = false,
  size = 'medium',
  width,
}) => {
  const [isShowPassword, setShowPassword] = React.useState(false);

  const handleChange = React.useCallback(
    (e) => {
      const inputted = e.target.value;

      if (maxLength && inputted.length > maxLength) return;

      onChange(inputted, e.target.name);
    },
    [onChange, maxLength],
  );

  const handleBlur = React.useCallback(
    (e) => {
      let inputted = e.target.value.trim();

      if (
        type === 'number' ||
        type === 'year' ||
        type === 'month' ||
        type === 'date' ||
        type === 'hour' ||
        type === 'minute' ||
        type === 'url' ||
        type === 'email' ||
        type === 'tel' ||
        type === 'alphaNumeric' ||
        type === 'password'
      ) {
        inputted = toHalfWIdthChars(inputted);
      }

      if (type === 'fullWidth') {
        inputted = toFullWIdthChars(inputted);
      }

      if (type === 'month' || type === 'date' || type === 'hour' || type === 'minute') {
        inputted = inputted.padStart(2, '0');
      }

      onChange(inputted, e.target.name);
      onBlur?.(inputted, e.target.name);
    },
    [onChange, onBlur, type],
  );

  const handleToggleShowPassword = React.useCallback(() => {
    setShowPassword(!isShowPassword);
  }, [isShowPassword]);

  return React.useMemo(
    () => (
      <div className={style['textinput-wrapper']}>
        <input
          ref={refs}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={onClick}
          onFocus={onFocus}
          className={`${style.textinput} is-${type} is-${size} ${isInvalid ? 'is-invalid' : ''} ${className}`}
          type={type === 'password' ? (isShowPassword ? 'text' : 'password') : 'text'}
          autoComplete="off"
          readOnly={readonly}
          style={{ ...(width !== undefined && { width: `${width}px` }) }}
        />
        {type === 'password' && (
          <>
            <button className={style['textinput-toggle-password']} onClick={handleToggleShowPassword}>
              {isShowPassword ? (
                <SvgIcon type="eye" width={24} className={`${style['textinput-icon-password']}`} />
              ) : (
                <SvgIcon type="eye" width={24} className={`${style['textinput-icon-password']}`} />
              )}
            </button>
          </>
        )}
      </div>
    ),
    [
      className,
      handleBlur,
      handleChange,
      onClick,
      onFocus,
      maxLength,
      placeholder,
      type,
      refs,
      name,
      value,
      isInvalid,
      disabled,
      handleToggleShowPassword,
      isShowPassword,
      readonly,
      size,
      width,
    ],
  );
};
