import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Main, Container, Section, SectionInner, ButtonContainer } from 'components/Atoms/Layout';
import { H1, H2, Text } from 'components/Atoms/Typography';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { ApiGetMeasuresDetailProvider, ApiSelectUser } from 'components/Business/Api';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { TextLink } from 'components/Business/TextLink';
import { FormTextInput, FormTextbox, FormDate, FormRadioList, FormRadioAccordion, FormArray } from 'components/Business/Form';
import { ApiSaveMeasureButton } from 'components/Business/Api/ApiSaveMeasureButton';
import { ServerErrorBox } from 'components/Business/ServerErrorBox';
import { BrandListModal } from 'components/Business/BrandListModal';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { StickyFooter } from 'components/Organisms/StickyFooter';
import {
  RHF_SETTINGS
  ,RHF_RESET_PARAMS
  ,EMAIL_OPERATIONS
  ,MEASURESCLASSIFICATION_VALUES
  ,MEASURESENDCONDITIONS_VALUES
  ,OLSDOCUMENTCREATION_VALUES
  ,WEBPRODUCTION_VALUES
  ,JANCODEISSUANCE_VALUES
  ,OSWORKREQUEST_VALUES
  ,CAMPAIGNSETTINGS_VALUES
  ,SAMPLESETTINGS_VALUES
  ,GIFTSETCODESETTINGS_VALUES
  ,XPOSTASPIRATION_VALUES
  ,XIMAGEPREPARATION_VALUES
  ,IGSTORYPOSTASPIRATION_VALUES
  ,IGSTORYIMAGEPREPARATION_VALUES
  ,LINEMESSAGEPOSTASPIRATION_VALUES
  ,LINECONNECTSTREAMING_VALUES
  ,LINEHOMEPOSTASPIRATION_VALUES
} from 'constants/index';
import { objectToRadioList } from 'utils';
import { useValidationSchema } from './useValidationSchema';
import { defaultValues } from './defaultValues';
import type { MeasureParamsProps, MeasureDetailProps } from 'types';

export const CPF001: React.FC = () => {
  const { measureId } = useParams<MeasureParamsProps>();
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  RHF_SETTINGS.defaultValues = defaultValues;
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, reset } = useFormMethods;
  const [response, setResponse] = React.useState<MeasureDetailProps>();
  const [isOpenBrandListModal, setOpenBrandListModal] = React.useState(false);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    return {
      measuresName: formValues.measuresName || '',
      inCharge: formValues.inCharge || '',
      inChargeSubNum: formValues.inChargeSubNum ? formValues.inChargeSubNum.filter((elem: {}) => elem) : [],
      measuresClassification: formValues.measuresClassification || '',
      ...((formValues?.measuresClassification === '1' || formValues?.measuresClassification === '2') && {
        measuresPurpose: formValues.measuresPurpose || '',
        measuresProduct: formValues.measuresProduct || '',
        monthlySalesPlan: formValues.monthlySalesPlan || '',
      }),
      ...(formValues?.measuresClassification === '2' && {
        benefitContent: formValues.benefitContent || '',
        benefitGrantConditions: formValues.benefitGrantConditions || '',
      }),
      measuresStartDateYear: formValues.measuresStartDateYear || '',
      measuresStartDateMonth: formValues.measuresStartDateMonth || '',
      measuresStartDateDay: formValues.measuresStartDateDay || '',
      measuresStartTimeHours: formValues.measuresStartTimeHours || '',
      measuresStartTimeMinutes: formValues.measuresStartTimeMinutes || '',
      measuresEndConditions: formValues.measuresEndConditions || '',
      ...((formValues?.measuresEndConditions === '1' || formValues?.measuresEndConditions === '2') && {
        measuresEndDateYear: formValues.measuresEndDateYear || '',
        measuresEndDateMonth: formValues.measuresEndDateMonth || '',
        measuresEndDateDay: formValues.measuresEndDateDay || '',
        measuresEndTimeHours: formValues.measuresEndTimeHours || '',
        measuresEndTimeMinutes: formValues.measuresEndTimeMinutes || '',
      }),
      olsDocumentCreation: formValues.olsDocumentCreation || '',
      webProduction: formValues.webProduction || '',
      janCodeIssuance: formValues.janCodeIssuance || '',
      osWorkRequest: formValues.osWorkRequest || '',
      campaignSettings: formValues.campaignSettings || '',
      sampleSettings: formValues.sampleSettings || '',
      giftSetCodeSettings: formValues.giftSetCodeSettings || '',
      remarksColumn: formValues.remarksColumn || '',
      xPostAspiration: formValues.xPostAspiration || '',
      xImagePreparation: formValues.xImagePreparation || '',
      igStoryPostAspiration: formValues.igStoryPostAspiration || '',
      igStoryImagePreparation: formValues.igStoryImagePreparation || '',
      lineMessagePostAspiration: formValues.lineMessagePostAspiration || '',
      ...((formValues?.lineMessagePostAspiration === '1') && {
        lineConnectStreaming: formValues.lineConnectStreaming || '',
      }),
      ...((formValues?.lineConnectStreaming === '1') && {
        streamingConditions: formValues.streamingConditions || '',
      }),
      lineHomePostAspiration: formValues.lineHomePostAspiration || '',
      snsLineRemarks: formValues.snsLineRemarks || '',
    };
  }, [getValues]);

  const apiCallbackHandler = React.useCallback(
    (result) => {
      reset(result, RHF_RESET_PARAMS);
      setResponse(result);
      console.log(result);
    },
    [reset],
  );

  const handleOpenBrandListModal = React.useCallback(() => {
    setOpenBrandListModal(true);
  }, []);

  const handleCloseBrandListModal = React.useCallback(() => {
    setOpenBrandListModal(false);
  }, []);

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <ApiGetMeasuresDetailProvider type="outline" defaultValues={defaultValues} callback={apiCallbackHandler}>
            <Container>
              <Breadcrumb
                list={[
                  {
                    label: 'TOP',
                    url: '/CMN001',
                  },
                  {
                    label: '施策概要・依頼内容',
                  },
                ]}
              />

              <ServerErrorBox />

              <Section>
                <H1>施策概要・依頼内容</H1>
                <Text align="center">
                  下記の項目を入力してください。
                  <br />
                  [必須]項目は、全て入力いただく必要があります。
                </Text>

                <Section hasBg>
                  <H2>施策概要</H2>
                  <SectionInner>
                    <FormTextInput
                      name="measuresName"
                      label="施策名"
                      placeholder="yy.mm.dd【ブランド略称】施策名を入力"
                      isRequired
                      notes={[
                        'ブランド略称がご不明な場合は、登録可能なブランド略称一覧をご参照ください。',
                        <TextLink onClick={handleOpenBrandListModal} icon="arrow-right" iconPosition="before">
                          登録可能なブランド略称一覧
                        </TextLink>,
                        '対象ブランドが見つからない場合は運用保守メールアドレスにご連絡ください。',
                        `運用保守メールアドレス: ${EMAIL_OPERATIONS}`,
                      ]}
                    />

                    <BrandListModal isShow={isOpenBrandListModal} onClose={handleCloseBrandListModal} />

                    <ApiSelectUser label="施策担当者名" note="※担当者が複数名いる場合はメイン担当者様をご入力ください。" isRequired />

                    <FormArray
                      type="tab"
                      label="その他担当者数"
                      unit="人"
                      name="inChargeSubNum"
                      min={0}
                      max={5}
                      contentLabel={(key: number) => `${key + 1}:その他担当者`}
                      content={(key: number) => {
                        return (
                          <React.Fragment key={key}>
                            <ApiSelectUser name={`inChargeSubNum[${key}].inChargeSub`} label="その他担当者名" />
                          </React.Fragment>
                        );
                      }}
                    />

                    <FormRadioAccordion
                      name="measuresClassification"
                      label="施策分類"
                      isRequired
                      list={objectToRadioList(MEASURESCLASSIFICATION_VALUES)}
                      contents={{
                        '1': (
                          <>
                            <FormTextbox
                              name="measuresPurpose"
                              label="施策目的"
                              placeholder="「新商品の告知」等を入力"
                              defaultValue="新商品の告知のみ"
                              isRequired
                            />

                            <FormTextbox
                              name="measuresProduct"
                              label="施策対象商品"
                              placeholder="商品名やプール中・美類など商品が特定できる情報を入力"
                              note="※特典がある場合は特典付与対象商品を入力してください。"
                              isRequired
                            />

                            <FormTextInput name="monthlySalesPlan" label="月別販売計画数量" placeholder="「1月1500個、2月1000個」等を入力" isRequired />
                          </>
                        ),
                        '2': (
                          <>
                            <FormTextbox
                              name="measuresPurpose"
                              label="施策目的"
                              placeholder="「美白美容液、美白美容液ファンデの併売販促を中心にHAKU購入単価を向上」等を入力"
                              isRequired
                            />

                            <FormTextbox name="benefitContent" label="特典内容" placeholder="「HKプレミアポーチ、HKプレミア告知POP」等を入力" isRequired />

                            <FormTextbox
                              name="benefitGrantConditions"
                              label="特典付与条件"
                              placeholder="「期間中、対象商品（HAKU全商品）の15000円（税込）以上購入」等を入力"
                              isRequired
                            />

                            <FormTextbox
                              name="measuresProduct"
                              label="施策対象商品"
                              placeholder="商品名やプール中・美類など商品が特定できる情報を入力"
                              note="※特典がある場合は特典付与対象商品を入力してください。"
                              isRequired
                            />

                            <FormTextInput name="monthlySalesPlan" label="月別販売計画数量" placeholder="「1月1500個、2月1000個」等を入力" isRequired />
                          </>
                        ),
                        '3': null,
                      }}
                    />

                    <FormDate
                      names={{
                        year: 'measuresStartDateYear',
                        month: 'measuresStartDateMonth',
                        date: 'measuresStartDateDay',
                        hour: 'measuresStartTimeHours',
                        minute: 'measuresStartTimeMinutes',
                      }}
                      defaultValues={{
                        year: '',
                        month: '',
                        date: '',
                        hour: '00',
                        minute: '00',
                      }}
                      label="施策開始日/時間"
                      isRequired
                      note="※調整中またはでき次第のご依頼の場合、おおよその希望日にてご入力ください。"
                    />

                    <FormRadioAccordion
                      name="measuresEndConditions"
                      label="施策終了条件"
                      isRequired
                      list={objectToRadioList(MEASURESENDCONDITIONS_VALUES)}
                      contents={{
                        '1': (
                          <FormDate
                            names={{
                              year: 'measuresEndDateYear',
                              month: 'measuresEndDateMonth',
                              date: 'measuresEndDateDay',
                              hour: 'measuresEndTimeHours',
                              minute: 'measuresEndTimeMinutes',
                            }}
                            defaultValues={{
                              year: '',
                              month: '',
                              date: '',
                              hour: '23',
                              minute: '59',
                            }}
                            label="施策終了日/時間"
                            isRequired
                            hasUndecidedButton
                          />
                        ),
                        '2': (
                          <FormDate
                            names={{
                              year: 'measuresEndDateYear',
                              month: 'measuresEndDateMonth',
                              date: 'measuresEndDateDay',
                              hour: 'measuresEndTimeHours',
                              minute: 'measuresEndTimeMinutes',
                            }}
                            defaultValues={{
                              year: '2100',
                              month: '12',
                              date: '31',
                              hour: '23',
                              minute: '59',
                            }}
                            label="施策終了日/時間"
                            isRequired
                            hasUndecidedButton
                          />
                        ),
                      }}
                    />
                  </SectionInner>
                </Section>

                <Section hasBg>
                  <H2>依頼内容</H2>

                  <SectionInner>
                    <FormRadioList name="olsDocumentCreation" label="OLS会議資料作成" isRequired list={objectToRadioList(OLSDOCUMENTCREATION_VALUES)} />

                    <FormRadioList
                      name="webProduction"
                      label="Web制作"
                      isRequired
                      list={objectToRadioList(WEBPRODUCTION_VALUES)}
                      confirmOnChange={response?.webProduction === '1' && response?.webProductionDetail !== undefined}
                      confirmConditionValue="2"
                      confirmTitle="登録済みの内容は削除されます"
                      confirmChildren={
                        <Text align="center">
                          「なし」に変更すると「下書き保存」「入力完了」クリック時に、
                          <br />
                          「Web制作」ページの入力内容が削除されます。
                          <br />
                          よろしいですか？
                        </Text>
                      }
                    />

                    <FormRadioList
                      name="janCodeIssuance"
                      label="JANコード発行"
                      isRequired
                      hasUndecidedButton
                      list={objectToRadioList(JANCODEISSUANCE_VALUES)}
                    />

                    <FormRadioList
                      name="osWorkRequest"
                      label="OS作業依頼"
                      note="※ありの場合、EC事業部オペレーション窓口より別途ご連絡させていただきます。"
                      isRequired
                      hasUndecidedButton
                      list={objectToRadioList(OSWORKREQUEST_VALUES)}
                    />

                    <FormRadioList
                      name="campaignSettings"
                      label="キャンペーン設定・クーポン発行"
                      isRequired
                      list={objectToRadioList(CAMPAIGNSETTINGS_VALUES)}
                      confirmOnChange={response?.campaignSettings === '1' && response?.campaignDetail !== undefined}
                      confirmConditionValue="2"
                      confirmTitle="登録済みの内容は削除されます"
                      confirmChildren={
                        <Text align="center">
                          「なし」に変更すると「下書き保存」「入力完了」クリック時に、
                          <br />
                          「キャンペーン設定・クーポン発行」ページの入力内容が削除されます。
                          <br />
                          よろしいですか？
                        </Text>
                      }
                    />

                    <FormRadioList
                      name="sampleSettings"
                      label="サンプル・モニター設定"
                      note="※配送情報を含むフローについては、個人情報の取り扱いが発生するためIMJでは対応できません。こちらへの入力は不要ですので、施策担当者ご自身で申請を行ってください。"
                      isRequired
                      list={objectToRadioList(SAMPLESETTINGS_VALUES)}
                      confirmOnChange={response?.sampleSettings === '1' && response?.sampleSettingsDetail !== undefined}
                      confirmConditionValue="2"
                      confirmTitle="登録済みの内容は削除されます"
                      confirmChildren={
                        <Text align="center">
                          「なし」に変更すると「下書き保存」「入力完了」クリック時に、
                          <br />
                          「サンプル・モニター設定」ページの入力内容が削除されます。
                          <br />
                          よろしいですか？
                        </Text>
                      }
                    />

                    <FormRadioList
                      name="giftSetCodeSettings"
                      label="ギフト・セットコード発行"
                      isRequired
                      hasUndecidedButton
                      list={objectToRadioList(GIFTSETCODESETTINGS_VALUES)}
                    />

                    <FormTextbox
                      name="remarksColumn"
                      label="備考記入欄"
                      placeholder="施策内容について、補足等がある場合は入力ください。"
                      note="※入力欄がある内容については、各入力欄へご登録ください。"
                    />
                  </SectionInner>
                </Section>

                <Section hasBg>
                  <H2>SNS/LINE投稿希望</H2>

                  <SectionInner>
                    <FormRadioList
                      name="xPostAspiration"
                      label="X(Twitter)投稿希望"
                      isRequired
                      list={objectToRadioList(XPOSTASPIRATION_VALUES)}
                    />

                    <FormRadioList
                      name="xImagePreparation"
                      label="X(Twitter)画像手配​"
                      list={objectToRadioList(XIMAGEPREPARATION_VALUES)}
                    />

                    <FormRadioList
                      name="igStoryPostAspiration"
                      label="IGストーリーズ投稿希望"
                      isRequired
                      list={objectToRadioList(IGSTORYPOSTASPIRATION_VALUES)}
                    />

                    <FormRadioList
                      name="igStoryImagePreparation"
                      label="IGストーリーズ画像手配​"
                      list={objectToRadioList(IGSTORYIMAGEPREPARATION_VALUES)}
                    />

                    <FormRadioAccordion
                      name="lineMessagePostAspiration"
                      label="LINEメッセージ投稿希望"
                      isRequired
                      list={objectToRadioList(LINEMESSAGEPOSTASPIRATION_VALUES)}
                      contents={{
                        '1': (
                          <>
                            <FormRadioAccordion
                              name='lineConnectStreaming'
                              label='コネクト配信'
                              isRequired
                              list={objectToRadioList(LINECONNECTSTREAMING_VALUES)}
                              contents={{
                                '1': (
                                  <>
                                    <FormTextbox
                                      name='streamingConditions'
                                      label='配信条件'
                                      isRequired
                                      placeholder="「過去ブランドXX購入者」等を入力。テスト配信時に対象者の変更は可能です。円滑な入稿作業のためにご協力をお願い致します。"
                                      note="※入力がない場合や、「未定」「後送」等の入力では受付できません。必ず条件の詳細を記入してください。"
                                    />
                                  </>
                                ),
                                '2': null,
                              }}
                            />
                          </>
                        ),
                        '2': null,
                      }}
                    />

                    <FormRadioList
                      name="lineHomePostAspiration"
                      label="LINEホーム投稿希望"
                      isRequired
                      list={objectToRadioList(LINEHOMEPOSTASPIRATION_VALUES)}
                    />

                    <FormTextbox
                      name="snsLineRemarks"
                      label="備考記入欄"
                      placeholder="リッチメニューは横断や規模の大きな施策のみ対象ですが、希望がある場合はこちらにご入力ください。"
                    />
                  </SectionInner>
                </Section>

                <ButtonContainer>
                  <ApiSaveMeasureButton type="outline" getRequestData={getRequestData} />
                </ButtonContainer>
              </Section>
            </Container>

            <StickyFooter getRequestData={getRequestData} />

            <RHFDevTools />
          </ApiGetMeasuresDetailProvider>
        </FormProvider>
      </Main>
      <Footer mb={measureId ? 80 : undefined} />
    </>
  );
};
