import React from 'react';
import { useLocation } from 'react-router-dom';
import { DirtyState, DirtyUpdate } from 'context';

export const BeforeUnload: React.FC = () => {
  const { pathname } = useLocation();
  const isDirty = React.useContext(DirtyState);
  const setDirty = React.useContext(DirtyUpdate);

  const handleUnload = React.useCallback((e) => {
    e.preventDefault();
    e.returnValue = '';
  }, []);

  React.useEffect(() => {
    console.log(`isDirty: ${isDirty}`);
    if (isDirty) {
      window.addEventListener('beforeunload', handleUnload, false);
    } else {
      window.removeEventListener('beforeunload', handleUnload, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  React.useEffect(() => {
    setDirty(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <></>;
};
