import React from 'react';
import { useHistory } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from 'aws-exports';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { FormTextInput } from 'components/Business/Form';
import { LoginSessionErrorModal } from 'components/Business/LoginSessionErrorModal';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { RHF_SETTINGS } from 'constants/index';
import { TempCognitoUserState, NetworkErrorUpdate } from 'context';
import { scrollToFirstError } from 'utils';
import { useValidationSchema } from './useValidationSchema';
import type { LoginPageProps } from 'components/Pages/Login';

Amplify.configure(awsconfig);

export const CMN009: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const tempCognitoUser = React.useContext(TempCognitoUserState);
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, handleSubmit, setError } = useFormMethods;
  const [isLoading, setLoading] = React.useState(false);
  const [isSessionError, setSessionError] = React.useState(false);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    const data = {
      password: formValues.password || '',
      passwordConfirm: formValues.passwordConfirm || '',
    };
    return data;
  }, [getValues]);

  const signUp = React.useCallback(async () => {
    setLoading(true);
    const data = getRequestData();
    try {
      const user = await Auth.completeNewPassword(tempCognitoUser, data.password);
      console.log(user);
      if (user.challengeName === 'MFA_SETUP') {
        history.push('/CMN011');
      } else {
        const state: LoginPageProps = {
          nextPage: '/CMN012',
        };
        history.push('/Login', state);
      }
    } catch (error) {
      console.error(error);
      if (error.code === 'InvalidPasswordException') {
        // パスワードが数字、特殊文字、大文字、小文字を含まない
        setError('password', {
          type: 'custom',
          message: t('ERR-FORMAT-PASSWORD'),
        });
      } else if (error.code === 'InvalidParameterException') {
        // 最小長未満
        setError('password', {
          type: 'custom',
          message: t('ERR-LENGTH-MIN', { min: 8 }),
        });
      } else if (error.code === 'NotAuthorizedException') {
        setSessionError(true);
      } else {
        setNetworkError(true);
      }
      // UserLambdaValidationException: PreSignUp failed with error 'email'.
      setLoading(false);
    }
  }, [getRequestData, setError, t, history, setNetworkError, tempCognitoUser]);

  const onSuccess = React.useCallback(() => {
    signUp();
  }, [signUp]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  return (
    <>
      <LoginSessionErrorModal isShow={isSessionError} />
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />
          <Container type="login">
            <Section>
              <H1>パスワード本登録</H1>
              <Text align="center">任意のパスワードを入力いただき、本登録を行ってください。</Text>

              <FormTextInput
                type="password"
                name="password"
                label="パスワード"
                note="※大文字、小文字、数字、記号を全て使用し、8字以上で入力してください。"
                size="medium"
                inputSize="large"
              />

              <FormTextInput type="password" name="passwordConfirm" label="パスワード（確認用）" size="medium" inputSize="large" />

              <ButtonContainer>
                <Button type="secondary" icon="arrow-right" iconPosition="right" text="次へ進む" onClick={() => handleSubmit(onSuccess, onError)()} />
              </ButtonContainer>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
