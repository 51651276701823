import React from 'react';
import { Button } from 'components/Atoms/Button';
import { UNDECIDED_LABEL } from 'constants/index';
import style from './style.module.scss';

type Props = {
  name?: string;
  undecided?: boolean;
  hasUndecidedButton?: boolean;
  onChangeDecided?: (checked: boolean) => void;
};

export const UndecidedButton: React.FC<Props> = ({ name, undecided, hasUndecidedButton = false, onChangeDecided = () => {} }) => {
  const handleChangeUndecided = React.useCallback(() => {
    onChangeDecided(!undecided);
  }, [onChangeDecided, undecided]);

  return (
    <>
      {hasUndecidedButton && (
        <div className={style['undecidedButton']} data-undecidedbutton>
          <Button
            type="delete"
            size="small"
            isFlexible
            icon="check"
            iconPosition="before"
            text={UNDECIDED_LABEL}
            onClick={handleChangeUndecided}
            className={`${style['undecidedButton-button']} ${undecided ? 'is-undecided' : ''}`}
          />
        </div>
      )}
    </>
  );
};
