export const scrollToFirstError = (): void => {
  setTimeout(() => {
    const targetElement: HTMLElement | null = document.querySelector('.is-invalid');
    if (!targetElement) return;

    const scrollToTarget = (target: HTMLElement, block: ScrollLogicalPosition = 'center') => {
      target.scrollIntoView({ behavior: 'smooth', block: block });
    };

    // 非表示のタブ内にエラーがある場合
    if (targetElement.closest('.tabmenu-tabcontent') && !targetElement.closest('.tabmenu-tabcontent')?.classList.contains('is-show')) {
      const target = targetElement.closest('.tabmenu-tabcontent')?.parentElement;
      if (!target) return;
      scrollToTarget(target, 'start');
      return;
    }

    // エラー対象のelementが非表示の場合
    if (window.getComputedStyle(targetElement).getPropertyValue('display') === 'none') {
      /** 対象要素のstyleの'display'をバックアップ */
      const beforeDisplayStyle = targetElement.style.getPropertyValue('display');
      /** 対象要素のstyleの'display'のpriorityをバックアップ */
      const beforeDisplayStylePriority = targetElement.style.getPropertyPriority('display');
      // 一時的にdisplay: blockにする
      targetElement.style.setProperty('display', 'block', 'important');
      // 対象要素へスクロール
      scrollToTarget(targetElement);
      if (beforeDisplayStyle) {
        // バックアップしたstyleの'display'がある場合、戻す
        targetElement.style.setProperty('display', beforeDisplayStyle, beforeDisplayStylePriority);
        return;
      }
      // バックアップしたstyleの'display'がない場合、一時的に設定したdisplay: blockを削除
      targetElement.style.removeProperty('display');
      return;
    }

    scrollToTarget(targetElement);
  }, 0);
};
