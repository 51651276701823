import React from 'react';
import { TextLink } from 'components/Business/TextLink';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';
import type { SvgIconTypeProps } from 'components/Atoms/SvgIcon';

// type PartialRequire<O, K extends keyof O> = {
//   [P in K]-?: O[P];
// } &
//   O;

// type RequireOne<T, K extends keyof T = keyof T> = K extends keyof T ? PartialRequire<T, K> : never;

// type ActionProps = RequireOne<{
//   to?: string;
//   onClick?: () => void;
// }>;

const Types = {
  primary: 'primary',
  secondary: 'secondary',
  cancel: 'cancel',
  delete: 'delete',
  add: 'add',
} as const;
type TypesProps = typeof Types[keyof typeof Types];

const Size = {
  large: 'large',
  medium: 'medium',
  small: 'small',
} as const;
type SizeProps = typeof Size[keyof typeof Size];

const IconPosition = {
  before: 'before',
  after: 'after',
  left: 'left',
  right: 'right',
} as const;
type IconPositionProps = typeof IconPosition[keyof typeof IconPosition];

type Props = {
  to?: string;
  onClick?: () => void;
  type: TypesProps;
  size?: SizeProps;
  isFlexible?: boolean;
  icon?: SvgIconTypeProps;
  iconPosition?: IconPositionProps;
  isNoBorder?: boolean;
  text: string;
  className?: string;
  disabled?: boolean;
};

export const Button: React.FC<Props> = ({
  to,
  onClick,
  type,
  size = 'medium',
  isFlexible = false,
  icon,
  iconPosition,
  isNoBorder = false,
  text,
  className = '',
  disabled = false,
}) => {
  const iconTheme = React.useMemo(() => {
    if (disabled) return '#e6e6e6';
    if (type === 'secondary') return '#2896f0';
    if (type === 'add') return '#373940';
    return '#fff';
  }, [type, disabled]);

  const iconWidth = React.useMemo(() => {
    if (icon === 'arrow-right') return 8;
    if (icon === 'arrow-left') return 8;
    if (icon === 'check') return 12;
    if (icon === 'search-white') return 15;
    if (icon === 'plus') return 10;
    if (icon === 'loading-small') return 22;
    if (icon === 'dustbox') return 13;

    return 0;
  }, [icon]);

  return React.useMemo(
    () => (
      <>
        {!to && onClick && (
          <button
            className={`${style['button']} is-${type} is-${size} ${isFlexible ? 'is-flexible' : ''} ${isNoBorder ? 'is-no-border' : ''} ${className}`}
            disabled={disabled}
            onClick={() => {
              onClick();
            }}
          >
            {icon && iconPosition === 'left' && (
              <SvgIcon type={icon} fill={iconTheme} width={iconWidth} className={`${style['button-icon']} has-icon-${icon} is-left`} />
            )}
            {icon && iconPosition === 'before' && (
              <SvgIcon type={icon} fill={iconTheme} width={iconWidth} className={`${style['button-icon']} icon-${icon} is-before`} />
            )}
            {text}
            {icon && iconPosition === 'after' && (
              <SvgIcon type={icon} fill={iconTheme} width={iconWidth} className={`${style['button-icon']} has-icon-${icon} is-after`} />
            )}
            {icon && iconPosition === 'right' && (
              <SvgIcon type={icon} fill={iconTheme} width={iconWidth} className={`${style['button-icon']} has-icon-${icon} is-right`} />
            )}
          </button>
        )}

        {to && !onClick && (
          <TextLink
            className={`${style['button']} is-${type} is-${size} ${isFlexible ? 'is-flexible' : ''} ${isNoBorder ? 'is-no-border' : ''} ${className}`}
            to={to}
          >
            {icon && iconPosition === 'left' && (
              <SvgIcon type={icon} fill={iconTheme} width={iconWidth} className={`${style['button-icon']} has-icon-${icon} is-left`} />
            )}
            {icon && iconPosition === 'before' && (
              <SvgIcon type={icon} fill={iconTheme} width={iconWidth} className={`${style['button-icon']} has-icon-${icon} is-before`} />
            )}
            {text}
            {icon && iconPosition === 'after' && (
              <SvgIcon type={icon} fill={iconTheme} width={iconWidth} className={`${style['button-icon']} has-icon-${icon} is-after`} />
            )}
            {icon && iconPosition === 'right' && (
              <SvgIcon type={icon} fill={iconTheme} width={iconWidth} className={`${style['button-icon']} has-icon-${icon} is-right`} />
            )}
          </TextLink>
        )}
      </>
    ),
    [type, className, disabled, onClick, text, to, size, isFlexible, icon, iconPosition, iconWidth, isNoBorder, iconTheme],
  );
};
