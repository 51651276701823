import React from 'react';
import { Modal } from 'components/Organisms/Modal';
import type { ModalTypesProps } from 'components/Organisms/Modal/Modal';

type Props = {
  type?: ModalTypesProps;
  title: string;
};

export const ModalAuto: React.FC<Props> = ({ children, type, title = '' }) => {
  const [isShow, setShow] = React.useState(true);

  const handleCloseModal = React.useCallback(() => {
    setShow(false);
  }, []);

  return React.useMemo(
    () => (
      <Modal type={type} title={title} isShow={isShow} hasSubmit={true} onSubmit={handleCloseModal} onCancel={handleCloseModal}>
        {children}
      </Modal>
    ),
    [children, handleCloseModal, isShow, title, type],
  );
};
