import React from 'react';
// import { useFormContext } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

export const RHFDevTools: React.FC = () => {
  // const { control } = useFormContext();

  // return React.useMemo(() => <DevTool control={control} placement={'top-left'} />, [control]);
  return null;
};
