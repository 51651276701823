import React from 'react';
import { SvgIcon } from 'components/Atoms/SvgIcon';
import style from './style.module.scss';
import { RefCallBack } from 'react-hook-form';

const Align = {
  right: 'right',
  center: 'center',
  left: 'left',
} as const;
type AlignProps = typeof Align[keyof typeof Align];

type Props = {
  name: string;
  refs?: RefCallBack;
  onChange?: (checked: boolean) => void;
  label?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  checked?: boolean;
  className?: string;
  align?: AlignProps;
  isPlain?: boolean;
};

export const Checkbox: React.FC<Props> = ({
  name,
  refs,
  onChange = () => {},
  label = '',
  disabled = false,
  isInvalid,
  checked = false,
  className,
  align = 'left',
  isPlain = false,
}) => {
  const handleChange = React.useCallback((e) => onChange(e.target.checked), [onChange]);

  return React.useMemo(
    () => (
      <div className={`${style.checkbox} is-align-${align} ${className}`}>
        <label
          className={`${style['checkbox-label']} ${checked ? 'is-checked' : ''} ${disabled ? 'is-disabled' : ''} ${isInvalid ? 'is-invalid' : ''} ${
            isPlain ? 'is-plain' : ''
          }`}
        >
          {checked && <SvgIcon type="check" fill="#00d299" width={14} className={style['checkbox-icon']} />}
          <input
            ref={refs}
            className={`${style['checkbox-input']}`}
            name={name}
            disabled={disabled}
            checked={checked}
            onChange={handleChange}
            type="checkbox"
          />
          <span className={`${style['checkbox-text']}`}>{label}</span>
        </label>
      </div>
    ),
    [checked, className, disabled, isInvalid, label, name, handleChange, refs, align, isPlain],
  );
};
