import React from 'react';
import { Note } from 'components/Atoms/Typography';
import style from './style.module.scss';

type Props = {
  list: (string | JSX.Element)[];
  className?: string;
};

export const NoteList: React.FC<Props> = ({ list, className = '' }) => {
  return React.useMemo(
    () => (
      <ul className={`${style['list-note']} ${className}`}>
        {list.map((note, index) => (
          <li key={index}>
            <Note>{note}</Note>
          </li>
        ))}
      </ul>
    ),
    [list, className],
  );
};
