import React from 'react';
import { Flex, FormGrid, FormField } from 'components/Atoms/Layout';
import { Unit } from 'components/Atoms/Typography';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { FormDate } from 'components/Business/Form';
import style from './style.module.scss';
import type { FormGridSizeProps } from 'components/Atoms/Layout/FormGrid';

type Props = {
  names: {
    start: {
      year: string;
      month: string;
      date: string;
    };
    end: {
      year: string;
      month: string;
      date: string;
    };
  };
  label: string;
  size?: FormGridSizeProps;
  isRequired?: boolean;
  help?: string;
  labelFontSize?: number;
};

export const FormDatePeriod: React.FC<Props> = ({ names, label, size = 'large', isRequired = false, help, labelFontSize=14 }) => {
  return (
    <FormGrid className={style['form-datePeriod']} size={size}>
      <FormLabelSet label={label} isRequired={isRequired} help={help} size={size} fontSize={labelFontSize} />

      <FormField>
        <Flex alignItems="start">
          <div>
            <FormDate
              names={{
                year: names.start.year,
                month: names.start.month,
                date: names.start.date,
              }}
              size={size}
              hasDayOfWeek={false}
            />
          </div>

          <Unit text="～" mt={8} />

          <div>
            <FormDate
              names={{
                year: names.end.year,
                month: names.end.month,
                date: names.end.date,
              }}
              size={size}
              hasDayOfWeek={false}
            />
          </div>
        </Flex>
      </FormField>
    </FormGrid>
  );
};
