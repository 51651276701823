import React from 'react';
import { FormTextInput, FormTextbox, FormRadioAccordion } from 'components/Business/Form';
import { COUPONGRANTFORMAT_VALUES } from 'constants/index';
import { objectToRadioList } from 'utils';
import { CouponDetailCommonChildren1 } from '../CouponDetailCommonChildren1';
import { CouponDetailCommonChildren2 } from '../CouponDetailCommonChildren2';
import { CouponDetailCommonChildren3 } from '../CouponDetailCommonChildren3';

type Props = {
  k: number;
};

export const CouponDetailCrm: React.FC<Props> = ({ k }) => {
  return (
    <>
      <CouponDetailCommonChildren1 k={k} />

      <CouponDetailCommonChildren2 k={k} />

      <FormRadioAccordion
        name={`campaignDetail[${k}].couponGrantFormat`}
        label="付与形式"
        isRequired
        hasUndecidedButton
        list={objectToRadioList(COUPONGRANTFORMAT_VALUES)}
        contents={{
          '1': null,
          '2': (
            <FormTextInput
              type="number"
              name={`campaignDetail[${k}].couponIssueDate`}
              label="クーポン発行日"
              placeholder="30"
              unit="日後"
              isRequired
              hasUndecidedButton
            />
          ),
        }}
      />

      <FormTextbox
        name={`campaignDetail[${k}].couponConditionsIssue`}
        label="クーポン発行条件"
        placeholder="「オンラインショップメール受信可能者かつR180以内購入者」等を入力"
        isRequired
        hasUndecidedButton
      />

      <FormTextbox
        name={`campaignDetail[${k}].couponApplicableCondition`}
        label="適用条件"
        placeholder="「エリクシールのスキンケア商品限定で使える」等を入力"
        isRequired
        hasUndecidedButton
      />

      <FormTextbox
        name={`campaignDetail[${k}].couponCartCoupon`}
        label="お買物カートのクーポン利用画面テキスト"
        placeholder="「エリクシールのスキンケア商品のみを5,500円（税込）以上ご購入時に使用できる800円（税込）クーポン」等を入力"
        isRequired
        hasUndecidedButton
      />

      <CouponDetailCommonChildren3 k={k} />
    </>
  );
};
