import React from 'react';
import { useWatch } from 'react-hook-form';
import { ButtonContainer, NestBox } from 'components/Atoms/Layout';
import { Button } from 'components/Atoms/Button';
import { createKeys } from 'utils';
import style from './style.module.scss';

type Props = {
  max?: number;
  name: string;
  content: (key: number, index: number) => JSX.Element;
};

export const DynamicContent: React.FC<Props> = ({ max = 5, name, content }) => {
  const value: any = useWatch({ name });
  const [keys, setKeys] = React.useState<number[]>([0]);

  React.useEffect(() => {
    let keys;
    if (!value || !value.length) {
      keys = createKeys(1);
    } else {
      keys = createKeys(value?.length);
    }
    setKeys(keys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.dynamicContent}>
      {keys.length &&
        keys.map((elem, index) => {
          return (
            <NestBox key={elem} className={style['dynamicContent-item']}>
              {content(elem, index)}

              {keys.length > 1 && (
                <ButtonContainer className={style['dynamicContent-buttonContainer']}>
                  <Button
                    type="delete"
                    size="small"
                    isFlexible
                    icon="dustbox"
                    iconPosition="after"
                    text="削除"
                    onClick={() => {
                      const newKeys = keys.filter((key) => key !== elem);
                      setKeys(newKeys);
                    }}
                  />
                </ButtonContainer>
              )}
            </NestBox>
          );
        })}

      <ButtonContainer className={style['dynamicContent-buttonContainer']}>
        <Button
          type="add"
          size="small"
          isFlexible
          icon="plus"
          iconPosition="after"
          text="入力欄追加"
          disabled={keys.length >= max}
          onClick={() => {
            const lastKey = keys[keys.length - 1];
            const newKeys = keys.concat(lastKey + 1);
            setKeys(newKeys);
          }}
        />
      </ButtonContainer>
    </div>
  );
};
