import React from 'react';
import { AuthUser, HolidayProps, Server400ErrorProps } from 'types';

// TODO: guest用に分離
export const TempCognitoUserState = React.createContext<AuthUser | undefined>(undefined);
export const TempCognitoUserUpdate = React.createContext<React.Dispatch<React.SetStateAction<AuthUser | undefined>>>(() => {});

export const CognitoUserState = React.createContext<AuthUser | undefined>(undefined);
export const CognitoUserUpdate = React.createContext<React.Dispatch<React.SetStateAction<AuthUser | undefined>>>(() => {});

export const NetworkErrorState = React.createContext(false);
export const NetworkErrorUpdate = React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(() => {});

export const DirtyState = React.createContext(false);
export const DirtyUpdate = React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(() => {});

export const Server400ErrorState = React.createContext<Server400ErrorProps>(undefined);
export const Server400ErrorUpdate = React.createContext<React.Dispatch<React.SetStateAction<Server400ErrorProps>>>(() => {});

export const HolidaysState = React.createContext<HolidayProps>([]);
export const HolidaysUpdate = React.createContext<React.Dispatch<React.SetStateAction<HolidayProps>>>(() => {});

export const Provider: React.FC = ({ children }) => {
  const [tempCognitoUser, setTempCognitoUser] = React.useState<AuthUser>();

  const [cognitoUser, setCognitoUser] = React.useState<AuthUser>();

  const [networkError, setNetworkError] = React.useState(false);

  const [isDirty, setDirty] = React.useState(false);

  const [server400Error, setServer400Error] = React.useState<Server400ErrorProps>(undefined);

  const [holidays, setHolidays] = React.useState<HolidayProps>([]);

  return (
    <TempCognitoUserState.Provider value={tempCognitoUser}>
      <TempCognitoUserUpdate.Provider value={setTempCognitoUser}>
        <CognitoUserState.Provider value={cognitoUser}>
          <CognitoUserUpdate.Provider value={setCognitoUser}>
            <NetworkErrorState.Provider value={networkError}>
              <NetworkErrorUpdate.Provider value={setNetworkError}>
                <DirtyState.Provider value={isDirty}>
                  <DirtyUpdate.Provider value={setDirty}>
                    <Server400ErrorState.Provider value={server400Error}>
                      <Server400ErrorUpdate.Provider value={setServer400Error}>
                        <HolidaysState.Provider value={holidays}>
                          <HolidaysUpdate.Provider value={setHolidays}>{children}</HolidaysUpdate.Provider>
                        </HolidaysState.Provider>
                      </Server400ErrorUpdate.Provider>
                    </Server400ErrorState.Provider>
                  </DirtyUpdate.Provider>
                </DirtyState.Provider>
              </NetworkErrorUpdate.Provider>
            </NetworkErrorState.Provider>
          </CognitoUserUpdate.Provider>
        </CognitoUserState.Provider>
      </TempCognitoUserUpdate.Provider>
    </TempCognitoUserState.Provider>
  );
};
