import React from 'react';
import style from './style.module.scss';

type Props = {
  className?: string;
  isShow: boolean;
};

export const TabContent: React.FC<Props> = ({ children, className = '', isShow }) => {
  return <div className={`${style['tabmenu-tabcontent']} tabmenu-tabcontent ${isShow ? 'is-show' : ''} ${className}`}>{children}</div>;
};
