import React from 'react';
import { useFormContext, useWatch, useFormState } from 'react-hook-form';
import get from 'lodash/get';
import { FormGrid, FormField, ButtonContainer } from 'components/Atoms/Layout';
import { Text, Note } from 'components/Atoms/Typography';
import { NoteList } from 'components/Atoms/List';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { FormTextReadOnly, FormArray, FormHiddenInput } from 'components/Business/Form';
import { AddRegisteredMaterialButton } from './AddRegisteredMaterialButton';
import { AddUnregisteredMaterialButton } from './AddUnregisteredMaterialButton';
import style from './style.module.scss';
import { ModuleProps } from 'types';

type Props = {
  name: string;
  label?: string;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  isRequired?: boolean;
  help?: string;
};

type ModuleComponentProps = {
  name: string;
  k: number;
};

const ModuleComponent: React.FC<ModuleComponentProps> = ({ name, k }) => {
  const { control } = useFormContext();
  const dataType = useWatch({ name: `${name}.dataType`, control });

  return React.useMemo(
    () => (
      <FormGrid className={`${style['api-form-aid-selectMaterialModal']}`}>
        <FormLabelSet label={`${k + 1}: 素材`} />
        <FormField>
          <FormHiddenInput name={`${name}.moduleId`} />

          <FormHiddenInput name={`${name}.attributes.area`} />

          <Text mt={0} className={`${style['readonly']} is-show`}>
            素材データ種別: <FormTextReadOnly name={`${name}.dataType`} />
          </Text>

          <Text mt={0} className={`${style['readonly']} is-show`}>
            識別番号: <FormTextReadOnly name={`${name}.moduleNum`} />
          </Text>

          <Text mt={0} className={`${style['readonly']} ${dataType === '画像' || dataType === '動画' || dataType === '他社埋め込みサービス' ? 'is-show' : ''}`}>
            URL: <FormTextReadOnly name={`${name}.url`} />
          </Text>

          <Text mt={0} className={`${style['readonly']} ${dataType === 'テキスト' ? 'is-show' : ''}`}>
            素材種類: <FormTextReadOnly name={`${name}.attributes.type`} />
          </Text>

          <Text mt={0} className={`${style['readonly']} ${dataType === 'テキスト' ? 'is-show' : ''}`}>
            テキスト文言: <FormTextReadOnly name={`${name}.text`} />
          </Text>

          <Text mt={0} className={`${style['readonly']} ${dataType === 'テキスト' ? 'is-show' : ''}`}>
            フォント指定: <FormTextReadOnly name={`${name}.fontName`} />
          </Text>

          <Text mt={0} className={`${style['readonly']} ${dataType === 'テキスト' ? 'is-show' : ''}`}>
            季節: <FormTextReadOnly name={`${name}.attributes.season`} />
          </Text>

          <Text mt={0} className={`${style['readonly']} ${dataType === 'テキスト' ? 'is-show' : ''}`}>
            ブランド: <FormTextReadOnly name={`${name}.attributes.brand`} />
          </Text>
        </FormField>
      </FormGrid>
    ),
    [dataType, k, name],
  );
};

export const ApiFormAidSelectMaterialModal: React.FC<Props> = ({ name, label = '', note, notes, isRequired = false, help }) => {
  const ref = React.useRef<any>();
  const watchedValue = useWatch({ name });
  const { errors } = useFormState();
  const error = get(errors, name);

  const handleAppend = React.useCallback((data: ModuleProps) => {
    console.log(data);
    ref.current && ref.current.append(data);
  }, []);

  return React.useMemo(
    () => (
      <>
        <FormArray
          ref={ref}
          name={name}
          label={label}
          isRequired={isRequired}
          help={help}
          className={`${error ? 'is-invalid' : ''}`}
          hasSelect={false}
          hasAddButton={false}
          contentLabel={(key: number) => `${key + 1}: 素材`}
          content={(key: number) => {
            return <ModuleComponent key={key} name={`${name}[${key}]`} k={key} />;
          }}
        />

        <ButtonContainer isVertical isLeft gap="small" mt={watchedValue && watchedValue.length > 0 ? 20 : 0}>
          <AddRegisteredMaterialButton append={handleAppend} />
          <AddUnregisteredMaterialButton append={handleAppend} />
        </ButtonContainer>

        {note && <Note>{note}</Note>}
        {notes && <NoteList list={notes} />}
      </>
    ),
    [name, help, isRequired, label, note, notes, watchedValue, error, handleAppend],
  );
};
