import React from 'react';
import { ENDPOINT_GET_HOLIDAYS_API } from 'constants/index';
import { useApiCall } from 'hooks/useApiCall';
import { HolidaysUpdate } from 'context';

export const ApiGetHolidaysProvider = () => {
  const apiCall = useApiCall();
  const setHolidays = React.useContext(HolidaysUpdate);

  React.useEffect(() => {
    apiCall({
      method: 'GET',
      url: ENDPOINT_GET_HOLIDAYS_API,
    }).then((res) => {
      setHolidays(res.data.result.publicHolidays);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
