import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { FormGrid, FormField } from 'components/Atoms/Layout';
import { Note, ErrorText } from 'components/Atoms/Typography';
import { NoteList } from 'components/Atoms/List';
import { Textbox } from 'components/Atoms/Form';
import { FormLabelSet } from 'components/Molecules/FormLabelSet';
import { UndecidedButton } from 'components/Molecules/UndecidedButton';
import { UNDECIDED_VALUE, RHF_UNREGISTER_PARAMS } from 'constants/index';
import style from './style.module.scss';
import type { TextboxTypesProps } from 'components/Atoms/Form/Textbox';

type Props = {
  name: string;
  type?: TextboxTypesProps;
  label?: string;
  placeholder?: string;
  note?: string | JSX.Element;
  notes?: (string | JSX.Element)[];
  isRequired?: boolean;
  maxLength?: number;
  help?: string;
  hasUndecidedButton?: boolean;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
};

export const FormTextbox: React.FC<Props> = ({
  name,
  type = 'text',
  label = '',
  placeholder = '',
  note,
  notes,
  isRequired = false,
  maxLength,
  help,
  hasUndecidedButton = false,
  defaultValue = '',
  onChange = () => {},
  onBlur = () => {},
}) => {
  const { control, trigger, unregister } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, defaultValue, shouldUnregister: true });
  const [undecided, setUndecided] = React.useState(false);
  const [backupValue, setBackupValue] = React.useState('');

  const componentWillUnmount = React.useCallback(() => {
    console.log(`unregister: ${name}`);
    unregister(name, RHF_UNREGISTER_PARAMS);
  }, [name, unregister]);

  const handleChange = React.useCallback(
    (value) => {
      field.onChange(value);
      onChange(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onChange, onChange],
  );

  const handleBlur = React.useCallback(
    (value) => {
      field.onBlur();
      onBlur(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onBlur, onBlur],
  );

  const handleUndecided = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setBackupValue(field.value);
        field.onChange(UNDECIDED_VALUE);
        trigger(name); // 初回なぜかバリデーションされないため
        return;
      }
      field.onChange(backupValue);
      setBackupValue('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.value, backupValue, setBackupValue],
  );

  React.useEffect(() => {
    setUndecided(field.value === UNDECIDED_VALUE);
  }, [field.value]);

  React.useEffect(() => {
    return () => componentWillUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormGrid className={style['form-textbox']}>
      <FormLabelSet label={label} isRequired={isRequired} help={help} />

      <FormField>
        <Textbox
          name={field.name}
          value={field.value}
          refs={field.ref}
          onChange={handleChange}
          onBlur={handleBlur}
          className={undecided ? 'is-undecided' : ''}
          isInvalid={error ? true : false}
          disabled={undecided}
          type={type}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        <ErrorText error={error?.message} />
        {note && <Note>{note}</Note>}
        {notes && <NoteList list={notes} />}
      </FormField>

      <UndecidedButton name={name} hasUndecidedButton={hasUndecidedButton} undecided={undecided} onChangeDecided={handleUndecided} />
    </FormGrid>
  );
};
