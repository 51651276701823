import React from 'react';
import { CouponDetailCommonChildren1 } from '../CouponDetailCommonChildren1';
import { CouponDetailCommonChildren2 } from '../CouponDetailCommonChildren2';
import { CouponDetailCommonChildren3 } from '../CouponDetailCommonChildren3';
import { CouponDetailCommonChildren4 } from '../CouponDetailCommonChildren4';

type Props = {
  k: number;
};

export const CouponDetailCampaignOrGet: React.FC<Props> = ({ k }) => {
  return (
    <>
      <CouponDetailCommonChildren1 k={k} />

      <CouponDetailCommonChildren2 k={k} />

      <CouponDetailCommonChildren3 k={k} />

      <CouponDetailCommonChildren4 k={k} />
    </>
  );
};
