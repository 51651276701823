import React from 'react';
import style from './style.module.scss';

const Align = {
  right: 'right',
  center: 'center',
  left: 'left',
} as const;
type AlignProps = typeof Align[keyof typeof Align];

const Color = {
  black: 'black',
  grey: 'grey',
  blue: 'blue',
  red: 'red',
} as const;
type ColorProps = typeof Color[keyof typeof Color];

type Props = {
  value: string;
  align?: AlignProps;
  color?: ColorProps;
};

export const MultiLineText: React.FC<Props> = ({ value, align = 'left', color = 'black' }) =>
  React.useMemo(
    () => (
      <p className={`${style['typography-multilineText']} is-align-${align} is-${color}`}>
        {value.split('\n').map((str, index, array) => {
          return index === array.length - 1 ? (
            str
          ) : (
            <React.Fragment key={index}>
              {str}
              <br />
            </React.Fragment>
          );
        })}
      </p>
    ),
    [value, align, color],
  );
