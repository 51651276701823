import React from 'react';
import { Main, ButtonContainer } from 'components/Atoms/Layout';
import { Hr } from 'components/Atoms/Hr';
import { PanelButton } from 'components/Atoms/Button';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import style from './style.module.scss';

export const CMN001: React.FC = () => {
  return (
    <>
      <Header />
      <Main>
        <div className={style['wrapper']}>
          <div>
            <div className={style['grid']}>
              <div className={style['grid-item-left']}>
                <PanelButton type="primary" size="large" icon="note" to="/CPF001" text="施策情報の新規登録" />
              </div>
              <div className={style['grid-item-right-top']}>
                <PanelButton type="primary" icon="clipboard" to="/CPF007" text="施策情報の検索・更新" />
              </div>
              <div className={style['grid-item-right-bottom']}>
                <PanelButton type="primary" icon="lipstick" to="/PRF001" text="販促品の確認" />
              </div>
            </div>

            <Hr mt={40} />

            <ButtonContainer>
              <PanelButton type="secondary" icon="palette" to="/AID001" text="AI Designer" />
            </ButtonContainer>
          </div>
        </div>
      </Main>
      <Footer type="top" hasPageTop={false} />
    </>
  );
};
