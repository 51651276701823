import React from 'react';
import { useHistory } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsconfig from 'aws-exports';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, ButtonContainer } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { FormTextInput } from 'components/Business/Form';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { useApiCall } from 'hooks/useApiCall';
import { RHF_SETTINGS, ENDPOINT_ADDUSER_API } from 'constants/index';
import { NetworkErrorUpdate } from 'context';
import { scrollToFirstError } from 'utils';
import { useValidationSchema } from './useValidationSchema';
import style from './style.module.scss';

Amplify.configure(awsconfig);

export type AddUserStateProps = {
  isSubmitted: boolean;
};

export const CMN007: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const apiCall = useApiCall();
  const setNetworkError = React.useContext(NetworkErrorUpdate);
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, handleSubmit, setError } = useFormMethods;
  const [isLoading, setLoading] = React.useState(false);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    const data = {
      username: formValues.username || '',
      email: formValues.email || '',
    };
    return data;
  }, [getValues]);

  const signUp = React.useCallback(async () => {
    setLoading(true);
    const data = getRequestData();
    apiCall({
      method: 'POST',
      url: ENDPOINT_ADDUSER_API,
      data: {
        userName: data.username,
        email: data.email,
      },
    })
      .then((res) => {
        if (res.status !== 200) return;
        const state: AddUserStateProps = { isSubmitted: true };
        history.push('/CMN008', state);
      })
      .catch((error) => {
        if (error.response.status === 400 && error.response.data.error.code === 'ERR02001') {
          setError('username', {
            type: 'custom',
            message: t('ERR-DUPLICATE-USERNAME'),
          });
        } else {
          setNetworkError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getRequestData, setError, t, history, setNetworkError, apiCall]);

  const onSuccess = React.useCallback(() => {
    signUp();
  }, [signUp]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />
          <Container type="login">
            <Section>
              <H1>ユーザー登録</H1>
              <Text align="center">ユーザー名とメールアドレスを登録することでユーザー登録ができます。</Text>

              <FormTextInput
                name="username"
                label="ユーザー名"
                notes={[`※${t('MSG-USERNAME-BACKLOG')}`, '※英字を使用する場合は小文字を入力してください。例：acn、imj']}
                size="medium"
                inputSize="large"
                formFieldClassName={style['formField']}
              />

              <FormTextInput
                type="email"
                name="email"
                label="メールアドレス"
                note="※@shiseido.com、@shiseidointeractive.com、@accenture.com、@imjp.co.jpのいずれかのメールアドレスを入力してください。"
                size="medium"
                inputSize="large"
                formFieldClassName={style['formField']}
              />

              <ButtonContainer>
                <Button type="primary" text="登録" onClick={() => handleSubmit(onSuccess, onError)()} />
              </ButtonContainer>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
