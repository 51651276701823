import { isMatchDayOfWeek } from 'utils';

type Props = {
  year?: string;
  month?: string;
  date?: string;
};

export const isDayOfWeek = ({ year, month, date }: Props) => {
  if (!year && !month && !date) return true;
  return isMatchDayOfWeek({
    year,
    month,
    date,
    days: [1, 2, 3, 4, 5],
  });
};
