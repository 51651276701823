import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Main, Container, Section, Flex, FlexRow, FlexCol, Box, ButtonContainer, FormGrid } from 'components/Atoms/Layout';
import { H1, Text } from 'components/Atoms/Typography';
import { Button, DownloadButton } from 'components/Atoms/Button';
import { Loading } from 'components/Atoms/Loading';
import { CampaignStatusTag } from 'components/Molecules/Tag';
import { SortList } from 'components/Molecules/SortList';
import { Header } from 'components/Business/Header';
import { Footer } from 'components/Organisms/Footer';
import { Breadcrumb } from 'components/Organisms/Breadcrumb';
import { FormRadioList, FormTextInput, FormDatePeriod } from 'components/Business/Form';
import { ApiSelectBrand, ApiSelectUser } from 'components/Business/Api';
import { TextLink } from 'components/Business/TextLink';
import { RHFDevTools } from 'components/Business/RHFDevTools';
import { Pager } from 'components/Molecules/Pager';
import {
  RHF_SETTINGS
  ,MEASURESSELECTKEYWORD_VALUES
  ,ENDPOINT_GET_MEASURE_API
  ,ENDPOINT_GET_MEASURE_DOWNLOAD_API
} from 'constants/index';
import {
  objectToRadioList
  ,smoothScroll
  ,scrollToFirstError
  ,formatDate
} from 'utils';
import { useApiCall } from 'hooks/useApiCall';
import { useValidationSchema } from './useValidationSchema';
import type { MeasuresListProps, DirectionProps } from 'types';
import type { MeasureCopyStateProps } from 'types';

export const CPF007: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const apiCall = useApiCall();
  RHF_SETTINGS.resolver = yupResolver(useValidationSchema());
  const useFormMethods = useForm(RHF_SETTINGS);
  const { getValues, trigger, handleSubmit } = useFormMethods;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = React.useState('10');
  const [offset, setOffset] = React.useState('0');
  const [direction, setDirection] = React.useState<DirectionProps>('DESC');
  const [response, setResponse] = React.useState<MeasuresListProps[]>([]);
  const [searchFlag, setSearchFlag] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [measureItemCount, setMeasureItemCount] = React.useState(0);
  const [previousFormValues, setPreviousFormValues] = React.useState<{ [key: string]: any }>();
  const [maxDownloads, setMaxDownloads] = React.useState<number>(999);

  const getRequestData = React.useCallback((): { [key: string]: any } => {
    const formValues = getValues();
    return {
      selectKeyWord: formValues.selectKeyWord || '',
      keyWord: formValues.keyWord || '',
      brandCode: formValues.brandCode || '',
      inCharge: formValues.inCharge || '',
      measuresDateStartFromYear: formValues.measuresDateStartFromYear || '',
      measuresDateStartFromMonth: formValues.measuresDateStartFromMonth || '',
      measuresDateStartFromDay: formValues.measuresDateStartFromDay || '',
      measuresDateStartToYear: formValues.measuresDateStartToYear || '',
      measuresDateStartToMonth: formValues.measuresDateStartToMonth || '',
      measuresDateStartToDay: formValues.measuresDateStartToDay || '',
      measuresDateEndFromYear: formValues.measuresDateEndFromYear || '',
      measuresDateEndFromMonth: formValues.measuresDateEndFromMonth || '',
      measuresDateEndFromDay: formValues.measuresDateEndFromDay || '',
      measuresDateEndToYear: formValues.measuresDateEndToYear || '',
      measuresDateEndToMonth: formValues.measuresDateEndToMonth || '',
      measuresDateEndToDay: formValues.measuresDateEndToDay || '',
      limit: limit,
      offset: offset,
      direction: direction,
    };
  }, [limit, offset, direction, getValues]);

  const handleSearch = React.useCallback(
    async ({ scrollOnComplete = false }) => {
      const data = getRequestData();

      const previousValues = {...data};
      delete previousValues.limit;
      delete previousValues.offset;
      setPreviousFormValues({...previousValues});

      setIsLoading(true);
      apiCall({
        method: 'GET',
        url: ENDPOINT_GET_MEASURE_API,
        data,
      })
      .then((res: { status: number; data: { result: { measuresMap: MeasuresListProps[]; count: string; max: string; } } }) => {
        if (res.status === 200) {
          const measuresMap: MeasuresListProps[] = res.data.result.measuresMap;
          setResponse(measuresMap);
          setMeasureItemCount(Number(res.data.result.count));
          setPageCount(Math.ceil(Number(res.data.result.count) / Number(limit)));
          setSearchFlag(true);
          setMaxDownloads(Number(res.data.result.max));
        }
      })
      .finally(() => {
        setIsLoading(false);
        if (scrollOnComplete) {
          smoothScroll({
            to: 'result',
            easing: 'easeInOutCirc',
            offset: -50,
          });
        }
      });
    },
    [limit, getRequestData, apiCall, setMeasureItemCount],
  );

  const onSuccess = React.useCallback(() => {
    handleSearch({ scrollOnComplete: true });
  }, [handleSearch]);

  const onError = React.useCallback(() => {
    scrollToFirstError();
  }, []);

  const handlePageChange = React.useCallback(
    (selected: number) => {
      setOffset(String(Number(limit) * selected));
      setPageNumber(selected);
    },
    [limit],
  );

  const handleClickSubmit = React.useCallback(() => {
    if (offset === '0') {
      handleSubmit(onSuccess, onError)();
      return;
    }
    handlePageChange(0);
  }, [handleSubmit, offset, onError, onSuccess, handlePageChange]);

  const handleClickCopy = React.useCallback(
    (measureId) => {
      const state: MeasureCopyStateProps = { originalMeasureId: measureId };
      history.push('/CPF001', state);
    },
    [history],
  );

  React.useEffect(() => {
    if (searchFlag) {
      if (offset === '0') {
        handleSubmit(onSuccess, onError)();
      } else {
        handlePageChange(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  React.useEffect(() => {
    if (searchFlag) handleSubmit(onSuccess, onError)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  React.useEffect(() => {
    handleSearch({ scrollOnComplete: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <Main>
        <FormProvider {...useFormMethods}>
          <Loading isLoading={isLoading} />

          <Container>
            <Breadcrumb
              list={[
                {
                  label: 'TOP',
                  url: '/CMN001',
                },
                {
                  label: '登録済み施策一覧',
                },
              ]}
            />

            <Section>
              <H1>登録済み施策一覧</H1>
              <Text align="center">
                登録されている施策情報を確認できます。
                <br />
                詳細な情報を見るには「詳細を見る」をクリックしてください。
                <br />
                必要な項目を入力すると、絞り込みが可能です。
              </Text>

              <Section hasBg>
                <Box color="grey">
                  <FormRadioList
                    name="selectKeyWord"
                    label="キーワード"
                    defaultValue="1"
                    isHorizontal
                    radioSize="small"
                    size="small"
                    list={objectToRadioList(MEASURESSELECTKEYWORD_VALUES)}
                    onChange={() => {
                      trigger('keyWord');
                    }}
                  />

                  <FormTextInput name="keyWord" label="" placeholder="キーワードを入力" note="※キーワードは一部の入力でも絞り込みが可能です。" size="small" />

                  <FormGrid size="small">
                    <FlexRow>
                      <FlexCol col={6}>
                        <ApiSelectBrand size="small" inputSize="small" />
                      </FlexCol>
                      <FlexCol col={6}>
                        <ApiSelectUser label="施策担当者名" size="small" inputSize="small" />
                      </FlexCol>
                    </FlexRow>
                  </FormGrid>

                  <FormDatePeriod
                    names={{
                      start: {
                        year: 'measuresDateStartFromYear',
                        month: 'measuresDateStartFromMonth',
                        date: 'measuresDateStartFromDay',
                      },
                      end: {
                        year: 'measuresDateStartToYear',
                        month: 'measuresDateStartToMonth',
                        date: 'measuresDateStartToDay',
                      },
                    }}
                    label="施策開始日"
                    size="small"
                  />

                  <FormDatePeriod
                    names={{
                      start: {
                        year: 'measuresDateEndFromYear',
                        month: 'measuresDateEndFromMonth',
                        date: 'measuresDateEndFromDay',
                      },
                      end: {
                        year: 'measuresDateEndToYear',
                        month: 'measuresDateEndToMonth',
                        date: 'measuresDateEndToDay',
                      },
                    }}
                    label="施策終了日"
                    size="small"
                  />

                  <ButtonContainer>
                    <Button type="secondary" text="絞り込む" onClick={handleClickSubmit} />
                  </ButtonContainer>
                </Box>

                <Flex justifyContent='end' mt={40}>
                  <Text size={24} bold mr={5}>{measureItemCount}</Text>
                  <FlexRow alignItems="center" mt={-2}>
                    <FlexCol>
                      <Text size={20} mr={-20}>件</Text>
                    </FlexCol>
                    <FlexCol>
                      <DownloadButton
                        title="施策情報"
                        formData={previousFormValues}
                        url={ENDPOINT_GET_MEASURE_DOWNLOAD_API}
                        maxDownloads={maxDownloads}
                        itemCount={measureItemCount}
                        contentType='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        filename="施策情報一覧.xlsx"
                      />
                    </FlexCol>
                  </FlexRow>
                </Flex>

                {response.length > 0 && (
                  <SortList
                    list={[
                      {
                        text: '施策開始日の新しい順',
                        onClick: () => {
                          setDirection('DESC');
                        },
                        current: direction === 'DESC',
                      },
                      {
                        text: '施策開始日の古い順',
                        onClick: () => {
                          setDirection('ASC');
                        },
                        current: direction === 'ASC',
                      },
                    ]}
                  />
                )}

                <Box id="result" mt={10}>
                  {response.length === 0 && searchFlag && <Text mt={40}>{t('MSG-ZERO-RESULT')}</Text>}

                  {response.map((elem: MeasuresListProps, index: number) => (
                    <Box key={index} color="white" mt={10}>
                      <Flex justifyContent="space-between" alignItems="start">
                        <Text size={18} bold mr={20}>
                          {elem.measuresName || '-'}
                        </Text>
                        <FlexRow alignItems="center" mt={-2} separator={true}>
                          <FlexCol>
                            <TextLink to={`/CPF008/${elem.measuresId}`} icon="arrow-right" iconPosition="after" nowrap>
                              詳細を見る
                            </TextLink>
                          </FlexCol>
                          <FlexCol>
                            <Button type="secondary" size="small" text="複製する" onClick={() => handleClickCopy(elem.measuresId)} />
                          </FlexCol>
                        </FlexRow>
                      </Flex>

                      <FlexRow alignItems="center" mt={15} separator={true}>
                        <FlexCol>
                          <Flex alignItems="center">
                            <Text size={14} color="grey" mt={0}>
                              施策担当者名
                            </Text>
                            <Text mt={0} ml={20}>
                              {elem.inCharge || '-'}
                            </Text>
                          </Flex>
                        </FlexCol>
                        <FlexCol>
                          <Flex alignItems="center">
                            <Text size={14} color="grey" mt={0}>
                              最終更新日時
                            </Text>
                            <Text mt={0} ml={20}>
                              {formatDate(elem.updateDate)}
                            </Text>
                          </Flex>
                        </FlexCol>
                      </FlexRow>

                      <Text size={14} color="grey" mt={10}>
                        入力状況
                      </Text>

                      <FlexRow mt={10} gap="small">
                        <FlexCol col={6}>
                          <CampaignStatusTag status={elem.measuresStatus} />
                          <TextLink to={`/CPF001/${elem.measuresId}`} ml={20}>
                            施策概要・依頼内容
                          </TextLink>
                        </FlexCol>
                        <FlexCol col={6}>
                          <CampaignStatusTag status={elem.webStatus} />
                          <TextLink to={`/CPF002/${elem.measuresId}`} ml={20}>
                            Web制作
                          </TextLink>
                        </FlexCol>
                      </FlexRow>
                      <FlexRow mt={10} gap="small">
                        <FlexCol col={6}>
                          <CampaignStatusTag status={elem.campaignStatus} />
                          <TextLink to={`/CPF003/${elem.measuresId}`} ml={20}>
                            キャンペーン設定・クーポン発行
                          </TextLink>
                        </FlexCol>
                        <FlexCol col={6}>
                          <CampaignStatusTag status={elem.sampleStatus} />
                          <TextLink to={`/CPF004/${elem.measuresId}`} ml={20}>
                            サンプル・モニター設定
                          </TextLink>
                        </FlexCol>
                      </FlexRow>
                    </Box>
                  ))}
                </Box>

                <Pager pageCount={pageCount} pageNumber={pageNumber} onPageChange={handlePageChange} />
              </Section>
            </Section>
          </Container>
          <RHFDevTools />
        </FormProvider>
      </Main>
      <Footer />
    </>
  );
};
