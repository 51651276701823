import React from 'react';
import { ApiSelectMultipleBrand, ApiSelectMultipleItem } from 'components/Business/Api';
import { FormTextInput, FormTextbox, FormRadioList, FormRadioAccordion, FormArray } from 'components/Business/Form';
import {
  APPLICATIONSCONDITIONS_VALUES,
  SKUSPECIFIEDCONDITION_VALUES,
  POOLSPECIFIEDCONDITION_VALUES,
  PURCHSESPECIFIEDCONDITION_VALUES,
  PURCHASESPECIFIEDCONDITIONSITEM_VALUES,
  MEMBERLOWERRANK_VALUES,
  MEMBERHIGHERRANK_VALUES,
  INFLOWROUTECOUPON_VALUES,
  URLLINECONNECT_VALUES,
} from 'constants/index';
import { objectToRadioList } from 'utils';

type Props = {
  k: number;
  selectable?: string[];
  defaultValue?: string;
};

export const ApplicableCondition: React.FC<Props> = ({ k, selectable, defaultValue }) => {
  const SelectBrandComponent: React.FC = () => {
    return (
      <ApiSelectMultipleBrand
        name={`campaignDetail[${k}].purchaseSelectedBrandNum`}
        valueName="purchaseSelectedBrands"
        labelName="purchaseSelectedBrandsName"
        isRequired
        hasUndecidedButton
      />
    );
  };

  const SelectItemComponent: React.FC = () => {
    return (
      <>
        <FormRadioList
          name={`campaignDetail[${k}].purchaseSpecifiedConditionsItem`}
          label="指定アイテム条件"
          isRequired
          hasUndecidedButton
          list={objectToRadioList(PURCHASESPECIFIEDCONDITIONSITEM_VALUES)}
        />

        <ApiSelectMultipleItem
          name={`campaignDetail[${k}].purchaseSelectedItemNum`}
          valueName="purchaseSelectedItem"
          labelName="purchaseSelectedItemName"
          isRequired
          hasUndecidedButton
        />
      </>
    );
  };

  return (
    <>
      <FormRadioAccordion
        name={`campaignDetail[${k}].applicationsConditions`}
        label="適用条件"
        isRequired
        hasUndecidedButton
        {...(defaultValue && { defaultValue: defaultValue })}
        list={objectToRadioList(APPLICATIONSCONDITIONS_VALUES, selectable ? selectable : undefined)}
        contents={{
          '1': (
            <>
              <FormRadioAccordion
                name={`campaignDetail[${k}].skuSpecifiedCondition`}
                label="指定条件"
                isRequired
                hasUndecidedButton
                list={objectToRadioList(SKUSPECIFIEDCONDITION_VALUES)}
                contents={{
                  '1': (
                    <FormTextInput
                      name={`campaignDetail[${k}].skuPurchase`}
                      type="number"
                      label="指定商品に対してCP適用する購入個数"
                      placeholder="5"
                      unit="個"
                      note="※1～99までの数を入力してください。"
                      maxLength={2}
                      isRequired
                      hasUndecidedButton
                    />
                  ),
                  '2': (
                    <FormTextInput
                      name={`campaignDetail[${k}].skuPurchase`}
                      type="number"
                      label="指定商品に対してCP適用する購入個数"
                      placeholder="5"
                      unit="個"
                      note="※1～99までの数を入力してください。"
                      maxLength={2}
                      isRequired
                      hasUndecidedButton
                    />
                  ),
                }}
              />

              <FormArray
                name={`campaignDetail[${k}].skuMeasuresNum`}
                label="施策対象SKU数"
                isRequired
                hasUndecidedButton
                contentLabel={(key: number) => `${key + 1}:施策対象SKU`}
                content={(k2: number) => {
                  return (
                    <FormTextInput
                      key={k2}
                      type="number"
                      name={`campaignDetail[${k}].skuMeasuresNum[${k2}].skuMeasures`}
                      label={`${k2 + 1}:施策対象SKU`}
                      placeholder="「4514254977767」等を入力"
                      isRequired
                      hasUndecidedButton
                    />
                  );
                }}
              />
            </>
          ),
          '2': (
            <>
              <FormRadioAccordion
                name={`campaignDetail[${k}].poolSpecifiedCondition`}
                label="指定条件"
                isRequired
                hasUndecidedButton
                list={objectToRadioList(POOLSPECIFIEDCONDITION_VALUES)}
                contents={{
                  '1': (
                    <FormTextInput
                      name={`campaignDetail[${k}].poolPurchase`}
                      type="number"
                      label="指定商品に対してCP適用する購入個数"
                      placeholder="5"
                      unit="個"
                      note="※1～99までの数を入力してください。"
                      maxLength={2}
                      isRequired
                      hasUndecidedButton
                    />
                  ),
                  '2': (
                    <FormTextInput
                      name={`campaignDetail[${k}].poolPurchase`}
                      type="number"
                      label="指定商品に対してCP適用する購入個数"
                      placeholder="5"
                      unit="個"
                      note="※1～99までの数を入力してください。"
                      maxLength={2}
                      isRequired
                      hasUndecidedButton
                    />
                  ),
                }}
              />

              <FormArray
                name={`campaignDetail[${k}].poolMeasuresNum`}
                label="施策対象プール中数"
                isRequired
                hasUndecidedButton
                contentLabel={(key: number) => `${key + 1}:施策対象プール中`}
                content={(k2: number) => {
                  return (
                    <FormTextInput
                      key={k2}
                      type="alphaNumeric"
                      name={`campaignDetail[${k}].poolMeasuresNum[${k2}].poolMeasures`}
                      label={`${k2 + 1}:施策対象プール中`}
                      placeholder="「E10100」等を入力"
                      isRequired
                      hasUndecidedButton
                    />
                  );
                }}
              />
            </>
          ),
          '3': (
            <>
              <FormRadioAccordion
                name={`campaignDetail[${k}].purchaseSpecifiedConditions`}
                label="指定条件"
                isRequired
                hasUndecidedButton
                list={objectToRadioList(PURCHSESPECIFIEDCONDITION_VALUES)}
                note="※[複数ブランド×複数アイテム]は指定できません。"
                contents={{
                  '1': <SelectBrandComponent />,
                  '2': <SelectItemComponent />,
                  '3': (
                    <>
                      <SelectBrandComponent />
                      <SelectItemComponent />
                    </>
                  ),
                }}
              />
            </>
          ),
          '4': (
            <>
              <FormRadioList
                name={`campaignDetail[${k}].memberLowerRank`}
                label="会員ランク（下限）"
                isRequired
                hasUndecidedButton
                list={objectToRadioList(MEMBERLOWERRANK_VALUES)}
              />

              <FormRadioList
                name={`campaignDetail[${k}].memberHigherRank`}
                label="会員ランク（上限）"
                isRequired
                hasUndecidedButton
                list={objectToRadioList(MEMBERHIGHERRANK_VALUES)}
              />
            </>
          ),
          '5': (
            <>
              <FormArray
                name={`campaignDetail[${k}].inflowRouteCodeNum`}
                label="指定媒体コード数"
                isRequired
                hasUndecidedButton
                contentLabel={(key: number) => `${key + 1}:指定媒体コード`}
                content={(k2: number) => {
                  return (
                    <FormTextInput
                      key={k2}
                      name={`campaignDetail[${k}].inflowRouteCodeNum[${k2}].inflowRouteCode`}
                      label={`${k2 + 1}:指定媒体コード`}
                      placeholder="媒体コードを入力"
                      isRequired
                      hasUndecidedButton
                    />
                  );
                }}
              />

              <FormTextInput
                type="number"
                name={`campaignDetail[${k}].inflowRouteExpirationDate`}
                label="特定媒体流入後の有効期間の日数"
                placeholder="10"
                unit="日"
              />

              <FormTextInput
                type="number"
                name={`campaignDetail[${k}].inflowRouteApplicable`}
                label="新規会員登録からの適用日数指定"
                placeholder="10"
                unit="日"
              />

              <FormTextInput type="number" name={`campaignDetail[${k}].inflowRouteAmount`} label="購入金額（税抜）" placeholder="1000" unit="円" />

              <FormRadioList
                name={`campaignDetail[${k}].inflowRouteCoupon`}
                label="注文開始時のクーポン付与"
                list={objectToRadioList(INFLOWROUTECOUPON_VALUES)}
              />
            </>
          ),
          '6': (
            <>
              <FormTextInput type="number" name={`campaignDetail[${k}].urlApplicableDays`} label="新規会員登録からの適用日数指定" placeholder="10" unit="日" />

              <FormRadioList name={`campaignDetail[${k}].urlLineConnect`} label="LINEコネクトとの設定" list={objectToRadioList(URLLINECONNECT_VALUES)} />

              <FormTextbox
                type="url"
                name={`campaignDetail[${k}].urlPcTag`}
                label="PCタグ／Getクーポンの場合はクーポンGet後の遷移先URL"
                placeholder="タグを入力"
              />

              <FormTextbox
                type="url"
                name={`campaignDetail[${k}].urlSmartphoneTag`}
                label="SPタグ／Getクーポンの場合はクーポンGet後の遷移先URL"
                placeholder="タグを入力"
              />
            </>
          ),
        }}
      />
    </>
  );
};
