export const numberToDayString = (num: number): string => {
  let dayString = '';
  switch (num) {
    case 0:
      dayString = 'sun';
      break;
    case 1:
      dayString = 'mon';
      break;
    case 2:
      dayString = 'tue';
      break;
    case 3:
      dayString = 'wed';
      break;
    case 4:
      dayString = 'thu';
      break;
    case 5:
      dayString = 'fri';
      break;
    case 6:
      dayString = 'sat';
      break;
  }
  return dayString;
};
