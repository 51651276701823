import dayjs from 'dayjs';

type DateProps = {
  year?: string;
  month?: string;
  date?: string;
};

type Props = {
  date1: DateProps;
  date2: DateProps;
};

export const isAnotherDate = ({ date1, date2 }: Props) => {
  if ((!date1.year && !date1.month && !date1.date) || (!date2.year && !date2.month && !date2.date)) return true;

  const dateObject1 = dayjs()
    .year(Number(date1.year))
    .month(Number(date1.month) - 1)
    .date(Number(date1.date))
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  const dateObject2 = dayjs()
    .year(Number(date2.year))
    .month(Number(date2.month) - 1)
    .date(Number(date2.date))
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  if (dateObject1.isSame(dateObject2)) return false;
  return true;
};
