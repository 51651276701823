import React from 'react';
import style from './style.module.scss';

type Props = {
  mt?: number;
  mb?: number;
};

export const Hr: React.FC<Props> = ({ mt, mb }) =>
  React.useMemo(
    () => <hr className={style['hr']} style={{ ...(mt !== undefined && { marginTop: `${mt}px` }), ...(mb !== undefined && { marginBottom: `${mb}px` }) }} />,
    [mb, mt],
  );
