import React from 'react';
import { Flex } from 'components/Atoms/Layout';
import { BorderList } from 'components/Atoms/List';
import { Text } from 'components/Atoms/Typography';
import { DesignDetailProps } from 'types';

type Props = {
  data?: DesignDetailProps;
};

export const ProductionContentsList: React.FC<Props> = ({ data }) => {
  const [typeList, setTypeList] = React.useState<{ value: string; count: number }[]>();

  React.useEffect(() => {
    if (!data) return;
    let typeList: { value: string; count: number }[] = [];
    data?.moduleAssignments?.forEach((elem) => {
      const target = typeList.find((obj) => obj.value === elem.productType);
      if (typeList.length > 0 && target) {
        target.count += 1;
      } else {
        typeList.push({ value: elem.productType, count: 1 });
      }
    });
    setTypeList(typeList);
  }, [data]);

  return React.useMemo(
    () => (
      <BorderList split={4} mt={20}>
        {typeList?.map((elem, index) => (
          <div key={index}>
            <Flex justifyContent="space-between" alignItems="center" height="100%">
              <Text size={14}>{elem.value}</Text>
              <Text mt={0} ml={10} nowrap>
                {elem.count}点
              </Text>
            </Flex>
          </div>
        ))}
      </BorderList>
    ),
    [typeList],
  );
};
