import React from 'react';
import dayjs from 'dayjs';
import { HolidaysState } from 'context';
import type { HolidayProps } from 'types';

type Props = {
  year?: string;
  month?: string;
  date?: string;
};

export const useIsNotHoliday = () => {
  const holidays: HolidayProps = React.useContext(HolidaysState);

  return ({ year, month, date }: Props) => {
    if (!year && !month && !date) return true;
    const dayjsObject = dayjs()
      .year(Number(year))
      .month(Number(month) - 1)
      .date(Number(date))
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    if (!dayjsObject.isValid()) return false;

    return !holidays.some((holiday) => {
      const holidayDateObject = dayjs(holiday);
      return dayjsObject.isSame(holidayDateObject, 'day');
    });
  };
};
