import React from 'react';
import style from './style.module.scss';

const AlignItems = {
  start: 'start',
  center: 'center',
  end: 'end',
} as const;
export type FlexAlignItemsProps = typeof AlignItems[keyof typeof AlignItems];

const JustifyContent = {
  center: 'center',
  start: 'start',
  end: 'end',
  'space-between': 'space-between',
  'space-around': 'space-around',
  'space-evenly': 'space-evenly',
  stretch: 'stretch',
  normal: 'normal',
} as const;
export type FlexJustifyContentProps = typeof JustifyContent[keyof typeof JustifyContent];

type Props = {
  alignItems?: FlexAlignItemsProps;
  justifyContent?: FlexJustifyContentProps;
  className?: string;
  mt?: number;
  height?: string;
};

export const Flex: React.FC<Props> = ({ children, alignItems = 'normal', justifyContent = 'normal', className = '', mt, height }) => (
  <div
    className={`${style['layout-flex']} is-alignitems-${alignItems} is-justifycontent-${justifyContent} ${className}`}
    style={{ ...(mt !== undefined && { marginTop: `${mt}px` }), ...(height !== undefined && { height: height }) }}
  >
    {children}
  </div>
);
