import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    username: yup.string().required(t('ERR-REQUIRED')),

    email: yup
      .string()
      .required(t('ERR-REQUIRED'))
      .email(t('ERR-FORMAT-EMAIL'))
      .matches(/(@shiseido.com|@shiseidointeractive.com|@accenture.com|@imjp.co.jp)$/, t('MSG-LIMITED-ADDRESS')),
  });
};
