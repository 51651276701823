import React from 'react';
import Amplify from 'aws-amplify';
import axios from 'axios';
import {
  // CognitoUserState,
  NetworkErrorUpdate,
} from 'context';
import {
  ENDPOINT_GET_MEASURE_DOWNLOAD_API
  ,ENDPOINT_GET_PROMOTION_DOWNLOAD_API
  ,ENDPOINT_POST_DELIVERY_ITEM_UPLOAD_API
} from 'constants/index';
import type { AxiosRequestConfig } from 'axios/index.d';

type Props = {
  method: AxiosRequestConfig['method'];
  url: AxiosRequestConfig['url'];
  data?: AxiosRequestConfig['data'];
};

export const useApiCall = () => {
  // const userInfo = React.useContext(CognitoUserState);
  const setNetworkError: React.Dispatch<React.SetStateAction<boolean>> = React.useContext(NetworkErrorUpdate);
  // axios.defaults.headers.common['Authorization'] = userInfo?.signInUserSession.idToken.jwtToken;

  const updateSession = async () => {
    const session = await Amplify.Auth.currentSession();
    return session.idToken.jwtToken;
  };

  const onSuccess = (response: any) => response;

  const onError = (error: any) => {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
      if (error.response.status === 500) {
        if (![ENDPOINT_POST_DELIVERY_ITEM_UPLOAD_API].includes(error.config.url)){
          setNetworkError(true);
        }
      }
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      console.log(error.request);

      // ダウンロード処理に限り、共通の通信エラーではなくPages側で定義したセッションタイムアウトの通知メッセージを表示する
      if (![ENDPOINT_GET_MEASURE_DOWNLOAD_API, ENDPOINT_GET_PROMOTION_DOWNLOAD_API].includes(error.config.url)) {
        setNetworkError(true);
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    return Promise.reject(error);
  };

  axios.interceptors.response.use(onSuccess, onError);

  return async ({ method, url, data }: Props) => {
    const idToken = await updateSession();

    if (method === 'GET' && data) {
      return await axios({
        headers: {
          Authorization: idToken,
        },
        method: method,
        url: url,
        params: data,
      });
    } else {
      return await axios({
        headers: {
          Authorization: idToken,
        },
        method: method,
        url: url,

        ...(data && { data: data }),
      });
    }
  };
};
